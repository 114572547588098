import * as React from "react";
import { Outlet, useParams } from "react-router-dom";
import Paper from "@mui/material/Paper";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import ControlsIcon from "@mui/icons-material/Tune";
import SettingsIcon from "@mui/icons-material/Settings";
import SensorsIcon from "@mui/icons-material/Sensors";
import ProgramsIcon from "@mui/icons-material/AutoMode";
import MuiNavLink from "../../components/Navigation/MuiNavLink";
import Box from "@mui/material/Box";
import ExtendedUnitDataProvider from "../../context/ExtendedUnitDataCtx/ExtendedUnitDataProvider";
import TopNav from "../../components/Navigation/TopNav";
import { useContext } from "react";
import BackgroundDataCtx from "../../context/BackgroundDataCtx/BackgroundDataCtx";
import UnitNav from "../../components/Navigation/UnitNav";

const UnitLayout: React.FC = () => {
  const { unitId } = useParams();
  if (!unitId) return <>Unit not recognised</>;
  const bgdata = useContext(BackgroundDataCtx);
  const unit = bgdata.units[unitId];

  return (
    <Box
      sx={{
        height: "100vh",
        overflow: "hidden",
      }}
    >
      <TopNav
        UnitNav={
          <UnitNav
            currentUnit={unit}
            units={Object.values(bgdata.filteredUnits)}
          />
        }
      />
      <Box
        sx={{
          height: {
            xs: "calc(100% - 56px - 56px)",
            sm: "calc(100% - 64px - 56px)",
          },
          mt: {
            xs: "56px",
            sm: "64px",
          },
          mb: "56px",
          overflow: "hidden",
        }}
      >
        <ExtendedUnitDataProvider unitId={unitId}>
          <Outlet />
        </ExtendedUnitDataProvider>
      </Box>
      <Paper
        sx={{ position: "fixed", bottom: 0, left: 0, right: 0, zIndex: 10 }}
        elevation={3}
      >
        <BottomNavigation showLabels>
          <BottomNavigationAction
            label="Controls"
            icon={<ControlsIcon />}
            component={MuiNavLink}
            to="controls"
          />
          <BottomNavigationAction
            label="Settings"
            icon={<SettingsIcon />}
            component={MuiNavLink}
            to="settings"
          />
          <BottomNavigationAction
            label="Sensors"
            icon={<SensorsIcon />}
            component={MuiNavLink}
            to="sensors"
          />
          <BottomNavigationAction
            label="Programs"
            icon={<ProgramsIcon />}
            component={MuiNavLink}
            to="programs"
          />
        </BottomNavigation>
      </Paper>
    </Box>
  );
};

export default UnitLayout;
