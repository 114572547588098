import { Feature } from "geojson";
import IUnitData from "../../context/BackgroundDataCtx/IUnitData";
import { feetToMeters } from "../../helpers/Conversions";
import { getCoordinatesOnCircleFromAngle, getSecondaryCoordinates, getRectPoints, getLateralRectPointsForUnit, Segment, simplifySegments } from "./MapHelper";
import { circle, point, bearing, destination, lineString, along } from "@turf/turf";

export const sectorFillColors = [ "#278ECF", "#4BD762", "#FFCA1F", "#FF9416", "#D42AE8", "#535AD7", "#FF402C", "#83BFFF", "#6EDB8F", "#FFE366", "#FFC266",
                                "#D284BD", "#8784DB", "#FF7B65", "#CAEEFC", "#9ADBAD", "#FFF1B2", "#FFE0B2", "#FFBEB2", "#B1AFDB", "#278ECF", "#4BD762", 
                                "#FFCA1F","#FF9416", "#D42AE8",];

export const buildProgramFeatures = (unit: IUnitData): Feature[] => {
    var programFeatures: Feature[] = [];

    if (unit.runningProgram) {
        if ( unit.runningProgram.vriSectors && unit.runningProgram.vriSectors.length > 0 ) {
            buildSectorProgram(unit).forEach((sectorFeature) => programFeatures.push(sectorFeature));
        }
        if ( unit.runningProgram.endGunSectors && unit.runningProgram.endGunSectors.length > 0) {
            buildEndGunAuxProgram(unit).forEach((feature) => programFeatures.push(feature) );
        }
        if ( unit.runningProgram.auxSectors && unit.runningProgram.auxSectors.length > 0) {
            buildEndGunAuxProgram(unit, true).forEach((feature) => programFeatures.push(feature) );
        }
        if (unit.runningProgram.barricade) {
            buildBarrierProgram(unit).forEach((f) => programFeatures.push(f));
        }
    }

    return programFeatures;
};

const buildEndGunAuxProgram = (unit: IUnitData, isAux?: boolean): Feature[] => {
    var segmentFeatures: Feature[] = [];
    var barrierProgram = unit.runningProgram!.barricade ? [unit.runningProgram!.barricade.forward.position, unit.runningProgram!.barricade.reverse.position] : null;
    var endguns: Segment[] = isAux ? unit.runningProgram!.auxSectors.filter((x) => !isNaN(x.begin) && !isNaN(x.end))
                                                                    .map((x, index) => {return { start: x.begin, end: x.end, id: index }; })
                            : unit.runningProgram!.endGunSectors.filter((x) => !isNaN(x.begin) && !isNaN(x.end))
                                                                .map((x, index) => {return { start: x.begin, end: x.end, id: index };});

    if (unit.systemType === "Pivot") {
        const lineWidth = 15;
        var endGunsWithBarrier: Segment[] = !barrierProgram ? simplifySegments(endguns): getSegmentsWithBarrier(endguns, barrierProgram);
        endGunsWithBarrier.forEach((s, index) => {
            var points: any[] = [];
            const gap = !isAux ? 35 : 20;

            for (let i = s.start; i <= s.end; i++) {
                points.push( getCoordinatesOnCircleFromAngle( [unit.longitude, unit.latitude],feetToMeters(unit.lengthFeet) + gap, i));
            }
            for (let i = s.end; i >= s.start; i--) {
                points.push( getCoordinatesOnCircleFromAngle( [unit.longitude, unit.latitude],feetToMeters(unit.lengthFeet) + gap + lineWidth, i));
            }

            const f: Feature = {
                type: "Feature",
                geometry: { type: "Polygon", coordinates: [points] },
                properties: { type: "program", color: isAux ? "#31f505" : "red" },
            };
            segmentFeatures.push(f);
        });
    } else {
        endguns.forEach((eg) => {
            var start = eg.start;
            var end = eg.end;
            var start2;
            var end2;
            if (barrierProgram) {
                if (start >= barrierProgram[0] && end <= barrierProgram[1]) {
                    return; //ignore endguns inside barrier
                } else {
                    if ((start < barrierProgram[0] && end > barrierProgram[1] && barrierProgram[0] <= barrierProgram[1] &&  start <= end) ||
                        (start < barrierProgram[0] && end > barrierProgram[1] &&  barrierProgram[0] > barrierProgram[1] && start > end)
                    ) {
                        //barricade is inside endgun borders
                        end = barrierProgram[0];
                        start2 = barrierProgram[1];
                        end2 = eg.end;
                    } else {
                        if (barrierProgram[0] <= barrierProgram[1]) {
                            start =  start >= barrierProgram[0] && start <= barrierProgram[1] ? barrierProgram[1] : start;
                            end = end >= barrierProgram[0] && end <= barrierProgram[1] ? barrierProgram[0] : end;
                        } else {
                            start = start < barrierProgram[1] || start > barrierProgram[0] ? barrierProgram[1] : start;
                            end = end < barrierProgram[1] || end > barrierProgram[0] ? barrierProgram[0] : end;
                        }
                    }
                }
            }
            var offset = !isAux ? -0.02 : -0.01;
            const br = bearing([ unit.longitude, unit.latitude], getSecondaryCoordinates(unit));
            const destin = destination([ unit.longitude, unit.latitude], offset, br, {units: 'miles'});
            var offset1 = destin.geometry.coordinates;  
            var offset2 = getSecondaryCoordinates(unit); 
            if (start < end) {
                const rect1 = getRectPoints( offset1[0], offset1[1], offset2[0], offset2[1], feetToMeters(start));
                const rect2 = getRectPoints( offset1[0], offset1[1], offset2[0], offset2[1], feetToMeters(end));
                segmentFeatures.push({
                    type: "Feature",
                    geometry: { type: "LineString", coordinates: [rect1[3], rect2[3]] },
                    properties: {
                        type: "program",
                        color: isAux ? "#31f505" : "red",
                        width: 10,
                    },
                });
            }
            if (start2 && end2 && start2 < end2) {
                const rect1 = getRectPoints(offset1[0], offset1[1], offset2[0], offset2[1], feetToMeters(start2));
                const rect2 = getRectPoints( offset1[0], offset1[1], offset2[0], offset2[1], feetToMeters(end2)
                );
                segmentFeatures.push({
                    type: "Feature",
                    geometry: { type: "LineString", coordinates: [rect1[3], rect2[3]] },
                    properties: {
                        type: "program",
                        color: isAux ? "#31f505" : "red",
                        width: 10,
                    },
                });
            }
        });
    }

    return segmentFeatures;
};

const buildBarrierProgram = (unit: IUnitData): Feature[] => {
    var barriers: Feature[] = [];
    var barrierProgram = [ unit.runningProgram!.barricade!.forward.position, unit.runningProgram!.barricade!.reverse.position,];
    if (unit.systemType === "Pivot") {
        var barrierStartCoord: number[] = getCoordinatesOnCircleFromAngle([unit.longitude, unit.latitude], feetToMeters(unit.lengthFeet), barrierProgram[0] );
        var barrierEndCoord: number[] = getCoordinatesOnCircleFromAngle([unit.longitude, unit.latitude],feetToMeters(unit.lengthFeet), barrierProgram[1]);

        if (!isNaN(barrierProgram[0])) {
            barriers.push({
                type: "Feature",
                geometry: {
                    type: "LineString",
                    coordinates: [[unit.longitude, unit.latitude], barrierStartCoord],
                },
                properties: { type: "program", color: "red", width: 5 },
            });
        }

        if (!isNaN(barrierProgram[1])) {
            barriers.push({
                type: "Feature",
                geometry: {
                    type: "LineString",
                    coordinates: [[unit.longitude, unit.latitude], barrierEndCoord],
                },
                properties: { type: "program", color: "red", width: 5 },
            });
        }
    } else { 
        if (!isNaN(barrierProgram[0])) {
            var barr1 = getLateralRectPointsForUnit(unit, feetToMeters(unit.runningProgram!.barricade!.forward.position));
            barriers.push({
                type: "Feature",
                geometry: { type: "LineString", coordinates: [barr1[2], barr1[3]] },
                properties: { type: "program", color: "red", width: 5 },
            });
        }

        if (!isNaN(barrierProgram[1])) {
            var barr2 = getLateralRectPointsForUnit(unit, feetToMeters(unit.runningProgram!.barricade!.reverse.position));
            barriers.push({
                type: "Feature",
                geometry: { type: "LineString", coordinates: [barr2[2], barr2[3]] },
                properties: { type: "program", color: "red", width: 5 },
            });
        }
    }

    return barriers;
};

const buildSectorProgram = (unit: IUnitData): Feature[] => {
    var segmentFeatures: Feature[] = [];
    var segments: Segment[] = unit.runningProgram!.vriSectors.filter((x) => !isNaN(x.begin) && !isNaN(x.end))
                                                            .map((x, index) => {
                                                                return { start: x.begin, end: x.end, id: index };
                                                            });
    var barrierProgram = unit.runningProgram!.barricade ? [unit.runningProgram!.barricade.forward.position, unit.runningProgram!.barricade.reverse.position,] : null;
    if (unit.systemType === "Pivot") {
        var segmentsWithBarrier: Segment[] = [];
        if (!barrierProgram) {
            segmentsWithBarrier = simplifySegments(segments);
        } else {
            segmentsWithBarrier = getSegmentsWithBarrier(segments, barrierProgram);
        }

        segmentsWithBarrier.forEach((s, index) => {
            var points: any[] = [];
            points.push([unit.longitude, unit.latitude]);

            for (let i = Math.round(s.start); i <= Math.round(s.end); i++) {
                points.push( getCoordinatesOnCircleFromAngle([unit.longitude, unit.latitude], feetToMeters(unit.lengthFeet), i));
            }
            points.push([unit.longitude, unit.latitude]);

            const f: Feature = {
                type: "Feature",
                geometry: { type: "Polygon", coordinates: [points] },
                properties: { type: "program", color: sectorFillColors[s.id] },
            };
            segmentFeatures.push(f);
        });
    } else {
        segments.forEach((segment) => {
            var start = segment.start;
            var end = segment.end;
            var start2;
            var end2;
            if (barrierProgram) {
                if (start >= barrierProgram[0] && end <= barrierProgram[1]) {
                    return; //ignore segments inside barrier
                } else {
                    if ((start < barrierProgram[0] && end > barrierProgram[1] && barrierProgram[0] <= barrierProgram[1] && start <= end) ||
                        (start < barrierProgram[0] && end > barrierProgram[1] && barrierProgram[0] > barrierProgram[1] && start > end)
                    ) {
                        //barricade is inside endgun borders
                        end = barrierProgram[0];
                        start2 = barrierProgram[1];
                        end2 = segment.end;
                    } else {
                        if (barrierProgram[0] <= barrierProgram[1]) { start = start >= barrierProgram[0] && start <= barrierProgram[1] ? barrierProgram[1] : start;
                            end = end >= barrierProgram[0] && end <= barrierProgram[1] ? barrierProgram[0] : end;
                        } else {
                            start = start < barrierProgram[1] || start > barrierProgram[0] ? barrierProgram[1] : start;
                            end = end < barrierProgram[1] || end > barrierProgram[0] ? barrierProgram[0] : end;
                        }
                    }
                }
            }
            if (start < end) {
                const rect1 = getLateralRectPointsForUnit(unit, feetToMeters(start));
                const rect2 = getLateralRectPointsForUnit(unit, feetToMeters(end));

                segmentFeatures.push({
                    type: "Feature",
                    geometry: {
                        type: "Polygon",
                        coordinates: [[rect1[3], rect1[2], rect2[2], rect2[3], rect1[3]]],
                    },
                    properties: { type: "program", color: sectorFillColors[segment.id] },
                });
            }
            if (start2 && end2 && start2 < end2) {
                const rect1 = getLateralRectPointsForUnit(unit, feetToMeters(start2));
                const rect2 = getLateralRectPointsForUnit(unit, feetToMeters(end2));
                segmentFeatures.push({
                    type: "Feature",
                    geometry: {
                        type: "Polygon",
                        coordinates: [
                            [rect1[2], rect1[3], rect2[3], rect2[2], rect1[2], rect1[2]],
                        ],
                    },
                    properties: { type: "program", color: sectorFillColors[segment.id] },
                });
            }
        });
    }

    return segmentFeatures;
};

const getSegmentsWithBarrier = ( segments: Segment[],barrierProgram: number[]): Segment[] => {
    //takes barriers into count and converts the list of segments to exclude the barrier zone
    var segmentsWithBarrier: Segment[] = [];
    if (!barrierProgram) {
        segmentsWithBarrier = simplifySegments(segments);
    } else {
        const simplified = simplifySegments(segments);
        simplified.forEach((eg, index) => {
            var intersection = intersectSegmentWithBarrier(eg, barrierProgram, );
            if (intersection) {
                intersection.forEach((interSec) => {
                    if (segmentsWithBarrier.findIndex((x) => x.start === interSec.start && x.end === interSec.end) === -1) {
                        segmentsWithBarrier.push(interSec);
                    }
                });
            }
        });
    }
    return segmentsWithBarrier;
};

const intersectSegmentWithBarrier = ( segment: Segment, barrier: number[] | null, ): Segment[] | null => {
    var start = segment.start;
    var end = segment.end;
    var start2: number | undefined = undefined;
    var end2: number | undefined = undefined;

    if (barrier) {      
        const barrierStart = barrier[0];
        const barrierEnd = barrier[1];

        if (barrierStart < barrierEnd) {      
             //barrier is not overlapping 0 degrees
            if (segment.start > barrierStart && segment.end < barrierEnd) return null; //in barricaded area, ignore segment

            if (segment.start < barrierEnd && segment.start > barrierStart) {
                start = barrierEnd;
            }
            if (segment.end > barrierStart && segment.end < barrierEnd) {
                end = barrierStart;
            }
            if(segment.start < barrierStart && segment.end > barrierEnd){ //barrier is inside segment
                start = segment.start;
                end = barrierStart;
                start2 = barrierEnd;
                end2 = segment.end;
            } 
        } else {
           //overlapping 0 degrees
           if((segment.start < barrierEnd && segment.end < barrierEnd) || (segment.end > barrierStart && segment.start > barrierStart)){
                return null;  //in barricaded area, ignore segment
           }

            if (segment.end > barrierStart || segment.end < barrierEnd) {
                end = barrierStart;
            }

            if (segment.start < barrierEnd || segment.start > barrierStart) {
                start = barrierEnd;
            }

            if(segment.start < barrierEnd && segment.end > barrierStart){ 
                start = barrierEnd;
                end = barrierStart;            
            }
        }
    }

    var list = [{ start: start, end: end, id: segment.id }];
    if(start2 && end2){
        list.push({ start: start2, end: end2, id: segment.id });
    }

    return list;
};