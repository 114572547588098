import * as React from "react";
import { useNavigate, useParams } from "react-router-dom";
import BackgroundDataCtx from "../../../context/BackgroundDataCtx/BackgroundDataCtx";
import { useContext } from "react";
import { DataGrid, GridRowsProp } from "@mui/x-data-grid";
import { Box } from "@mui/material";

interface UnitRow {
  id: string;
  name: string;
  value: string;
  time: string;
}

const UnitSensors: React.FC = () => {
  const { unitId } = useParams();
  const navigate = useNavigate();
  if (!unitId) return <>Unit not recognised</>;
  const bgdata = useContext(BackgroundDataCtx);
  const unit = bgdata.units[unitId];
  const rows: GridRowsProp<UnitRow> = Object.values(unit.sensor)
    .filter((sensorData) => sensorData.last !== undefined)
    .map((sensorData, index) => ({
      id: sensorData.id.toString(),
      name: sensorData.descr,
      value: `${sensorData.last.value} ${sensorData.last.measUnit}`,
      time: new Date(sensorData.last.time).toLocaleString(),
    }));

  const columns = [
    { field: "name", headerName: "Sensor", width: 200 },
    { field: "value", headerName: "Value", width: 130 },
    { field: "time", headerName: "Last Updated", flex: 1, minWidth: 200 },
  ];

  return (
    <Box sx={{ height: "100%", width: "100%" }}>
      <DataGrid rows={rows} columns={columns} onRowClick={(params) => navigate(`/units/${unitId}/sensors/${params.id}`)} />
    </Box>
  );
};

export default UnitSensors;
