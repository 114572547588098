import {
  Button,
  Fade,
  ListItemText,
  Menu,
  MenuItem,
  MenuList,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useMemo } from "react";
import IUnitData from "../../context/BackgroundDataCtx/IUnitData";
import BackgroundDataCtx from "../../context/BackgroundDataCtx/BackgroundDataCtx";
import AddIcon from "@mui/icons-material/Add";


interface Props {
  unitsInGroup: IUnitData[];
  handleAddUnit: (unit: IUnitData) => void;
}

const AddNewUnitMenu: React.FC<Props> = ({
  unitsInGroup,
  handleAddUnit,
}: Props) => {
  const bgdata = React.useContext(BackgroundDataCtx);
  const units = Object.values(bgdata.units);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const theme = useTheme();
  const isDesktopScreen = useMediaQuery(theme.breakpoints.up("md"));

  const sortedUnits = useMemo(() => {
    return [...units].sort((a, b) => a.name.localeCompare(b.name));
  }, [units]);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleUnitClick = (newUnitId: string) => {
    const selectedUnit = units.find((unit) => unit.id === newUnitId);
    if (selectedUnit) {
      handleAddUnit(selectedUnit);
    }
    setAnchorEl(null);
  };

  return (
    <>
      <Button variant="text" fullWidth onClick={handleClick}><AddIcon />  Add Unit</Button>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={() => setAnchorEl(null)}
        TransitionComponent={Fade}
      >
        <MenuList dense={isDesktopScreen}>
          {sortedUnits.map((unit) => {
            return (
              <MenuItem disabled={unitsInGroup.some((u) => u.id === unit.id)} 
              key={unit.id} onClick={() => handleUnitClick(unit.id)}>
                <ListItemText>{unit.name}</ListItemText>
              </MenuItem>
            );
          })}
        </MenuList>
      </Menu>
    </>
  );
};

export default AddNewUnitMenu;
