import * as React from "react";
import { useContext, useState } from "react";
import {
  Stack,
  Box,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Fab,
  CircularProgress,
  ListItemButton,
  MenuItem,
  Menu,
} from "@mui/material";
import Map from "../../../components/Map";
import BackgroundDataCtx from "../../../context/BackgroundDataCtx/BackgroundDataCtx";
import { useParams } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import AddIcon from "@mui/icons-material/Add";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import PlayCircleFilledIcon from "@mui/icons-material/PlayCircleFilled";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import CreateProgramEditor from "../../../components/ProgramsEditor/CreateProgramEditor";
import ProgramIcon from "../../../components/ProgramIcon";
import buildGeoJson from "../../../components/Map/buildGeoJson";
import { UnitData } from "../../../context/BackgroundDataCtx/IBackgroundData";
import {
  IProgram,
} from "../../../context/BackgroundDataCtx/IUnitData";
import QueueCtx from "../../../context/QueueCtx/QueueCtx";

const UnitPrograms: React.FC = () => {
  const { unitId } = useParams();
  if (!unitId) return <>Unit not recognised</>;
  const bgdata = useContext(BackgroundDataCtx);
  const unit = bgdata.units[unitId];
  const queue = useContext(QueueCtx);
  const [openCreateProgramEditor, setOpenCreateProgramEditor] = useState(false);
  const [programToEdit, setProgramToEdit] = useState<
    IProgram | undefined
  >(undefined);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const [selectedProgram, setSelectedProgram] = useState<IProgram | undefined>(undefined);

  const handleOpenProgramMenu = (
    event: React.MouseEvent<HTMLDivElement>,
    program: IProgram
  ) => {
    setSelectedProgram(program);
    setAnchorEl(event.currentTarget);
  };
  const handleCloseProgramMenu = () => setAnchorEl(null);

  const handleEditProgram = (program: IProgram) => {
    setProgramToEdit(structuredClone(program));
    setOpenCreateProgramEditor(true);
  };

  const handleDeleteProgram = async (program: IProgram) => {
    await bgdata.deleteProgram(program);
  };

  const handleChangeProgram = async (program: IProgram) => {
    if (isAnyProgInPendState()) return;
    if (unit.runningProgram) {
      bgdata.unloadProgram(unit.runningProgram, unit);
    }
    bgdata.loadProgram(program, unit);
  };

  const handleSaveProgram = async (program: IProgram) => {
    await bgdata.saveProgram(unit, program);
  };

  const isAnyProgInPendState = (): boolean => {
    return queue.isQueueInProgress(`${unit.id}-program-queue`);
  };

  const isProgInPendState = (program: IProgram | undefined): boolean => {
    if (!program) return false;
    return queue.isTaskInQueue(
      `${unit.id}-program-${program.name}`,
      `${unit.id}-program-queue`
    );
  };

  const isRunningProgram = (program: IProgram | undefined): boolean => {
    if (!program) return false;
    return unit.runningProgram?.name === program.name;
  };

  // put running program first in list
  if (unit.runningProgram && unit.programs) {
    unit.programs = [
      unit.runningProgram,
      ...unit.programs.filter((p) => p.name !== unit.runningProgram?.name),
    ];
  }

  return (
    <>
      {openCreateProgramEditor ? (
        <CreateProgramEditor
          unitData={unit}
          programToEdit={programToEdit}
          closeEditor={() => setOpenCreateProgramEditor(false)}
          handleSaveProgram={handleSaveProgram}
          editExistingProgram={programToEdit !== undefined}
        />
      ) : (
        <Stack
          direction={{ xs: "column", md: "row" }}
          sx={{
            height: "100%",
          }}
        >
          <Box sx={{ flexGrow: 1 }}>
            <Map unitId={unit.id} />
          </Box>
          <Box
            sx={{
              width: {
                xs: "100%",
                md: 380,
              },
              height: {
                xs: "50%",
                md: "100%",
              },
              overflow: "auto",
              position: "relative",
            }}
          >
            <List>
              {unit.programs?.map((program, index) => {
                var ud: UnitData = {};
                var newUnit = { ...unit };
                newUnit.runningProgram = program;
                ud[unitId] = newUnit;
                return (
                  <ListItem key={index} disablePadding divider>
                    <ListItemButton
                      id="open-program-menu-button"
                      aria-controls={open ? "program-options-menu" : undefined}
                      aria-haspopup="true"
                      aria-expanded={open ? "true" : undefined}
                      onClick={(e) => handleOpenProgramMenu(e, program)}
                      sx={{
                        backgroundColor: isRunningProgram(program)
                          ? "rgba(25, 118, 210, 0.1)"
                          : "inherit",
                      }}
                    >
                      <ListItemIcon sx={{ mr: 1 }}>
                        <ProgramIcon
                          featureCollection={buildGeoJson(
                            ud,
                            undefined,
                            "#bfbfbf"
                          )}
                        />
                      </ListItemIcon>
                      <ListItemText primary={program.name} />

                      {isProgInPendState(program) && (
                        <CircularProgress
                          color="inherit"
                          size={"1rem"}
                          sx={{ alignSelf: "center", mr: 1 }}
                        />
                      )}
                      {isRunningProgram(program) && (
                        <span style={{ marginRight: 1 }}>Running</span>
                      )}
                      <MoreVertIcon />
                    </ListItemButton>
                  </ListItem>
                );
              })}
            </List>
            <Fab
              color="primary"
              aria-label="add"
              onClick={() => setOpenCreateProgramEditor(true)}
              sx={{
                position: "fixed",
                bottom: 72,
                right: 16,
              }}
            >
              <AddIcon />
            </Fab>
            <Menu
              id="program-options-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleCloseProgramMenu}
              MenuListProps={{
                "aria-labelledby": "open-program-menu-button",
              }}
              anchorOrigin={{
                vertical: "center",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
            >
              <MenuItem
                onClick={() => {
                  handleCloseProgramMenu();
                  handleChangeProgram(selectedProgram!);
                }}
                disabled={
                  isAnyProgInPendState() || isRunningProgram(selectedProgram)
                }
              >
                <PlayCircleFilledIcon sx={{ mr: 2 }} />
                Load
              </MenuItem>
              <MenuItem
                disabled={
                  isProgInPendState(selectedProgram) ||
                  isRunningProgram(selectedProgram)
                }
                onClick={() => {
                  handleCloseProgramMenu();
                  handleEditProgram(selectedProgram!);
                }}
              >
                <EditIcon sx={{ mr: 2 }} />
                Edit
              </MenuItem>
              <MenuItem
                onClick={() => {
                  handleCloseProgramMenu();
                  handleDeleteProgram(selectedProgram!);
                }}
                disabled={
                  isProgInPendState(selectedProgram) ||
                  isRunningProgram(selectedProgram)
                }
              >
                <DeleteForeverIcon sx={{ mr: 2 }} />
                Delete
              </MenuItem>
            </Menu>
          </Box>
        </Stack>
      )}
    </>
  );
};

export default UnitPrograms;
