import * as React from "react";
import { useRef, useState } from "react";

import IQueue, { Task, QueuedTask } from "./IQueueCtx";
import QueueCtx from "./QueueCtx";

const QueueCtxProvider: React.FC<React.PropsWithChildren> = (props) => {
  const [queueCount, setQueueCount] = useState(0);

  const isProcessing = useRef<Set<string>>(new Set());
  const taskQueues = useRef<Map<string, QueuedTask[]>>(new Map());

  const enqueueTask = (task: Task): Promise<void> => {
    return new Promise((resolve) => {
      const newTask: QueuedTask = { ...task, resolve };
      const queue = taskQueues.current.get(task.queueId) || [];
      queue.push(newTask);
      taskQueues.current.set(task.queueId, queue);
      setQueueCount((currentCount) => currentCount + 1);
      if (!isProcessing.current.has(task.queueId)) {
        processTaskForQueue(task.queueId);
      }
    });
  };

  const processTaskForQueue = async (queueId: string) => {
    const queue = taskQueues.current.get(queueId);
    if (queue && queue.length > 0) {
      const task = queue[0];
      if (task) {
        isProcessing.current.add(queueId);
        try {
          const result = await task.perform();
          if (result === "error") throw new Error("Task failed");

          await pollToCompletion(task);
        } catch (error) {
          // if not completed try again
          if (!(await task.verifyCompletion())) processTaskForQueue(queueId);
        } finally {
          task.resolve();
          queue.shift();
          setQueueCount((currentCount) => currentCount - 1);

          if (queue.length > 0) {
            processTaskForQueue(queueId);
          } else {
            isProcessing.current.delete(queueId);
            taskQueues.current.delete(queueId);
          }
        }
      }
    }
  };

  const pollToCompletion = async (task: Task) => {
    let isComplete = false;
    let attempts = 0;
    while (!isComplete && attempts < 40) {
      await new Promise((r) =>
        setTimeout(r, task.timeoutSec ? task.timeoutSec * 1000 : 7000)
      ); // default to 7 seconds
      isComplete = await task.verifyCompletion();
      console.log("Polling " + task.queueId + " completed?", isComplete);
      attempts++;
    }
    return isComplete;
  };

  const queue: IQueue = {
    enqueueTask,
    tasksInProgress: queueCount,
    isQueueInProgress: (queueId: string) => isProcessing.current.has(queueId),
    isTaskInQueue: (taskId: string, queueId: string) => {
      const queue = taskQueues.current.get(queueId);
      return queue ? queue.some((task) => task.id === taskId) : false;
    },
    getQueueIds: () => Array.from(taskQueues.current.keys()),
    getQueueTaskIds: (queueId: string) => {
      const queue = taskQueues.current.get(queueId);
      return queue ? queue.map((task) => task.id) : [];
    },
  };

  return <QueueCtx.Provider value={queue}>{props.children}</QueueCtx.Provider>;
};

export default QueueCtxProvider;
