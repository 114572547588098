import {
  Stack,
  Switch,
  IconButton,
  FormControl,
  Button,
} from "@mui/material";
import React, { useContext, useMemo } from "react";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { IUnitState } from "../../context/BackgroundDataCtx/IUnitData";
import AddCircle from "@mui/icons-material/AddCircle";
import DeleteForever from "@mui/icons-material/DeleteForever";
import SpeedDepthInput from "../ui/SpeedDepthInput";
import { ICapabilites } from "../../context/ExtendedUnitDataCtx/IExtendedUnitData";

const defaultActions = [
  { key: "pump", label: "Pump" },
  { key: "chemPump", label: "Chem" },
  { key: "speedpc", label: "Speed" },
  { key: "aux1", label: "Aux" },
  { key: "direction", label: "Auto Rev" },
  { key: "stop", label: "Stop" },
  { key: "start", label: "Start" },
];

interface Props {
  onChange: (newVal: IUnitState) => void;
  state: IUnitState;
  capabilities: ICapabilites;
}
const SelectActions: React.FC<Props> = ({ onChange, state, capabilities }) => {
  const filteredActions = useMemo(
    () => defaultActions.filter((a) => capabilities.controls[a.key] === true),
    [capabilities]
  );

  const handleChange = (event: SelectChangeEvent, prevKey: string) => {
    if (event.target.value === prevKey) return;
    const { newKey, defaultValue } = getNewKeyAndDefault(event.target.value);
    const newState = {
      ...state,
      [newKey]: defaultValue,
    };
    if (prevKey === "stop" || prevKey === "start") {
      if (newKey !== "running") {
        delete newState["running"];
      }
    } else {
      delete newState[prevKey];
    }
    onChange(newState);
  };

  const handleAddAction = () => {
    const nextAction = filteredActions.find((action) => {
      if (action.key === "start" || action.key === "stop") {
        return "running" in state === false;
      } else {
        return action.key in state === false;
      }
    });

    if (nextAction) {
      const { newKey, defaultValue } = getNewKeyAndDefault(nextAction.key);
      onChange({ ...state, [newKey]: defaultValue });
    }
  };

  const handleDelete = (key: string) => {
    const newState = { ...state };
    if (key === "start" || key === "stop") {
      delete newState["running"];
    } else {
      delete newState[key];
    }
    onChange(newState);
  };

  const getNewKeyAndDefault = (key: string) => {
    let newKey = key;
    let defaultValue = key === "speedpc" ? 100 : false;

    if (key === "start" || key === "stop") {
      newKey = "running";
      defaultValue = key === "start";
    }

    return { newKey, defaultValue };
  };

  return (
    <Stack spacing={1}>
      <Stack
        direction={{
          xs: "column",
          sm: "row",
        }}
        flexWrap={"wrap"}
        justifyContent={"space-between"}
        rowGap={1}
      >
        {Object.entries(state).map(([key, value], index) => {
          if (key === "running" && value === true) {
            key = "start";
            value = "Start";
          } else if (key === "running" && value === false) {
            key = "stop";
            value = "Stop";
          }
          return (
            <Stack
              key={index}
              direction={"row"}
              justifyContent={"space-between"}
              sx={{ width: { xs: "100%", sm: "268px" } }}
            >
              <FormControl size="small">
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={key}
                  onChange={(e) => handleChange(e, key)}
                  sx={{ width: 100 }}
                >
                  {filteredActions.map((action, index) => {
                    return (
                      <MenuItem
                        disabled={
                          state[action.key] !== undefined ||
                          (action.key === "start" && state.running === true) ||
                          (action.key === "stop" && state.running === false)
                        }
                        key={index}
                        value={action.key}
                      >
                        {action.label}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
              <div style={{ marginLeft: 8 }}>
                {key !== "start" &&
                  key !== "stop" &&
                  (key === "speedpc" ? (
                    <SpeedDepthInput
                      speedpc={value as number}
                      onBlur={(value) => onChange({ ...state, [key]: value })}
                      maxWidth="120px"
                    />
                  ) : (
                    <Switch
                      checked={value as boolean}
                      onChange={(e) =>
                        onChange({ ...state, [key]: e.target.checked })
                      }
                    />
                  ))}

                <IconButton onClick={() => handleDelete(key)}>
                  <DeleteForever />
                </IconButton>
              </div>
            </Stack>
          );
        })}
      </Stack>
      {!filteredActions.every((action) => {
        if (action.key === "start" || action.key === "stop") {
          return "running" in state === true;
        }
        return action.key in state === true;
      }) && (
        <Button size="small" onClick={handleAddAction}>
          <AddCircle sx={{ mr: 1 }} /> Add Action
        </Button>
      )}
    </Stack>
  );
};
export default SelectActions;
