import * as React from "react";
import { useState } from "react";

import RswsApiCtx from "./RswsApiCtx";
import RswsApi from "./RswsApi";

const RswsApiCtxProvider: React.FC<React.PropsWithChildren> = (props) => {
  const token = sessionStorage.getItem("authToken") ?? undefined;
  const [authToken, setAuthToken] = useState<string | undefined>(token);

  const setAuthTokenAndSession = (authToken: string | undefined) => {
    setAuthToken(authToken);
    try {
      if (authToken) {
        sessionStorage.setItem("authToken", authToken);
      } else {
        sessionStorage.removeItem("authToken");
      }
    } catch (error) {
      console.log("Error setting authToken in session storage: ", error);
    }
  };

  return (
    <RswsApiCtx.Provider value={new RswsApi(authToken, setAuthTokenAndSession)}>
      {props.children}
    </RswsApiCtx.Provider>
  );
};

export default RswsApiCtxProvider;
