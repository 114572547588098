import * as React from "react";
import { useEffect, useState } from "react";
import MapRefCtx from "./IMapRefCtx";

const MapRefCtxProvider: React.FC<React.PropsWithChildren> = (props) => {
  const map = React.useRef(null);
  const mapContainer = React.useRef(null);
  const [isMapLoaded, setIsMapLoaded] = useState(false);
  const [lngLatZoom, setLngLatZoom] = useState({ lng: 10, lat: 50, zoom: 3.5 });

  return (
    <MapRefCtx.Provider value={{ map, mapContainer, lngLatZoom, setLngLatZoom, isMapLoaded, setIsMapLoaded }}>{props.children}</MapRefCtx.Provider>
  );
};

export default MapRefCtxProvider;
