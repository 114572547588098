import { Stack, Typography, Checkbox } from "@mui/material";
import React, { useState } from "react";
import SpeedDepthInput from "../ui/SpeedDepthInput";
import IUnitData from "../../context/BackgroundDataCtx/IUnitData";

interface Props {
  speedValue: number | null;
  onChange: (newValue: number | null) => void;
  label?: string;
  disabled?: boolean;
}

const SpeedCheckboxInput: React.FC<Props> = ({
  onChange,
  label,
  disabled,
  speedValue,
}) => {
  const [checked, setChecked] = useState<boolean>(
    speedValue !== null && !isNaN(speedValue)
  );
  const [value, setValue] = useState<number>(speedValue || NaN);

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
    if (!event.target.checked) {
      onChange(null);
    } else {
      onChange(value);
    }
  };

  const handleSpeedChange = (newValue: number) => {
    setValue(newValue);
    if (checked) {
      onChange(newValue);
    }
  };

  return (
    <Stack alignItems={"flex-start"} sx={{ minWidth: "120px" }}>
      {label && <Typography sx={{ pl: "42px" }}>{label}</Typography>}
      <Stack direction={"row"} alignItems={"flex-end"}>
        <Checkbox
          disabled={disabled}
          checked={checked}
          onChange={handleCheckboxChange}
        />
        <SpeedDepthInput
          speedpc={value}
          onBlur={(value) => handleSpeedChange(value)}
          disabled={!checked}
        />
      </Stack>
    </Stack>
  );
};

export default SpeedCheckboxInput;
