export enum UnitColorStatus{
    Wet,
    Dry,
    Chemigation,
    Fertigation, //Chemiogation reverse
    Effluent,
    Overwater,
    Error,
    Offline,
    Default
}


export default interface IUserSettings{
    statusColors: { [id: number]: string};
}