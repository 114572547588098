import React, { useContext } from "react";
import IUnitData, {
  IScheduledAction,
} from "../../context/BackgroundDataCtx/IUnitData";
import {
  Divider,
  IconButton,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import Grid from "@mui/material/Unstable_Grid2";
import dayjs, { Dayjs } from "dayjs";
import SelectActions from "./SelectActions";
import ExtendedUnitDataCtx from "../../context/ExtendedUnitDataCtx/ExtendedUnitDataCtx";

const dayMap = {
  Sun: "S",
  Mon: "M",
  Tue: "T",
  Wed: "W",
  Thu: "T",
  Fri: "F",
  Sat: "S",
};

interface Props {
  scheduledActions: IScheduledAction[];
  onChange: (newScheduledAction: IScheduledAction[]) => void;
  unit: IUnitData;
}
const ScheduledActionsEditor: React.FC<Props> = ({
  onChange,
  scheduledActions,
  unit,
}: Props) => {
  const { capabilities } = useContext(ExtendedUnitDataCtx);
  const handleDeleteSA = (index: number) => {
    const newSA = [...scheduledActions];
    newSA.splice(index, 1);
    onChange(newSA);
  };

  return (
    <Grid container spacing={2} sx={{ width: "100%", maxWidth: "600px" }}>
      {scheduledActions.length === 0 && (
        <Grid xs={12}>No Scheduled Actions added</Grid>
      )}
      {scheduledActions.map((sa, index) => (
        <Grid container xs={12} key={index}>
          <Grid xs={12} sm={6}>
            <DateTimePicker
              label="Start date and time"
              disablePast
              /* False TS error. It dissapears when you open /reincloud-frontend/index.tsx where the date LocalizationProvider is nested */
              value={dayjs(sa.time?.runAtTime) as Dayjs}
              onChange={(newDateTime) => {
                if (!newDateTime) return;
                const newSA = [...scheduledActions];
                /* False TS error. It dissapears when you open /reincloud-frontend/index.tsx where the date LocalizationProvider is nested */
                newSA[index].time.runAtTime = newDateTime.toISOString();
                onChange(newSA);
              }}
              sx={{ width: "100%" }}
            />
          </Grid>
          <Grid xs={11} sm={5} position={"relative"}>
            <div
              style={{
                position: "absolute",
                top: -1,
                left: 15,
                fontSize: 12,
                backgroundColor: "#fff",
                color: "#00000099",
                paddingLeft: 2,
                paddingRight: 2,
                zIndex: 1,
                cursor: "default",
              }}
            >
              Repeat on
            </div>
            <ToggleButtonGroup
              value={sa.time.repeatOnDays}
              onChange={(event, newDays) => {
                if (!newDays) return;
                const updatedActions = [...scheduledActions];
                updatedActions[index].time.repeatOnDays = newDays;
                onChange(updatedActions);
              }}
              aria-label="text formatting"
              fullWidth
              color="primary"
              sx={{ height: "100%" }}
            >
              {Object.entries(dayMap).map(([day, short]) => (
                <ToggleButton value={day} aria-label={day} key={day}>
                  {short}
                </ToggleButton>
              ))}
            </ToggleButtonGroup>
          </Grid>
          <Grid
            xs={1}
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <IconButton onClick={() => handleDeleteSA(index)} size="small">
              <DeleteForeverIcon />
            </IconButton>
          </Grid>
          <Grid xs={12}>
            <SelectActions
              state={sa.state}
              onChange={(newState) => {
                const updatedActions = [...scheduledActions];
                updatedActions[index].state = newState;
                onChange(updatedActions);
              }}
              capabilities={capabilities}
            />
          </Grid>
          {index !== scheduledActions.length - 1 && (
            <Grid xs={12}>
              <Divider />
            </Grid>
          )}
        </Grid>
      ))}
    </Grid>
  );
};

export default ScheduledActionsEditor;
