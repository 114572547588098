import * as React from "react";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Collapse,
  IconButton,
  IconButtonProps,
  Tooltip,
  styled,
} from "@mui/material";
import ActionPanel from "../ControlsEditor/ActionPanel";
import { NavigateFunction } from "react-router-dom";
import ExtendedUnitDataProvider from "../../context/ExtendedUnitDataCtx/ExtendedUnitDataProvider";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import BackgroundDataCtx from "../../context/BackgroundDataCtx/BackgroundDataCtx";
import InfoBar from "../ControlsEditor/InfoBar";

interface ExpandMoreProps extends IconButtonProps {
  expand: boolean;
}

const ExpandMore = styled(
  React.forwardRef<HTMLButtonElement, ExpandMoreProps>(({ expand, ...other }, ref) => {
    return <IconButton ref={ref} {...other} />;
  })
)(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

interface Props {
  unitId: string;
  navigate: NavigateFunction;
  onExit: () => void;
  isPopupOpenedByClick: boolean;
}
const MapUnitPopup: React.FC<Props> = ({ unitId, navigate, onExit, isPopupOpenedByClick }: Props) => {
  const bgdata = React.useContext(BackgroundDataCtx);
  const unit = bgdata.units[unitId];
  const [expanded, setExpanded] = React.useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <Card sx={{ width: "260px" }}>
      <CardHeader
        title={unit.name}
        titleTypographyProps={{ variant: "h6" }}
        subheader={unit.id}
        subheaderTypographyProps={{ variant: "body2" }}
        sx={{ py: 1 }}
      />
      <CardContent sx={{ py: 0 }}>
        <InfoBar unit={unit} isCardDisplay={true} />
      </CardContent>
      {isPopupOpenedByClick && (
        <>
          <CardActions disableSpacing>
            <Button
              onClick={() => {
                onExit();
                navigate(`/units/${unitId}`);
              }}
            >
              Go to unit
            </Button>
            <Tooltip title="Send Commands">
              <ExpandMore expand={expanded} onClick={handleExpandClick}>
                <ExpandMoreIcon />
              </ExpandMore>
            </Tooltip>
          </CardActions>
          <Collapse in={expanded} timeout="auto">
            <ExtendedUnitDataProvider unitId={unitId}>
              <ActionPanel unitId={unitId} onMapView={true} />
            </ExtendedUnitDataProvider>
          </Collapse>
        </>
      )}
    </Card>
  );
};

export default MapUnitPopup;
