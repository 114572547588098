import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import dayjs, { Dayjs } from "dayjs";
import { IEvent } from "./createEvent";

interface Props {
  event: IEvent;
}
const EventCard: React.FC<Props> = ({ event }: Props) => {
  return (
    <>
      {event.sectors.length > 0 ? (
        <TableContainer>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>Time</TableCell>
                <TableCell>Begin</TableCell>
                <TableCell>End</TableCell>
                {Object.keys(event.sectors[0].state).map((key) => (
                  <TableCell key={key}>{key}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {event.sectors.map((sector, index) => (
                <TableRow
                  key={index}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {dayjs(sector.sectorStartTimeMS).format(
                      "YYYY-MM-DD HH:mm:ss"
                    ) + " - " + dayjs(sector.sectorEndTimeMS).format("YYYY-MM-DD HH:mm:ss")}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {sector.begin}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {sector.end}
                  </TableCell>
                  {Object.values(sector.state).map((val, index) => (
                    <TableCell key={index}>{"" + val}</TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <Typography>No sectors for event</Typography>
      )}
    </>
  );
};

export default EventCard;
