import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { TextField, MenuItem, Menu, Button } from "@mui/material";
import { cmMinToFtMin, cmToInches, ftMinToCmMin, inchesToCm } from "../../helpers/Conversions";
import {
  canCalcDepth,
  ftMinToSpeedPc,
  getDepthInch,
  getSpeedPcFromDepth,
  speedPcToFtMin,
} from "../../helpers/unitUtils";
import ExtendedUnitDataCtx from "../../context/ExtendedUnitDataCtx/ExtendedUnitDataCtx";
import { useParams } from "react-router-dom";
import BackgroundDataCtx from "../../context/BackgroundDataCtx/BackgroundDataCtx";

interface InputOption {
  unit: string;
  label: string;
  transformToUnit: (speedpc: number) => number;
  transformToSpeedPc: (value: number) => number;
}

interface Props {
  speedpc: number;
  onBlur: (newSpeedPc: number) => void;
  disabled?: boolean;
  width?: string;
  maxWidth?: string;
  unitId?: string;
}
const SpeedDepthInput: React.FC<Props> = (props: Props) => {
  var { unitId } = useParams();
  if (!unitId) {
    unitId = props.unitId; //map popup
  }
  if (!unitId) return <>Unit not recognised: {props.unitId}</>;
  const bgdata = useContext(BackgroundDataCtx);
  const unit = bgdata.units[unitId];
  const { unitSettings } = useContext(ExtendedUnitDataCtx);
  const [error, setError] = useState<boolean>(false);
  const maxSpeedPc = unit.systemType === "Pivot" ? 100 : 90;
  const textFieldRef = useRef<HTMLInputElement>(null);

  const inputOptions: InputOption[] = useMemo(
    () =>
      [
        {
          unit: "%",
          label: "Speed",
          transformToUnit: (speedpc: number) => speedpc,
          transformToSpeedPc: (value: number) => value,
        },
        {
          unit: "ft/min",
          label: "Speed",
          transformToUnit: (speedpc: number) => {
            return speedPcToFtMin(speedpc, unit, unitSettings);
          },
          transformToSpeedPc: (value: number) => {
            return ftMinToSpeedPc(value, unit, unitSettings);
          },
        },
        {
          unit: "cm/min",
          label: "Speed",
          transformToUnit: (speedpc: number) => {
            return ftMinToCmMin(speedPcToFtMin(speedpc, unit, unitSettings));
          },
          transformToSpeedPc: (value: number) => {
            return cmMinToFtMin(ftMinToSpeedPc(value, unit, unitSettings));
          },
        },
        {
          unit: "in",
          label: "Depth",
          transformToUnit: (speedpc: number) => {
            return getDepthInch(unit, speedpc, maxSpeedPc)!;
          },
          transformToSpeedPc: (value: number) => {
            return getSpeedPcFromDepth(unit, value, maxSpeedPc)!;
          },
        },
        {
          unit: "cm",
          label: "Depth",
          transformToUnit: (speedpc: number) => {
            return inchesToCm(getDepthInch(unit, speedpc, maxSpeedPc)!);
          },
          transformToSpeedPc: (value: number) => {
            return getSpeedPcFromDepth(unit, cmToInches(value), maxSpeedPc)!;
          },
        },
      ].filter((opt) => {
        if (opt.label === "Depth") {
          return canCalcDepth(unit);
        } else if (unit.systemType === "Lateral" && (opt.unit === "ft/min" || opt.unit === "cm/min")) {
          return unitSettings.feetperhour !== undefined;
        } else {
          return true;
        }
      }),
    [unit, unitSettings, props.speedpc, maxSpeedPc]
  );

  const [currentOption, setCurrentOption] = useState<InputOption>(inputOptions[0]);
  const [displayValue, setDisplayValue] = useState<string>("");
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [rawSpeed, setRawSpeed] = useState<number>(props.speedpc);
  const open = Boolean(anchorEl);

  useEffect(() => {
    if (!isNaN(props.speedpc)) {
      setError(!isValidSpeed(props.speedpc));
      setDisplayValue(roundToTwoDecimals(currentOption.transformToUnit(props.speedpc)).toString());
    } else {
      setRawSpeed(props.speedpc);
      setDisplayValue("");
    }
  }, [props.speedpc]);

  const isValidSpeed = (speedpc: number): boolean => {
    return speedpc >= 1 && speedpc <= maxSpeedPc;
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const transformedInput = transformInput(event.target.value, currentOption.unit);
    if (transformedInput === displayValue) return;
    const valueInspeedpc = currentOption.transformToSpeedPc(parseFloat(transformedInput));
    setError(!isValidSpeed(valueInspeedpc));
    setDisplayValue(transformedInput);
    if (isValidSpeed(valueInspeedpc)) {
      setRawSpeed(valueInspeedpc);
    }
  };


  const handleBlur = () => {
    if (!error && isValidSpeed(rawSpeed) && rawSpeed !== props.speedpc) {
      props.onBlur(Math.round(rawSpeed));
    }
  };

  const handleUnitClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuItemClick = (option: InputOption) => {
    if (option.unit !== currentOption.unit) {
      setCurrentOption(option);
      if (!isNaN(rawSpeed)) {
        setDisplayValue(roundToTwoDecimals(option.transformToUnit(rawSpeed)).toString());
      } else {
        setDisplayValue("");
      }
    }
    handleClose();
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <TextField
        sx={{ width: props.width, maxWidth: props.maxWidth, p: 0 }}
        disabled={props.disabled}
        label={currentOption.label}
        size="small"
        autoComplete="off"
        value={displayValue}
        onBlur={handleBlur}
        error={error}
        onChange={handleChange}
        inputRef={textFieldRef}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            e.preventDefault();
            e.stopPropagation();
            textFieldRef.current!.blur();
          }
        }}
        InputProps={{
          endAdornment: (
            <Button
              sx={{ px: 0, pr: 0, fontSize: "0.75rem", minWidth: "40px" }}
              disabled={props.disabled}
              onClick={handleUnitClick}
            >
              {currentOption.unit}
            </Button>
          ),
        }}
      />
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        {inputOptions.map((option) => (
          <MenuItem
            key={option.unit}
            selected={option.unit === currentOption.unit}
            onClick={() => handleMenuItemClick(option)}
          >
            {option.unit}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default SpeedDepthInput;

const roundToTwoDecimals = (value: number): number => {
  return Math.round(value * 100) / 100;
};

export const transformInput = (input: string, currentUnitofMeasure: string) => {
  const allowTwoDecimals = currentUnitofMeasure !== "%";
  if (allowTwoDecimals) {
    input = transformNumberStringTwoDecimals(input);
  } else {
    // Remove all non-digit characters
    input = input.replace(/[^0-9]/g, "");
  }
  return input;
};

export const transformNumberStringTwoDecimals = (input: string): string => {
  // Replace commas with dots
  input = input.replace(/,/g, ".");
  // Remove all non-digit and non-dot characters
  input = input.replace(/[^0-9.]/g, "");
  // Split by dot to handle decimals correctly
  let parts = input.split(".");
  if (parts.length > 1) {
    // Check if the first part is empty and prepend "0" if it is
    if (parts[0] === "") {
      parts[0] = "0";
    }
    input = parts[0] + "." + parts.slice(1).join("");
  } else if (parts[0] === "") {
    input = "";
  }
  // Remove all but two decimals
  if (/^\d+\.\d{3,}$/.test(input)) {
    input = input.replace(/(\.\d{2})\d+$/, "$1");
  }
  return input;
};
