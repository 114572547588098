import * as React from "react";
import GroupsApiCtx from "./GroupsApiCtx";
import { IGroup } from "./IGroupsApi";
import { v4 as uuidv4 } from "uuid";

const GroupsApiCtxProvider: React.FC<React.PropsWithChildren> = (props) => {
  const getGroups = (userId: string): IGroup[] => {
    return JSON.parse(localStorage.getItem(`groups-${userId}`) || "[]");
  };

  const setGroups = (groups: IGroup[], userId: string) =>
    localStorage.setItem(`groups-${userId}`, JSON.stringify(groups));

  const saveGroup = (group: IGroup, userId: string): IGroup[] => {
    const updatedEvents = [...getGroups(userId), { ...group, id: uuidv4() }];
    setGroups(updatedEvents, userId);
    return updatedEvents;
  };

  const deleteGroup = (group: IGroup, userId: string): IGroup[] => {
    const updatedEvents = getGroups(userId).filter((g) => g.id !== group.id);
    setGroups(updatedEvents, userId);
    return updatedEvents;
  };

  const editGroup = (group: IGroup, userId: string): IGroup[] => {
    const updatedGroups = getGroups(userId).map((g) =>
      g.id === group.id ? group : g
    );
    setGroups(updatedGroups, userId);
    return updatedGroups;
  };

  return (
    <GroupsApiCtx.Provider
      value={{ getGroups, saveGroup, deleteGroup, editGroup }}
    >
      {props.children}
    </GroupsApiCtx.Provider>
  );
};
export default GroupsApiCtxProvider;
