import React from "react";
import { Box } from "@mui/material";

interface StatusDotProps {
  status: "Online" | "Offline";
  sizePx?: number;
}

const StatusDot: React.FC<StatusDotProps> = ({ status, sizePx = 16 }) => {
  return (
    <Box
      component="span"
      sx={{
        height: sizePx,
        width: sizePx,
        bgcolor: status === "Online" ? "green" : "red",
        borderRadius: "50%",
        display: "inline-block",
      }}
    />
  );
};

export default StatusDot;
