import * as React from "react";
import {
  DataGridPro,
  GridRowsProp,
  GridColDef,
  GridEventListener,
  GridToolbarContainer,
  GridToolbarQuickFilter,
  useGridApiRef,
  GridGroupNode,
  GridRenderCellParams,
} from "@mui/x-data-grid-pro";
import IBackgroundData from "../../context/BackgroundDataCtx/IBackgroundData";
import { useNavigate } from "react-router-dom";
import BackgroundDataCtx from "../../context/BackgroundDataCtx/BackgroundDataCtx";
import GroupsApiCtx from "../../context/GroupsApiCtx/GroupsApiCtx";
import { useCallback, useContext, useMemo, useRef } from "react";
import StatusDot from "../../components/ui/StatusDot";
import CommandsDialog from "./CommandsDialog";
import IUnitData from "../../context/BackgroundDataCtx/IUnitData";
import { Button } from "@mui/material";

interface UnitRow {
  id: string;
  unitId: string;
  name: string;
  status: string;
  connection: string;
  ETA: string;
}
interface ExpansionState {
  [key: string]: boolean;
}

const GroupGrid: React.FC = () => {
  const navigate = useNavigate();
  const bgdata: IBackgroundData = useContext(BackgroundDataCtx);
  const [openCommadsDialog, setOpenCommandsDialog] = React.useState(false);
  const apiRef = useGridApiRef();
  const expansionState = useRef<ExpansionState>({});
  const groupsApi = useContext(GroupsApiCtx);
  const [unitsForBatchCommands, setUnitsForBatchCommands] = React.useState<IUnitData[]>([]);

  React.useEffect(() => {
    apiRef.current.subscribeEvent("rowExpansionChange", (node) => {
      expansionState.current[node.id] = node.childrenExpanded ?? false;
    });
  }, [apiRef]);

  const isGroupExpanded = useCallback(
    (node: GridGroupNode) => expansionState.current[node.id] ?? false,
    [expansionState]
  );

  React.useEffect(() => {
    if (unitsForBatchCommands.length > 0) {
      setUnitsForBatchCommands(unitsForBatchCommands.map((unit) => bgdata.filteredUnits[unit.id]));
    }
  }, [bgdata]);

  const rowsGroup: GridRowsProp<UnitRow> = useMemo(() => {
    return Object.values(bgdata.filteredUnits)
      .filter((u) => u.groups.length > 0)
      .flatMap((unit, index1) => {
        return unit.groups.map((g, index2) => {
          return {
            id: `${unit.id}-${index1}-${index2}`,
            path: [g.name, unit.name],
            unitId: unit.id,
            name: unit.name,
            status: unit.status,
            connection: unit.connection,
            ETA: unit.ETA,
          };
        });
      });
  }, [bgdata.filteredUnits]);

  const columns: GridColDef[] = [
    { field: "unitId", headerName: "ID", width: 85 },
    { field: "status", headerName: "Status", width: 150 },
    {
      field: "connection",
      headerName: "Connection",
      width: 150,
      renderCell: (params) => {
        if (!params.value) return <></>;
        return (
          <>
            <StatusDot status={params.value} sizePx={14} />
            <span style={{ marginLeft: 6 }}>{params.value}</span>
          </>
        );
      },
    },
    { field: "ETA", headerName: "ETA", flex: 1, minWidth: 150 },
    {
      field: "opt",
      headerName: "",
      minWidth: 190,
      sortable: false,
      filterable: false,
      renderCell: (params) => {
        // if row is unit row, don't show options
        if (params.row.unitId) return <></>;
        return (
          <Button
            variant="outlined"
            onClick={(e) => {
              e.stopPropagation();
              const units = getUnitsInGroup(params);
              if (!units) return;
              setUnitsForBatchCommands(units);
              setOpenCommandsDialog(true);
            }}
          >
            Group commands
          </Button>
        );
      },
      disableColumnMenu: true,
    },
  ];

  const getUnitsInGroup = (rowParams: GridRenderCellParams) => {
    const groupName = getGroupName(rowParams.id.toString());
    const groups = groupsApi.getGroups(bgdata.user.name);
    return groups.find((group) => group.name === groupName)?.units;
  };

  const getGroupName = (groupRowId: string) => {
    return groupRowId.split("/")[1];
  };

  const rowClicked: GridEventListener<"rowClick"> = (params, event, details) => {
    if (!params.row.unitId) {
      navigate(`/groups/`);
      return;
    }
    navigate(`/units/${params.row.unitId}`);
  };

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarQuickFilter />
      </GridToolbarContainer>
    );
  }

  const getTreeDataPathMemo = useRef((row) => row.path);
  const groupingColDef: GridColDef = {
    headerName: "Group",
    field: "__tree_data_path__",
  };

  return (
    <>
      <DataGridPro
        disableRowSelectionOnClick
        apiRef={apiRef}
        isGroupExpandedByDefault={isGroupExpanded}
        treeData
        getTreeDataPath={getTreeDataPathMemo.current}
        groupingColDef={groupingColDef}
        onRowClick={rowClicked}
        rows={rowsGroup}
        columns={columns}
        slots={{ toolbar: CustomToolbar }}
        slotProps={{
          toolbar: {
            showQuickFilter: true,
          },
        }}
      />
      <CommandsDialog
        open={openCommadsDialog}
        onClose={() => {
          setOpenCommandsDialog(false);
        }}
        units={unitsForBatchCommands}
        bgdata={bgdata}
      />
    </>
  );
};

export default GroupGrid;
