import { Feature, FeatureCollection, Position } from "geojson";
import IUnitData from "../../context/BackgroundDataCtx/IUnitData";
import { feetToMeters, feetToMiles } from "../../helpers/Conversions";
import { Segment, simplifySegments, getCoordinatesOnCircleFromAngle, getLateralRectPointsForUnit, getSecondaryCoordinates, getRectPoints, distFromKeyPoints, buildRainDropPolygonCoordinates, buildDiamondPolygonCoordinates, getAngleFromPoint, getAngleBetweenTwoPoints } from "./MapHelper";
import { circle, point, bearing, destination, lineString, along, lineDistance, length, angle, lineArc, distance } from "@turf/turf";
import { UnitData } from "../../context/BackgroundDataCtx/IBackgroundData";

export const buildUnitCoverageAreaZoomedOut = (unit: IUnitData): Feature[] =>{
    var features: Feature[] = [];
    features.push({ type: 'Feature',
    geometry: {
        type: 'Point',
        coordinates:[unit.longitude, unit.latitude]
    },
    properties: {type: "symbol", icon: "teardrop", iconOffset: [0,13], iconSize: 0.5, latitude: unit.latitude, longitude: unit.longitude}, }); 

    return features;
}
    
export const buildUnitCoverageArea = (
    unit: IUnitData,
    baseColor?: string
): Feature => {
    var points: any[] = [];
    var opacity = unit.sensor["position"] && unit.sensor["position"].last && unit.sensor["position"].last.value && unit.unitState.parkAngle !== undefined && unit.unitState.parkAngle !== null ?
        0 : 0.25; //when park angle and current position is set we color only the space between these 2
    if (unit.systemType === "Pivot") {
        if (!unit.barricade) {
            return circle([unit.longitude, unit.latitude], unit.lengthFeet, {
                units: "feet",
                properties: {
                    type: "base",
                    kind: "irrigatedArea",
                    statusColor: unit.statusColor,
                    color: baseColor,
                    opacity: opacity,
                    latitude: unit.latitude, 
                    longitude: unit.longitude
                },
            });
        } else {
            //when there's a physical barricade the base need to be drawn without that section
            var middleAdded = false;
            for (let i = 0; i < 360; i++) {
                if(((unit.barricade[0] < unit.barricade[1] && (i >= unit.barricade[1] || i <= unit.barricade[0]))) || (
                    unit.barricade[0] > unit.barricade[1] && ( i <= unit.barricade[0] && i >= unit.barricade[1])
                )){
                    points.push( getCoordinatesOnCircleFromAngle([unit.longitude, unit.latitude], feetToMeters(unit.lengthFeet), i));
                }else{
                    if(!middleAdded){
                        points.push([unit.longitude, unit.latitude]);
                        middleAdded = true;
                    }
                }               
            }
            points.push(points[0]);
        }
    } else {
        points = getLateralRectPointsForUnit(unit);
        points.push(points[0]);
    }
    var f: Feature = { type: "Feature", geometry: { type: "Polygon", coordinates: [points] }, 
        properties: { type: "base", statusColor: unit.statusColor, opacity: opacity, latitude: unit.latitude, longitude: unit.longitude}, };

    if (baseColor) {
        (f.properties as any).color = baseColor;
    }

    return f;
};

export const buildBadgeFeatures = (unit: IUnitData): Feature[] => {
    var features: Feature[] = [];
    const offlineBadgeColor = "#EAAB00";
    
    const circleColorZoomedOut = unit.connection === "Offline" ? offlineBadgeColor : unit.statusColor;
    const circleColorZoomedIn = unit.connection === "Offline" ? offlineBadgeColor : "white";
    features.push(point([unit.longitude, unit.latitude], { title: unit.name, statusColor: circleColorZoomedOut, type: "badgeBaseZoomedOut",}));
    if(unit.systemType === "Pivot"){
        features.push(point([unit.longitude, unit.latitude], { title: unit.name, statusColor: circleColorZoomedIn, type: "badgeBaseZoomedIn", circleRadius: unit.connection === "Offline" ? 20 : 10}));
    }else{
        if(unit.connection === "Offline"){ //for laterals we don't need the white circle only badge if there's an error
            features.push(point([unit.longitude, unit.latitude], { title: unit.name, statusColor: offlineBadgeColor, type: "badgeBaseZoomedIn", circleRadius: 20 }));
        }
    }
    if(unit.connection === "Offline"){ //add offline badge
        features.push({ type: 'Feature',
        geometry: {
            type: 'Point',
            coordinates:[unit.longitude, unit.latitude]
        },
        properties: {type: "badgeIcon", icon: "connectionLost", iconOffset: [0,10.7], iconSize: 0.03},});
    }

    return features;
}

export const buildOperationSymbols = (unit: IUnitData): Feature[] => {
    var features: Feature[] = [];

    const coords = getCoordinatesOnCircleFromAngle([unit.longitude, unit.latitude], feetToMeters(unit.lengthFeet), unit.sensor["position"].last.value);
    if(unit.systemType === "Pivot"){    
        var coordinates = [[unit.longitude, unit.latitude], coords];
      
    }else{
        var pos = getLateralRectPointsForUnit(unit, feetToMeters(unit.sensor["position"].last.value)); 
        var coordinates =  [pos[2], pos[3]];
    }

    const line = lineString(coordinates);
    const circleRadiusFeet = unit.unitState.chemPump ? unit.lengthFeet / 18 : unit.lengthFeet / 20;
    // Get the total length of the line
    const totalLength = length(line,  {units: 'meters'}) - feetToMeters(circleRadiusFeet); 
    // Calculate the interval between each coordinate
    const numPoints =  12; // Including the start and end points
    const interval = totalLength / (numPoints - 1);
    const points: number[][] = [];
    for (let i = 1; i < numPoints; i++) {
        const distance = i * interval - feetToMeters(circleRadiusFeet / 2); //todo check
        const point = along(line, distance, {units: 'meters'});
        points.push(point.geometry.coordinates);
      }
    
    const fullOpacityIndexes: number[] = [0,  Math.round((points.length - 1) / 2), points.length - 1];
    var angleLat = 0;
    if(unit.systemType === "Lateral"){
        var pos = getLateralRectPointsForUnit(unit, feetToMeters(unit.sensor["position"].last.value)); 
        angleLat = angle(getCoordinatesOnCircleFromAngle(pos[3], feetToMeters(circleRadiusFeet), 0), pos[3], points[0]);
    }

  
    points.forEach((p, index) => {
        var fe: Feature =  { 
            type: "Feature", 
            geometry: {    
                type: "Polygon", 
                coordinates: [unit.unitState.chemPump ? buildDiamondPolygonCoordinates(p, feetToMeters(circleRadiusFeet), unit, angleLat) : buildRainDropPolygonCoordinates(p, feetToMeters(circleRadiusFeet), unit, angleLat)] }, 
            properties: {
                type: "program",
                hideLines: "true",
                color: unit.unitState.chemPump ? "#8CDD24" : "#389CE4",
                opacity: fullOpacityIndexes.findIndex(x => x === index) > -1 ? 0.9 : 0.8 / distFromKeyPoints(fullOpacityIndexes, index),
            },};
        features.push(fe);
    });

    return features; 
}

export const buildEndgunAuxStatus = (unit: IUnitData): Feature[] => {
    var features: Feature[] = [];
    
    if(unit.sensor["position"] && unit.sensor["position"].last && unit.sensor["position"].last.value){
        const currentAngleOrPosition = unit.sensor["position"].last.value;
        if (unit.systemType === "Pivot") {
            const gunSizeInner = 50; 
            const gunSizeOuter = 80;
            const gunMidPos = getCoordinatesOnCircleFromAngle([unit.longitude, unit.latitude], feetToMeters(unit.lengthFeet), currentAngleOrPosition);          
            const innerCircleAngleStart =  currentAngleOrPosition - 5 < 360 ? currentAngleOrPosition - 5 + 360 : currentAngleOrPosition - 5;
            const innerCircleAngleEnd = currentAngleOrPosition + 5 > 360 ? currentAngleOrPosition + 5 - 360 : currentAngleOrPosition + 5;
            const innerCircleCoordinateStart = getCoordinatesOnCircleFromAngle([unit.longitude, unit.latitude], feetToMeters(unit.lengthFeet), innerCircleAngleStart);
            const innerCircleCoordinateEnd = getCoordinatesOnCircleFromAngle([unit.longitude, unit.latitude], feetToMeters(unit.lengthFeet), innerCircleAngleEnd);
            
            features = getEndgunFeaturesFrom3Points(gunMidPos, innerCircleCoordinateStart, innerCircleCoordinateEnd, gunSizeInner, gunSizeOuter, unit);
        }else{
            const rect2 = getLateralRectPointsForUnit(unit, feetToMeters(unit.lengthFeet));
            const b = bearing(rect2[0], rect2[3]);
            const extensionDistance = 100; // Distance in meters
            const gunSizeInner = 10; //m
            const gunSizeOuter = 20;//m

            // Create new points at the extended ends of the line
            const startPointExtended = destination(rect2[0], -extensionDistance, b, {units: 'meters'});
            const endPointExtended = destination(rect2[3], extensionDistance, b, {units: 'meters'});

            // Connect the original line with the extended points
            const extendedLine = lineString([startPointExtended.geometry.coordinates, endPointExtended.geometry.coordinates]);

            const point1 = along(extendedLine, extensionDistance + feetToMeters(currentAngleOrPosition) + gunSizeInner, {units: 'meters'});
            const middle = along(extendedLine, extensionDistance + feetToMeters(currentAngleOrPosition), {units: 'meters'});
            const point2 = along(extendedLine, extensionDistance + feetToMeters(currentAngleOrPosition) - gunSizeInner, {units: 'meters'});
            var p: Position[] = [];
            p.push(point1.geometry.coordinates);
            p.push(point2.geometry.coordinates);

            features = getEndgunFeaturesFrom3Points(middle.geometry.coordinates, point2.geometry.coordinates, point1.geometry.coordinates, gunSizeInner, gunSizeOuter, unit);
        }     
    }

    return features;
}

const getEndgunFeaturesFrom3Points = (gunMidPos: Position, startCoord: Position, endCoord: Position, gunSizeInner: number, gunSizeOuter: number, unit: IUnitData) => {
    var features: Feature[] = [];
    var innerCirclePoints: Position[] = [];
    var outerCirclePoints: Position[] = [];

    const halfInnerCircleStartAngle = getAngleBetweenTwoPoints(startCoord, gunMidPos);
    const halfInnerCircleEndAngle = getAngleBetweenTwoPoints(endCoord, gunMidPos);
    if(halfInnerCircleStartAngle < halfInnerCircleEndAngle){
        for(let i = halfInnerCircleStartAngle; i <= halfInnerCircleEndAngle; i++){
            innerCirclePoints.push(getCoordinatesOnCircleFromAngle(gunMidPos, gunSizeInner, i));
            outerCirclePoints.push(getCoordinatesOnCircleFromAngle(gunMidPos, gunSizeOuter, i));
        }
    }else{
        for(let i = halfInnerCircleStartAngle; i < 360; i++){
            innerCirclePoints.push(getCoordinatesOnCircleFromAngle(gunMidPos, gunSizeInner, i));
            outerCirclePoints.push(getCoordinatesOnCircleFromAngle(gunMidPos, gunSizeOuter, i));
        }
        for(let i = 0; i < halfInnerCircleEndAngle; i++){
            innerCirclePoints.push(getCoordinatesOnCircleFromAngle(gunMidPos, gunSizeInner, i));
            outerCirclePoints.push(getCoordinatesOnCircleFromAngle(gunMidPos, gunSizeOuter, i));
        }
    }

    var c1:  Position[] = [...innerCirclePoints];
    var c2:  Position[] = [...innerCirclePoints];
    c1.push(gunMidPos);        
    outerCirclePoints.reverse().forEach(p => c2.push(p));

    const endgunOn = unit.unitState.endGun || false;
    const auxOn = unit.unitState.aux1 || false;

    features.push({ 
        type: "Feature", 
        geometry: { type: "Polygon",  coordinates: [c1] }, 
        properties: { type: "program", color: auxOn ? "#389CE4" : "transparent", opacity: 0.4, hideLines: "true", }});

    features.push({ 
        type: "Feature", 
        geometry: { type: "Polygon",  coordinates: [c2] }, 
        properties: { type: "program", color: endgunOn ? "#389CE4" : "transparent", opacity: 0.4, hideLines: "true", }});

    features.push({
        type: "Feature",
        geometry: { type: "LineString", coordinates: innerCirclePoints},
        properties: { type: "program", color: auxOn ? "#389CE4" : "#868F96", width: 6, hideInner: "true" },
    });
    features.push({
        type: "Feature",
        geometry: { type: "LineString", coordinates: outerCirclePoints },
        properties: { type: "program", color: endgunOn ? "#389CE4" : "#868F96", width: 6, hideInner: "true" },
    });

    return features;
}

export const buildIrrigatedArea = (unit: IUnitData): Feature | null => {
    if(unit.sensor["position"] && unit.sensor["position"].last && unit.sensor["position"].last.value && 
        unit.unitState.parkAngle !== undefined && unit.unitState.parkAngle !== null){
            var points: Position[] = [];
            if (unit.systemType === "Pivot") {
                var middleAdded = false;         
                const start = unit.unitState.parkAngle;
                const end = unit.sensor["position"].last.value;
                for (let i = 0; i < 360; i++) {
                    if(((i >= start && i <= end)) ){
                        points.push( getCoordinatesOnCircleFromAngle([unit.longitude, unit.latitude], feetToMeters(unit.lengthFeet), i) as Position);
                    }else{
                        if(!middleAdded){
                            points.push([unit.longitude, unit.latitude]);
                            middleAdded = true;
                        }
                    }               
                }
                points.push(points[0]);
            }else{
                const rect1 = getLateralRectPointsForUnit(unit, feetToMeters(unit.unitState.parkAngle));
                const rect2 = getLateralRectPointsForUnit(unit, feetToMeters(unit.sensor["position"].last.value));
                points = [rect1[3], rect1[2], rect2[2], rect2[3], rect1[3]];
            }
           
            return { type: "Feature", geometry: { type: "Polygon", coordinates: [points] }, 
            properties: { type: "base", noBorder: "true", statusColor: unit.statusColor, opacity: 0.4, latitude: unit.latitude, longitude: unit.longitude}, };
    }
   return null;
}

export const buildSpeedIndLineString = (unit: IUnitData, patternLength: number): Feature => {
    const adjustedLength = unit.lengthFeet - (unit.lengthFeet / 100 * 5);
    return unit.systemType === "Pivot" ? buildPivotSpeedIndicator(unit, adjustedLength, patternLength) : buildLateralSpeedIndicator(unit, adjustedLength, patternLength);
}

const buildPivotSpeedIndicator = (unit: IUnitData, adjLength: number, patternLength: number ): Feature => {
    const steps =  Math.round(adjLength  / patternLength) * patternLength - 1;
    const startAngle = unit.barricade ? unit.barricade[0] : 0;
    const endAngle = unit.barricade ? unit.barricade[1] : 360;

    return lineArc([unit.longitude, unit.latitude], adjLength,  startAngle , endAngle, {steps: steps, units: "feet",});
}

const buildLateralSpeedIndicator = (unit: IUnitData, adjLength: number, patternLength: number): Feature => {
    const numCoordinates =  Math.round(( adjLength ) / patternLength) * patternLength;
    const {start, end} = getLateralSpeedIndicatorStartEndCoords(unit);
    let coordinates: Position[] = [];
    const [x1, y1] = start;
    const [x2, y2] = end;

    // linear interpolate should work for most field sizes?
    for (let i = 0; i < numCoordinates; i++) {
        const t = i / (numCoordinates - 1);
        const x = x1 + t * (x2 - x1);
        const y = y1 + t * (y2 - y1);
        coordinates.push([x, y]);
    }

    return { type: "Feature", geometry: { type: "LineString", coordinates: coordinates.reverse() }, 
        properties: { type: "speed", title: unit.name}};
}

const getLateralSpeedIndicatorStartEndCoords = (unit: IUnitData): {start: Position, end: Position} => {
    let start = 0;
    let end = unit.lengthFeet;
    let start2: number | undefined = undefined;
    let end2: number | undefined = undefined;
    if(unit.barricade){
        if(unit.barricade[0] < unit.barricade[1]){
            start = unit.barricade[0];
            end = unit.barricade[1];
        }else{
            start = unit.barricade[0];
            start2 = 0;
            end2 = unit.barricade[1];
        }
    }
    
    const br = bearing([ unit.longitude, unit.latitude], getSecondaryCoordinates(unit));
    const destin = destination([ unit.longitude, unit.latitude], 0.01, br, {units: 'miles'});
    let offset1 = destin.geometry.coordinates; 
    let offset2 = getSecondaryCoordinates(unit);
    const rect1 = getRectPoints( offset1[0], offset1[1], offset2[0], offset2[1], feetToMeters(start));
    const rect2 = getRectPoints( offset1[0], offset1[1], offset2[0], offset2[1], feetToMeters(end));

    return {start: rect1[3], end: rect2[3]};
}

export const buildParkPosition = (unit: IUnitData): Feature | null => {
    if(unit.unitState.park && unit.unitState.parkAngle !== undefined && unit.unitState.parkAngle !== null){ 
        var coordinates: number[][] = [];   
        if(unit.systemType === "Pivot"){    
            const coords = getCoordinatesOnCircleFromAngle([unit.longitude, unit.latitude], feetToMeters(unit.lengthFeet), unit.unitState.parkAngle);
            coordinates = [[unit.longitude, unit.latitude], coords];
          
        }else{
            var pos = getLateralRectPointsForUnit(unit, feetToMeters(unit.unitState.parkAngle)); 
            var coordinates =  [pos[2], pos[3]]
        }
    
        return {
            type: "Feature",
            geometry: {
                type: "LineString",
                coordinates: coordinates,
            },
            properties: { type: "program", color: "#EAAB00", width: 2 },
        };
    }
    
    return null;
}

export const buildCurrentPosition = (unit: IUnitData): Feature[] => {
    var features: Feature[] = [];
    const coords = getCoordinatesOnCircleFromAngle([unit.longitude, unit.latitude], feetToMeters(unit.lengthFeet), unit.sensor["position"].last.value);
    if(unit.systemType === "Pivot"){    
        var coordinates = [[unit.longitude, unit.latitude], coords];
      
    }else{
        var pos = getLateralRectPointsForUnit(unit, feetToMeters(unit.sensor["position"].last.value)); //TODO: check what measurement is used
        var coordinates =  [pos[2], pos[3]]
    }

    features.push({
        type: "Feature",
        geometry: {
            type: "LineString",
            coordinates: coordinates,
        },
        properties: { type: "program", color: "white", width: 8 },
    });

    features.push({
        type: "Feature",
        geometry: {
            type: "LineString",
            coordinates: coordinates,
        },
        properties: { type: "program", color: unit.statusColor, width: 4 },
    });

    return features;
}

export const buildUnitsPointFeatures = (units: IUnitData[]): Feature[] => {
    return units.map((unit) => {
        return {
          type: "Feature",
          properties: {
            unitId: unit.id,
          },
          geometry: {
            type: "Point",
            coordinates: [unit.longitude, unit.latitude],
          },
        };
    }) as Feature[];
}
