import React from "react";
import { IGroup } from "../../context/GroupsApiCtx/IGroupsApi";
import {
  Card,
  Stack,
  Typography,
  IconButton,
  Divider,
  Menu,
  MenuItem,
} from "@mui/material";
import AddNewUnitMenu from "./AddNewUnitMenu";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import IUnitData from "../../context/BackgroundDataCtx/IUnitData";
import EditIcon from "@mui/icons-material/Edit";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import UnitsList from "../ui/UnitsList";

interface Props {
  group: IGroup;
  editGroup(group: IGroup): void;
  deleteGroup(group: IGroup): void;
  addUnit(unit: IUnitData, group: IGroup): void;
}

const GroupCard: React.FC<Props> = ({
  group,
  editGroup,
  deleteGroup,
  addUnit,
}) => {
  const [menuAnchorEl, setMenuAnchorEl] = React.useState<null | HTMLElement>(
    null
  );
  const openMenu = Boolean(menuAnchorEl);
  const closeMenu = () => setMenuAnchorEl(null);
  const handleEditGroup = () => {
    console.log("TODO: Edit group");
    closeMenu();
  };

  return (
    <Card key={group.id}>
      <Stack direction="row" alignItems={"center"} sx={{ p: 2 }}>
        <Typography variant="h6" sx={{ flexGrow: 1 }}>
          {group.name}
        </Typography>
        <IconButton
          onClick={(e: React.MouseEvent<HTMLButtonElement>) =>
            setMenuAnchorEl(e.currentTarget)
          }
        >
          <MoreHorizIcon />
        </IconButton>
      </Stack>
      <Divider />
      <Stack sx={{ px: 2, py: 1 }}>
        <UnitsList
          units={group.units}
          deleteUnit={(unitId) => {
            editGroup({
              ...group,
              units: group.units.filter((u) => u.id !== unitId),
            });
          }}
        />
        <AddNewUnitMenu
          unitsInGroup={group.units}
          handleAddUnit={(unit) => addUnit(unit, group)}
        />
      </Stack>
      <Menu anchorEl={menuAnchorEl} open={openMenu} onClose={closeMenu}>
        <MenuItem
          onClick={() => {
            closeMenu();
            handleEditGroup();
          }}
        >
          <EditIcon sx={{ mr: 2 }} />
          Edit
        </MenuItem>
        <MenuItem
          onClick={() => {
            deleteGroup(group);
            closeMenu();
          }}
        >
          <DeleteForeverIcon sx={{ mr: 2 }} />
          Delete
        </MenuItem>
      </Menu>
    </Card>
  );
};

export default GroupCard;
