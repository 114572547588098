import React, { useContext, useState } from "react";
import { TextField, Button, Box, Alert, Snackbar, Stack } from "@mui/material";
import BackgroundDataCtx from "../../context/BackgroundDataCtx/BackgroundDataCtx";

const PasswordChangeForm: React.FC = () => {
  const bgdata = useContext(BackgroundDataCtx);

  const [snack, setSnack] = React.useState<{
    type: string;
    message: string;
  } | null>(null);
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (newPassword !== confirmPassword) {
      setSnack({ type: "error", message: "Passwords do not match" });
      return;
    }
    const res = await bgdata.changePassord(currentPassword, newPassword);
    if (res === "PASSWORD_SUCCESSFULLY_CHANGED") {
      setSnack({ type: "success", message: "Password changed successfully" });
    } else {
      setSnack({ type: "error", message: "Failed to change password" });
    }
  };

  return (
    <Stack alignItems={"center"}>
      <Box
        component="form"
        onSubmit={handleSubmit}
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 2,
          width: "300px",
        }}
      >
        <TextField
          label="Current Password"
          type="password"
          value={currentPassword}
          onChange={(e) => setCurrentPassword(e.target.value)}
          required
        />
        <TextField
          label="New Password"
          type="password"
          value={newPassword}
          onChange={(e) => setNewPassword(e.target.value)}
          required
        />
        <TextField
          label="Confirm New Password"
          type="password"
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
          required
        />
        <Button type="submit" variant="contained" color="primary">
          Change Password
        </Button>
      </Box>
      <Snackbar
        open={!!snack}
        autoHideDuration={6000}
        onClose={() => setSnack(null)}
      >
        <Alert
          severity={snack?.type === "error" ? "error" : "success"}
          variant="filled"
          sx={{ width: "100%" }}
        >
          {snack?.message}
        </Alert>
      </Snackbar>
    </Stack>
  );
};

export default PasswordChangeForm;
