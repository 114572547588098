import { Stack, Typography, Checkbox, Switch } from "@mui/material";
import React, { useEffect, useState } from "react";
import { PumpSetting } from "../../context/BackgroundDataCtx/IUnitData";

interface Props {
  value: PumpSetting;
  onChange: (newValue: PumpSetting) => void;
  label?: string;
  disabled?: boolean;
}

const CheckboxSwitchInput: React.FC<Props> = ({
  onChange,
  label,
  disabled,
  value,
}) => {
  const [checked, setChecked] = useState<boolean>(value > 0);
  const [switched, setSwitched] = useState<boolean>(value === 1);

  useEffect(() => {
    if (value < 0) setSwitched(false);
    if (value === -1) setChecked(false);
  }, [value]);

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
    onChange(event.target.checked ? 0 : -1);
  };

  const handleSwitchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSwitched(event.target.checked);
    onChange(event.target.checked ? 1 : 0);
  };
  return (
    <Stack alignItems={"center"} sx={{ minWidth: "100px" }}>
      <Typography>{label}</Typography>
      <div>
        <Checkbox
          disabled={disabled}
          checked={checked}
          onChange={handleCheckboxChange}
        />
        <Switch
          disabled={disabled || value < 0}
          checked={switched}
          onChange={handleSwitchChange}
        />
      </div>
    </Stack>
  );
};

export default CheckboxSwitchInput;
