//
//Contains helper functions for extracting and transforming program data from the API response.
//
import dayjs from "dayjs";
import IUnitData, { IProgram, IBarricade, IVriSector, IEndGunAuxSector, IScheduledAction, Day, IUnitState } from "../context/BackgroundDataCtx/IUnitData";
import { IProgramResponse, ITimeDefinition, IBarricadeDefinition, ISectorDefinition, IEndGunDefinition, IAction } from "../context/RswsApiCtx/IProgramResponse";
import { ISaveProgramReq } from "../context/RswsApiCtx/IRswsApi";
import { v4 as uuidv4 } from "uuid";


export const getRunningProgram = (pUnits: IProgramResponse[]): IProgram | undefined => {
  const loadedProgUnits = pUnits.filter((p) => p.isLoaded)
  return loadedProgUnits.length > 0 ? buildProgram(loadedProgUnits) : undefined;
};

export const getPrograms = (programUnits: IProgramResponse[]): IProgram[] => {
  const programsByName = programUnits.reduce<
    Record<string, IProgramResponse[]>
  >((acc, pu) => {
    const name = extractName(pu.title);
    if (name) {
      acc[name] = acc[name] || [];
      acc[name].push(pu);
    }
    return acc;
  }, {});

  return Object.values(programsByName).map((programSortedByName) => buildProgram(programSortedByName));
};

const buildProgram = (
  programUnits: IProgramResponse[]
): IProgram => {
  const endGunProgram = getEndgunProgram(programUnits);
  const auxProgram = getAuxProgram(programUnits);
  const sectorProgram = getSectorProgram(programUnits);
  const barricadeProgram = getBarricadeProgram(programUnits);
  return {
    name: programUnits[0]?.title.split(":")[1],
    endGunSectors: getEndgunAuxSectors(endGunProgram),
    egId: endGunProgram?.id,
    auxSectors: getEndgunAuxSectors(auxProgram),
    auxId: auxProgram?.id,
    vriSectors: getVriSectors(sectorProgram),
    sectorId: sectorProgram?.id,
    barricade: getBarricadeSettings(barricadeProgram),
    barricadeId: barricadeProgram?.id,
    scheduledActions: getScheduledActions(programUnits),
  };
};

const getScheduledActions = (programUnits: IProgramResponse[]): IScheduledAction[] => {
  const timePrograms = programUnits.filter((p) => p.type === "time") as (IProgramResponse & { definition: ITimeDefinition })[];

  return timePrograms.map((p) => {
    return {
      id: p.id,
      time: {
        runAtTime: p.definition.runAtTime,
        repeatOnDays: convertApiReapeatOnDaysStringToDayArr(p.definition.repeatOnDays),
      },
      state: getTimeProgStateFromActions(p.definition.actions)
    } as IScheduledAction;
  });
};

const getTimeProgStateFromActions = (actions: IAction[]): IUnitState => {
  return actions.reduce<IUnitState>((acc, action) => {
    switch (action.controlName) {
      case "Speed / Depth":
        acc.speedpc = action.value;
        break;
      case "Start":
        acc.running = action.value === 1;
        break;
      case "Auto Reverse":
        acc.direction = action.value === 0 ? "Forward" : "Reverse";
        break;
      case "Pump":
        acc.pump = action.value === 1;
        break;
      case "Chemigation":
        acc.chemPump = action.value === 1;
        break;
      case "Auxiliary":
        acc.aux1 = action.value === 1;
        break;
    }
    return acc;
  }, {} as IUnitState);
}



const getBarricadeSettings = (
  barricadeProgram: (IProgramResponse & {
    definition: IBarricadeDefinition;
  }) | undefined
): IBarricade | undefined => {

  if (!barricadeProgram) return undefined;

  return {
    forward: {
      position: barricadeProgram.definition.barricade.forward.position,
      autoRev: barricadeProgram.definition.barricade.forward.autoRev,
    },
    reverse: {
      position: barricadeProgram.definition.barricade.reverse.position,
      autoRev: barricadeProgram.definition.barricade.reverse.autoRev,
    },
  };
};

const getBarricadeProgram = (programUnits: IProgramResponse[]): (IProgramResponse & {
  definition: IBarricadeDefinition;
}) | undefined => {
  return findProgram<
    IProgramResponse & { definition: IBarricadeDefinition }
  >(programUnits, (p) => p.type === "barricade");
};

const getVriSectors = (sectorProgram: (IProgramResponse & {
  definition: ISectorDefinition;
}) | undefined): IVriSector[] => {


  return mapDefinition(sectorProgram, (p) =>
    p.definition.sectors.map((s) => ({
      begin: s.begin,
      end: s.end,
      forward: {
        pump: s.forward.pump,
        chem: s.forward.chem,
        speedpc: s.forward.speed,
      },
      reverse: {
        pump: s.reverse.pump,
        chem: s.reverse.chem,
        speedpc: s.reverse.speed,
      },
    }))
  );
};

const getSectorProgram = (programUnits: IProgramResponse[]): (IProgramResponse & {
  definition: ISectorDefinition;
}) | undefined => {
  return findProgram<
    IProgramResponse & { definition: ISectorDefinition }
  >(programUnits, (p) => p.type === "sector");
};

const getEndgunAuxSectors = (
  endGunProgram: (IProgramResponse & {
    definition: IEndGunDefinition;
  }) | undefined
): IEndGunAuxSector[] => {
  return endGunProgram
    ? endGunProgram.definition.endGunPairs.map(([begin, end]) => ({
      begin,
      end,
    }))
    : [];
};

const getEndgunProgram = (programUnits: IProgramResponse[]): (IProgramResponse & {
  definition: IEndGunDefinition;
}) | undefined => {
  return programUnits.find((p) => p.type === "endgun" && "controls" in p.definition && p.definition.controls === "eg") as (IProgramResponse & {
    definition: IEndGunDefinition;
  }) | undefined;
}

const getAuxProgram = (programUnits: IProgramResponse[]): (IProgramResponse & {
  definition: IEndGunDefinition;
}) | undefined => {
  return programUnits.find((p) => p.type === "endgun" && "controls" in p.definition && p.definition.controls === "aux") as (IProgramResponse & {
    definition: IEndGunDefinition;
  }) | undefined;
}

const extractName = (title: string): string | undefined => title.split(":")[1];

function findProgram<T>(
  programUnits: IProgramResponse[],
  predicate: (program: IProgramResponse) => boolean
): T | undefined {
  return programUnits.find(predicate) as T | undefined;
}

function mapDefinition<T, U>(
  program: T | undefined,
  mapper: (def: T) => U[]
): U[] {
  if (!program) {
    return [];
  }
  return mapper(program);
}

// Transform program to back  program units to use in request to API
export const transformProgramToApi = (
  unit: IUnitData,
  program: IProgram,
): ISaveProgramReq[] => {
  const programReqs: ISaveProgramReq[] = [];
  // Transform End Gun Sectors
  if (program.endGunSectors.length > 0) {
    programReqs.push({
      program: {
        definition: {
          unitName: unit.id,
          type: 'endgun',
          isPivot: unit.systemType === 'Pivot',
          actions: [],
          controls: "eg",
          endGunPairs: program.endGunSectors.map(s => [s.begin, s.end]),
        },
        title: `${uuidv4()}:${program.name}`,
        id: program.egId ? program.egId : null,
        propertyId: unit.property,
        type: 'endgun',
      }
    });
  }

  // Transform Aux Sectors
  if (program.auxSectors.length > 0) {
    programReqs.push({
      program: {
        definition: {
          unitName: unit.id,
          type: 'endgun',
          isPivot: unit.systemType === 'Pivot',
          actions: [],
          controls: "aux",
          endGunPairs: program.auxSectors.map(s => [s.begin, s.end]),
        },
        title: `${uuidv4()}:${program.name}`,
        id: program.auxId ? program.auxId : null,
        propertyId: unit.property,
        type: 'endgun',
      }
    });
  }

  // Transform VRI Sectors
  if (program.vriSectors.length > 0) {
    programReqs.push({
      program: {
        definition: {
          unitName: unit.id,
          type: 'sector',
          isPivot: unit.systemType === 'Pivot',
          actions: [],
          sectors: program.vriSectors.map(s => ({
            begin: s.begin,
            end: s.end,
            forward: {
              pump: s.forward.pump,
              chem: s.forward.chem,
              speed: s.forward.speedpc,
            },
            reverse: {
              pump: s.reverse.pump,
              chem: s.reverse.chem,
              speed: s.reverse.speedpc,
            },
          })),
          isVri: true,
        },
        title: `${uuidv4()}:${program.name}`,
        id: program.sectorId ? program.sectorId : null,
        propertyId: unit.property,
        type: 'sector',
      }
    });
  }

  // Transform Barricade
  if (program.barricade) {
    programReqs.push({
      program: {
        definition: {
          unitName: unit.id,
          type: 'barricade',
          isPivot: unit.systemType === 'Pivot',
          actions: [],
          barricade: {
            forward: {
              position: program.barricade.forward.position,
              autoRev: program.barricade.forward.autoRev,
            },
            reverse: {
              position: program.barricade.reverse.position,
              autoRev: program.barricade.reverse.autoRev,
            },
          },
        },
        title: `${uuidv4()}:${program.name}`,
        id: program.barricadeId ? program.barricadeId : null,
        propertyId: unit.property,
        type: 'barricade',
      }
    });
  }

  // Transform Scheduled Actions
  if (program.scheduledActions.length > 0) {
    program.scheduledActions.forEach((sa) => {
      programReqs.push({
        program: {
          definition: {
            unitName: unit.id,
            type: 'time',
            isPivot: unit.systemType === 'Pivot',
            actions: getTimeProgActionsFromState(sa.state, unit),
            fromMinutesInThePast: 15, // This seems to be hardcoded in the old RC2
            runAtTime: sa.time.runAtTime!,
            repeatOnDays: convertDayArrToApiRepeatOnDaysString(sa.time.repeatOnDays),
          },
          title: `${uuidv4()}:${program.name}`,
          id: sa.id ? sa.id : null,
          propertyId: unit.property,
          type: 'time',
        }
      });
    });
  }
  return programReqs;
};

const getTimeProgActionsFromState = (state: IUnitState, unit: IUnitData): IAction[] => {
  const actions: IAction[] = [];
  if (state.running !== undefined) {
    actions.push({
      controlName: "Start",
      sensorId: unit.sensor["running"]!.id,
      value: state.running ? 1 : 0,
    });

  }
  if (state.speedpc !== undefined) {
    actions.push({
      controlName: "Speed / Depth",
      sensorId: unit.sensor["speedpc"]!.id,
      value: state.speedpc,
    });
  }
  if (state.pump !== undefined) {
    actions.push({
      controlName: "Pump",
      sensorId: unit.sensor["pump"]!.id,
      value: state.pump ? 1 : 0,
    });
  }
  if (state.chemPump !== undefined) {
    actions.push({
      controlName: "Chemigation",
      sensorId: unit.sensor["chemPump"]!.id,
      value: state.chemPump ? 1 : 0,
    });
  }
  if (state.aux1 !== undefined) {
    actions.push({
      controlName: "Auxiliary",
      sensorId: unit.sensor["aux1"]!.id,
      value: state.aux1 ? 1 : 0,
    });
  }

  if (state.direction !== undefined) {
    actions.push({
      controlName: "Auto Reverse",
      sensorId: unit.sensor["direction"]!.id,
      value: state.direction === "Forward" ? 0 : 1,
    });
  }
  return actions;
}

export const getProgramUnitIds = (program: IProgram): number[] => {
  const ids: number[] = [];
  if (program.auxId) ids.push(program.auxId);
  if (program.egId) ids.push(program.egId);
  if (program.barricadeId) ids.push(program.barricadeId);
  if (program.sectorId) ids.push(program.sectorId);
  if (program.scheduledActions.length > 0) {
    program.scheduledActions.forEach((sa) => {
      ids.push(sa.id!);
    });
  }
  return ids;
}

const convertApiReapeatOnDaysStringToDayArr = (repeatOnDays: string): Day[] => {
  const days = repeatOnDays.split(",");
  if (days.length > 7) throw new Error("Cant have more than 7 days in repeatOnDays string.");
  return days.map((d) => d.slice(0, 3)) as Day[];
}

const convertDayArrToApiRepeatOnDaysString = (days: Day[]): string => {
  const dayMap = {
    Sun: "Sunday",
    Mon: "Monday",
    Tue: "Tuesday",
    Wed: "Wednesday",
    Thu: "Thursday",
    Fri: "Friday",
    Sat: "Saturday",
  };
  return days.map((d) => dayMap[d]).join(",");
}

export const filterOutEmptySectors = (
  sectors: IVriSector[] | IEndGunAuxSector[]
) => {
  return sectors.filter(
    (sector) =>
      !isNaN(sector.begin) &&
      !isNaN(sector.end) &&
      sector.begin !== null &&
      sector.end !== null &&
      !(sector.begin === 0 && sector.end === 0)
  );
};


const isValidSectorPair = (
  begin: number,
  end: number,
  min: number,
  max: number
) => {
  return (
    !isNaN(begin) && !isNaN(end) && begin >= min && begin <= max && end >= min && end <= max
  );
};

const isValidSpeedPc = (speedpc: number | null) => {
  if (speedpc === null) return true;
  return !isNaN(speedpc) && speedpc >= 0 && speedpc <= 100;
};

export const validateProgramInput = <T extends keyof IProgram>(
  settings: IProgram[T],
  key: T,
  unit: IUnitData
): { isValid: boolean; error?: string } => {
  if (!settings || Array.isArray(settings) && settings.length === 0) {
    // allow empty settings
    return { isValid: true };
  }
  const isPivot = unit.systemType === "Pivot";
  const min = 0;
  const max = isPivot ? 360 : unit.lengthFeet;
  switch (key) {
    case "endGunSectors":
    case "auxSectors":
      const egAuxSettings = settings as IEndGunAuxSector[];
      if (!egAuxSettings.every(sector => isValidSectorPair(sector.begin, sector.end, min, max))) {
        return { isValid: false, error: "Invalid sector input" };
      }
      break;
    case "vriSectors":
      const vriSectors = settings as IVriSector[];
      for (const sector of vriSectors) {
        if (!isValidSectorPair(sector.begin, sector.end, min, max)) {
          return { isValid: false, error: "Invalid sector input" };
        } else if (!isValidSpeedPc(sector.forward.speedpc) ||
          !isValidSpeedPc(sector.reverse.speedpc)) {
          return { isValid: false, error: "Invalid speed input" };
        }
      }
      break;
    case "barricade":
      const barricade = settings as IBarricade | undefined;
      if (
        barricade === undefined || !( !isNaN(barricade.forward.position) && barricade.forward.position >= min && barricade.forward.position <= max &&
        !isNaN(barricade.reverse.position) && barricade.reverse.position >= min && barricade.reverse.position <= max)) {
        return { isValid: false, error: "Invalid barricade input" };
      }
      break;
    case "scheduledActions":
      const scheduledActions = settings as IScheduledAction[];
      for (const action of scheduledActions) {
        if (
          action.time.runAtTime === "" ||
          dayjs(action.time.runAtTime).isBefore(dayjs())
        ) {
          return { isValid: false, error: "Invalid start date" };
        } else if (Object.keys(action.state).length === 0) {
          return { isValid: false, error: "Must have at least one action" };
        } else if (
          action.state.speedpc !== undefined &&
          !isValidSpeedPc(action.state.speedpc)
        ) {
          return { isValid: false, error: "Invalid speed input" };
        }
      }
      break;
  }
  return { isValid: true };
};