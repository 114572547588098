import React, { useMemo, useRef, useState } from "react";
import { TextField, MenuItem, Menu, Button } from "@mui/material";
import { cmMinToFtMin, cmToInches } from "../../helpers/Conversions";
import { canCalcDepth, ftMinToSpeedPc, getSpeedPcFromDepth } from "../../helpers/unitUtils";
import IUnitData from "../../context/BackgroundDataCtx/IUnitData";
import { IUnitSettings } from "../../context/RswsApiCtx/IRswsApi";
import { IUnitsCapabilitiesAndSettings } from "../../routes/UnitList/CommandsDialog";
import { transformInput } from "./SpeedDepthInput";

interface InputOption {
  unit: string;
  label: string;
  transformToSpeedPc: (value: number, unit: IUnitData, unitSettings: IUnitSettings) => number;
}

interface Props {
  onBlur: (newSpeedPcValues: { [unitId: string]: number }) => void;
  disabled?: boolean;
  width?: string;
  maxWidth?: string;
  units: IUnitData[];
  unitsCapabilitiesAndSettings: IUnitsCapabilitiesAndSettings;
}
const MultipleUnitsSpeedDepthInput: React.FC<Props> = (props: Props) => {
  const [error, setError] = useState<boolean>(false);
  const maxSpeedPc = 100;
  const textFieldRef = useRef<HTMLInputElement>(null);

  const inputOptions: InputOption[] = useMemo(
    () =>
      [
        {
          unit: "%",
          label: "Speed",
          transformToSpeedPc: (value: number, unit: IUnitData, unitSettings: IUnitSettings) => value,
        },
        {
          unit: "ft/min",
          label: "Speed",
          transformToSpeedPc: (value: number, unit: IUnitData, unitSettings: IUnitSettings) => {
            return ftMinToSpeedPc(value, unit, unitSettings);
          },
        },
        {
          unit: "cm/min",
          label: "Speed",
          transformToSpeedPc: (value: number, unit: IUnitData, unitSettings: IUnitSettings) => {
            return cmMinToFtMin(ftMinToSpeedPc(value, unit, unitSettings));
          },
        },
        {
          unit: "in",
          label: "Depth",
          transformToSpeedPc: (value: number, unit: IUnitData, unitSettings: IUnitSettings) => {
            return getSpeedPcFromDepth(unit, value, maxSpeedPc)!;
          },
        },
        {
          unit: "cm",
          label: "Depth",
          transformToSpeedPc: (value: number, unit: IUnitData, unitSettings: IUnitSettings) => {
            return getSpeedPcFromDepth(unit, cmToInches(value), maxSpeedPc)!;
          },
        },
      ].filter((opt) =>
        props.units?.every((unit) => {
          if (opt.label === "Depth") {
            return canCalcDepth(unit);
          } else if (unit.systemType === "Lateral" && (opt.unit === "ft/min" || opt.unit === "cm/min")) {
            const unitSettings = props.unitsCapabilitiesAndSettings[unit.id]?.settings;
            return unitSettings.feetperhour !== undefined;
          } else {
            return true;
          }
        })
      ),
    []
  );
  const [currentOption, setCurrentOption] = useState<InputOption>(inputOptions[0]);
  const [displayValue, setDisplayValue] = useState<string>("");
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const isValidSpeed = (speed: string): boolean => {
    return props.units.every((unit) => {
      const unitSettings = props.unitsCapabilitiesAndSettings[unit.id]?.settings;
      const transformedSpeed = currentOption.transformToSpeedPc(parseFloat(speed), unit, unitSettings);
      return transformedSpeed >= 1 && transformedSpeed <= maxSpeedPc;
    });
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const transformedInput = transformInput(event.target.value, currentOption.unit);
    if (transformedInput === displayValue) return;
    setError(!isValidSpeed(transformedInput));
    setDisplayValue(transformedInput);
  };

  const handleBlur = () => {
    if (!error && displayValue !== "") {
      const speedsPc = props.units.reduce((acc, unit) => {
        const unitSettings = props.unitsCapabilitiesAndSettings[unit.id]?.settings;
        acc[unit.id] = Math.round(currentOption.transformToSpeedPc(parseFloat(displayValue), unit, unitSettings));
        return acc;
      }, {} as { [unitId: string]: number });
      props.onBlur(speedsPc);
    }
  };

  const handleUnitClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuItemClick = (option: InputOption) => {
    if (option.unit !== currentOption.unit) {
      setCurrentOption(option);
      setDisplayValue("");
    }
    handleClose();
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <TextField
        sx={{ width: props.width, maxWidth: props.maxWidth, p: 0 }}
        disabled={props.disabled}
        label={currentOption.label}
        size="small"
        autoComplete="off"
        value={displayValue}
        onBlur={handleBlur}
        error={error}
        onChange={handleChange}
        inputRef={textFieldRef}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            e.preventDefault();
            e.stopPropagation();
            textFieldRef.current!.blur();
          }
        }}
        InputProps={{
          endAdornment: (
            <Button
              sx={{ px: 0, pr: 0, fontSize: "0.75rem", minWidth: "40px" }}
              disabled={props.disabled}
              onClick={handleUnitClick}
            >
              {currentOption.unit}
            </Button>
          ),
        }}
      />
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        {inputOptions.map((option) => (
          <MenuItem
            key={option.unit}
            selected={option.unit === currentOption.unit}
            onClick={() => handleMenuItemClick(option)}
          >
            {option.unit}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default MultipleUnitsSpeedDepthInput;
