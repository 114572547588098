import { Box, Tab, Tabs, useMediaQuery, useTheme } from "@mui/material";
import * as React from "react";
import TopNav from "../../components/Navigation/TopNav";
import AccountInfo from "./AccountInfo";
import ChangePass from "./ChangePass";

const AccountPage: React.FC = () => {
    const theme = useTheme();
    const isBelowSm = useMediaQuery(theme.breakpoints.down("sm"));
  const [tab, setTab] = React.useState("your-info");


  return (
    <Box
      sx={{
        height: "100vh",
        overflow: "hidden",
      }}
    >
      <TopNav />
      <Box
        sx={{
          height: {
            xs: "calc(100% - 56px)",
            sm: "calc(100% - 64px)",
          },
          mt: {
            xs: "56px",
            sm: "64px",
          },
          display: "flex",
          flexDirection: isBelowSm ? "column" : "row",
          overflow: "auto",

        }}
      >
        <Tabs
            orientation={isBelowSm ? "horizontal" : "vertical"}
            variant="scrollable"
            value={tab}
            onChange={(e, newVal) => setTab(newVal)}
            sx={isBelowSm ? { borderBottom: 1, borderColor: "divider" } : { borderRight: 1, borderColor: "divider", minWidth: 175 }}
        >
            <Tab label="Your Info" value={"your-info"} />
            <Tab label="Change Password" value={"change-pass"} />
        </Tabs>
        <Box sx={{ flexGrow: 1, padding: 2 }}>
          {tab === "your-info" && <AccountInfo />}
          {tab === "change-pass" && <ChangePass />}
        </Box>
      </Box>
    </Box>
  );
};

export default AccountPage;
