import React, { FC, useEffect, useState } from "react";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";

interface Props {
  id: string;
  value: number;
  onChange: (newValue: number) => void;
  label: string;
  disabled?: boolean;
  onValue?: number; // Value to be considered as "on"
  offValue?: number; // Value to be considered as "off"
}

const SwitchEditor: FC<Props> = ({
  id,
  value,
  onChange,
  label,
  disabled = false,
  onValue = 1, // Default to 1 for "on"
  offValue = 0, // Default to 0 for "off"
}) => {
  // A value greater than or equal to onValue is considered "on"
  const isOn = value >= onValue;
  const [checked, setChecked] = useState<boolean>(isOn);

  // Update checked state when the value or onValue changes
  useEffect(() => {
    // A value greater than or equal to onValue is considered "on"
    setChecked(value >= onValue);
  }, [value, onValue, offValue]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.checked ? onValue : offValue;
    onChange(newValue);
  };

  return (
    <FormControl component="fieldset" fullWidth id={id}>
      <FormLabel component="legend">{label}</FormLabel>
      <FormControlLabel
        sx={{ width: "min-content" }}
        control={
          <Switch
            checked={checked}
            onChange={handleChange}
            name="switch"
            color="primary"
            disabled={disabled}
          />
        }
        label={checked ? "Enabled" : "Disabled"}
        labelPlacement="start"
      />
    </FormControl>
  );
};

export default SwitchEditor;
