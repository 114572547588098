import { IProgram } from "../context/BackgroundDataCtx/IUnitData";
import { ISensorData } from "../context/RswsApiCtx/IRswsApi";
import IUserSettings, {
  UnitColorStatus,
} from "../context/UserSettingsCtx/IUserSettings";
import { mmToInches } from "./Conversions";

const sensorMapping = {
  running: 8240,
  direction: 8241,
  speedpc: 8203,
  minDepth: 8303,
  park: 8246,
  pump: 8242,
  chemPump: 8243,
  endGun: 8245,
  aux1: 8244,
  aux2: 8253,
  aux3: 8254,
  aux4: 8255,
  aux5: 8256,
  rainfall: 8210,
  status: 8252,
  interrogation: 8308,
  safety: 8250,
  g1g1Control: 8300,
  externalPower: 8249,
  pressureSw: 8247,
  wetSource: 8301,
  actualPressure: 8230,
  isTheft: 8251,
  totalRain: 8212,
  isPivot: 8248,
  fieldLength: 8205,
  appliedDepth: 8207,
  autoRestart: 8312,
  eos: 8257,
  ignoreSafety: 8314,
} as const;

export type SensorName = keyof typeof sensorMapping;

class Unit {
  sensors: ISensorData[] = [];
  sensor: { [key: string]: ISensorData } = {};
  typeId: number;
  unitOfMeasure: number;
  settingsOEM: any;

  constructor(rsUnit: any) {
    Object.assign(this, rsUnit);

    // Map 'standard sensors' to friendly names
    Object.keys(sensorMapping).forEach((key) => {
      this.sensor[key] =
        this.sensors.find((s) => s.typeId === sensorMapping[key]) ||
        ({} as ISensorData);
    });

    // The position sensor has to be further disambiguated by the subPort number
    this.sensor["position"] =
      this.sensors.find((s) => s.typeId === 8204 && s.subPort === 1) ||
      ({} as ISensorData);
    this.sensor["parkAngle"] =
      this.sensors.find((s) => s.typeId === 8204 && s.subPort === 2) ||
      ({} as ISensorData);
    this.sensor["forwardBarricade"] =
      this.sensors.find((s) => s.typeId === 8204 && s.subPort === 4) ||
      ({} as ISensorData);
    this.sensor["reverseBarricade"] =
      this.sensors.find((s) => s.typeId === 8204 && s.subPort === 5) ||
      ({} as ISensorData);

    // // Add a reference to the containing Unit to each sensor object
    // this.sensors.forEach((sensor) => {
    //   sensor.unit = this;
    // });
  }

  getStatusColor = (userSettings: IUserSettings): string => {
    //TODO map all statuses correctly, these are the old one, but there are missing status conditions like Effluent etc
    if (this.getConnectionStatus() === "Offline") {
      return userSettings.statusColors[UnitColorStatus.Offline];
      // return "#787878"; //greyMid;
    } else {
      if ((!this.isSafe() && this.ignoreSafety() === false) || this.isTheft()) {
        //return "#c20f0f"; //redWarn
        return userSettings.statusColors[UnitColorStatus.Error];
      }

      if (!this.isRunning() && !this.isWet()) {
        // return "black"; //'black;
        return userSettings.statusColors[UnitColorStatus.Dry];
      }

      if (this.isChemPumpOn()) {
        return userSettings.statusColors[UnitColorStatus.Chemigation];
        // return "#2a781e"; //green;
      }

      if (this.isWet()) {
        // return "#0000ff"; //blueAqua;
        return userSettings.statusColors[UnitColorStatus.Wet];
      }

      //return "#96d48e"; //greenPastel;
      return userSettings.statusColors[UnitColorStatus.Default];
    }
  };

  isRunning = (): boolean => {
    return this.sensor.running?.last?.value === 1;
  };

  isSafe = (): boolean => {
    return this.sensor.safety?.last?.value === 1;
  };

  ignoreSafety = (): boolean => {
    return this.sensor.ignoreSafety?.last?.value === 1;
  };

  /**
   *check if unit is online/offline
   *	touch and pac3, use both sensor and AND logic
   *	g1/g2, check externalPower first, then status
   *	rbaseweb, use status only
   */
  getConnectionStatus = (): "Online" | "Offline" => {
    //for touch and pac3
    if (
      this.typeId === 8210 ||
      this.typeId === 8213 ||
      this.typeId === 8214 ||
      this.typeId === 8215 ||
      this.typeId === 8218 ||
      this.typeId === 8219
    ) {
      if (
        this.sensor.status.last === undefined ||
        this.sensor.externalPower.last === undefined
      ) {
        return "Offline";
      }

      if (
        this.sensor.status.last.value === 1 &&
        this.sensor.externalPower.last.value === 1
      ) {
        return "Online";
      } else {
        return "Offline";
      }
    }

    //g1/g2
    if (this.typeId === 8201 || this.typeId === 8202) {
      let extPower = 1;
      if (this.sensor.status.last === undefined) {
        return "Offline";
      } else if (this.sensor.externalPower.last !== undefined) {
        extPower = this.sensor.externalPower.last.value;
      }

      if (this.sensor.status.last.value === 1 && extPower === 1) {
        return "Online";
      } else {
        return "Offline";
      }
    }

    //rbaseweb
    if (this.sensor.status.last === undefined) {
      return "Offline";
    }
    const value = this.sensor.status.last.value;
    return value === 1 ? "Online" : "Offline";
  };

  getStatus = ():
    | "Theft Alert"
    | "Running"
    | "Running Wet"
    | "Stopped"
    | "Stopped Wet" => {
    if (this.isTheft()) {
      return "Theft Alert";
    }

    if (this.isRunning()) {
      if (!this.isWet()) {
        return "Running";
      } else {
        return "Running Wet";
      }
    } else {
      if (!this.isWet()) {
        return "Stopped";
      } else {
        return "Stopped Wet";
      }
    }
  };

  /**
   *Determin if it is wet
   *for g1/g2, use pressureSw first, if it not exists, use pump
   *for touch, use 8301 virtual sensor
   *for pac3, if system is on, show wet
   *for rbaseweb, use pump directly
   */
  isWet = (): boolean => {
    //g1/g2
    if (this.typeId === 8201 || this.typeId === 8202) {
      if (this.isActualPressureOn()) {
        return true;
      }
      if (this.isPressureSwOn()) {
        return true;
      }
      if (this.isPumpOn()) {
        return true;
      }
      return false;
    }

    //touch
    if (
      this.typeId === 8210 ||
      this.typeId === 8214 ||
      this.typeId === 8215 ||
      this.typeId === 8218 ||
      this.typeId === 8219
    ) {
      var last = this.sensor.wetSource.last;
      if (last) {
        switch (last.value) {
          case 8242:
            return this.isPumpOn() ?? false;
          case 8247:
            return this.isPressureSwOn();
          case 8230:
            return this.isActualPressureOn();
        }
      }
      return false;
    }

    //pac3
    if (this.typeId === 8213) {
      if (this.isRunning()) {
        return true;
      }
      return false;
    }

    //rbaseweb
    if (
      this.typeId === 8211 ||
      this.typeId === 8212 ||
      this.typeId === 8216 ||
      this.typeId === 8217
    ) {
      if (this.isPumpOn()) {
        return true;
      }
      return false;
    }

    return false;
  };

  isTheft = (): boolean => {
    return this.sensor.isTheft?.last?.value === 1;
  };

  isActualPressureOn = (): boolean => {
    return this.sensor.actualPressure?.last?.value === 1;
  };

  isPressureSwOn = (): boolean => {
    return this.sensor.pressureSw?.last?.value === 1;
  };

  isPumpOn = (): boolean | undefined => {
    if (!this.sensor.pump.last) return undefined;
    return this.sensor.pump.last.value === 1;
  };

  isChemPumpOn = (): boolean | undefined => {
    if (!this.sensor.chemPump.last) return undefined;
    return this.sensor.chemPump.last.value === 1;
  };

  isEndGunOn = (): boolean | undefined => {
    if (!this.sensor.endGun.last) return undefined;
    return this.sensor.endGun.last.value === 1;
  };

  isAux1On = (): boolean | undefined => {
    if (!this.sensor.aux1.last) return undefined;
    return this.sensor.aux1.last.value === 1;
  };

  isPivot = (): boolean | undefined => {
    if (!this.sensor.isPivot.last) return undefined;
    return this.sensor.isPivot.last.value === 1;
  };

  isParked = (): boolean | undefined => {
    if (!this.sensor.park.last) return undefined;
    return this.sensor.park.last.value === 1;
  };

  // Possible update of this would be to rewrite it with the strength of type checking in min
  getETA = (runningProgram: IProgram | undefined): string | null => {
    if ( typeof this.getSpeed() !== 'number' || typeof this.settingsOEM.hoursPerRevolution !== 'number') {
      return null;
    }	
    
    if (!this.isRunning() ) {
      return "Stopped";
    }
  
    let degreesToBarricade: number | null = null;
    let degreesToPark: number | null = null;
    let distToBarricade: number | null = null;
    let distToPark: number | null = null;
  
    var hoursSinceLastPosReport = 0;
    if(this.sensor.position && this.sensor.position.lastTimestamp ){
      var diffmiliSec = Date.now() - Date.parse(this.sensor.position.lastTimestamp);
      hoursSinceLastPosReport = diffmiliSec / (1000 * 60 * 60);
    }
  
    var parkETA;
    var barrierETA;
    var hoursPerRev;
    var actualHoursPerRevolution;
  
    if(this.isPivot()){
      if(typeof this.getCurrentAngle() === 'number' && this.getCurrentAngle() !== null){		
          if (this.getDirection() === "Forward") {
            if(typeof this.getForwardBarricade() === 'number' && typeof this.getReverseBarricade() === 'number'){ 		
              degreesToBarricade = this.getCurrentAngle()! <= this.getForwardBarricade()! ? 
              this.getForwardBarricade()! - this.getCurrentAngle()! : 360 - this.getCurrentAngle()! + this.getForwardBarricade()!;								
            }
            if(typeof this.getParkAngle() === 'number' && this.isParked()){				
                degreesToPark = this.getCurrentAngle()! <= this.getParkAngle()! ? 
                this.getParkAngle()! - this.getCurrentAngle()! : 360 - this.getCurrentAngle()! + this.getParkAngle()!;								
            }
          }
          if(this.getDirection() === "Reverse"){
            if(typeof this.getForwardBarricade() === 'number' && typeof this.getReverseBarricade() === 'number'){						
                degreesToBarricade = this.getCurrentAngle()! < this.getReverseBarricade()! ? 
                this.getCurrentAngle()! + 360 - this.getReverseBarricade()! : this.getCurrentAngle()! - this.getReverseBarricade()!;							
            }
            if(typeof this.getParkAngle() === 'number' && this.isParked()){
                degreesToPark = this.getCurrentAngle()! < this.getParkAngle()! ? 
                this.getCurrentAngle()! + 360 - this.getParkAngle()! : this.getCurrentAngle()! - this.getParkAngle()!;									
            }
          }		
        }
  
      hoursPerRev = this.settingsOEM.hoursPerRevolution;
      if(this.settingsOEM.minsPerRevolution !== 'number'){
        hoursPerRev += this.settingsOEM.minsPerRevolution / 60;
      }
      actualHoursPerRevolution = hoursPerRev / (this.getSpeed()! / 100);
  
      parkETA = degreesToPark !== null ? "Park: " + this.formatHours((actualHoursPerRevolution * degreesToPark / 360.0) - hoursSinceLastPosReport) : "-";
      barrierETA = degreesToBarricade !== null ? "Barrier: " + this.formatHours((actualHoursPerRevolution * degreesToBarricade / 360.0) - hoursSinceLastPosReport) : "-";
    
      if(degreesToBarricade !== null && degreesToPark !== null){
        return degreesToBarricade < degreesToPark ? barrierETA : parkETA;
      }else{
        if(degreesToBarricade !== null) return barrierETA;
        if(degreesToPark !== null) return parkETA;
    
        return "Revolution: " + this.formatHours(actualHoursPerRevolution - hoursSinceLastPosReport);
      }
    }else{
      //lateral systems
  
      var barricadeProgram = runningProgram && runningProgram.barricade ? runningProgram.barricade : null;
      var fwdBarricade: number | null = null;
      var rvsBarricade: number | null = null;	
  
      if(barricadeProgram){
        fwdBarricade = barricadeProgram.forward.position;
        rvsBarricade = barricadeProgram.reverse.position;
      }
  
      var currentPos = this.getCurrentPosition();
  
      if(currentPos !== null){		
        if (this.getDirection() === "Forward") {
          if(fwdBarricade !== null){ 
              distToBarricade = currentPos <= fwdBarricade ? fwdBarricade - currentPos : null;							
          }
          if(typeof this.getParkAngle() === 'number' && this.isParked()){
              distToPark = currentPos <= this.getParkAngle()! ? this.getParkAngle()! - currentPos : null;						
          }
        }
        if(this.getDirection() === "Reverse"){
          if(rvsBarricade !== null){
              distToBarricade = currentPos > rvsBarricade ? currentPos - rvsBarricade : null;					
          }
          if(typeof this.getParkAngle() === 'number' && this.isParked()){
            distToPark = currentPos > this.getParkAngle()! ?  currentPos - this.getParkAngle()! : null;		
          }
        }		
      }
  
      //TODO: this need to be updated as soon as we have access to that field without the extra request to get the unit settings
      const fph = this.settingsOEM ? this.settingsOEM.latFeetPerHour : null;// (this.unitSettings && this.unitSettings.feetperhour) ? this.unitSettings.feetperhour :  null;
  
      var feetPerHour = this.unitOfMeasure === 1 || this.unitOfMeasure === 3 ? fph/3.28 : fph;
      if(!feetPerHour){
        return "0 speed";
      }
      feetPerHour *= (this.getSpeed()! / 90);
  
      parkETA = distToPark !== null ? "Park: " + this.formatHours((distToPark / feetPerHour) - hoursSinceLastPosReport) : "-";
      barrierETA = distToBarricade !== null ? "Barrier: " + this.formatHours((distToBarricade / feetPerHour) - hoursSinceLastPosReport) : "-";
      
      if(distToBarricade !== null && distToPark !== null){
        return distToBarricade < distToPark ? barrierETA : parkETA;
      }else{
        if(distToBarricade !== null) return barrierETA;
        if(distToPark !== null) return parkETA;

        if(currentPos == null) return "N/A";
  
        var distToEnd = this.getDirection() === "Forward" ? this.getFieldLength() - currentPos : currentPos ; //if no barrier or park
        return "Field End: " + this.formatHours((distToEnd / feetPerHour) - hoursSinceLastPosReport);	
      }
    }	
  }

  formatHours = (hours) => {
    if(hours <= 0){
      return "0 hr";
    }else{
      var mins = Math.trunc((hours - Math.trunc(hours)) * 60);
      return Math.trunc(hours) + " hr" + (mins !== 0 ? " " + mins + " mins" : "");
    }	
  }


  getSpeed = (): number | undefined => {
    var last = this.sensor.speedpc.last;
    return last ? Math.round(last.value * 10) / 10 : undefined;
  };

  getCurrentAngle = (): number | undefined => {
    return this.sensor.position?.last?.value;
  };

  getCurrentPosition = (): number | null => {
    var last = this.sensor.position.last;
    if(this.unitOfMeasure === 1 || this.unitOfMeasure === 3){
      return last ? Math.round(last.value*10/3.28) : null;
    }
    return last ? Math.round(last.value * 10) : null;
  };

  getParkAngle = (): number | null => {
    const last = this.sensor.parkAngle?.last;
    if (
      this.typeId === 8211 ||
      this.typeId === 8212 ||
      this.typeId === 8216 ||
      this.typeId === 8217
    ) {
      return null;
    }
    if (!this.isPivot()) {
      if (this.unitOfMeasure === 1 || this.unitOfMeasure === 3) {
        return last ? Math.round((last.value * 10) / 3.28) : null;
      }
      return last ? Math.round(last.value * 10) : null;
    }
    return last ? Math.round(last.value * 10) / 10 : null;
  };

  getForwardBarricade = (): number | undefined => {
    return this.sensor.forwardBarricade?.last?.value;
  };

  getReverseBarricade = (): number | undefined => {
    return this.sensor.reverseBarricade?.last?.value;
  };

  getDirection = (): "Forward" | "Reverse" | undefined => {
    if (!this.sensor.direction.last) return undefined;
    return this.sensor.direction.last.value === 1 ? "Reverse" : "Forward";
  };

  getFieldLength = (): number => {
    var last = this.sensor.fieldLength.last;
    return last ? last.value : 1000;
  };

  getLateralFieldLengthFeet = (): number => {
    const length = this.sensor.fieldLength?.last?.value;
    if (!length) return 1000; // this is done in old code. Not sure why
    return this.unitOfMeasure === 1 || this.unitOfMeasure === 3
      ? Math.round(length * 3.28)
      : length;
  };

  getDepthInch = (): number | undefined => {
    const appliedDepth = this.sensor.appliedDepth?.last?.value;
    const minDepth = this.sensor.minDepth?.last?.value;
    if (appliedDepth) {
      return this.isUnitofMeasureMetric() ? mmToInches(appliedDepth) : appliedDepth;
    } else if (minDepth) {
      const speed = this.sensor.speedpc?.last?.value;
      if (!speed) return undefined;
      const maxSpeed = this.isPivot() ? 100 : 90;
      const depth = Math.round((maxSpeed / speed) * minDepth * 100) / 100;
      return this.isUnitofMeasureMetric() ? mmToInches(depth) : depth;
    } else {
      return undefined;
    }
  };

  isUnitofMeasureMetric = (): boolean => {
    return this.unitOfMeasure === 1 || this.unitOfMeasure === 3;
  }
}

export default Unit;
