import * as React from "react";
import { FC, useCallback, useContext, useEffect, useRef, useState } from "react";
import mapboxgl, { GeoJSONSource, MapMouseEvent, MapTouchEvent } from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import buildGeoJson from "./buildGeoJson";
import BackgroundDataCtx from "../../context/BackgroundDataCtx/BackgroundDataCtx";
import LocationDataCtx from "../../context/LocationDataCtx/LocationDataCtx";
import IUnitData from "../../context/BackgroundDataCtx/IUnitData";
import { Button } from "@mui/material";
import { Feature, Position } from "geojson";
import {
  calculateClosestPointOnCircle,
  calculateColsestPointOnLine,
  getAngleFromPoint,
  getBounds,
  getDistanceBetweenPoints,
} from "./MapHelper";
import MapUnitPopup from "./MapUnitPopup";
import { useNavigate } from "react-router-dom";
import MapLayersCtx from "../../context/MapLayersCtx/MapLayersCtx";
import MapMenu from "./MapMenu";
import { IGroup } from "../../context/GroupsApiCtx/IGroupsApi";
import { addSpeedRing } from "./speedRing";
import {
  activePointLayer,
  speedRingLayer,
  symbolLayer,
  badgeBaseLayerZoomedOut,
  featureLayer,
  programLayer,
  programLineLayer,
  featureOutlineLayer,
  badgeBaseLayerZoomedIn,
  userLocationPointLayer,
  userLocationCircleLayer,
  badgeIconLayer,
  selectedUnitLayer,
} from "./mb-layers";
import MapRefCtx from "../../context/MapRefCtx/IMapRefCtx";
import isEqual from "lodash.isequal";
import { UnitData } from "../../context/BackgroundDataCtx/IBackgroundData";
import { buildUnitsPointFeatures } from "./buildFeatureBase";
import StatusColorLegend from "./StatusColorLegend";

export enum MapDrawingMode {
  Disabled,
  DrawExactLocation,
  DrawClosest,
  ContinuousUserLocation,
}

mapboxgl.accessToken =
  "pk.eyJ1IjoiaWNvbnNvZnR3YXJlIiwiYSI6ImNsdHJibXpicTBlaDIyaXA3aG16cDE0YngifQ.mojgvxrNr_QyXETnDyeDqg";

interface Props {
  unitId?: string;
  interactiveMode?: MapDrawingMode;
  onExitInteractiveMode?: (calcPos?: Position, realPos?: Position, angle?: number) => void;
  onMouseEvent?: (ev: MapMouseEvent) => void;
  onTouchEvent?: (ev: MapTouchEvent) => void;
}

const Map: FC<Props> = (props) => {
  const { map, mapContainer, lngLatZoom, setLngLatZoom, isMapLoaded, setIsMapLoaded } = useContext(MapRefCtx);
  const popupContainer = useRef(null);
  const animationRef = useRef<number>();
  const bgdata = useContext(BackgroundDataCtx);
  const locationData = useContext(LocationDataCtx);
  const layerContext = useContext(MapLayersCtx);
  const unit = props.unitId ? bgdata.units[props.unitId] : null;
  const navigate = useNavigate();
  const [editingPosition, setEditingPosition] = useState<Position | null>(null); //closest point on circle radius or lateral side
  const [clickedPosition, setClickedPosition] = useState<Position | null>(null); //the actual point the user clicked on the map
  const [popUpUnitId, setPopUpUnitId] = useState<string | undefined>(undefined);
  const isPopUpPersistentOpen = useRef(false);
  const [isPopupOpenedByClick, setIsPopupOpenedByClick] = useState(false);
  const popup = useRef<mapboxgl.Popup>(new mapboxgl.Popup({ offset: [0, -17], maxWidth: "none", closeButton: false }));
  const isSelectUnitsModeRef = useRef<boolean>(false);
  const [unitsToShow, setUnitsToShow] = useState<UnitData>(bgdata.filteredUnits);
  const [unitsToShowIds, setUnitsToShowIds] = useState<string[]>(
    bgdata.filteredUnits ? Object.keys(bgdata.filteredUnits) : []
  );

  // If no map, initialize map, source and layers and add event listeners.
  useEffect(() => {
    const initializeMap = ({ mapContainer }) => {
      const mapInst = new mapboxgl.Map({
        container: mapContainer.current,
        center: [lngLatZoom.lng, lngLatZoom.lat],
        zoom: lngLatZoom.zoom,
        style: "mapbox://styles/mapbox/satellite-streets-v12",
        projection: {
          name: "globe", // "mercator",
        },
      });
      map.current = mapInst;

      mapInst.on("load", () => {
        map.current = mapInst;
        map.current.resize();

        //TODO Do we need to store current map position  in context? This causes rerender of map on every move
        // mapInst.on("move", () => {
        //   setLngLatZoom({
        //     lng: mapInst.getCenter().lng,
        //     lat: mapInst.getCenter().lat,
        //     zoom: mapInst.getZoom(),
        //   });
        // });

        mapInst.loadImage(new URL("../../assets/teardrop.png", import.meta.url).href, (error, image) => {
          if (error) throw error;
          // Add the image to the map style.
          mapInst.addImage("teardrop", image!);
        });

        mapInst.loadImage(new URL("../../assets/connectionLost.png", import.meta.url).href, (error, image) => {
          if (error) throw error;
          // Add the image to the map style.
          mapInst.addImage("connectionLost", image!);
        });

        mapInst.addSource("features", {
          type: "geojson",
          data: buildGeoJson(unitsToShow, locationData),
        });

        mapInst.addSource("speed-ring", {
          type: "geojson",
          data: {
            type: "FeatureCollection",
            features: [],
          },
        });

        mapInst.addSource("selected-units", {
          type: "geojson",
          data: {
            type: "FeatureCollection",
            features: [],
          },
        });

        for (let layerId in layerContext.layers) {
          if (layerContext.layers[layerId].show) {
            mapInst.addSource(
              (layerContext.layers[layerId].mapboxSource as any).id,
              layerContext.layers[layerId].mapboxSource
            );
            mapInst.addLayer(layerContext.layers[layerId].mapboxLayerConfig);
            mapInst.setPaintProperty(
              layerContext.layers[layerId].mapboxLayerConfig.id,
              layerContext.layers[layerId].mapboxLayerConfig.layoutOpacityName,
              layerContext.layers[layerId].opacity / 100
            );
          }
        }

        mapInst.addLayer(selectedUnitLayer);
        mapInst.addLayer(symbolLayer);
        mapInst.addLayer(badgeBaseLayerZoomedOut);
        mapInst.addLayer(featureLayer);
        mapInst.addLayer(programLayer);
        mapInst.addLayer(speedRingLayer);
        mapInst.addLayer(programLineLayer);
        mapInst.addLayer(featureOutlineLayer);
        mapInst.addLayer(badgeBaseLayerZoomedIn);
        // Add user location point and circle
        if (locationData.coords && !locationData.error) {
          mapInst.addLayer(userLocationPointLayer);
          mapInst.addLayer(userLocationCircleLayer);
        }
        mapInst.addLayer(badgeIconLayer);
        
        setIsMapLoaded(true);
      });
    };

    if (!map.current) {
      initializeMap({ mapContainer });
    }
  }, []);

  const onMouseUp = useCallback((e) => {
    var newCoord =
      bgdata.units[props.unitId!].systemType === "Pivot"
        ? calculateClosestPointOnCircle([e.lngLat.lng, e.lngLat.lat], bgdata.units[props.unitId!])
        : calculateColsestPointOnLine([e.lngLat.lng, e.lngLat.lat], bgdata.units[props.unitId!]);
    setEditingPosition(newCoord);
    setClickedPosition([e.lngLat.lng, e.lngLat.lat]);
    var data: Feature = {
      type: "Feature",
      properties: { type: "activePoint" },
      geometry: {
        type: "Point",
        coordinates: props.interactiveMode === MapDrawingMode.DrawClosest ? newCoord : [e.lngLat.lng, e.lngLat.lat],
      },
    };
    (map.current!.getSource("activePoint") as GeoJSONSource).setData(data);
  }, []);

  const getClickedUnitId = (e: mapboxgl.MapMouseEvent & { features?: mapboxgl.MapboxGeoJSONFeature[] | undefined }) => {
    const features = e.features;
    if (!features || !features.length) return;
    const feature = features[0]; // Assuming there's only one feature per click
    let clickedUnitId: string | undefined = undefined;
    for (let unitId in unitsToShow) {
      if (
        bgdata.units[unitId].longitude === (feature.properties as any).longitude &&
        bgdata.units[unitId].latitude === (feature.properties as any).latitude
      ) {
        clickedUnitId = unitId;
      }
    }
    return clickedUnitId;
  };

  const openUnitPopUp = (
    e: mapboxgl.MapMouseEvent & {
      features?: mapboxgl.MapboxGeoJSONFeature[] | undefined;
    } & mapboxgl.EventData
  ) => {
    const clickedUnitId = getClickedUnitId(e);
    setPopUpUnitId(clickedUnitId);
    if (!clickedUnitId) return;

    popup
      .current!.on("close", () => {
        isPopUpPersistentOpen.current = false;
        setPopUpUnitId(undefined);
        setIsPopupOpenedByClick(false);
      })
      .setLngLat([bgdata.units[clickedUnitId].longitude, bgdata.units[clickedUnitId].latitude])
      .setDOMContent(popupContainer.current!)
      .addTo(map.current!);

    // fix for bug where popup opens outside map bounds
    setTimeout(() => map.current!.snapToNorth(), 5);
  };

  const closeUnitPopUp = () => {
    popup.current!.remove();
    setPopUpUnitId(undefined);
    isPopUpPersistentOpen.current = false;
  };

  const clickHandler = (e) => {
    setIsPopupOpenedByClick(true);
    if (isSelectUnitsModeRef.current) {
      const clickedUnitId = getClickedUnitId(e);
      if (clickedUnitId) {
        bgdata.setSelectedUnits((prevSelUnits: UnitData) => {
          return { ...prevSelUnits, [clickedUnitId]: bgdata.units[clickedUnitId] };
        });
      }
    } else {
      isPopUpPersistentOpen.current = true;
      openUnitPopUp(e);
    }
  };

  const hoverEnterHandler = (e) => {
    if (!isPopUpPersistentOpen.current) {
      openUnitPopUp(e);
    }
  };

  const hoverLeaveHandler = (e) => {
    if (!isPopUpPersistentOpen.current) {
      popup.current!.remove();
      setPopUpUnitId(undefined);
    }
  };

  useEffect(() => {
    if (!map.current) return;

    if (!props.unitId) {
      map.current.on("mouseenter", "symbolLayer", hoverEnterHandler);
      map.current.on("mouseenter", "features", hoverEnterHandler);
      map.current.on("mouseleave", "symbolLayer", hoverLeaveHandler);
      map.current.on("mouseleave", "features", hoverLeaveHandler);
      map.current.on("click", "symbolLayer", clickHandler);
      map.current.on("click", "features", clickHandler);
    }

    // The code below runs if we already have a map or not!
    if (props.onMouseEvent) {
      map.current.on("mousedown", props.onMouseEvent);
      map.current.on("mousemove", props.onMouseEvent);
      map.current.on("mouseup", props.onMouseEvent);
      map.current.on("click", props.onMouseEvent);
    }

    if (props.onTouchEvent) {
      map.current.on("touchstart", props.onTouchEvent);
      map.current.on("touchmove", props.onTouchEvent);
      map.current.on("touchend", props.onTouchEvent);
      map.current.on("touchcancel", props.onTouchEvent);
    }

    if (props.onTouchEvent || props.onMouseEvent) {
      map.current.dragPan.disable(); // Disable default pan behavior
    }
    return () => {
      // This is the cleanup function which is called before every render. It binds the old version of the props field, including the
      // old mouse event handlers, so we can remove these before they're added during the next render.
      if (!map.current) return;
      map.current.off("mouseenter", "symbolLayer", hoverEnterHandler);
      map.current.off("mouseenter", "features", hoverEnterHandler);
      map.current.off("mouseleave", "symbolLayer", hoverLeaveHandler);
      map.current.off("mouseleave", "features", hoverLeaveHandler);
      map.current.off("click", "symbolLayer", clickHandler);
      map.current.off("click", "features", clickHandler);

      if (props.onMouseEvent) {
        map.current.off("mousedown", props.onMouseEvent);
        map.current.off("mousemove", props.onMouseEvent);
        map.current.off("mouseup", props.onMouseEvent);
        map.current.off("click", props.onMouseEvent);
      }

      if (props.onTouchEvent) {
        map.current.off("touchstart", props.onTouchEvent);
        map.current.off("touchmove", props.onTouchEvent);
        map.current.off("touchend", props.onTouchEvent);
        map.current.off("touchcancel", props.onTouchEvent);
      }
    };
  }, [map.current, props.unitId, unitsToShowIds]);

  useEffect(() => {
    if (!map.current) return;

    if (
      props.interactiveMode === MapDrawingMode.DrawClosest ||
      props.interactiveMode === MapDrawingMode.DrawExactLocation
    ) {
      if (map.current.dragPan.isEnabled()) map.current.dragPan.disable(); // Disable dragging
      if (map.current.touchZoomRotate.isEnabled()) map.current.touchZoomRotate.disableRotation(); // Disable touch rotation
      map.current.getCanvas().style.cursor = "pointer";
      var data: Feature = {
        type: "Feature",
        properties: { type: "activePoint" },
        geometry: { type: "Point", coordinates: [] },
      };
      if (!map.current!.getSource("activePoint")) {
        map.current!.addSource("activePoint", { type: "geojson", data: data });
      } else {
        (map.current!.getSource("activePoint") as GeoJSONSource).setData(data);
      }
      if (!map.current!.getLayer("activePoint")) {
        map.current!.addLayer(activePointLayer);
      }
      map.current.on("mouseup", onMouseUp);
    } else {
      if (!map.current.dragPan.isEnabled() && !props.onTouchEvent && !props.onMouseEvent) {
        map.current.dragPan.enable();
      }
      if (!map.current.touchZoomRotate.isEnabled() && !props.onTouchEvent && !props.onMouseEvent)
        map.current.touchZoomRotate.enableRotation();
      map.current.getCanvas().style.cursor = "default";
      map.current.off("mouseup", onMouseUp);
    }

    if (props.interactiveMode === MapDrawingMode.ContinuousUserLocation && locationData.coords) {
      var newCoord =
        bgdata.units[props.unitId!].systemType === "Pivot"
          ? calculateClosestPointOnCircle(
              [locationData.coords!.longitude, locationData.coords!.latitude],
              bgdata.units[props.unitId!]
            )
          : calculateColsestPointOnLine(
              [locationData.coords!.longitude, locationData.coords!.latitude],
              bgdata.units[props.unitId!]
            );
      setEditingPosition(newCoord);
      setClickedPosition([locationData.coords!.longitude, locationData.coords!.latitude]);

      var data: Feature = {
        type: "Feature",
        properties: { type: "activePoint" },
        geometry: { type: "Point", coordinates: newCoord },
      };

      if (!map.current!.getSource("activePoint")) {
        map.current!.addSource("activePoint", { type: "geojson", data: data });
      } else {
        (map.current!.getSource("activePoint") as GeoJSONSource).setData(data);
      }
      if (!map.current!.getLayer("activePoint")) {
        map.current!.addLayer(activePointLayer);
      }
    }
  }, [props.interactiveMode, map.current]);

  useEffect(() => {
    //when location data has changed and we are in Nudge mode we redraw the point closest to user's location on unit
    if (props.interactiveMode === MapDrawingMode.ContinuousUserLocation && locationData.coords) {
      var newCoord =
        bgdata.units[props.unitId!].systemType === "Pivot"
          ? calculateClosestPointOnCircle(
              [locationData.coords!.longitude, locationData.coords!.latitude],
              bgdata.units[props.unitId!]
            )
          : calculateColsestPointOnLine(
              [locationData.coords!.longitude, locationData.coords!.latitude],
              bgdata.units[props.unitId!]
            );
      setEditingPosition(newCoord);
      setClickedPosition([locationData.coords!.longitude, locationData.coords!.latitude]);
      var data: Feature = {
        type: "Feature",
        properties: { type: "activePoint" },
        geometry: { type: "Point", coordinates: newCoord },
      };
      (map.current!.getSource("activePoint") as GeoJSONSource).setData(data);
    }
  }, [locationData]);

  useEffect(() => {
    const updateSourceData = () => {
      const source = map.current!.getSource("features") as GeoJSONSource;
      if (!source) return;
      source.setData(buildGeoJson(unitsToShow, locationData));
    };
    if (isMapLoaded) updateSourceData();
  }, [bgdata.units, locationData, isMapLoaded, unitsToShow]);

  useEffect(() => {
    if (!isMapLoaded) return;

    const inProgramMode = !!props.onMouseEvent;
    if (inProgramMode) {
      map.current!.setLayoutProperty("speedRingLayer", "visibility", "none");
    } else {
      map.current!.setLayoutProperty("speedRingLayer", "visibility", "visible");
    }

    if (unit?.lengthFeet && !inProgramMode) {
      const source = map.current!.getSource("speed-ring") as GeoJSONSource;
      if (source) {
        addSpeedRing(unit, map.current!, animationRef, source);
      }
    }

    return () => {
      if (animationRef.current) cancelAnimationFrame(animationRef.current);
    };
  }, [map.current, unit?.unitState, props.unitId, isMapLoaded, props.onMouseEvent]);

  useEffect(() => {
    if (!map.current || isMapLoaded) return;

    if (map.current.isStyleLoaded()) {
      for (let layerId in layerContext.layers) {
        if (!map.current.getSource(layerContext.layers[layerId].mapboxSource.id)) {
          map.current.addSource(
            (layerContext.layers[layerId].mapboxSource as any).id,
            layerContext.layers[layerId].mapboxSource
          );
        }
        if (map.current.getLayer(layerId)) {
          map.current.removeLayer(layerId);
        }
        map.current.addLayer(layerContext.layers[layerId].mapboxLayerConfig);
        //update opacity
        map.current.setPaintProperty(
          layerContext.layers[layerId].mapboxLayerConfig.id,
          layerContext.layers[layerId].mapboxLayerConfig.layoutOpacityName,
          layerContext.layers[layerId].opacity / 100
        );
        if (!layerContext.layers[layerId].show) {
          //update visibility
          map.current.setLayoutProperty(layerContext.layers[layerId].mapboxLayerConfig.id, "visibility", "none");
        }
      }
      //when layer gets deleted we need to remove it form the map too
      const importedLayersOnMap = map.current.getStyle().layers.filter((x) => x.id.startsWith("layer_"));
      const actualLayers = Object.values(layerContext.layers).filter((x) => x.id.startsWith("layer_"));
      if (importedLayersOnMap.length !== actualLayers.length) {
        importedLayersOnMap.forEach((l) => {
          if (!actualLayers.map((x) => x.mapboxLayerConfig.id).includes(l.id)) {
            map.current!.setLayoutProperty(l.id, "visibility", "none");
          }
        });
      }
    }
  }, [layerContext.layers, map.current, isMapLoaded]);

  const resetDrawingLayer = () => {
    setEditingPosition(null);
    setClickedPosition(null);
    if (map.current!.getSource("activePoint")) {
      var data: Feature = {
        type: "Feature",
        properties: { type: "activePoint" },
        geometry: { type: "Point", coordinates: [] },
      };
      (map.current!.getSource("activePoint") as GeoJSONSource).setData(data);
    }
  };

  const getBoundUnits = (units: IUnitData[]) => {
    return units.reduce((bounds, unit) => {
      if (!!unit.latitude || !!unit.longitude) {
        return bounds.extend([unit.longitude, unit.latitude]);
      } else {
        return bounds;
      }
    }, new mapboxgl.LngLatBounds());
  };

  const focusOnGroup = (group: IGroup) => {
    if (!map.current) return;
    map.current.fitBounds(getBoundUnits(group.units));
  };

  useEffect(() => {
    if (!isEqual(bgdata.filteredUnits, unitsToShow)) {
      setUnitsToShow(bgdata.filteredUnits);

      if (!isEqual(Object.keys(bgdata.filteredUnits), unitsToShowIds)) {
        setUnitsToShowIds(Object.keys(bgdata.filteredUnits));
        if (popup.current) {
          popup.current.remove();
        }
      }
    }
  }, [bgdata.filteredUnits]);

  // Handle map position change Unit/no unit. Fit Bounds.
  useEffect(() => {
    if (!map.current) return;
    let bounds: mapboxgl.LngLatBounds;
    if (props.unitId) {
      bounds = getBounds(unit!);
    } else {
      bounds = getBoundUnits(Object.values(unitsToShow));
    }
    map.current.fitBounds(bounds!, {
      padding: 40,
      animate: false,
    });
  }, [props.unitId, unitsToShowIds]);

  // Update selected units map source
  useEffect(() => {
    if (!map.current) return;
    const source = map.current.getSource("selected-units") as GeoJSONSource;

    if (source) {
      const units = Object.values(bgdata.selectedUnits ?? {}).filter(unit => bgdata.filteredUnits[unit.id] !== undefined)
      source.setData({
        type: "FeatureCollection",
        features: buildUnitsPointFeatures(units),
      });
    }
  }, [bgdata.selectedUnits, unitsToShowIds]);

  // When switching page, update the map container id, and update map size
  useEffect(() => {
    const container = document.getElementById("map-container");
    if (container && map.current) {
      container.replaceWith(map.current.getContainer());
      map.current.resize();
    }
  }, []);

  return (
    <div style={{ width: "100%", height: "100%", position: "relative" }}>
      <div id="map-container" ref={(el) => (mapContainer.current = el)} style={{ width: "100%", height: "100%" }} />
      <div ref={popupContainer}>
        {popUpUnitId && (
          <MapUnitPopup
            isPopupOpenedByClick={isPopupOpenedByClick}
            unitId={popUpUnitId}
            navigate={navigate}
            onExit={() => closeUnitPopUp()}
          />
        )}
      </div>
      <MapMenu
        unit={unit}
        bgdata={bgdata}
        flyToGroup={focusOnGroup}
        isSelectMode={isSelectUnitsModeRef.current}
        setSelectMode={(isSelectMOde) => {
          closeUnitPopUp();
          isSelectUnitsModeRef.current = isSelectMOde;
        }}
      />
      <StatusColorLegend  position={ "absolute"} bottom={22} right={10}  />
      {(props.interactiveMode === MapDrawingMode.DrawClosest ||
        props.interactiveMode === MapDrawingMode.DrawExactLocation ||
        props.interactiveMode === MapDrawingMode.ContinuousUserLocation) && (
        <>
          <Button
            style={{ position: "absolute", right: 20, bottom: 50, zIndex: 2 }}
            variant="contained"
            color="primary"
            disabled={!editingPosition}
            onClick={() => {
              if (props.onExitInteractiveMode) {
                props.onExitInteractiveMode(
                  editingPosition!,
                  clickedPosition!,
                  bgdata.units[props.unitId!].systemType === "Pivot"
                    ? getAngleFromPoint(editingPosition!, bgdata.units[props.unitId!])
                    : getDistanceBetweenPoints(editingPosition!, [
                        bgdata.units[props.unitId!].longitude,
                        bgdata.units[props.unitId!].latitude,
                      ])
                );
              }
              resetDrawingLayer();
            }}
            size="medium"
          >
            SAVE
          </Button>
          <Button
            style={{
              position: "absolute",
              left: 20,
              bottom: 50,
              zIndex: 2,
              backgroundColor: "white",
            }}
            variant="outlined"
            color="primary"
            onClick={() => {
              if (props.onExitInteractiveMode) {
                props.onExitInteractiveMode();
              }
              resetDrawingLayer();
            }}
            size="medium"
          >
            Cancel
          </Button>
        </>
      )}
    </div>
  );
};

export default Map;
