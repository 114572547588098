import * as React from "react";
import { useContext } from "react";
import { useParams } from "react-router-dom";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import {
  Button,
  CircularProgress,
  Grid,
  Switch,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import BackgroundDataCtx from "../../context/BackgroundDataCtx/BackgroundDataCtx";
import IUnitData, {
  IUnitState,
  UnitStateKey,
} from "../../context/BackgroundDataCtx/IUnitData";
import QueueCtx from "../../context/QueueCtx/QueueCtx";
import { directionWithStartCommand } from "../../helpers/unitCapabilites";
import ExtendedUnitDataCtx from "../../context/ExtendedUnitDataCtx/ExtendedUnitDataCtx";
import InteractiveInput from "../ui/InteractiveInput";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import PauseIcon from "@mui/icons-material/Pause";
import SpeedDepthInput from "../ui/SpeedDepthInput";

interface Props {
  unitId?: string;
  onMapView?: boolean;
}

const ActionPanel: React.FC<Props> = (props: Props) => {
  var { unitId } = useParams();
  if (!unitId) {
    unitId = props.unitId;
  }

  if (!unitId) return <>Unit not recognised</>;

  const bgdata = useContext(BackgroundDataCtx);
  const { capabilities } = useContext(ExtendedUnitDataCtx);
  const queue = useContext(QueueCtx);

  const unit = bgdata.units[unitId];
  const unitState = unit.unitState;

  const isInProgress = (key: UnitStateKey): boolean => {
    return queue.isQueueInProgress(`${unit.id}-controls-${key}`);
  };

  const changeControlState = async <T extends UnitStateKey>(
    key: T,
    value: IUnitState[T]
  ) => {
    if (key === "running" && capabilities.controls.directionCommandWithStart) {
      await directionWithStartCommand(bgdata.units[unitId!], bgdata);
      return;
    }
    await bgdata.changeUnitState(bgdata.units[unitId!], key, value);
  };

  return (
    <Stack direction="column" sx={{ pt: 1 }}>
      <Stack
        sx={{
          px: 2,
          width: {
            sm: props.onMapView ? "auto" : "100%",
            md: props.onMapView ? "auto" : 390,
          },
        }}
        style={{ flexFlow: "row wrap" }}
        direction="row"
        justifyContent="space-between"
        marginBottom={2}
      >
        <Stack direction={"row"}>
          <Button
            size="small"
            sx={{
              mr: 1,
              minWidth: "80px",
            }}
            color="success"
            variant="contained"
            disabled={
              unitState.running ||
              isInProgress("running") ||
              !capabilities.controls.start
            }
            onClick={() => changeControlState("running", true)}
          >
            <span>Start</span>
            {!unitState.running && isInProgress("running") ? (
              <CircularProgress color="inherit" size={"1rem"} sx={{ ml: 1 }} />
            ) : (
              <PlayArrowIcon />
            )}
          </Button>
          <Button
            size="small"
            sx={{
              minWidth: "80px",
              marginRight: 1,
            }}
            color="error"
            variant="contained"
            disabled={
              !unitState.running ||
              isInProgress("running") ||
              !capabilities.controls.stop
            }
            onClick={() => changeControlState("running", false)}
          >
            <span>Stop</span>
            {unitState.running && isInProgress("running") ? (
              <CircularProgress color="inherit" size={"1rem"} sx={{ ml: 1 }} />
            ) : (
              <PauseIcon />
            )}
          </Button>
        </Stack>
        <ToggleButtonGroup
          style={{ marginTop: props.onMapView ? 5 : 0 }}
          color="primary"
          id="direction"
          value={unitState.direction}
          disabled={
            !unitState.direction ||
            isInProgress("direction") ||
            !capabilities.controls.direction
          }
          size="small"
          exclusive
          onChange={(e, value) => {
            if (value)
              changeControlState("direction", value as "Forward" | "Reverse");
          }}
        >
          <ToggleButton value="Reverse">
            {unitState.direction === "Forward" && isInProgress("direction") && (
              <CircularProgress color="inherit" size={"1rem"} sx={{ mr: 1 }} />
            )}
            Reverse
          </ToggleButton>
          <ToggleButton value="Forward">
            {unitState.direction === "Reverse" && isInProgress("direction") && (
              <CircularProgress color="inherit" size={"1rem"} sx={{ mr: 1 }} />
            )}
            Forward
          </ToggleButton>
        </ToggleButtonGroup>
      </Stack>
      <Box
        sx={{
          width: "100%",
          px: 2,
          pb: 1,
          borderBottom: 1,
          borderColor: "divider",
        }}
      >
        <SpeedDepthInput
          speedpc={unitState.speedpc || NaN}
          unitId={props.unitId}
          disabled={isInProgress("speedpc") || !capabilities.controls.speedpc}
          onBlur={(newSpeedPc) => {
            if (newSpeedPc !== unitState.speedpc) {
              changeControlState("speedpc", newSpeedPc);
            }
          }}
          width="100%"
        />
      </Box>

      <Grid container={!props.onMapView}>
        <Grid item xs={6} md={12}>
          <List sx={{ mt: 0 }} dense disablePadding>
            <ListItem
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%",
                height: props.onMapView ? "35px" : "50px",
              }}
              divider
            >
              <ListItemText primary="Park" />
              <div style={{ display: "flex" }}>
                {isInProgress("park") && (
                  <CircularProgress
                    color="inherit"
                    size={"1rem"}
                    sx={{ mr: 1, alignSelf: "center" }}
                  />
                )}
                <Switch
                  sx={{ alignSelf: "flex-end" }}
                  checked={unitState.park || false}
                  onChange={(e) => changeControlState("park", e.target.checked)}
                  disabled={isInProgress("park") || !capabilities.controls.park}
                />
              </div>
            </ListItem>
            <ListItem
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%",
                height: props.onMapView ? "35px" : "50px",
              }}
              divider
            >
              <ListItemText primary="Pump" />
              <div style={{ display: "flex" }}>
                {isInProgress("pump") && (
                  <CircularProgress
                    color="inherit"
                    size={"1rem"}
                    sx={{ mr: 1, alignSelf: "center" }}
                  />
                )}
                <Switch
                  disabled={isInProgress("pump") || !capabilities.controls.pump}
                  sx={{ alignSelf: "flex-end" }}
                  checked={unitState.pump || false}
                  onChange={(e) => changeControlState("pump", e.target.checked)}
                />
              </div>
            </ListItem>
            <ListItem
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%",
                height: props.onMapView ? "35px" : "50px",
              }}
              divider
            >
              <ListItemText primary="Endgun" />
              <div style={{ display: "flex" }}>
                {isInProgress("endGun") && (
                  <CircularProgress
                    color="inherit"
                    size={"1rem"}
                    sx={{ mr: 1, alignSelf: "center" }}
                  />
                )}
                <Switch
                  disabled={
                    isInProgress("endGun") || !capabilities.controls.endGun
                  }
                  sx={{ alignSelf: "flex-end" }}
                  checked={unitState.endGun || false}
                  onChange={(e) =>
                    changeControlState("endGun", e.target.checked)
                  }
                />
              </div>
            </ListItem>
          </List>
        </Grid>
        <Grid item xs={6} md={12}>
          <List sx={{ mt: 0 }} dense disablePadding>
            <ListItem
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%",
                height: "50px",
              }}
              divider
            >
              <ListItemText
                primary={
                  unit.systemType === "Pivot" ? "Park Angle" : "Park Position"
                }
              />
              <div
                style={{
                  display: "flex",
                  maxWidth: props.onMapView ? 90 : "unset",
                }}
              >
                {isInProgress("parkAngle") && (
                  <CircularProgress
                    color="inherit"
                    size={"1rem"}
                    sx={{ mr: 1, alignSelf: "center" }}
                  />
                )}
                <InteractiveInput
                  id="parkAngle"
                  minValue={0}
                  unitId={props.unitId}
                  maxValue={unit.systemType === "Pivot" ? 359 : unit.lengthFeet}
                  value={unitState.parkAngle == null ? 0 : unitState.parkAngle}
                  displayUnit="°"
                  onChange={(newNum) => {
                    if (newNum !== unitState.parkAngle)
                      changeControlState("parkAngle", newNum);
                  }}
                  unit={unit}
                  disabled={
                    //  !props.capabilities.controls.parkAngle ||
                    unitState.parkAngle == null ||
                    isInProgress("parkAngle") ||
                    !unit.unitState.park
                  }
                  hideErrorHelperText={true}
                  maxWidth="120px"
                />
              </div>
            </ListItem>

            <ListItem
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%",
                height: props.onMapView ? "35px" : "50px",
              }}
              divider
            >
              <ListItemText primary="Chem. Pump" />
              <div style={{ display: "flex" }}>
                {isInProgress("chemPump") && (
                  <CircularProgress
                    color="inherit"
                    size={"1rem"}
                    sx={{ mr: 1, alignSelf: "center" }}
                  />
                )}
                <Switch
                  disabled={
                    isInProgress("chemPump") || !isWet(unit.status)
                    // || !props.capabilities.controls.chemPump
                  }
                  sx={{ alignSelf: "flex-end" }}
                  checked={unitState.chemPump || false}
                  onChange={(e) =>
                    changeControlState("chemPump", e.target.checked)
                  }
                />
              </div>
            </ListItem>
            <ListItem
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%",
                height: props.onMapView ? "35px" : "50px",
              }}
              divider
            >
              <ListItemText primary="Auxiliary" />
              <div style={{ display: "flex" }}>
                {isInProgress("aux1") && (
                  <CircularProgress
                    color="inherit"
                    size={"1rem"}
                    sx={{ mr: 1, alignSelf: "center" }}
                  />
                )}
                <Switch
                  disabled={
                    isInProgress("aux1") //|| !props.capabilities.controls.aux1
                  }
                  sx={{ alignSelf: "flex-end" }}
                  checked={unitState.aux1 || false}
                  onChange={(e) => changeControlState("aux1", e.target.checked)}
                />
              </div>
            </ListItem>
          </List>
        </Grid>
      </Grid>
    </Stack>
  );
};

export default ActionPanel;

const isWet = (status: IUnitData["status"]): boolean => {
  return status === "Running Wet" || status === "Stopped Wet";
};
