import * as React from "react";
import { Box, Button, CircularProgress, Divider, FormControl, InputLabel, ListItem, ListItemText, MenuItem, Modal, Select, Stack, TextField, Typography } from "@mui/material";
import LayersIcon from '@mui/icons-material/Layers';
import CloseIcon from '@mui/icons-material/Close';
import MapLayersCtx from "../../../context/MapLayersCtx/MapLayersCtx";
import { useContext, useMemo, useState } from "react";
import LayerListItem from "./LayerListItem";
import AddIcon from '@mui/icons-material/Add';
import { FeatureCollection } from "geojson";
import { GeoJSONSource } from "mapbox-gl";
import shp from 'shpjs';
import { SliderPicker, HuePicker } from 'react-color'
import { IMapLayer } from "../../../context/MapLayersCtx/IMapLayer";
import { convertPointsToPolygons } from "../MapHelper";
import DeleteIcon from '@mui/icons-material/Delete';

interface Props {
    open: boolean;
    onClose: () => void;
    editingLayer?: IMapLayer;
}

const AddEditLayerModal: React.FC<Props> = (props: Props) => {

    const fillColorPaintProperty = props.editingLayer && props.editingLayer.mapboxLayerConfig && props.editingLayer.mapboxLayerConfig.paint &&
        props.editingLayer.mapboxLayerConfig.paint['fill-color'] ? props.editingLayer.mapboxLayerConfig.paint['fill-color'] : null;
    const defaultProp = fillColorPaintProperty && fillColorPaintProperty!['property'] ? fillColorPaintProperty!['property'] : '';

    const layerContext = useContext(MapLayersCtx);
    const [name, setName] = useState<string>(props.editingLayer ? props.editingLayer.displayName : "");

    const [geojson, setGeoJSON] = useState<FeatureCollection | null>(props.editingLayer && props.editingLayer.mapboxSource["data"] ? props.editingLayer.mapboxSource["data"] : null);
    const [displayProp, setDisplayProp] = useState<string>(defaultProp);
    const [shapeFileProcessing, setShapeFileProcessing] = useState<boolean>(false);
    const [paintStops, setPaintStops] = useState<{ value: number, color: string }[]>(fillColorPaintProperty && fillColorPaintProperty.stops ?
        (fillColorPaintProperty.stops).map(s => { return { value: s[0], color: s[1] } }) : [{ value: 0, color: "#0000fe" }, { value: 100, color: "#ff0400" }]);

    const [geojsonProps, setGeojsonProps] = useState<string[]>(
        props.editingLayer && props.editingLayer && props.editingLayer.mapboxSource["data"] ? Object.keys(props.editingLayer.mapboxSource["data"].features[0].properties) : []);
    const [errors, setErrors] = useState<{ [id: string]: string | undefined }>({});
    const [editColorIndex, setEditColorIndex] = useState<number | undefined>(undefined);
    const [newLayerId, setNewLayerId] = useState<string | undefined>(undefined);

    const originalEditingLayer = useMemo(
        () => props.editingLayer,
        [props.editingLayer]
    );

    const processAndSaveLayer = (ignoreValidation?: boolean, fillPaintStops?: { value: number, color: string }[], dispProp?: string): boolean => {
        var valid = true;
        var e = { ...errors };
        if (!ignoreValidation && (!name || name.length == 0)) {
            valid = false;
            e["name"] = "Name cannot be empty";
        }
        if (!geojson) {
            e["file"] = "No file has been processed";
            valid = false;
        }

        if (!ignoreValidation && (!displayProp || displayProp.length === 0)) {
            e["prop"] = "A property must be set to display";
            valid = false;
        }

        if (valid) {

            const layerId = props.editingLayer ? props.editingLayer.id : newLayerId  ? newLayerId : "layer_" + Date.now();
            if(!newLayerId){
                setNewLayerId(layerId);
            } 
            var stops = fillPaintStops ? fillPaintStops.map(es => [es.value, es.color]) :  paintStops.map(es => [es.value, es.color]);
            var newLayer: IMapLayer = {
                displayName: name,
                id: layerId,
                show: props.editingLayer ? props.editingLayer.show : true,
                opacity: props.editingLayer ? props.editingLayer.opacity : 50,
                mapboxLayerConfig: {
                    id: layerId,
                    type: 'fill',
                    paint: {
                        'fill-color': {
                            property: dispProp ?? displayProp!,
                            stops: stops,
                        },
                        "fill-outline-color": "transparent",
                        //"fill-color": 'white', //default
                    },
                    source: layerId + "_source",
                    layoutOpacityName: 'fill-opacity',
                    // minzoom: 13,  //can be defined on UI
                },
                mapboxSource: {
                    id: layerId + "_source",
                    type: "geojson",
                    data: geojson!,
                },
            };
            layerContext.setLayer(newLayer.id, newLayer);
        } else {
            setErrors(e);
        }

        return valid;
    }

    const processZipFile = async (file: File) => {
        var e = { ...errors };
        e["file"] = undefined;
        if (!file) {
            e["file"] = "No file selected.";
            setErrors(e);
            return;
        }
        if (!file.type.startsWith('application/zip') && !file.type.startsWith('application/x-zip-compressed')) {
            e["file"] = "Please select a .zip file";
            setErrors(e);
            return;
        }

        try {
            const arrayBuffer = await file.arrayBuffer();
            const processed: FeatureCollection = await shp(arrayBuffer);
            var geojson;
            if (processed.features) {
                geojson = processed;
            } else {
                if (processed[0].features) {
                    geojson = processed[0];
                } else if (processed[1].features) {
                    geojson = processed[1];
                }
            }
            if (geojson) {
                setShapeFileProcessing(false);
                if (geojson.features[0].geometry.type === "Point") {
                    const fs = convertPointsToPolygons(geojson.features);
                    geojson.features = fs;
                }
                setGeoJSON(geojson);
                if (geojson.features.length > 0) {
                    //assuming all features(polygons) have the same properties
                    if (geojson.features[0].properties) setGeojsonProps(Object.keys(geojson.features[0].properties));
                } else {
                    e["file"] = 'No polygon features found in file.';
                }
            } else {
                e["file"] = 'Shapefile conversion error.';
            }
        } catch (error) {
            e["file"] = 'Failed to parse the ZIP file:' + error;
        }
        setErrors(e);
    }

    const revertLayer = () => {
        if (props.editingLayer && JSON.stringify(layerContext.layers[props.editingLayer.id]) !== JSON.stringify(originalEditingLayer)) {
            layerContext.setLayer(originalEditingLayer!.id, originalEditingLayer!);
        }else{
            if(newLayerId){
                var updated: {[id: string]: IMapLayer} = {};
                for(let layerId in layerContext.layers){
                    if(layerId !== newLayerId){
                        updated[layerId] = layerContext.layers[layerId];
                    }
                }
                layerContext.setLayer(newLayerId, null);
            }
        }
        props.onClose();
    }

    return (
        <Modal
            open={props.open}
            slotProps={{ backdrop: { sx: { backgroundColor: 'transparent' } } }}
            onClose={() => { revertLayer(); }}     
        >
            <div
                style={{
                    position: 'absolute',
                    borderRadius: 5,
                    backgroundColor: 'white',
                    top: '50%',
                    left: '50%',
                    minWidth: 400,
                    transform: 'translate(-50%, -50%)',
                    padding: 10,
                    display: 'flex',
                    flexDirection: 'column'
                }}>
                <Stack direction="row" style={{ justifyContent: 'space-between', width: '100%' }}>
                    <Stack direction="row">
                        <LayersIcon />
                        <span style={{ fontWeight: 'bold', marginLeft: 5 }}>{props.editingLayer ? "Edit layer" : "Add new Layer"}</span>
                    </Stack>
                    <CloseIcon style={{ color: 'gray', cursor: 'pointer' }} onClick={() => revertLayer()} />
                </Stack>
                <Divider style={{ width: '100%', marginTop: 10, marginBottom: 10 }} />
                <TextField
                    error={errors["name"] !== undefined}
                    helperText={errors["name"]}
                    label="Name"
                    variant="outlined"
                    style={{ marginBottom: 10 }}
                    value={name}
                    onChange={(ev) => {
                        setName(ev.target.value);
                        var e = { ...errors };
                        e["name"] = undefined;
                        setErrors(e);
                    }}
                />
                {
                    !props.editingLayer &&
                    <>
                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                            <div>
                                <input
                                    id="fileInput"
                                    type="file"
                                    style={{ marginBottom: 10, }}
                                    accept=".zip"
                                    onChange={async (e) => {
                                        if (e.target.files) {
                                            setShapeFileProcessing(true);
                                            await processZipFile(e.target.files[0]);
                                        }
                                    }}
                                />
                                {errors["file"] && <span style={{ color: '#d32f2f', fontSize: '0.75rem', paddingLeft: 15 }}>{errors["file"]}</span>}
                            </div>
                            {
                                shapeFileProcessing && <CircularProgress size={"1.5rem"} />
                            }
                        </div>
                    </>
                }

                {
                    geojson &&
                    <FormControl style={{ marginBottom: 10 }}>
                        <InputLabel id="display-prop-select-label">Display property</InputLabel>
                        <Select
                            labelId="display-prop-select-label"
                            value={displayProp}
                            error={errors["prop"] !== undefined}
                            label="Display property"
                            onChange={(event) => {
                                const selected = event.target.value as string;
                                setDisplayProp(selected);
                                var min = Number.MAX_VALUE;
                                var max = Number.MIN_VALUE;
                                var dataSet: number[] = [];
                                geojson.features.forEach(x => {
                                    if (x.properties && x.properties[selected] && x.properties[selected] < min) {
                                        min = x.properties[selected];
                                    }
                                    if (x.properties && x.properties[selected] && x.properties[selected] > max) {
                                        max = x.properties[selected];
                                    }
                                    if (x.properties && x.properties[selected]) {
                                        dataSet.push(x.properties[selected]);
                                    }
                                });

                                var stops = [...paintStops];
                                if (min !== Number.MAX_VALUE) {
                                    stops[0].value = min;
                                }
                                if (max !== Number.MIN_VALUE) {
                                    stops[stops.length - 1].value = max;
                                }

                                if (min !== Number.MAX_VALUE && max !== Number.MIN_VALUE) { //set pre-defined color stops based on data
                                    dataSet.sort();
                                    stops.splice(stops.length - 1, 0, { value: dataSet[Math.floor(dataSet.length * 0.25)], color: '#00d3ff' });
                                    stops.splice(stops.length - 1, 0, { value: dataSet[Math.floor(dataSet.length * 0.5)], color: '#7eff81' });
                                    stops.splice(stops.length - 1, 0, { value: dataSet[Math.floor(dataSet.length * 0.75)], color: '#ffd700' });
                                }
                               
                                processAndSaveLayer(true, stops, selected); //need to pass as params as state is not updated at this stage
                                var e = { ...errors };
                                e["prop"] = undefined;
                                setErrors(e);
                                setPaintStops(stops);
                            }}
                        >
                            {geojsonProps.map(p => <MenuItem key={p} value={p}>{p}</MenuItem>)}
                        </Select>
                    </FormControl>

                }
                {geojson && errors["prop"] && <span style={{ color: '#d32f2f', fontSize: '0.75rem', paddingLeft: 15 }}>{errors["prop"]}</span>}
                {
                    displayProp && displayProp.length > 0 &&
                    <>
                        <Stack direction="column">
                            {
                                paintStops.map((es, index) => <Stack style={{ marginBottom: 10 }}>
                                    <Stack direction="row" style={{ justifyContent: 'space-between', alignItems: 'center', marginBottom: 5 }}>
                                        {index === 0 && <b style={{ fontSize: 14, marginRight: 5 }}>Min value</b>}
                                        {index > 0 && index < paintStops.length - 1 && <span style={{ fontSize: 14, marginRight: 5 }}>Color stop</span>}
                                        {index === paintStops.length - 1 && <b style={{ fontSize: 14, marginRight: 5 }}>Max value</b>}
                                        <Stack direction="row" style={{ alignItems: 'center' }}>
                                            {(index === 0 || index === paintStops.length - 1) && <b>{es.value}</b>}
                                            {index > 0 && index < paintStops.length - 1 &&
                                                <Stack direction="row" style={{ alignItems: 'center' }}>
                                                    <DeleteIcon style={{ color: 'gray', cursor: 'pointer' }} onClick={() => {
                                                        var stops = [...paintStops];
                                                        stops.splice(index, 1);
                                                        setPaintStops(stops);
                                                        processAndSaveLayer(true);
                                                    }} />
                                                    <TextField
                                                        size="small"
                                                        variant="outlined"
                                                        style={{ maxWidth: 150, padding: 0, color: 'gray', }}
                                                        inputProps={{ min: paintStops[0].value, max: paintStops[paintStops.length - 1].value, style: { textAlign: 'right' } }}
                                                        value={es.value}
                                                        onChange={(ev) => {
                                                            var stops = [...paintStops];
                                                            stops[index].value = parseFloat(ev.target.value);
                                                            setPaintStops(stops);
                                                            processAndSaveLayer(true);
                                                        }}
                                                    />

                                                </Stack>
                                            }
                                            <TextField
                                                size="small"
                                                variant="outlined"
                                                style={{ maxWidth: 90, padding: 0, color: 'gray', marginLeft: 5 }}
                                                inputProps={{ min: paintStops[0].value, max: paintStops[paintStops.length - 1].value, style: { textAlign: 'right' } }}
                                                value={es.color}
                                                onClick={() => setEditColorIndex(index)}
                                                onChange={(ev) => {
                                                    var stops = [...paintStops];
                                                    stops[index].color = ev.target.value; //todo validate
                                                    setPaintStops(stops);
                                                    processAndSaveLayer(true);
                                                }}
                                            />
                                            <div
                                                style={{
                                                    height: 24,
                                                    width: 24,
                                                    border: '1px solid lightgray',
                                                    borderRadius: 12,
                                                    cursor: 'pointer',
                                                    backgroundColor: es.color,
                                                    marginLeft: 10
                                                }}
                                                onClick={() => setEditColorIndex(index)}
                                            />
                                        </Stack>
                                    </Stack>
                                </Stack>)
                            }
                            {
                                editColorIndex !== undefined &&
                                <div style={{ width: '100%', display: 'flex', justifyContent: 'center', marginBottom: 10 }}>
                                    <HuePicker color={paintStops[editColorIndex].color} onChange={(color) => {
                                        var stops = [...paintStops];
                                         stops[editColorIndex].color = color.hex;
                                        setPaintStops(stops);
                                        processAndSaveLayer(true);
                                    }} />
                                </div>
                            }

                        </Stack>
                        <Button variant="outlined" onClick={() => {
                            var existingStops = [...paintStops];
                            existingStops.splice(existingStops.length - 1, 0, { value: paintStops[paintStops.length - 1].value, color: paintStops[paintStops.length - 1].color });
                            setPaintStops(existingStops);
                            processAndSaveLayer(true);
                        }}>Add color stop</Button>

                    </>
                }
                <Divider style={{ width: '100%', marginTop: 10, marginBottom: 10 }} />
                <Stack direction="row" style={{ cursor: 'pointer', justifyContent: props.editingLayer ? 'space-between' : 'flex-end' }} >
                    <Button
                        disabled={shapeFileProcessing}
                        variant={props.editingLayer ? "outlined" : "contained"}
                        color="primary"
                        onClick={() => {
                            if (props.editingLayer) {
                                revertLayer();
                            } else {
                                const valid = processAndSaveLayer();
                                if(valid){
                                    props.onClose();
                                }                           
                            }
                        }}
                    >
                        {props.editingLayer ? "CANCEL" : "SAVE"}
                    </Button>
                    {
                        props.editingLayer &&
                        <Button variant="contained" color="primary" onClick={() => props.onClose()}>UPDATE</Button>
                    }
                </Stack>
            </div>
        </Modal>
    );
}

export default AddEditLayerModal;

