import { FC, ReactElement, useCallback, useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import React from "react";
import InputAdornment from "@mui/material/InputAdornment";

interface Props {
  id: string;
  value: number;
  onChange?: (newValue: number) => void;
  onError?: (hasError: boolean) => void;
  onBlur?: (newValue: number) => void;
  onClick?: () => void;
  label?: string;
  unit?: string;
  min?: number;
  max?: number;
  disabled?: boolean;
  size?: "small";
  width?: string;
  isUnitPositionEnd?: boolean;
  hideErrorHelperText?: boolean;
  inlineIcon?: ReactElement;
  ignoreDecimalPlaceValidation?: boolean;
  maxWidth?: string;
}

const NumberBoxEditor: FC<Props> = (props) => {
  const {
    id,
    onChange,
    onError,
    onBlur,
    value: propValue,
    label,
    unit,
    min,
    max,
    disabled,
    size,
    width,
    isUnitPositionEnd,
    hideErrorHelperText,
    inlineIcon,
    ignoreDecimalPlaceValidation,
    maxWidth,
    onClick,
  } = props;

  const [error, setError] = useState<string>("");
  const [value, setValue] = useState<string>(
    propValue === null || isNaN(propValue) ? "" : propValue.toString()
  );

  useEffect(() => {
    const newValue =
      propValue === null || isNaN(propValue) ? "" : propValue.toString();
    if (newValue !== value) {
      setValue(newValue);
      validateValue(newValue);
    }
  }, [propValue]);

  const setErrorAndTriggerCallback = (message: string, isError: boolean) => {
    setError(message);
    if (onError) onError(isError);
  };

   const validateValue = (inputValue: string): boolean => {
    const normalizedInput = inputValue.replace(/,/g, ".");
    const hasMoreThanTwoDecimals = /^\d+\.\d{3,}$/.test(normalizedInput);
    const parsedValue = parseFloat(normalizedInput);

    if (normalizedInput.trim() === "") {
      setErrorAndTriggerCallback("Cannot be empty", true);
      return false;
    } else if (isNaN(parsedValue)) {
      setErrorAndTriggerCallback("Must be a number", true);
      return false;
    } else if (!ignoreDecimalPlaceValidation && hasMoreThanTwoDecimals) {
      setErrorAndTriggerCallback("Only two decimals allowed", true);
      return false;
    } else if (min !== undefined && parsedValue < min) {
      setErrorAndTriggerCallback(`Must be at least ${min}`, true);
      return false;
    } else if (max !== undefined && parsedValue > max) {
      setErrorAndTriggerCallback(`Must not exceed ${max}`, true);
      return false;
    }

    setError("");
    if (onError) onError(false);
    return true;
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value;

    const validInput = inputValue.replace(/[^0-9.,]/g, "");
    setValue(validInput);

    if (validateValue(validInput) && onChange) {
      onChange(parseFloat(validInput.replace(/,/g, ".")));
    }
  };

  const handleBlur = () => {
    if (validateValue(value) && onBlur) {
      onBlur(parseFloat(value.replace(/,/g, ".")));
    }
  };

  return (
    <TextField
      id={id}
      label={label}
      value={value}
      error={!!error}
      helperText={!hideErrorHelperText ? error : undefined}
      disabled={disabled}
      size={size}
      onChange={handleChange}
      onBlur={handleBlur}
      onClick={onClick}
      InputLabelProps={{
        shrink: true,
      }}
      {...(unit || inlineIcon
        ? {
            InputProps: {
              [isUnitPositionEnd ? "endAdornment" : "startAdornment"]: (
                <InputAdornment position={isUnitPositionEnd ? "end" : "start"}>
                  {inlineIcon}
                  {!inlineIcon && unit}
                </InputAdornment>
              ),
            },
          }
        : {})}
      sx={{ width: width, maxWidth: maxWidth }}
    />
  );
};
export default NumberBoxEditor;
