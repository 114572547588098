import { Box } from "@mui/material";
import * as React from "react";
import Map from "../../components/Map";
import TopNav from "../../components/Navigation/TopNav";

const MapPage: React.FC = () => {
  return (
    <Box
      sx={{
        height: "100vh",
        overflow: "hidden",
      }}
    >
      <TopNav />
      <Box
        sx={{
          height: {
            xs: "calc(100% - 56px)",
            sm: "calc(100% - 64px)",
          },
          mt: {
            xs: "56px",
            sm: "64px",
          },
        }}
      >
        <Map />
      </Box>
    </Box>
  );
};

export default MapPage;
