import * as React from "react";
import { useParams } from "react-router-dom";

import Map from "../../../components/Map";
import Stack from "@mui/material/Stack";
import Paper from "@mui/material/Paper";
import BackgroundDataCtx from "../../../context/BackgroundDataCtx/BackgroundDataCtx";
import { useContext, useEffect, useState } from "react";
import Highcharts, { YAxisOptions } from 'highcharts';
import HighchartsReact, { HighchartsReactRefObject } from 'highcharts-react-official';
import { ILoadSensorDataReq, ISensorData } from "../../../context/RswsApiCtx/IRswsApi";
import * as moment from "moment";
import { FormControl, InputLabel, Select, MenuItem, Button, SelectChangeEvent } from "@mui/material";
import RswsApiCtx from "../../../context/RswsApiCtx/RswsApiCtx";

import exportData from 'highcharts/modules/export-data';

exportData(Highcharts);

enum TimeRange {
  halfDay,
  day,
  week,
  month,
  year,
  twoYears
}

enum ChartType {
  Line,
  Bar
}

const SensorGraph: React.FC = () => {
  const { unitId, sensorId } = useParams();
  if (!unitId) return <>Unit not recognised</>;
  if (!sensorId) return <>Sensor not recognised</>;

  const bgdata = useContext(BackgroundDataCtx);
  const unit = bgdata.units[unitId];
  //var initialSensor = Object.values(unit.sensor)?.find(s => s.id?.toString() === sensorId);
  const api = useContext(RswsApiCtx);
  const [displayedSensors, setDisplayedSensors] = useState<string[]>([sensorId]);

  const allSensors = Object.values(unit.sensor)
    .filter((sensorData) => sensorData.last !== undefined)
    .map((sensorData, index) => ({
      id: sensorData.id,
      name: sensorData.descr,
    }));

  const [chartType, setChartType] = useState<ChartType>(ChartType.Line);
  const [series, setSeries] = useState<Highcharts.SeriesOptionsType[]>([]);
  const [range, setRange] = useState<TimeRange>(TimeRange.week);

  const getFromTime = (range: TimeRange): number => {
    switch (range) {
      case TimeRange.halfDay:
        return moment().add(-12, 'h').unix();
      case TimeRange.day:
        return moment().add(-24, 'h').unix();
      case TimeRange.week:
        return moment().add(-1, 'w').unix();
      case TimeRange.month:
        return moment().add(-1, 'M').unix();
      case TimeRange.year:
        return moment().add(-1, 'y').unix();
      case TimeRange.twoYears:
        return moment().add(-2, 'y').unix();
    }
  }

  const getDateFormatter = (range: TimeRange): string => {
    switch (range) {
      case TimeRange.halfDay:
      case TimeRange.day:
        return 'h:mma';
      case TimeRange.week:
      case TimeRange.month:
      case TimeRange.year:
      case TimeRange.twoYears:
        return 'YYYY-M-D';
    }
  }



  const updateSeriesVisibility = (name: string, visible: boolean) => {
    var newSeries: Highcharts.SeriesOptionsType[] = [];
    series.forEach(s => {
      var modified = { ...s };
      if (s.name === name) {
        modified.visible = visible;
        (modified as any).selected = visible;
      }
      newSeries.push(modified as Highcharts.SeriesOptionsType);
    });
    setSeries(newSeries);
  }

  const getChartOptionsDef = (): Highcharts.Options => {
    return {
      chart: {
        type: chartType === ChartType.Line ? "line" : "column",
        height: window.innerHeight - 250,
      },
      title: {
        text: getChartTitle(),
      },
      legend: {
        itemDistance: 50,
        symbolWidth: 0,
        useHTML: true,
        itemCheckboxStyle: { "position": "absolute", "margin-left": "-45px" }
      },
      series: series,
      plotOptions: {
        series: {
          showCheckbox: true,
          events: {
            checkboxClick: function (event) {
              if (!event.checked) {
                this.setVisible(false, false); // The second parameter 'false' prevents the chart from redrawing
                updateSeriesVisibility(this.getName(), false);
              } else {
                this.setVisible(true, false); // The second parameter 'false' prevents the chart from redrawing   
                updateSeriesVisibility(this.getName(), true);
              }
              // Return false to prevent the default action
              return false;
            }
          },
        }
      },
      tooltip: {
        enabled: true,
        formatter: function () {         
          return '<b>' + moment(this.x).format('YYYY-M-D h:mma') + '</b><br />' + this.series.name + ' <b>' + translateValue(this.y, undefined, this.series.name)  + '</b>';
        }
      },
      xAxis: {
        tickWidth: 0,
        gridLineWidth: 1,
        title: {
          text: null
        },
        labels: {
          align: 'left',
          x: 3,
          y: -3,
          formatter: function () {
            return moment(this.value).format(getDateFormatter(range));
          }
        }
      },
      yAxis: getYAxises(),
    };
  }

  const getChartTitle = (): string => {
    var title = "";
    series.forEach((se, i) => {
      title += se.name! + (i < series.length - 1 && series.length > 1 ? ", " : "");
    });

    return title;
  }

  const getYAxises = (): YAxisOptions[] => { 
    var yAxises: any = [];
    series.forEach((se, i) => {
      const sensor = Object.values(unit.sensor).find(s => s.id && s.id === parseInt(se.id!));
      const binarySensor = (sensor!.typeId >= 8240 && sensor!.typeId < 8260);

      yAxises.push({
        title: {
          text: null
        },
        tickAmount: (binarySensor ? 4 : undefined),
        tickInterval: (binarySensor ? 0.5 : undefined),
        showLastLabel: (binarySensor ? false : true),
        max: (binarySensor ? 1.5 : undefined),
        min: (binarySensor ? -0.5 : undefined),
        showFirstLabel: false,
        labels: {
          align: 'left',
          x: 40 * (i) + 5,
          y: 16,
          formatter: function () {
            return translateValue(this.value, se.id!);
          }
        },
      },)
    });

    return yAxises;
  }

  useEffect(() => {
    const fetchData = async () => {
      var newSeries: Highcharts.SeriesOptionsType[] = [];
      for (const sId of displayedSensors) {
        const sensor = Object.values(unit.sensor).find(s => s.id && s.id === parseInt(sId));
        const data = await api.findSensorData({
          sensorDataQueryFilter: {
            from: (getFromTime(range) * 1000).toString(),
            to: (moment().unix() * 1000).toString(),
            unitId: unitId,
            port: sensor!.port.toString(),
            subPort: sensor!.subPort.toString(),
            tzAdj: false
          }
        });

        var prev = series.find(x => x.id === sId);

        newSeries.push({
          type: chartType === ChartType.Line ? "line" : "column",
          name: sensor!.descr,
          id: sId,
          data: data.sensorData.values,
          selected: prev ? (prev as any).selected : true,
          visible: prev ? (prev as any).visible : true,
          yAxis: newSeries.length,
        });

      }
      setSeries(newSeries);
    };
    fetchData();


  }, [range, displayedSensors]);

  const translateValue = (value, sId?: string, sName?: string) => {
      const sensor = sId ? Object.values(unit.sensor).find(s => s.id && s.id === parseInt(sId)) : 
      Object.values(unit.sensor).find(s => s.descr && s.descr === sName);

   
    //formatter copied from old ReinCloud
    var pump = (sensor?.typeId === 8242);
    var chemiPump = (sensor?.typeId === 8243);
    var aux1 = (sensor?.typeId === 8244);
    var endGun = (sensor?.typeId === 8245);
    var externalPower = (sensor?.typeId === 8249);
    var degree = (sensor?.typeId === 8204 || sensor?.typeId === 8260);
    var systemMode = (sensor?.typeId === 8248);
    var enabled = (sensor?.typeId === 8246);
    var tripped = (sensor?.typeId === 8250);
    var direction = (sensor?.typeId === 8241);
    var runstop = (sensor?.typeId === 8240);
    var pressure = (sensor?.typeId === 8247);
    var battery = (sensor?.typeId === 8201);
    var appliedDepth = (sensor?.typeId === 8207);
    var commuStatus = (sensor?.typeId === 8252);
    var boardAirTemp = (sensor?.typeId === 8202);
    var switchSensor = (sensor?.typeId === 8396);
    var isTheft = (sensor?.typeId === 8251);

    if (!degree) {
      value = Math.round(value * 100) / 100;
    }

    if (degree) {
      value = Math.round(value * 10) / 10;
      if (unit.systemType === "Lateral" && sensor?.typeId === 8204) {
        value *= 10;
        /*	if(unitOfMeasure === '1' || unitOfMeasure === '3') {
            value /= 3.28;
            value = Math.round( value * 10) / 10;
            return value + 'm';
          }*/  //TODO
        return value + 'ft';  //unit measurement is empty so I hard code it to ft
      }
      return value + '\xB0';
    } else if (runstop) {
      return (value === 1) ? 'Running' : 'Stopped';
    } else if (pressure) {
      return (value === 1) ? 'Wet' : 'Dry';
    } else if (battery) {
      return value + 'V';
    } else if (appliedDepth) {
      /*if(unitOfMeasure === '1' || unitOfMeasure === '3') {
        return value + 'mm';
      }*/ //TODO
      return value + 'in';
    } else if (boardAirTemp) {
      /*if(unitOfMeasure === '1' || unitOfMeasure === '3') {
        return value + '\xB0' + 'C';
      }*/ //TODO
      return value + '\xB0' + 'F';
    } else if (isTheft) {
      return (value === 1) ? 'Alert' : 'OK';
    }

    if (direction) {
      return (value === 1) ? 'Reverse' : 'Forward';
    } else if (systemMode) {
      return (value === 1) ? 'Pivot' : 'Lateral';
    } else if (enabled) {
      return (value === 1) ? 'Enabled' : 'Disabled';
    } else if (tripped) {
      return (value === 1) ? 'OK' : 'Open';
    } else if (pump || chemiPump || aux1 || endGun || externalPower || commuStatus || switchSensor) {
      return (value === 1) ? 'On' : 'Off';
    } else {
      return value;
    }
  }

  const downloadFile = () => {
    var processedData: { [id: number]: string[]} = {};
    series.forEach((s, i) => {
      const chartData: number[][] = (s as any).data;
      chartData.forEach(cd => {
        if(!processedData[cd[0]]){
          processedData[cd[0]] = [cd[0].toString(), moment(cd[0]).format('YYYY-M-D h:mma')];
          for(let j=0; j< series.length; j++){
            processedData[cd[0]].push(""); //fill up with empty data
          }
        }
      });
    });

    series.forEach((s, i) => {
      const chartData: number[][] = (s as any).data;
      chartData.forEach(cd => {
        processedData[cd[0]][i + 2] = cd[1].toString();
      });
    });
    
    var data: string[][] = [];
    for(let id in processedData){
      data.push(processedData[id]);
    }
    data = data.sort((x,y)=> {return parseInt(x[0]) - parseInt(y[0])});
    for(var i=0; i<data.length; i++){
      data[i].splice(0,1);
    }
    
    data.unshift(["Date & Time", getChartTitle()]); 
    const csvString = data.map(row => row.join(',')).join('\n');
    const blob = new Blob([csvString], { type: 'text/csv' });
    const a = document.createElement('a');
    a.download = unit.name + " - " + getChartTitle();
    a.href = window.URL.createObjectURL(blob);
    const clickEvt = new MouseEvent('click', {
      view: window,
      bubbles: true,
      cancelable: true,
    });
    a.dispatchEvent(clickEvt);
    a.remove();
  }

  const handleSensorListChange = (event: SelectChangeEvent<string[]>) => {
    const { target: { value }, } = event;
    setDisplayedSensors(typeof value === 'string' ? value.split(',') : value,);
  };

  return (
    <Stack>
      <Stack direction="row" style={{ padding: 10, flexFlow: 'wrap' }}>
        <FormControl style={{ minWidth: 100, marginTop: 10 }}>
          <InputLabel id="rangle-label">Range</InputLabel>
          <Select
            value={range}
            labelId="rangle-label"
            label="Range"
            onChange={(evt) => {
              setRange(evt.target.value as TimeRange);
            }}
            style={{ maxHeight: 40, marginRight: 10, minWidth: 100, }}
          >
            <MenuItem key={TimeRange.halfDay} value={TimeRange.halfDay}>12 Hours</MenuItem>
            <MenuItem key={TimeRange.day} value={TimeRange.day}>24 Hours</MenuItem>
            <MenuItem key={TimeRange.week} value={TimeRange.week}>1 Week</MenuItem>
            <MenuItem key={TimeRange.month} value={TimeRange.month}>1 Month</MenuItem>
            <MenuItem key={TimeRange.year} value={TimeRange.year}>1 Year</MenuItem>
            <MenuItem key={TimeRange.twoYears} value={TimeRange.twoYears}>2 Years</MenuItem>
          </Select>
        </FormControl>
        <FormControl style={{ minWidth: 100, marginTop: 10 }}>
          <InputLabel id="chart-type-label">Chart Type</InputLabel>
          <Select
            value={chartType}
            label="Chart Type"
            labelId="chart-type-label"
            onChange={(evt) => {
              const newChartType = evt.target.value as ChartType;
              setChartType(newChartType);
            }}
            style={{ maxHeight: 40 }}
          >
            <MenuItem key={ChartType.Line} value={ChartType.Line}>Line</MenuItem>
            <MenuItem key={ChartType.Bar} value={ChartType.Bar}>Bar</MenuItem>
          </Select>
        </FormControl>
        <FormControl style={{ minWidth: 150, marginTop: 10 }}>
          <InputLabel id="sensors-label">Sensor(s)</InputLabel>
          <Select
            value={displayedSensors}
            label="Sensor(s)"
            labelId="sensors-label"
            multiple
            onChange={handleSensorListChange}
            style={{ maxHeight: 40, marginLeft: 10, maxWidth: 250, }}
          >
            {
              allSensors.map(s => <MenuItem key={s.id} value={s.id}>{s.name}</MenuItem>)
            }
          </Select>
        </FormControl>

      </Stack>
      <HighchartsReact highcharts={Highcharts} options={getChartOptionsDef()} />
      <Button
        variant="contained"
        color="primary"
        onClick={() => {
         
            downloadFile();
          
        }}
        size="medium"
        style={{ marginLeft: 10, marginRight: 10 }}
      >
        EXPORT CHART
      </Button>
    </Stack>
  );
};

export default SensorGraph;
