import { Box, Button, TextField, Grid, Alert, Snackbar } from "@mui/material";
import React, {
  useState,
  useContext,
  FormEvent,
} from "react";
import BackgroundDataCtx from "../../context/BackgroundDataCtx/BackgroundDataCtx";
import CountrySelect from "./CountrySelect";
import { ILoadUserResp } from "../../context/RswsApiCtx/IRswsApi";

const AccountInfo: React.FC = () => {
  const bgdata = useContext(BackgroundDataCtx);
  const [form, setForm] = useState<ILoadUserResp["contact"]>({
    ...bgdata.user.contact,
  });
  const [snack, setSnack] = useState<{ type: string; message: string } | null>(
    null
  );

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    if (validateForm()) {
      try {
        const res = await bgdata.saveUserContact(bgdata.user.name, form);
        if (res !== "User contact saved successfully") {
          throw new Error();
        }
        setSnack({ type: "success", message: "Saved!" });
      } catch (e) {
        setSnack({
          type: "error",
          message: "Error saving contact info. Try again later.",
        });
      }
    }
  };

  const validateForm = (): boolean => {
    for (const key in form) {
      if (typeof form[key] === "string" && form[key].length > 80) {
        setSnack({
          type: "error",
          message: "One or more fields are too long.",
        });
        return false;
      }
    }
    setSnack(null);
    return true;
  };

  return (
    <Box component="form" onSubmit={handleSubmit}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label="First Name"
            name="firstName"
            value={form.name.givenName || ""}
            onChange={(e) =>
              setForm((prevForm) => ({
                ...prevForm,
                name: {
                  ...prevForm.name,
                  givenName: e.target.value,
                },
              }))
            }
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label="Last Name"
            name="lastName"
            value={form.name.familyName || ""}
            onChange={(e) =>
              setForm((prevForm) => ({
                ...prevForm,
                name: {
                  ...prevForm.name,
                  familyName: e.target.value,
                },
              }))
            }
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Address 1"
            name="streetAddress1"
            value={form.addresses[0].streetAddress1 || ""}
            onChange={(e) =>
              setForm((prevForm) => ({
                ...prevForm,
                addresses: [
                  {
                    ...prevForm.addresses[0],
                    streetAddress1: e.target.value,
                  },
                ],
              }))
            }
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Address 2"
            name="streetAddress2"
            value={form.addresses[0].streetAddress2 || ""}
            onChange={(e) =>
              setForm((prevForm) => ({
                ...prevForm,
                addresses: [
                  {
                    ...prevForm.addresses[0],
                    streetAddress2: e.target.value,
                  },
                ],
              }))
            }
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label="City"
            name="city"
            value={form.addresses[0].city || ""}
            onChange={(e) =>
              setForm((prevForm) => ({
                ...prevForm,
                addresses: [
                  {
                    ...prevForm.addresses[0],
                    city: e.target.value,
                  },
                ],
              }))
            }
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <CountrySelect
            countryCode={form.addresses[0].country || ""}
            onChange={(newCountryCode) => {
              setForm((prevForm) => ({
                ...prevForm,
                addresses: [
                  {
                    ...prevForm.addresses[0],
                    country: newCountryCode,
                  },
                ],
              }));
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label="State"
            name="state"
            value={form.addresses[0].state || ""}
            onChange={(e) =>
              setForm((prevForm) => ({
                ...prevForm,
                addresses: [
                  {
                    ...prevForm.addresses[0],
                    state: e.target.value,
                  },
                ],
              }))
            }
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label="Zip"
            name="zip"
            value={form.addresses[0].postalCode || ""}
            onChange={(e) =>
              setForm((prevForm) => ({
                ...prevForm,
                addresses: [
                  {
                    ...prevForm.addresses[0],
                    postalCode: e.target.value,
                  },
                ],
              }))
            }
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="E-mail"
            name="email"
            type="email"
            value={form.emails[0].value || ""}
            onChange={(e) =>
              setForm((prevForm) => ({
                ...prevForm,
                emails: [
                  {
                    ...prevForm.emails[0],
                    value: e.target.value,
                  },
                ],
              }))
            }
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Phone"
            name="phone"
            value={
              form.phoneNumbers.find((p) => p.type === "voice1")?.value || ""
            }
            onChange={(e) =>
              setForm((prevForm) => ({
                ...prevForm,
                phoneNumbers: form.phoneNumbers.find((p) => p.type === "voice1")
                  ? form.phoneNumbers.map((phone) =>
                      phone.type === "voice1"
                        ? { ...phone, value: e.target.value }
                        : phone
                    )
                  : form.phoneNumbers.concat({
                      type: "voice1",
                      value: e.target.value,
                    }),
              }))
            }
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            fullWidth
            label="Mobile Phone 1"
            name="mobilePhone1"
            value={
              form.phoneNumbers.find((p) => p.type === "cell1")?.value || ""
            }
            onChange={(e) =>
              setForm((prevForm) => ({
                ...prevForm,
                phoneNumbers: form.phoneNumbers.find((p) => p.type === "cell1")
                  ? form.phoneNumbers.map((phone) =>
                      phone.type === "cell1"
                        ? { ...phone, value: e.target.value }
                        : phone
                    )
                  : form.phoneNumbers.concat({
                      type: "cell1",
                      value: e.target.value,
                    }),
              }))
            }
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            fullWidth
            label="Mobile Phone 2"
            name="mobilePhone2"
            value={
              form.phoneNumbers.find((p) => p.type === "cell2")?.value || ""
            }
            onChange={(e) =>
              setForm((prevForm) => ({
                ...prevForm,
                phoneNumbers: form.phoneNumbers.find((p) => p.type === "cell2")
                  ? form.phoneNumbers.map((phone) =>
                      phone.type === "cell2"
                        ? { ...phone, value: e.target.value }
                        : phone
                    )
                  : form.phoneNumbers.concat({
                      type: "cell2",
                      value: e.target.value,
                    }),
              }))
            }
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            fullWidth
            label="Mobile Phone 3"
            name="mobilePhone3"
            value={
              form.phoneNumbers.find((p) => p.type === "cell3")?.value || ""
            }
            onChange={(e) =>
              setForm((prevForm) => ({
                ...prevForm,
                phoneNumbers: form.phoneNumbers.find((p) => p.type === "cell3")
                  ? form.phoneNumbers.map((phone) =>
                      phone.type === "cell3"
                        ? { ...phone, value: e.target.value }
                        : phone
                    )
                  : form.phoneNumbers.concat({
                      type: "cell3",
                      value: e.target.value,
                    }),
              }))
            }
          />
        </Grid>
        <Grid item xs={12}>
          <Button type="submit" variant="contained" color="primary" fullWidth>
            Save
          </Button>
        </Grid>
      </Grid>
      <Snackbar
        open={!!snack}
        autoHideDuration={6000}
        onClose={() => setSnack(null)}
      >
        <Alert severity={snack?.type === "error" ? "error" : "success" } variant="filled" sx={{ width: "100%" }}>
          {snack?.message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default AccountInfo;
