import React, { FC, useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";

interface Props {
  id: string;
  value: string;
  onChange: (newValue: string) => void;
  onError: (hasError: boolean) => void;
  label: string;
  unit?: string;
}

// TODO: Maybe change to mui time picker instead of text input
const TimeBoxEditor: FC<Props> = (props) => {
  const { id, onChange, onError, label, unit } = props;
  const [value, setValue] = useState<string>(props.value);
  const [error, setError] = useState<string>("");

  useEffect(() => {
    setValue(props.value);
  }, [props.value]);

  // Validates the time format "H:MM"
  const validateTime = (time: string) => {
    const timeRegex = /^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/;
    return timeRegex.test(time);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    setValue(newValue);

    if (validateTime(newValue)) {
      setError("");
      onError(false);
      onChange(newValue);
    } else {
      setError("Invalid time format. Please use h:mm.");
      onError(true);
    }
  };

  const handleBlur = () => {
    if (validateTime(value)) {
      onChange(value);
    }
  };

  return (
    <TextField
      id={id}
      label={label}
      type="text"
      value={value}
      error={!!error}
      helperText={error}
      onChange={handleChange}
      onBlur={handleBlur}
      InputLabelProps={{
        shrink: true,
      }}
      {...(unit && {
        InputProps: {
          endAdornment: <InputAdornment position="end">{unit}</InputAdornment>,
        },
      })}
    />
  );
};

export default TimeBoxEditor;
