import * as React from "react";
import * as d3 from "d3-geo";
import { Feature, FeatureCollection } from "geojson";
import { rewind } from "@turf/turf";

interface Props {
  featureCollection: FeatureCollection;
}

const ProgramIcon: React.FC<Props> = (props) => {
  //const features = DUMMY_FEATURES; // TODO generate from props

  const fc: FeatureCollection = rewind(props.featureCollection as any, {
    /* 
     * https://d3js.org/d3-geo
     * D3's winding order convention ... is the opposite convention of GeoJSON’s RFC 7946.
     */
    reverse: true
  }) as any;

  const proj = d3.geoMercator();
  proj.fitExtent([[0, 0], [50, 50]], fc);
  const path = d3.geoPath(proj);

  return (
    <svg
      style={{
        width: 50,
        height: 50,
      }}
      width={50}
      height={50}>
      {fc.features.filter((x: any) => x.properties.type == "program" || x.properties.type == "base").map((x, index) =>
        <path
          key={index}
          fill={x.geometry!.type === "Polygon" ? x.properties!.color : "transparent"}
          stroke={x.geometry!.type === "LineString" ? x.properties!.color : "transparent"}
          d={path(x)!}
        />
      )}
    </svg>
  );
}

export default ProgramIcon;


const DUMMY_FEATURES: d3.ExtendedFeatureCollection = {
  type: "FeatureCollection",
  features: [{
    "type": "Feature",
    "properties": {
      type: "irrigatedArea"
    },
    "geometry": {
      "type": "Polygon",
      "coordinates": [
        [
          [
            -97.75058887422354,
            40.139877932465566
          ],
          [
            -97.75104193791128,
            40.13986091625275
          ],
          [
            -97.75149063767276,
            40.13981003151517
          ],
          [
            -97.7519306516346,
            40.13972576837593
          ],
          [
            -97.75235774162333,
            40.139608938457435
          ],
          [
            -97.75276779400447,
            40.139460667060234
          ],
          [
            -97.7531568593189,
            40.13928238231941
          ],
          [
            -97.7535211903352,
            40.139075801443276
          ],
          [
            -97.75385727814961,
            40.138842914166794
          ],
          [
            -97.7541618859863,
            40.13858596357987
          ],
          [
            -97.75443208037193,
            40.13830742451524
          ],
          [
            -97.75466525938388,
            40.13800997970456
          ],
          [
            -97.7548591777008,
            40.137696493932665
          ],
          [
            -97.75501196821375,
            40.13736998643938
          ],
          [
            -97.75512215999119,
            40.13703360183486
          ],
          [
            -97.75518869242445,
            40.13669057980895
          ],
          [
            -97.75521092541915,
            40.136344223926535
          ],
          [
            -97.7551886455346,
            40.13599786980947
          ],
          [
            -97.75512206801345,
            40.13565485301178
          ],
          [
            -97.75501183468278,
            40.13531847689743
          ],
          [
            -97.75485900774808,
            40.134991980829994
          ],
          [
            -97.75466505954067,
            40.13467850898053
          ],
          [
            -97.75443185831803,
            40.13438108005383
          ],
          [
            -97.75416165025516,
            40.134102558224335
          ],
          [
            -97.75385703780022,
            40.13384562556135
          ],
          [
            -97.75352095460407,
            40.13361275620881
          ],
          [
            -97.75315663726502,
            40.1334061925678
          ],
          [
            -97.75276759416124,
            40.13322792371097
          ],
          [
            -97.75235757167064,
            40.1330796662362
          ],
          [
            -97.75193051810359,
            40.13296284774354
          ],
          [
            -97.75149054569502,
            40.13287859309449
          ],
          [
            -97.75104189102146,
            40.132827713585115
          ],
          [
            -97.75058887422354,
            40.13281069913766
          ],
          [
            -97.75013585742562,
            40.132827713585115
          ],
          [
            -97.74968720275204,
            40.13287859309449
          ],
          [
            -97.74924723034346,
            40.13296284774354
          ],
          [
            -97.7488201767764,
            40.1330796662362
          ],
          [
            -97.74841015428582,
            40.13322792371097
          ],
          [
            -97.74802111118204,
            40.1334061925678
          ],
          [
            -97.747656793843,
            40.13361275620881
          ],
          [
            -97.74732071064685,
            40.13384562556135
          ],
          [
            -97.7470160981919,
            40.134102558224335
          ],
          [
            -97.74674589012902,
            40.13438108005383
          ],
          [
            -97.74651268890639,
            40.13467850898053
          ],
          [
            -97.74631874069897,
            40.134991980829994
          ],
          [
            -97.74616591376427,
            40.13531847689743
          ],
          [
            -97.7460556804336,
            40.13565485301178
          ],
          [
            -97.74598910291245,
            40.13599786980947
          ],
          [
            -97.7459668230279,
            40.136344223926535
          ],
          [
            -97.7459890560226,
            40.13669057980895
          ],
          [
            -97.74605558845587,
            40.13703360183486
          ],
          [
            -97.74616578023331,
            40.13736998643938
          ],
          [
            -97.74631857074628,
            40.137696493932665
          ],
          [
            -97.74651248906316,
            40.13800997970456
          ],
          [
            -97.74674566807514,
            40.13830742451524
          ],
          [
            -97.74701586246076,
            40.13858596357987
          ],
          [
            -97.74732047029744,
            40.138842914166794
          ],
          [
            -97.74765655811186,
            40.139075801443276
          ],
          [
            -97.74802088912814,
            40.13928238231941
          ],
          [
            -97.7484099544426,
            40.139460667060234
          ],
          [
            -97.74882000682372,
            40.139608938457435
          ],
          [
            -97.74924709681248,
            40.13972576837593
          ],
          [
            -97.7496871107743,
            40.13981003151517
          ],
          [
            -97.75013581053578,
            40.13986091625275
          ],
        ]
      ]
    }
  }]
};