import React, { useCallback, useMemo, useState } from "react";
import IUnitData from "../../context/BackgroundDataCtx/IUnitData";
import { Paper, useMediaQuery, useTheme } from "@mui/material";
import UpdateIcon from "@mui/icons-material/Update";
import HistoryIcon from "@mui/icons-material/History";
import * as dayjs from "dayjs";
import * as relativeTime from "dayjs/plugin/relativeTime";
import StatusDot from "../ui/StatusDot";
import duration from "dayjs/plugin/duration";

dayjs.extend(duration);
dayjs.extend(relativeTime);

const calcFutureTimeFromETA = (EtaStr: string) => {
  const now = dayjs();
  const parts = EtaStr.match(/(\d+)\s*hr\s*(\d+)?\s*mins?/i);
  if (!parts) return "N/A";
  const hours = parseInt(parts[1], 10);
  const minutes = parts[2] ? parseInt(parts[2], 10) : 0;
  const futureTime = now.add(dayjs.duration({ hours, minutes }));

  if (hours < 24) {
    return futureTime.format("h:mm A");
  } else if (hours < 24 * 5) {
    return futureTime.format("ddd h:mm A");
  } else {
    return futureTime.format("MMM D, h:mm A");
  }
};

interface Props {
  unit: IUnitData;
  isCardDisplay?: boolean;
}
const InfoBar: React.FC<Props> = ({ unit, isCardDisplay }) => {
  const theme = useTheme();
  const isScreenSmOrBigger = useMediaQuery(theme.breakpoints.up("sm"));
  const [ETADisplayMode, setETADisplayMode] = useState<"DURATION" | "ARRIVAL-TIME">("ARRIVAL-TIME");
  const lastReport = isScreenSmOrBigger ? dayjs(unit.lastCallIn).fromNow() : dayjs(unit.lastCallIn).fromNow(true);

  return (
    <Paper
      square
      elevation={isCardDisplay ? 0 : 1}
      sx={{
        width: "100%",
        display: "flex",
        justifyContent: {
          xs: "space-between",
          sm: "space-evenly",
        },
        flexWrap: "wrap",
        alignItems: "center",
        position:isCardDisplay ? "static" : "absolute",
        px: isCardDisplay ? 0 : 1 ,
        top: 0,
        zIndex: isCardDisplay ? "auto" :  1000,
        backgroundColor: isCardDisplay ? "transparent" : "rgba(0, 0, 0, 0.5)",
        color: isCardDisplay ? "black" : "white",
        
      }}
    >
      <StatusDot status={unit.connection} />
      <span >{unit.status}</span>
      <div style={{ display: "flex", alignItems: "center" }}>
        <HistoryIcon sx={{ fontSize: 20, marginRight: { sm: 1 } }} />
        <span>{lastReport}</span>
      </div>
      {unit.status !== "Stopped" && unit.status !== "Stopped Wet" && (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            cursor: "pointer",
          }}
          onClick={() =>
            setETADisplayMode((prev) => {
              if (prev === "ARRIVAL-TIME") return "DURATION";
              return "ARRIVAL-TIME";
            })
          }
        >
          <UpdateIcon sx={{ fontSize: 20, marginRight: { sm: 1 } }} />
          {ETADisplayMode === "ARRIVAL-TIME" ? calcFutureTimeFromETA(unit.ETA) : unit.ETA}
        </div>
      )}
    </Paper>
  );
};

export default InfoBar;
