import { UnitStateKey } from "../context/BackgroundDataCtx/IUnitData";

export const transformSensorValue = (key: UnitStateKey, value: string | number | boolean): number => {
    if (key === "direction" && typeof value === "string") {
        return value === "Forward" ? 0 : 1;
    } else if ((key === "parkAngle" || key === "speedpc") && typeof value === "number") {
        return value;
    } else {
        return value ? 1 : 0;
    }
};