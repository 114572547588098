import * as React from "react";
import { useContext, useEffect, useState } from "react";
import RswsApiCtx from "../RswsApiCtx/RswsApiCtx";
import { ILoadUnitResp, ISaveUnitReq, IUnitSettings } from "../RswsApiCtx/IRswsApi";
import CircularProgress from "@mui/material/CircularProgress";
import ExtendedUnitDataCtx from "./ExtendedUnitDataCtx";
import { getUnitCapabilities } from "../../helpers/unitCapabilites";

interface Props {
  unitId: string;
}

const ExtendedUnitDataProvider: React.FC<React.PropsWithChildren<Props>> = (
  props
) => {
  const api = useContext(RswsApiCtx);

  const [loadingInitialData, setLoadingInitialData] = useState(true);
  const [errorLoadingInitialData, setErrorLoadingInitialData] = useState<
    string | undefined
  >(undefined);
  const [unitData, setUnitData] = useState<ILoadUnitResp | undefined>(
    undefined
  );

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const unitData = await api.loadUnit({
        unitName: props.unitId,
      });
      setUnitData(unitData);
    } catch (ex) {
      // log with stack trace
      console.log(ex);
      setErrorLoadingInitialData(`${ex}`);
    } finally {
      setLoadingInitialData(false);
    }
  };

  if (errorLoadingInitialData) {
    return <>Error loading unit data: {errorLoadingInitialData}</>;
  }

  if (loadingInitialData) {
    return (
      <CircularProgress
        size={24}
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          marginTop: "-12px",
          marginLeft: "-12px",
        }}
      />
    );
  }

  const updateUnitSettings = async (newSettings: IUnitSettings) => {
    try {
      const res = await api.saveUnitSettings({
        unitName: props.unitId,
        unitSettings: newSettings,
      });
      return res;
    } catch (ex) {
      console.error("Error saving unit settings", ex);
      throw ex;
    }
  };

  const saveUnit = async (req: ISaveUnitReq) => {
    try {
      const res = await api.saveUnit(req);
      return res;
    } catch (ex) {
      console.error("Error saving unit", ex);
      throw ex;
    }
  };

  return (
    <ExtendedUnitDataCtx.Provider
      value={{
        unitSettings: unitData!.unitSettings,
        capabilities: getUnitCapabilities(unitData!),
        updateUnitSettings: updateUnitSettings,
        saveUnit: saveUnit,
        fetchData: fetchData,
      }}
    >
      {props.children}
    </ExtendedUnitDataCtx.Provider>
  );
};

export default ExtendedUnitDataProvider;
