import React, {
  useEffect,
  FC,
  useContext,
  useState,
  useMemo,
  useRef,
} from "react";
import { useParams, useBlocker } from "react-router-dom";
import ExtendedUnitDataCtx from "../../context/ExtendedUnitDataCtx/ExtendedUnitDataCtx";
import NumberBoxEditor from "../ui/NumberBoxEditor";
import SelectEditor from "./SelectEditor";
import TimeBoxEditor from "./TimeBoxEditor";
import {
  Box,
  Button,
  CircularProgress,
  Stack,
  CardHeader,
  CardContent,
  Card,
  Dialog,
  DialogActions,
  DialogTitle,
} from "@mui/material";
import SwitchEditor from "./SwitchEditor";
import BackgroundDataCtx from "../../context/BackgroundDataCtx/BackgroundDataCtx";
import { IUnitSettings } from "../../context/RswsApiCtx/IRswsApi";
import IBackgroundData from "../../context/BackgroundDataCtx/IBackgroundData";
import Map, { MapDrawingMode } from "../Map";
import { MapInteractionCallback } from "../../context/MapInteractionCtx/IMapWithInteraction";
import MapInteractionCtx from "../../context/MapInteractionCtx/MapInteractionCtx";
import InteractiveInput from "../ui/InteractiveInput";
import {
  getSecondaryCoordinates,
  getDistanceFromLateralBaseLine,
  getDistanceBetweenPoints,
} from "../Map/MapHelper";
import isEqual from "lodash.isequal";

const SettingsEditor: FC = () => {
  const { unitId } = useParams();
  if (!unitId) return <>Unit not recognised</>;

  const bgdata = useContext(BackgroundDataCtx);
  const unit = bgdata.units[unitId];
  const extUD = useContext(ExtendedUnitDataCtx);
  const [settings, setSettings] = useState<IUnitSettings>({
    ...extUD.unitSettings,
    baselatitude: unit.latitude,
    baselongitude: unit.longitude,
  });
  const [latitude2, setLatitude2] = useState<number | undefined>(
    unit.latitude2
  );
  const [longitude2, setLongitude2] = useState<number | undefined>(
    unit.longitude2
  );
  const [systemLength, setSystemLength] = useState<number | undefined>(
    unit.lengthFeet
  );
  const [loadingButton, setLoadingButton] = useState(false);
  const [validationErrors, setValidationErrors] = useState({});

  const getEditingBgData = (
    baselatitude?: number,
    baselongitude?: number,
    length?: number,
    lat2?: number,
    lon2?: number
  ): IBackgroundData => {
    var ed = { ...bgdata };
    if (baselatitude) {
      ed.units[unitId!].latitude = baselatitude;
    }

    if (baselongitude) {
      ed.units[unitId!].longitude = baselongitude;
    }

    if (length) {
      ed.units[unitId!].lengthFeet = length;
    }

    if (lat2) {
      ed.units[unitId!].latitude2 = lat2;
    }

    if (lon2) {
      ed.units[unitId!].longitude2 = lon2;
    }

    return ed;
  };

  const [editingBgData, setEditingBgData] = useState<IBackgroundData>(
    getEditingBgData(
      settings.baselatitude,
      settings.baselongitude,
      systemLength,
      latitude2,
      longitude2
    )
  );
  const [mapInEditMode, setMapEditModeStatus] = useState<MapDrawingMode>(
    MapDrawingMode.Disabled
  );
  const mapEditCallback = useRef<MapInteractionCallback>();

  const resetUnitOnMap = () => {
    var ed = { ...bgdata };
    if (unit) {
      ed.units[unitId!].latitude = unit.latitude;
      ed.units[unitId!].longitude = unit.longitude;
      ed.units[unitId!].lengthFeet = unit.lengthFeet;
      ed.units[unitId!].latitude2 = unit.latitude2;
      ed.units[unitId!].longitude2 = unit.longitude2;

      setEditingBgData(ed);
    }
  };

  useEffect(() => {
    setSettings({
      ...extUD.unitSettings,
      baselatitude: unit.latitude,
      baselongitude: unit.longitude,
    });
  }, [extUD.unitSettings]);

  const hasErrors = useMemo(
    () => Object.values(validationErrors).some((hasError) => hasError),
    [validationErrors]
  );

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (hasErrors) return;
    setLoadingButton(true);
    if (
      settings.baselatitude !== unit.latitude ||
      settings.baselongitude !== unit.longitude ||
      systemLength !== unit.lengthFeet ||
      unit.latitude !== latitude2 ||
      unit.longitude !== longitude2
    ) {
      //need to save unit if coordinates or length (pivot only) have changed
      await extUD.saveUnit({
        unit: {
          actCode: 0,
          location: unit.name,
          locationAltitude: systemLength!.toString(),
          locationLatitude: settings.baselatitude!,
          locationLongitude: settings.baselongitude!,
          locationLatitude2: latitude2,
          locationLongitude2: longitude2,
          settings: settings,
          name: unit.id,
          settingsOEM: unit.settingsOEM,
        },
      });
    } else {
      //save settings only
      await extUD.updateUnitSettings(settings);
    }
    extUD.fetchData();
    setLoadingButton(false);
  };

  // Function to handle validation errors reported by children
  const handleValidationError = (id: string, hasError: boolean) => {
    setValidationErrors((prevErrors) => ({ ...prevErrors, [id]: hasError }));
  };

  const beginDrawPoint = (callback: MapInteractionCallback) => {
    setMapEditModeStatus(MapDrawingMode.DrawExactLocation);
    mapEditCallback.current = callback;
  };

  const anyUnsavedChanges = (): boolean => {
    const initialSettings = {
      ...extUD.unitSettings,
      baselatitude: unit.latitude,
      baselongitude: unit.longitude,
    };
    const initialLat2 = unit.latitude2;
    const initialLong2 = unit.latitude2;
    const initialSystemLength = unit.lengthFeet;

    return (
      !isEqual(initialSettings, settings) ||
      initialLat2 !== latitude2 ||
      initialLong2 !== longitude2 ||
      initialSystemLength !== systemLength
    );
  };

  let blocker = useBlocker(
    ({ currentLocation, nextLocation }) =>
      anyUnsavedChanges() && currentLocation.pathname !== nextLocation.pathname
  );

  return (
    <BackgroundDataCtx.Provider value={editingBgData}>
      {blocker.state === "blocked" && (
        <Dialog
          open={true}
          onClose={() => blocker.reset()}
          aria-labelledby="alert-dialog-title"
        >
          <DialogTitle id="copy-program-modal">
            Are you sure you want to leave
          </DialogTitle>
          <DialogActions>
            <Button
              onClick={() => {
                blocker.proceed();
                resetUnitOnMap();
              }}
            >
              Yes
            </Button>
            <Button onClick={() => blocker.reset()}>No</Button>
          </DialogActions>
        </Dialog>
      )}
      <Stack
        direction={{ sm: "column", md: "row" }}
        alignItems="stretch"
        sx={{
          display: "flex",
          height: "100%",
          width: "100%",
        }}
      >
        <Box sx={{ flexGrow: 1 }}>
          <Map
            unitId={unit.id}
            interactiveMode={mapInEditMode}
            onExitInteractiveMode={(calcPos, realPos, angle) => {
              setMapEditModeStatus(MapDrawingMode.Disabled);
              mapEditCallback.current!(calcPos, realPos, angle);
            }}
          />
        </Box>
        <MapInteractionCtx.Provider
          value={{
            beginDrawPoint,
          }}
        >
          <Stack
            direction="column"
            sx={{
              width: { sm: "100%", md: 400 },
              height: {
                sm: "50%",
                xs: "50%",
                md: "100%",
              },
            }}
          >
            <Box
              sx={{
                height: "100%",
                width: "100%",
                p: 2,
                display: "flex",
                flexDirection: "column",
                gap: 1,
                overflow: "auto",
                paddingBottom: "100px",
              }}
              noValidate
              component="form"
              autoComplete="off"
              onSubmit={(e) => handleSubmit(e)}
            >
              {extUD.capabilities.settings?.system && (
                //System settings
                <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
                  <Card>
                    <CardHeader title="System settings" />
                    <CardContent
                      sx={{ display: "flex", flexDirection: "column", gap: 2 }}
                    >
                      <SelectEditor
                        id="positiontype"
                        label="Position Type"
                        value={settings.positiontype!}
                        onChange={(newValue) =>
                          setSettings({ ...settings, positiontype: newValue })
                        }
                        options={[
                          { label: "GPS", value: 0 },
                          { label: "Resolver", value: 1 },
                          { label: "Dead Reck", value: 2 },
                          { label: "None", value: 3 },
                        ]}
                      />
                      <NumberBoxEditor
                        id="positionoffset"
                        label="Offset"
                        unit="deg"
                        value={settings.positionoffset!}
                        min={0}
                        max={999.9}
                        onBlur={(newValue) =>
                          setSettings({ ...settings, positionoffset: newValue })
                        }
                        onError={(hasError) =>
                          handleValidationError("positionoffset", hasError)
                        }
                        disabled={settings.positiontype !== 2}
                      />
                      <NumberBoxEditor
                        id="minimumdepth"
                        label="Minimum Depth"
                        unit="in"
                        value={settings.minimumdepth!}
                        min={0.01}
                        max={9.99}
                        onBlur={(newValue) =>
                          setSettings({ ...settings, minimumdepth: newValue })
                        }
                        onError={(hasError) =>
                          handleValidationError("minimumdepth", hasError)
                        }
                      />
                      <TimeBoxEditor
                        id="hoursperrev"
                        label="Hours per revolution"
                        unit="h:mm"
                        value={settings.hoursperrev!}
                        onChange={(newValue) =>
                          setSettings({ ...settings, hoursperrev: newValue })
                        }
                        onError={(hasError) =>
                          handleValidationError("hoursperrev", hasError)
                        }
                      />
                      <NumberBoxEditor
                        id="startpulse"
                        label="Start Pulse"
                        value={settings.startpulse!}
                        unit="sec"
                        min={0}
                        max={9}
                        onBlur={(newValue) =>
                          setSettings({ ...settings, startpulse: newValue })
                        }
                        onError={(hasError) =>
                          handleValidationError("startpulse", hasError)
                        }
                      />
                      {/* Unit of Measurement is commented out in their code.. Else maybe a select editor for this one */}
                      {/* <SelectEditor
            id="unitofmeasurement"
            label="Unit of Measurement"
            value={settings.unitofmeasurement!}
            onChange={(newValue) =>
              setSettings({ ...settings, unitofmeasurement: newValue })
            }
            options={[
              { label: 'Metric', value: 1},
              { label: 'Imperial', value: 2},
              { label: 'Canadian', value: 3}
            ]}
          /> */}
                      <SelectEditor
                        id="cycletime"
                        label="Cycle Time"
                        value={settings.cycletime!}
                        onChange={(newValue) =>
                          setSettings({ ...settings, cycletime: newValue })
                        }
                        options={[
                          { label: "60 sec", value: 60 },
                          { label: "30 sec", value: 30 },
                        ]}
                      />
                      <NumberBoxEditor
                        id="maxspeedpivot"
                        label="Max Speed Pivot"
                        value={settings.maxspeedpivot!}
                        unit="%"
                        min={0}
                        max={100}
                        onBlur={(newValue) =>
                          setSettings({ ...settings, maxspeedpivot: newValue })
                        }
                        onError={(hasError) =>
                          handleValidationError("maxspeedpivot", hasError)
                        }
                      />
                      <InteractiveInput
                        label="Base Latitude"
                        maxValue={90}
                        minValue={-90}
                        value={settings.baselatitude}
                        onError={(hasError) =>
                          handleValidationError("latitude", hasError)
                        }
                        displayUnit="deg"
                        onChange={(newValue, pos) => {
                          if (pos) {
                            setSettings({
                              ...settings,
                              baselatitude: parseFloat(pos![1].toFixed(6)),
                              baselongitude: parseFloat(pos![0].toFixed(6)),
                            });
                            setEditingBgData(
                              getEditingBgData(
                                parseFloat(pos![1].toFixed(6)),
                                parseFloat(pos![0].toFixed(6)),
                                systemLength
                              )
                            );
                          } else {
                            setSettings({
                              ...settings,
                              baselatitude: newValue,
                            });
                            setEditingBgData(
                              getEditingBgData(
                                newValue,
                                settings.baselongitude,
                                systemLength
                              )
                            );
                          }
                        }}
                        id={""}
                        disableSensorChoice={true}
                        unit={bgdata.units[unitId!]}
                      />
                      <InteractiveInput
                        label="Base Longitude"
                        maxValue={180}
                        minValue={-180}
                        onError={(hasError) =>
                          handleValidationError("longitude", hasError)
                        }
                        value={settings.baselongitude}
                        displayUnit="deg"
                        onChange={(newValue, pos) => {
                          if (pos) {
                            setSettings({
                              ...settings,
                              baselatitude: parseFloat(pos![1].toFixed(6)),
                              baselongitude: parseFloat(pos![0].toFixed(6)),
                            });
                            setEditingBgData(
                              getEditingBgData(
                                parseFloat(pos![1].toFixed(6)),
                                parseFloat(pos![0].toFixed(6)),
                                systemLength
                              )
                            );
                          } else {
                            setSettings({
                              ...settings,
                              baselongitude: newValue,
                            });
                            setEditingBgData(
                              getEditingBgData(
                                settings.baselatitude,
                                newValue,
                                systemLength
                              )
                            );
                          }
                        }}
                        id={""}
                        disableSensorChoice={true}
                        unit={bgdata.units[unitId!]}
                      />
                      {unit.systemType === "Lateral" && (
                        <InteractiveInput
                          label="Latitude 2"
                          maxValue={90}
                          minValue={-90}
                          value={latitude2}
                          onError={(hasError) =>
                            handleValidationError("latitude2", hasError)
                          }
                          displayUnit="deg"
                          onChange={(newValue, pos) => {
                            if (pos) {
                              setLatitude2(parseFloat(pos![1].toFixed(6)));
                              setLongitude2(parseFloat(pos![0].toFixed(6)));
                              setEditingBgData(
                                getEditingBgData(
                                  settings.baselatitude,
                                  settings.baselongitude,
                                  systemLength,
                                  parseFloat(pos![1].toFixed(6)),
                                  parseFloat(pos![0].toFixed(6))
                                )
                              );
                            } else {
                              setLatitude2(newValue);
                              setEditingBgData(
                                getEditingBgData(
                                  settings.baselatitude,
                                  settings.baselongitude,
                                  systemLength,
                                  newValue,
                                  longitude2
                                )
                              );
                            }
                          }}
                          id={""}
                          disableSensorChoice={true}
                          unit={bgdata.units[unitId!]}
                        />
                      )}
                      {unit.systemType === "Lateral" && (
                        <InteractiveInput
                          label="Longitude 2"
                          maxValue={180}
                          minValue={-180}
                          onError={(hasError) =>
                            handleValidationError("longitude2", hasError)
                          }
                          value={longitude2}
                          displayUnit="deg"
                          onChange={(newValue, pos) => {
                            if (pos) {
                              setLatitude2(parseFloat(pos![1].toFixed(6)));
                              setLongitude2(parseFloat(pos![0].toFixed(6)));
                              setEditingBgData(
                                getEditingBgData(
                                  settings.baselatitude,
                                  settings.baselongitude,
                                  systemLength,
                                  parseFloat(pos![1].toFixed(6)),
                                  parseFloat(pos![0].toFixed(6))
                                )
                              );
                            } else {
                              setLongitude2(newValue);
                              setEditingBgData(
                                getEditingBgData(
                                  settings.baselatitude,
                                  settings.baselongitude,
                                  systemLength,
                                  latitude2,
                                  newValue
                                )
                              );
                            }
                          }}
                          id={""}
                          disableSensorChoice={true}
                          unit={bgdata.units[unitId!]}
                        />
                      )}

                      <InteractiveInput
                        label="System Length"
                        maxValue={Infinity}
                        value={systemLength}
                        displayUnit="ft"
                        onError={(hasError) => {
                          handleValidationError("systemLength", hasError);
                        }}
                        onChange={(newValue, pos) => {
                          if (bgdata.units[unitId!].systemType === "Lateral") {
                            var secondaryCoordinate = getSecondaryCoordinates(
                              bgdata.units[unitId!]
                            );
                            const newLength = pos
                              ? parseFloat(
                                  getDistanceFromLateralBaseLine(
                                    pos,
                                    [
                                      settings.baselongitude!,
                                      settings.baselatitude!,
                                    ],
                                    secondaryCoordinate
                                  ).toFixed(0)
                                )
                              : newValue;
                            setSystemLength(newLength);
                            setSettings({
                              ...settings,
                              latfieldlength: newLength,
                            });
                            setEditingBgData(
                              getEditingBgData(
                                settings.baselatitude,
                                settings.baselongitude,
                                newLength
                              )
                            );
                          } else {
                            const l = pos
                              ? parseFloat(
                                  getDistanceBetweenPoints(pos, [
                                    settings.baselongitude!,
                                    settings.baselatitude!,
                                  ]).toFixed(0)
                                )
                              : newValue;
                            setSystemLength(l);
                            setEditingBgData(
                              getEditingBgData(
                                settings.baselatitude,
                                settings.baselongitude,
                                l
                              )
                            );
                          }
                        }}
                        id={""}
                        disableSensorChoice={true}
                        unit={bgdata.units[unitId!]}
                      />
                    </CardContent>
                  </Card>
                </Box>
              )}

              {/*       
      TODO:  Pretty much never used. Look closer later.  
      {dud.capabilities.startBehavior && (
        // Start behavior settings
        <Box>
          <Typography variant="h6">Start behavior settings</Typography>
          <NumberBoxEditor
            value={settings.flowhigh}
            onChange={(newValue) =>
              setSettings({ ...settings, flowhigh: newValue })
            }
            label="Label 1"
          />
        </Box>
      )} */}

              {extUD.capabilities.settings?.pump && (
                //Pump settings
                <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
                  <Card>
                    <CardHeader title="Pump settings" />
                    <CardContent
                      sx={{ display: "flex", flexDirection: "column", gap: 2 }}
                    >
                      {/* <PumpTypeAndPressureSettings
            settings={settings}
            setSettings={setSettings}
            onError={(hasError) =>
              handleValidationError("pressuresensortype", hasError)
            }
          /> */}

                      <SelectEditor
                        id="pressuresensortype"
                        label="Pressure Sensor Type"
                        value={settings.pressuresensortype!}
                        onChange={(newValue) =>
                          setSettings({
                            ...settings,
                            pressuresensortype: newValue,
                          })
                        }
                        options={[
                          { label: "None", value: 0 },
                          { label: "Xducer 0 - 200", value: 1 },
                          { label: "Xducer 0 - 25", value: 2 },
                          { label: "Switch", value: 4 },
                        ]}
                      />

                      <NumberBoxEditor
                        id="pressurelow"
                        label="Pressure Low"
                        value={settings.pressurelow!}
                        unit="psi"
                        min={0}
                        max={settings.pressuresensortype === 2 ? 25 : 200}
                        onBlur={(newValue) =>
                          setSettings({ ...settings, pressurelow: newValue })
                        }
                        disabled={
                          settings.pressuresensortype === 0 ||
                          settings.pressuresensortype === 4
                        }
                        onError={(hasError) =>
                          handleValidationError("pressurelow", hasError)
                        }
                      />

                      <NumberBoxEditor
                        id="pressurehigh"
                        label="Pressure High"
                        value={settings.pressurehigh!}
                        unit="psi"
                        min={0}
                        max={settings.pressuresensortype === 2 ? 25 : 200}
                        onBlur={(newValue) =>
                          setSettings({ ...settings, pressurehigh: newValue })
                        }
                        disabled={
                          settings.pressuresensortype === 0 ||
                          settings.pressuresensortype === 4
                        }
                        onError={(hasError) =>
                          handleValidationError("pressurehigh", hasError)
                        }
                      />

                      <NumberBoxEditor
                        id="pumpbuilddelay"
                        label="Pump Build Delay"
                        value={settings.pumpbuilddelay!}
                        unit="sec"
                        min={0}
                        max={9999}
                        onBlur={(newValue) =>
                          setSettings({ ...settings, pumpbuilddelay: newValue })
                        }
                        onError={(hasError) =>
                          handleValidationError("pumpbuilddelay", hasError)
                        }
                      />
                      <NumberBoxEditor
                        id="pumprestartdelay"
                        label="Pump Restart Delay"
                        value={settings.pumprestartdelay!}
                        unit="sec"
                        min={0}
                        max={9999}
                        onBlur={(newValue) =>
                          setSettings({
                            ...settings,
                            pumprestartdelay: newValue,
                          })
                        }
                        onError={(hasError) =>
                          handleValidationError("pumprestartdelay", hasError)
                        }
                      />
                      <NumberBoxEditor
                        id="pumpshutdowndelay"
                        label="Pump Shutdown Delay"
                        value={settings.pumpshutdowndelay!}
                        unit="sec"
                        min={0}
                        max={9999}
                        onBlur={(newValue) =>
                          setSettings({
                            ...settings,
                            pumpshutdowndelay: newValue,
                          })
                        }
                        onError={(hasError) =>
                          handleValidationError("pumpshutdowndelay", hasError)
                        }
                      />
                      <NumberBoxEditor
                        id="pumpattempts"
                        label="Pump Attempts"
                        value={settings.pumpattempts!}
                        min={0}
                        max={99}
                        onBlur={(newValue) =>
                          setSettings({ ...settings, pumpattempts: newValue })
                        }
                        onError={(hasError) =>
                          handleValidationError("pumpattempts", hasError)
                        }
                      />
                    </CardContent>
                  </Card>
                </Box>
              )}

              {extUD.capabilities.settings?.restart && (
                //Restart settings
                <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
                  <Card>
                    <CardHeader title="Restart settings" />
                    <CardContent
                      sx={{ display: "flex", flexDirection: "column", gap: 2 }}
                    >
                      <SelectEditor
                        id="powerrestartenable"
                        label="Power Restart Enable"
                        value={settings.powerrestartenable!}
                        onChange={(newValue) =>
                          setSettings({
                            ...settings,
                            powerrestartenable: newValue,
                          })
                        }
                        options={
                          // RC10d units have a start sequence option
                          extUD.capabilities.settings.hasStartSequence
                            ? [
                                { label: "Disable", value: 0 },
                                { label: "Last Known Status", value: 1 },
                                { label: "Start Sequence", value: 2 },
                              ]
                            : [
                                { label: "Disable", value: 0 },
                                { label: "Last Known Status", value: 1 },
                              ]
                        }
                      />
                      <NumberBoxEditor
                        id="powerrestarttime"
                        label="Restart Delay"
                        value={settings.powerrestarttime!}
                        unit="sec"
                        min={0}
                        max={9999}
                        onBlur={(newValue) =>
                          setSettings({
                            ...settings,
                            powerrestarttime: newValue,
                          })
                        }
                        onError={(hasError) =>
                          handleValidationError("powerrestarttime", hasError)
                        }
                        disabled={settings.powerrestartenable === 0}
                      />
                      {extUD.capabilities.settings?.restartPressure && (
                        <>
                          <SwitchEditor
                            id="pressurerestartstate"
                            label="Pressure Restart"
                            value={settings.pressurerestartstate!}
                            onChange={(newValue) =>
                              setSettings({
                                ...settings,
                                pressurerestartstate: newValue,
                              })
                            }
                            disabled={settings.pressuresensortype === 0}
                          />
                          <NumberBoxEditor
                            id="restartpressure"
                            label="Restart Pressure"
                            value={settings.restartpressure!}
                            unit="psi"
                            min={0}
                            max={999}
                            onBlur={(newValue) =>
                              setSettings({
                                ...settings,
                                restartpressure: newValue,
                              })
                            }
                            disabled={settings.pressurerestartstate === 0}
                            onError={(hasError) =>
                              handleValidationError("restartpressure", hasError)
                            }
                          />
                        </>
                      )}
                    </CardContent>
                  </Card>
                </Box>
              )}

              {extUD.capabilities.settings?.auxiliary && (
                //auxiliary settings
                <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
                  <Card>
                    <CardHeader title="Auxiliary settings" />
                    <CardContent
                      sx={{ display: "flex", flexDirection: "column", gap: 2 }}
                    >
                      <SelectEditor
                        id="auxiliarytype"
                        label="Auxiliary Type"
                        value={settings.auxiliarytype!}
                        onChange={(newValue) =>
                          setSettings({ ...settings, auxiliarytype: newValue })
                        }
                        options={[
                          { label: "End  Gun 2", value: 0 },
                          { label: "Chem", value: 1 },
                          { label: "Prestart", value: 2 },
                        ]}
                      />
                      {/* // AUX type description */}
                      {settings.auxiliarytype === 1 && (
                        <p>
                          Places safety conditions on auxiliary output
                          activation
                        </p>
                      )}
                      {settings.auxiliarytype === 2 && (
                        <p>
                          Activates auxiliary output for the specified time
                          before starting the system
                        </p>
                      )}
                      {settings.auxiliarytype === 0 && (
                        <p>
                          Allows independent control of the auxiliary output
                        </p>
                      )}
                      {(settings.auxiliarytype === 1 ||
                        settings.auxiliarytype === null) && (
                        <NumberBoxEditor
                          id="chemflush"
                          label="Chem Flush"
                          value={settings.chemflush!}
                          unit="sec"
                          min={0}
                          max={9999}
                          onBlur={(newValue) =>
                            setSettings({ ...settings, chemflush: newValue })
                          }
                          onError={(hasError) =>
                            handleValidationError("chemflush", hasError)
                          }
                        />
                      )}
                      {settings.auxiliarytype === 2 && (
                        <NumberBoxEditor
                          id="prestarttime"
                          label="Prestart Time"
                          value={settings.prestarttime!}
                          unit="sec"
                          min={0}
                          max={9999}
                          onBlur={(newValue) =>
                            setSettings({ ...settings, prestarttime: newValue })
                          }
                          onError={(hasError) =>
                            handleValidationError("prestarttime", hasError)
                          }
                        />
                      )}
                    </CardContent>
                  </Card>
                </Box>
              )}

              {extUD.capabilities.settings?.temperature && (
                //temperature settings
                <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
                  <Card>
                    <CardHeader title="Temperature settings" />
                    <CardContent
                      sx={{ display: "flex", flexDirection: "column", gap: 2 }}
                    >
                      <SwitchEditor
                        id="temperaturesensorstate"
                        label="Temperature Sensor: "
                        value={settings.temperaturesensorstate!}
                        onChange={(newValue) =>
                          setSettings((prevSettings) => ({
                            ...prevSettings,
                            temperaturesensorstate: newValue,
                            // Also reset the shutdown config if tmpsensor is disabled
                            temperatureshutdownconfig:
                              newValue === 0
                                ? 0
                                : prevSettings.temperatureshutdownconfig,
                          }))
                        }
                      />
                      <SwitchEditor
                        id="temperatureshutdownconfig"
                        label="Temperature Shutdown: "
                        value={settings.temperatureshutdownconfig!}
                        onChange={(newValue) =>
                          setSettings({
                            ...settings,
                            temperatureshutdownconfig: newValue,
                          })
                        }
                        disabled={settings.temperaturesensorstate === 0}
                      />
                      {(settings.temperatureshutdownconfig === 1 ||
                        settings.temperatureshutdownconfig === 2) && (
                        <>
                          <NumberBoxEditor
                            id="shutdowntemperature"
                            label="Shutdown Temperature"
                            value={settings.shutdowntemperature!}
                            unit="°F"
                            min={0}
                            max={99}
                            onBlur={(newValue) =>
                              setSettings({
                                ...settings,
                                shutdowntemperature: newValue,
                              })
                            }
                            onError={(hasError) =>
                              handleValidationError(
                                "shutdowntemperature",
                                hasError
                              )
                            }
                          />
                          <SwitchEditor
                            id="restarttemperature"
                            label="Temperature Restart"
                            value={settings.temperatureshutdownconfig!}
                            onValue={2}
                            offValue={1}
                            onChange={(newValue) =>
                              setSettings({
                                ...settings,
                                temperatureshutdownconfig: newValue,
                              })
                            }
                          />
                          {settings.temperatureshutdownconfig === 2 && (
                            <NumberBoxEditor
                              id="restarttemperature"
                              label="Restart Temperature"
                              value={settings.restarttemperature!}
                              unit="°F"
                              min={settings.shutdowntemperature! + 1}
                              max={99}
                              onBlur={(newValue) =>
                                setSettings({
                                  ...settings,
                                  restarttemperature: newValue,
                                })
                              }
                              onError={(hasError) =>
                                handleValidationError(
                                  "restarttemperature",
                                  hasError
                                )
                              }
                            />
                          )}
                        </>
                      )}
                    </CardContent>
                  </Card>
                </Box>
              )}
              {extUD.capabilities.settings?.flow && (
                //flow settings

                <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
                  <Card>
                    <CardHeader title="Flow settings" />
                    <CardContent
                      sx={{ display: "flex", flexDirection: "column", gap: 2 }}
                    >
                      <NumberBoxEditor
                        id="flowperpulse"
                        label="Flow per Pulse"
                        value={settings.flowperpulse!}
                        unit="gal/pulse"
                        min={0}
                        max={9.999}
                        onBlur={(newValue) =>
                          setSettings({ ...settings, flowperpulse: newValue })
                        }
                        onError={(hasError) =>
                          handleValidationError("flowperpulse", hasError)
                        }
                      />
                      <NumberBoxEditor
                        id="flowlow"
                        label="Low Flow Report "
                        value={settings.flowlow!}
                        unit="gpm"
                        min={0}
                        max={settings.flowhigh! - 1}
                        onBlur={(newValue) =>
                          setSettings({ ...settings, flowlow: newValue })
                        }
                        onError={(hasError) =>
                          handleValidationError("flowlow", hasError)
                        }
                      />
                      <NumberBoxEditor
                        id="flowhigh"
                        label="High Flow Report"
                        value={settings.flowhigh!}
                        unit="gpm"
                        min={settings.flowlow! + 1}
                        max={9999}
                        onBlur={(newValue) =>
                          setSettings({ ...settings, flowhigh: newValue })
                        }
                        onError={(hasError) =>
                          handleValidationError("flowhigh", hasError)
                        }
                      />
                    </CardContent>
                  </Card>
                </Box>
              )}
              {
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  sx={{
                    position: "absolute",
                    bottom: 70,
                    right: { sm: 20, xs: 20, md: 33 },
                    left: { sm: 20, xs: 20, md: "unset" },
                    margin: "auto",
                    width: { sm: "90%", xs: "90%", md: 350 },
                    zIndex: 3,
                    opacity: 1,
                    "&.Mui-disabled": {
                      background: "#eaeaea",
                      color: "black",
                    },
                  }}
                  disabled={loadingButton || hasErrors}
                  endIcon={loadingButton && <CircularProgress size={20} />}
                >
                  {hasErrors
                    ? "Please fix the errors before saving the settings."
                    : "Save"}
                </Button>
              }
            </Box>
          </Stack>
        </MapInteractionCtx.Provider>
      </Stack>
    </BackgroundDataCtx.Provider>
  );
};

export default SettingsEditor;
