import * as React from "react";
import { FC, PropsWithChildren, useContext, useState } from "react";
import RswsApiCtx from "../context/RswsApiCtx/RswsApiCtx";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import { Container, Paper, Stack } from "@mui/material";
import Typography from "@mui/material/Typography";
import ReinCloudLogo from "./Icons/ReinCloudLogo";

const backgroundImage = new URL(
  '../assets/login_background.jpg?as=webp&width=2000',
  import.meta.url
);

const LoginWrapper: FC<PropsWithChildren> = ({ children }) => {
  const api = useContext(RswsApiCtx);

  if (api.hasAuthToken()) {
    // Already logged in
    return children;
  }

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [fetchInProgress, setFetchInProgress] = useState(false);

  const login = async () => {
    setFetchInProgress(true);
    try {
      const resp = await api.login({ username, password });

      // The subsequent update causes this login wrapper to disappear
      api.setAuthToken(resp.authToken);
    } catch (ex) {
      alert(ex);
    } finally {
      setFetchInProgress(false);
    }
  };

  return (
    <div style={{
      height: "100vh",
      backgroundImage: "url(" + backgroundImage + ")",
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      paddingTop: 1
    }}>
    <Container component="main" maxWidth="xs">
      <Paper sx={{p: 2, marginTop: "max(0px, calc(50vh - 250px))", backgroundColor: "rgba(255, 255, 255, 0.95)"}}>
        <Stack direction="column" spacing={2}>
        <ReinCloudLogo style={{
          marginLeft: "auto",
          marginRight: "auto",
          width: 277,
          height: 80
        }} />
        <Typography component="h1" variant="h5" align="center">
          Welcome, please sign in.
        </Typography>
        <TextField
          label="Username"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          disabled={fetchInProgress}
          autoComplete="username"
        />
        <TextField
          label="Password"
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          disabled={fetchInProgress}
          autoComplete="current-password"
        />
        <Button variant="contained" onClick={login} disabled={fetchInProgress}>
          {fetchInProgress ? "Logging in..." : "Login"}
        </Button>
        {fetchInProgress && (
          <CircularProgress
            size={24}
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              marginTop: "-12px",
              marginLeft: "-12px",
            }}
          />
        )}
        </Stack>
      </Paper>
    </Container>
    </div>
  );
};

export default LoginWrapper;
