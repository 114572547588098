import * as React from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { CountryType, countries } from "./countries";

interface Props {
  countryCode: string;
  onChange: (newCountryCode: string) => void;
}
const CountrySelect: React.FC<Props> = ({
  countryCode = "US",
  onChange,
}: Props) => {
  const [inputValue, setInputValue] = React.useState("");



  return (
    <Autocomplete
      id="country-select"
      value={countries.find((country) => country.code === countryCode) || null}
      onChange={(event: any, newValue: CountryType | null) => {
        onChange(newValue?.code || "");
      }}
      sx={{ width: "100%" }}
      options={countries}
      inputValue={inputValue}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      autoHighlight
      getOptionLabel={(option) => option.label}
      renderOption={(props, option) => (
        <Box
          component="li"
          sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
          {...props}
        >
          <img
            loading="lazy"
            width="20"
            srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
            src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
            alt=""
          />
          {option.label} ({option.code}) 
          {/* +{option.phone} */}
        </Box>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Choose a country"
          inputProps={{
            ...params.inputProps,
            autoComplete: "new-password", // disable autocomplete and autofill
          }}
        />
      )}
    />
  );
};

export default CountrySelect;
