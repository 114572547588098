import {
  Typography,
  List,
  ListItem,
  IconButton,
  ListItemText,
} from "@mui/material";
import React from "react";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import IUnitData from "../../context/BackgroundDataCtx/IUnitData";

interface Props {
  units: IUnitData[];
  deleteUnit(unitId: string): void;
}
const UnitsList: React.FC<Props> = ({ units, deleteUnit }: Props) => {
  return (
    <div>
      {units.length > 0 ? (
        <List dense disablePadding>
          {units.map((unit) => (
            <ListItem
              divider
              key={unit.id}
              secondaryAction={
                <IconButton onClick={() => deleteUnit(unit.id)} edge="end">
                  <DeleteForeverIcon />
                </IconButton>
              }
            >
              <ListItemText>
                {unit.name} - {unit.id}
              </ListItemText>
            </ListItem>
          ))}
        </List>
      ) : (
        <Typography>No units to show</Typography>
      )}
    </div>
  );
};

export default UnitsList;
