import { IconButton, ListItemText, Box, Popover, ListItem, List } from "@mui/material";
import PaletteIcon from "@mui/icons-material/Palette";

import React from "react";

interface Props {
  position: "absolute" | "fixed" | "relative" | "static";
  top?: number;
  bottom?: number;
  right?: number;
  left?: number;
}

interface IStatus {
  color: string;
  description: string;
}

const legend: IStatus[] = [
  {
    color: "#42AA59",
    description: "Info Badge",
  },
  {
    color: "#FDDA16",
    description: "Notice Badge",
  },
  {
    color: "#EAAB00",
    description: "Warning Badge",
  },
  {
    color: "#DC2F2F",
    description: "Error Badge",
  },

];

const StatusColorLegend: React.FC<Props> = ({ ...props }: Props) => {
  const [moreActionsMenuAnchorEl, setMoreActionsMenuAnchorEl] = React.useState<null | HTMLElement>(null);

  return (
    <Box sx={props}>
      <IconButton
        style={{
          backgroundColor: "white",
          borderRadius: 5,
          cursor: "pointer",
          color: "black",
        }}
        size="small"
        onClick={(e) => setMoreActionsMenuAnchorEl(e.currentTarget)}
        aria-label="more"
      >
        <PaletteIcon />
      </IconButton>

      <Popover
        anchorEl={moreActionsMenuAnchorEl}
        open={Boolean(moreActionsMenuAnchorEl)}
        onClose={() => setMoreActionsMenuAnchorEl(null)}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
      >
        <List dense sx={{p: 1}}>
          {legend.map((status) => (
            <ListItem key={status.description} disablePadding>
              <div
                style={{
                  backgroundColor: status.color,
                  width: 20,
                  height: 20,
                  borderRadius: 5,
                  marginRight: 5,
                }}
                />
                <ListItemText>{status.description}</ListItemText>
            </ListItem>
          ))}
        </List>
      </Popover>
    </Box>
  );
};

export default StatusColorLegend;
