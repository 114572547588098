export const featureLayer: mapboxgl.FillLayer = {
  id: "features",
  type: "fill",
  source: "features",
  layout: {},
  filter: ["==", ["get", "type"], "base"],
  paint: {
    /*'fill-color': ["case",
                ["==", ["get", "kind"], "irrigatedArea"], "#0080ff",
                ["==", ["get", "kind"], "somethingElse"], "#ff0000",
                "#000000"
            ],*/
    "fill-color": ["get", "statusColor"],
    "fill-opacity": ["get", "opacity"],
  },
  minzoom: 13.3,
};

export const featureOutlineLayer: mapboxgl.AnyLayer = {
  //outer line of each feature
  id: "featuresOutline",
  type: "line",
  source: "features",
  layout: {},
  filter: ["all", ["==", ["get", "type"], "base"], ["!=", ["get", "noBorder"], "true"]],
  paint: {
    "line-color": ["get", "statusColor"],
    "line-width": 8,
  },
  minzoom: 13.3,
};

export const programLayer: mapboxgl.FillLayer = {
  id: "programs",
  type: "fill",
  source: "features",
  layout: {},
  filter: ["all", ["==", ["get", "type"], "program"], ["!=", ["get", "hideInner"], "true"]],
  paint: {
    "fill-color": ["get", "color"],
    "fill-opacity": ["get", "opacity"] ?? 1,
    "fill-outline-color": "transparent",
  } as any,
  minzoom: 13.3,
};

export const programLineLayer: mapboxgl.LineLayer = {
  id: "programslines",
  type: "line",
  source: "features",
  layout: {
    "line-cap": "round",
  },
  filter: ["all", ["==", ["get", "type"], "program"], ["!=", ["get", "hideLines"], "true"]],
  paint: {
    "line-width": ["get", "width"],
    "line-color": ["get", "color"],
  },
  minzoom: 13.3,
};

export const badgeBaseLayerZoomedOut: mapboxgl.AnyLayer = {
  id: "badgeBaseLayerZoomedOut",
  type: "circle",
  source: "features",
  filter: ["==", ["get", "type"], "badgeBaseZoomedOut"],
  paint: {
    "circle-radius": 15,
    "circle-color": ["get", "statusColor"],
  },
  maxzoom: 13.3,
};

export const badgeBaseLayerZoomedIn: mapboxgl.AnyLayer = {
  id: "badgeBaseLayerZoomedIn",
  type: "circle",
  source: "features",
  filter: ["==", ["get", "type"], "badgeBaseZoomedIn"],
  paint: {
    "circle-radius": ["get", "circleRadius"],
    "circle-color": ["get", "statusColor"],
  },
  minzoom: 13.3,
};

export const symbolLayer: mapboxgl.AnyLayer = {
  id: "symbolLayer",
  type: "symbol",
  source: "features",
  filter: ["==", ["get", "type"], "symbol"],
  layout: {
    "icon-image": ["get", "icon"], // reference the image
    "icon-size": ["get", "iconSize"],
    "icon-offset": ["get", "iconOffset"],
    "icon-allow-overlap": true, // Allow icons to overlap
  },
  maxzoom: 13.3,
};

export const badgeIconLayer: mapboxgl.AnyLayer = {
  //needs to be a separate layer as has to stay on top
  id: "badgeLayer",
  type: "symbol",
  source: "features",
  filter: ["==", ["get", "type"], "badgeIcon"],
  layout: {
    "icon-image": ["get", "icon"], // reference the image
    "icon-size": ["get", "iconSize"],
    "icon-offset": ["get", "iconOffset"],
    "icon-allow-overlap": true, // Allow icons to overlap
  },
};

export const activePointLayer: mapboxgl.AnyLayer = {
  id: "activePoint",
  type: "circle",
  source: "activePoint",
  filter: ["==", ["get", "type"], "activePoint"],
  paint: {
    "circle-radius": 5,
    "circle-color": "yellow",
  },
};

export const userLocationPointLayer: mapboxgl.AnyLayer = {
  id: "userLocationPointLayer",
  type: "circle",
  source: "features",
  filter: ["==", ["get", "type"], "location-point"],
  paint: {
    "circle-radius": 5,
    "circle-color": "#1E90FF", // deep blue
  },
};

export const userLocationCircleLayer: mapboxgl.FillLayer = {
  id: "userLocationCircleLayer",
  type: "fill",
  source: "features",
  filter: ["==", ["get", "type"], "location-circle"],
  layout: {},
  paint: {
    "fill-color": "#87ceeb", // sky blue
    "fill-opacity": 0.5,
  },
};

export const speedRingLayer: mapboxgl.LineLayer = {
  id: "speedRingLayer",
  type: "line",
  source: "speed-ring",
  layout: {
    //'line-join': 'round',
    "line-cap": "round",
  },
  paint: {
    "line-color": "#868F96",
    "line-width": 8,
  },
  minzoom: 13.3,
};

export const selectedUnitLayer: mapboxgl.CircleLayer = {
  id: "selectedUnitLayer",
  type: "circle",
  source: "selected-units",
  paint: {
    "circle-radius": 25, // Circle radius in pixels
    "circle-color": "#389CE4",
    "circle-opacity": 0.7,
  },
  maxzoom: 13.3,
};
