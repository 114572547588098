import {
  Stack,
  Button,
  Typography,
  IconButton,
  Divider,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import IUnitData from "../../context/BackgroundDataCtx/IUnitData";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import CloseIcon from "@mui/icons-material/Close";
import AddIcon from "@mui/icons-material/Add";
import EventCard from "./EventCard";
import CreateNewEventDialog from "./CreateNewEventDialog";
import IBackgroundData from "../../context/BackgroundDataCtx/IBackgroundData";
import { IEvent, createEvent } from "./createEvent";
import EventsApiCtx from "../../context/EventsApiCtx/EventsApiCtx";

interface Props {
  isOpen: boolean;
  unit: IUnitData;
  bgdata: IBackgroundData;
  onClose: () => void;
}
const UnitEventsDialog: React.FC<Props> = ({
  isOpen,
  unit,
  bgdata,
  onClose,
}: Props) => {
  const [openNewEventDialog, setOpenNewEventDialog] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [events, setEvents] = useState<IEvent[]>([]);
  const { saveEvent, deleteEvent, getEvents } = React.useContext(EventsApiCtx);
  const theme = useTheme();
  const isBelowSm = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    const events = getEvents(unit.id, bgdata.user.name);
    setEvents(events);
    setLoading(false);
  }, []);

  const handleAddNewEvent = async (initEvent: IEvent) => {
    setLoading(true);
    try {
      const newEvent = await createEvent(unit, initEvent, bgdata);
      const updatedEvents = saveEvent(newEvent, unit.id, bgdata.user.name);
      setEvents(
        updatedEvents.sort((a, b) => b.eventStartTimeMs - a.eventStartTimeMs)
      );
    } catch (error) {
      console.log("Error creating event:", error);
      setOpenNewEventDialog(true);
    }
    setLoading(false);
  };

  const handleDeleteEvent = (event: IEvent) => {
    const updatedEvents = deleteEvent(event, unit.id, bgdata.user.name);
    setEvents(updatedEvents);
  };

  const handleEditEvent = (event: IEvent) => {
    console.log("TODO: Edit event");
  };

  return (
    <Dialog
      fullScreen={isBelowSm}
      scroll="paper"
      open={isOpen}
      onClose={() => onClose()}
    >
    
      <DialogTitle sx={{ display: "flex", alignItems: "center", gap: 2 }}>
        <CalendarTodayIcon fontSize="small" />
        <span style={{ flexGrow: 1 }}>
          Events
        </span>
        <IconButton onClick={() => onClose()}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <Divider />
      <DialogContent>
        {!loading ? (
          <Stack spacing={2} sx={{ p: 2 }}>
            <Button
              color="info"
              sx={{ border: `2px dashed `, py: 3 }}
              onClick={() => setOpenNewEventDialog(true)}
            >
              <AddIcon /> Create New Event
            </Button>
            {events.map((event, index) => (
              <EventCard
                key={index}
                event={event}
                editEvent={handleEditEvent}
                deleteEvent={handleDeleteEvent}
              />
            ))}
            {events.length === 0 && <Typography>No events</Typography>}
          </Stack>
        ) : (
          <Stack
            alignItems={"center"}
            justifyContent={"center"}
            sx={{ width: "100%", height: "300px" }}
          >
            <CircularProgress size={24} />
          </Stack>
        )}
      </DialogContent>

      <CreateNewEventDialog
        open={openNewEventDialog}
        onClose={() => setOpenNewEventDialog(false)}
        addNewEvent={handleAddNewEvent}
        events={events}
      />
    </Dialog>
  );
};

export default UnitEventsDialog;
