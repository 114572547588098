import * as React from "react";
import { useEffect, useState } from "react";

import ILocationData from "./ILocationData";
import LocationDataCtx from "./LocationDataCtx";

const LocationDataCtxProvider: React.FC<React.PropsWithChildren> = (props) => {
  const [locationData, setLocationData] = useState<ILocationData>({
    coords: undefined,
    error: undefined,
  });

  useEffect(() => {
    if (!navigator.geolocation) {
      setLocationData((prev) => ({
        ...prev,
        error: "Geolocation is not supported by this browser.",
      }));
      return;
    }

    const handleSuccess = (position: GeolocationPosition) => {
      const accuracyThreshold = 200; // 200 meters 
      if (position.coords.accuracy <= accuracyThreshold) {
        setLocationData({ coords: position.coords, error: undefined });
      } else {
        setLocationData((prev) => ({
          ...prev,
          error: `Geolocation accuracy is too low.`,
        }));
      }
    };

    const handleError = (error: GeolocationPositionError) => {
      setLocationData((prev) => ({ ...prev, error: error.message }));
    };

    // Options for geolocation
    const geoOptions = {
      maximumAge: 300000, // Cache age (5 minutes)
      timeout: 15000, // Timeout (15 seconds)
      enableHighAccuracy: true,
    };

    const watcherId = navigator.geolocation.watchPosition(
      handleSuccess,
      handleError,
      geoOptions
    );

    return () => {
      navigator.geolocation.clearWatch(watcherId);
    };
  }, []);

  return (
    <LocationDataCtx.Provider value={locationData}>
      {props.children}
    </LocationDataCtx.Provider>
  );
};

export default LocationDataCtxProvider;
