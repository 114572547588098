import { Box, Fab, Stack, Typography } from "@mui/material";
import * as React from "react";
import TopNav from "../../components/Navigation/TopNav";
import { IGroup } from "../../context/GroupsApiCtx/IGroupsApi";
import GroupsApiCtx from "../../context/GroupsApiCtx/GroupsApiCtx";
import AddIcon from "@mui/icons-material/Add";
import CreateNewGroupDialog from "../../components/Groups/CreateNewGroupDialog";
import GroupCard from "../../components/Groups/GroupCard";
import BackgroundDataCtx from "../../context/BackgroundDataCtx/BackgroundDataCtx";

const GroupsPage: React.FC = () => {
  const { getGroups, saveGroup, editGroup, deleteGroup } =
    React.useContext(GroupsApiCtx);
    const {user} = React.useContext(BackgroundDataCtx);

  const [groups, setGroups] = React.useState<IGroup[]>([]);
  const [openNewGroupDialog, setOpenNewGroupDialog] =
    React.useState<boolean>(false);

  React.useEffect(() => {
    const groups = getGroups(user.name);
    setGroups(groups);
  }, []);

  const handleAddNewGroup = (newGroup: IGroup) => {
    const updatedGroups = saveGroup(newGroup, user.name);
    setGroups(updatedGroups);
  };

  return (
    <Box
      sx={{
        height: "100vh",
        overflow: "hidden",
      }}
    >
      <TopNav />
      <Box
        sx={{
          height: {
            xs: "calc(100% - 56px)",
            sm: "calc(100% - 64px)",
          },
          mt: {
            xs: "56px",
            sm: "64px",
          },
          overflow: "auto",
        }}
      >
        <Stack spacing={1} padding={2} position={"relative"}>
          <Typography variant="h5" align={"center"}>
            Groups
          </Typography>
          {groups.length > 0 ? (
            groups.map((group) => (
              <GroupCard
                key={group.id}
                group={group}
                editGroup={(group) => {
                  const updatedGroups = editGroup(group, user.name);
                  setGroups(updatedGroups);
                }}
                deleteGroup={(group) => {
                  const updatedGroups = deleteGroup(group, user.name);
                  setGroups(updatedGroups);
                }}
                addUnit={(unit, group) => {
                  const updatedGroup = {
                    ...group,
                    units: [...group.units, unit],
                  };
                  const updatedGroups = editGroup(updatedGroup, user.name);
                  setGroups(updatedGroups);
                }}
              />
            ))
          ) : (
            <Typography>No groups found</Typography>
          )}
          <Fab
            color="primary"
            onClick={() => setOpenNewGroupDialog(true)}
            sx={{ position: "fixed", bottom: 25, right: 25 }}
          >
            <AddIcon />
          </Fab>
          <CreateNewGroupDialog
            open={openNewGroupDialog}
            onClose={() => setOpenNewGroupDialog(false)}
            groups={groups}
            addNewGroup={(newGroup) => handleAddNewGroup(newGroup)}
          />
        </Stack>
      </Box>
    </Box>
  );
};

export default GroupsPage;
