import IBackgroundData from "../context/BackgroundDataCtx/IBackgroundData";
import IUnitData from "../context/BackgroundDataCtx/IUnitData";
import {
  ICapabilites,
  ISettingsCapabilities,
} from "../context/ExtendedUnitDataCtx/IExtendedUnitData";
import { ILoadUnitResp } from "../context/RswsApiCtx/IRswsApi";

// 10: 'Generic Unit',
// 8201: 'Ontrac G1 - Satellite',
// 8202: 'Ontrac G2 - Sat/Cell',
// 8210: 'Plus Sat/Cell - Rams/Touch',
// 8211: 'RBase',
// 8212: 'RBase LMTD',
// 8213: 'Plus Sat/Cell - Pac3',
// 8214: 'RC10i (Independent)',
// 8215: 'RC10d - Touch',
// 8216: 'RBase ADV+',
// 8217: 'RBase RAMS',
// 8218: 'RC10d - Advanced+',
// 8219: 'RC10d - Connect',
// 8220: 'Sensmit',
// 8221: 'Sensmit Mesh'
type UnitTypes =
  | "basic"
  | "pac3"
  | "rbaseweb"
  | "rbasewebPac"
  | "g1g2"
  | "rs300";

type IUnitTypeIdMap = {
  [key in UnitTypes]: number[];
};

const UNIT_TYPES: IUnitTypeIdMap = {
  basic: [8210, 8214, 8215, 8218, 8219], // touch // rc10i: 8214
  pac3: [8213],
  rbaseweb: [8211, 8216, 8217],
  rbasewebPac: [8212], 
  g1g2: [8201, 8202],
  rs300: [300, 8220],
};

export const getUnitCapabilities = (unit: ILoadUnitResp): ICapabilites => {
  const unitType = getUnitType(unit.typeId);
  if (unitType === "basic") {
    return {
      settings: getUnitSettingsCapabilities(unit.typeId),
      programs:
        unit.typeId === 8210 || hideOnPositionNone(unit)
          ? { scheduledActions: true }
          : {
              eg: true,
              aux: hasAuxCapability(unit),
              sector: true,
              scheduledActions: true,
              barricade: true,
            },
      controls: {
        aux1: hasAuxCapability(unit),
        endGun: true,
        chemPump: hasChemPumpCapability(unit),
        parkAngle: !hideOnPositionNone(unit),
        park: !hideOnPositionNone(unit),
        pump: hasPump(unit),
        speedpc: true,
        direction: hasDirectionControlCapability(unit),
        start: unit.typeId === 8214 ? hasStartG1G2(unit) : true,
        stop: unit.typeId === 8214 ? hasStopG1G2(unit) : true,
      },
    };
  } else if (unitType === "pac3") {
    return {
      programs: { scheduledActions: true },
      controls: {
        speedpc: true,
        park: true,
        parkAngle: true,
        endGun: true,
        start: true,
        stop: true,
      },
    };
  } else if (unitType === "rbaseweb") {
    return {
      programs: { scheduledActions: true },
      controls: {
        parkAngle: unit.typeId !== 8217,
        chemPump: unit.typeId !== 8216,
        park: false,
        aux1: true, //Todo seems to have aux1, aux2, aux3... 8216 also has aux1chem instead of chemPump
        endGun: true,
        speedpc: true,
        pump: true,
        direction: true,
        start: true,
        stop: true,
        directionCommandWithStart: unit.typeId === 8216,
      },
    };
  } else if (unitType === "rbasewebPac") {
    return {
      programs: { scheduledActions: true },
      controls: {
        start: true,
        stop: true,
        speedpc: true,
        parkAngle: true,
        endGun: true,
      },
    };
  } else if (unitType === "g1g2") {
    return {
      programs: { scheduledActions: true },
      controls: {
        start: hasStartG1G2(unit),
        stop: hasStopG1G2(unit),
      },
    };
  } else if (unitType === "rs300") {
    return {
      programs: { scheduledActions: true },
      controls: {
        start: false,
        stop: false,
      },
    };
  }
  throw new Error(`Unknown unit type: ${unit.typeId}`);
};

const getUnitType = (typeId: number): UnitTypes => {
  for (const key in UNIT_TYPES) {
    if (UNIT_TYPES[key].includes(typeId)) {
      return key as UnitTypes;
    }
  }
  throw new Error(`Unknown unit type: ${typeId}`);
};

const hasStartG1G2 = (unit: ILoadUnitResp): boolean => {
  const allowedg1g2 = [null, 2, 3, 6, 7];
  return allowedg1g2.includes(getG1G2Control(unit));
};

const hasStopG1G2 = (unit: ILoadUnitResp): boolean => {
  const allowedg1g2 = [null, 1, 3, 5, 7];
  return allowedg1g2.includes(getG1G2Control(unit));
};

const hasAuxCapability = (unit: ILoadUnitResp): boolean => {
  return (
    unit.unitSettings.auxiliarytype !== 1 &&
    unit.unitSettings.auxiliarytype !== 2
  );
};

const hasChemPumpCapability = (unit: ILoadUnitResp): boolean => {
  return hasChem(unit); // this seems to work similar to old software
  // but this is how I interpretate chemigation-control.pug implementation
  //  return hasChem(unit) && unit.unitSettings.auxiliarytype !== 1 && unit.typeId !== 8218 && unit.typeId !== 8219
};

const hasPump = (unit: ILoadUnitResp): boolean => {
  return getEOS(unit) === 1 || getEOS(unit) === 3;
};

const hasChem = (unit: ILoadUnitResp): boolean => {
  return getEOS(unit) === 3;
};

/**
 * 0 means no pump no chem; 1 means has pump no chem; 3 means has pump and chem;
 */
const getEOS = (unit: ILoadUnitResp): number => {
  // 	eos:         8257, // sensor typeID
  const eosSensor = unit.sensors.find((sensor) => sensor.typeId === 8257);
  const last = eosSensor?.last;
  return last ? last.value : 3;
};

const hasDirectionControlCapability = (unit: ILoadUnitResp): boolean => {
  const g1g2Control = getG1G2Control(unit);
  return g1g2Control === null || g1g2Control >= 4;
};

const getG1G2Control = (unit: ILoadUnitResp): number | null => {
  //	g1g1Control: 8300, // sensor typID
  const sensor = unit.sensors.find((sensor) => sensor.typeId === 8300);
  const last = sensor?.last;
  return last ? last.value : null;
};

/**
 * Checks if the unit should be hidden when position type setting equal None (3) and unit type Advance+/Connect (8218/8219).
 */
const hideOnPositionNone = (unit: ILoadUnitResp): boolean => {
  return (
    unit.unitSettings.positiontype === 3 &&
    (unit.typeId === 8218 || unit.typeId === 8219)
  );
};

export const getUnitSettingsCapabilities = (
  typeId: number
): ISettingsCapabilities | undefined => {
  // Mapping from old software...
  // Can this unit store download settings?
  switch (typeId) {
    // case 8210:
    case 8214:
    case 8215:
      return {
        system: false,
        startBehavior: false,
        pump: false,
        restart: true,
        restartPressure: false,
        auxiliary: false,
        temperature: false,
        flow: true,
        hasStartSequence: typeId === 8215, // // RC10d - Touch has start sequence option for powerrestart setting
      };
    case 8218:
      return {
        system: true,
        startBehavior: false,
        pump: true,
        restart: true,
        restartPressure: true,
        auxiliary: true,
        temperature: true,
        flow: true,
        hasStartSequence: false,
      };
    case 8219:
      return {
        system: true,
        startBehavior: true,
        pump: true,
        restart: true,
        restartPressure: true,
        auxiliary: true,
        temperature: true,
        flow: true,
        hasStartSequence: false,
      };
  }
};

/**
 * Sends start command for unit type 8216.
 * Rbase Adv+ need to send a direction command w/start, else the start is ignored
 *
 * @param unit - The unit data.
 * @param bgdata - The background data.
 * @returns A promise that resolves when the command is completed.
 */
export const directionWithStartCommand = async (
  unit: IUnitData,
  bgdata: IBackgroundData
): Promise<void> => {
  const promise1 = bgdata.changeUnitState(unit, "running", true);
  let promise2: Promise<void>;
  if (unit.unitState.direction === "Forward") {
    promise2 = bgdata.changeUnitState(unit, "direction", "Forward");
  } else {
    promise2 = bgdata.changeUnitState(unit, "direction", "Reverse");
  }
  await Promise.all([promise1, promise2]);
};

