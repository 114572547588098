import React from "react";
import IUnitData, {
  IEndGunAuxSector,
} from "../../context/BackgroundDataCtx/IUnitData";
import { IconButton, Stack, Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import DeleteIcon from "@mui/icons-material/Delete";
import InteractiveInput from "../ui/InteractiveInput";
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';

interface Props {
  endGunAuxSectors: IEndGunAuxSector[];
  onChange: (newSelectors: IEndGunAuxSector[]) => void;
  unit: IUnitData;
}

const EndgunAuxEditor: React.FC<Props> = ({
  onChange,
  endGunAuxSectors,
  ...props
}: Props) => {
  const handleDeleteSector = (index: number) => {
    const newSectors = [...endGunAuxSectors];
    newSectors.splice(index, 1);
    onChange(newSectors);
  };
  return (
    <Grid container xs={12} spacing={1}>
      {endGunAuxSectors.map((sector, index) => (
        <Grid
        xs={12}
          display={"flex"}
          justifyContent={"space-between"}
          style={{alignItems: 'flex-end'}}
          key={index}
          gap={1}
        >
          <Stack sx={{ flexGrow: 1 }}>
            {index === 0 && <Typography>Begin</Typography>}
            <InteractiveInput
              id={`${index}-begin`}
              hideErrorHelperText={true}
              value={sector.begin}
              onChange={(newValue) => {
                const newSectors = [...endGunAuxSectors];
                newSectors[index].begin = newValue;
                onChange(newSectors);
              }}
              unit={props.unit}
            />
          </Stack>
          <SwapHorizIcon style={{marginBottom: 10, color: 'gray', cursor: 'pointer'}} onClick={() => {
            const newSectors = [...endGunAuxSectors]
            const oldBegin = newSectors[index].begin;
            newSectors[index].begin = newSectors[index].end;
            newSectors[index].end = oldBegin;
            onChange(newSectors);
          }} />
          <Stack sx={{ flexGrow: 1 }}>
            {index === 0 && <Typography>End</Typography>}
            <Stack direction={"row"} gap={1}>
              <InteractiveInput
                id={`${index}-end`}
                hideErrorHelperText={true}
                value={sector.end}
                onChange={(newValue) => {
                  const newSectors = [...endGunAuxSectors];
                  newSectors[index].end = newValue;
                  onChange(newSectors);
                }}
                unit={props.unit}
              />
              <IconButton
                onClick={() => handleDeleteSector(index)}
                aria-label="delete"
              >
                <DeleteIcon />
              </IconButton>
            </Stack>
          </Stack>
        </Grid>
      ))}
 
    </Grid>
  );
};

export default EndgunAuxEditor;
