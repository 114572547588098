import IJsonApiReq from "./IJsonApiReq";
import IJsonApiResp from "./IJsonApiResp";
import IRswsApi, {
    ILoadPropertyReq,
    ILoadPropertyResp,
    ILoadUserResp,
    ILoginReq,
    ILoginResp,
    ILoadUnitReq,
    ILoadUnitResp,
    ISaveUnitSettingsReq,
    IFindProgramsReq,
    IFindProgramsResp,
    ISaveProgramReq,
    ISaveProgramResp,
    ISaveUnitReq,
    ILoadSensorDataReq,
    ILoadSensorDataRes,
    ISaveUserContact,
    IChangePasswordReq,
} from "./IRswsApi";
import { v4 as uuidv4 } from "uuid";

export default class RswsApi implements IRswsApi {
    baseUri = "https://reincloud2devel.azure-api.net/rpc/2.0/";

    #authToken: string | undefined;
    setAuthToken: (authToken: string | undefined) => void;

    constructor(
        authToken: string | undefined,
        setAuthToken: (authToken: string | undefined) => void
    ) {
        this.#authToken = authToken;
        this.setAuthToken = setAuthToken;
    }

    hasAuthToken = () => !!this.#authToken;

    login = async (req: ILoginReq): Promise<ILoginResp> => {
        const respRaw = await fetch(`${this.baseUri}login`, {
            body: JSON.stringify(req),
            headers: {
                "content-type": "application/json",
            },
            method: "POST",
        });

        // Throw away JSON response for now. We make a subsequent call to loadUser instead.

        return {
            authToken: respRaw.headers.get("x-auth-token")!,
        };
    };

    #sendRequest = async <TReq, TResp>(
        controller: string,
        method: string,
        req: TReq
    ): Promise<TResp> => {
        const body: IJsonApiReq<TReq> = {
            id: uuidv4(),
            jsonrpc: "2.0",
            method,
            params: req,
        };

        const respRaw = await fetch(`${this.baseUri}${controller}`, {
            body: JSON.stringify(body),
            headers: {
                "content-type": "application/json",
                "x-auth-token": this.#authToken!,
            },
            method: "POST",
        });

        const respJson: IJsonApiResp<TResp> = await respRaw.json();

        return respJson.result;
    };

    loadUser = () =>
        this.#sendRequest<undefined, ILoadUserResp>(
            "v1/UserService.json",
            "loadUser",
            undefined
        );

    findSensorData = (req: ILoadSensorDataReq) =>
        this.#sendRequest<ILoadSensorDataReq, ILoadSensorDataRes>(
            "v1/SensorService.json",
            "findSensorData",
            req
        );

    loadProperty = (req: ILoadPropertyReq) =>
        this.#sendRequest<ILoadPropertyReq, ILoadPropertyResp>(
            "v1/PropertyService.json",
            "loadProperty",
            req
        );

    loadUnit = (req: ILoadUnitReq) =>
        this.#sendRequest<ILoadUnitReq, ILoadUnitResp>(
            "v1/UnitService.json",
            "loadUnit",
            req
        );

    saveUnitSettings = (req: ISaveUnitSettingsReq) =>
        this.#sendRequest<ISaveUnitSettingsReq, string>(
            "v1/UnitService.json",
            "saveUnitSettings",
            req
        );

    saveUnit = (req: ISaveUnitReq) =>
        this.#sendRequest<ISaveUnitReq, string>(
            "v1/UnitService.json",
            "saveUnit",
            req
        );

    findPrograms = (req: IFindProgramsReq) =>
        this.#sendRequest<IFindProgramsReq, IFindProgramsResp>(
            "v1/ProgramService.json",
            "findPrograms",
            req
        );

    saveProgram = (req: ISaveProgramReq) =>
        this.#sendRequest<ISaveProgramReq, ISaveProgramResp>(
            "v1/ProgramService.json",
            "saveProgram",
            req
        );

    deleteProgram = (req: [number]) =>
        this.#sendRequest<[number], string>(
            "v1/ProgramService.json",
            "deleteProgram",
            req
        );

    loadProgram = (req: number[]) =>
        this.#sendRequest<number[], string>(
            "v1/ProgramService.json",
            "loadProgram",
            req
        );

    unloadProgram = (req: number[]) =>
        this.#sendRequest<number[], string>(
            "v1/ProgramService.json",
            "unloadProgram",
            req
        );

    actuateSensor = (req: number[]) =>
        this.#sendRequest<number[], { result: 0 }>(
            "v1/SensorService.json",
            "actuateSensor",
            req
        );

    saveUserContact = (req: ISaveUserContact) =>
        this.#sendRequest<ISaveUserContact, string>(
            "v1/UserService.json",
            "saveUserContact",
            req
        );

    changePassword = (req: IChangePasswordReq) =>
        this.#sendRequest<IChangePasswordReq, string>(
            "v1/UserService.json",
            "changePassword",
            req
        );

}
