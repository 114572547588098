import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import useMediaQuery from "@mui/material/useMediaQuery";
import useTheme from "@mui/material/styles/useTheme";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { Button, DialogActions, DialogContent, DialogContentText } from "@mui/material";
import { IGroup } from "../../context/GroupsApiCtx/IGroupsApi";
import AddNewUnitMenu from "./AddNewUnitMenu";
import IUnitData from "../../context/BackgroundDataCtx/IUnitData";
import UnitsList from "../ui/UnitsList";

interface Props {
  open: boolean;
  onClose: () => void;
  addNewGroup(group: IGroup): void;
  groups: IGroup[];
}
const CreateNewGroupDialog = ({ open, onClose, addNewGroup, groups }: Props) => {
  const theme = useTheme();
  const isBelowSm = useMediaQuery(theme.breakpoints.down("sm"));
  const [newGroup, setNewGroup] = React.useState<IGroup>({
    id: null,
    name: "",
    units: [],
  });

  const handleClose = () => {
    setNewGroup({ id: null, name: "", units: [] });
    onClose();
  };

  const handleNewGroup = async () => {
    if (newGroup.name.length > 2 && !nameNotUnique) {
      addNewGroup(newGroup);
      handleClose();
    }
  };

  const handleAddUnit = (unit: IUnitData) => {
    setNewGroup({ ...newGroup, units: [...newGroup.units, unit] });
  };

  const nameNotUnique = groups.some((e) => e.name === newGroup.name);
  return (
    <Dialog onClose={handleClose} open={open} fullWidth fullScreen={isBelowSm} maxWidth={"sm"}>
      {isBelowSm && (
        <IconButton onClick={() => onClose()} sx={{ position: "absolute", top: 3, right: 3 }}>
          <CloseIcon />
        </IconButton>
      )}
      <DialogTitle sx={{ display: "flex", justifyContent: "space-between" }}>Create New Group</DialogTitle>
      <DialogContent>
        <DialogContentText>Please select a name and units to include in the group.</DialogContentText>
        <TextField
          sx={{ my: 1 }}
          autoFocus
          label="Group Name"
          variant="outlined"
          fullWidth
          value={newGroup.name}
          onChange={(e) => setNewGroup({ ...newGroup, name: e.target.value })}
        />
        <UnitsList
          units={newGroup.units}
          deleteUnit={(unitId) => {
            setNewGroup({
              ...newGroup,
              units: newGroup.units.filter((u) => u.id !== unitId),
            });
          }}
        />
        <AddNewUnitMenu unitsInGroup={newGroup.units} handleAddUnit={(unit) => handleAddUnit(unit)} />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button disabled={newGroup.name.length < 2 || nameNotUnique} onClick={handleNewGroup}>
          Create Group
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CreateNewGroupDialog;
