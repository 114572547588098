import { Feature, Position } from "geojson";
import IUnitData from "../../context/BackgroundDataCtx/IUnitData";
import { feetToMeters, mileToFeet } from "../../helpers/Conversions";
import { bearing, point, nearestPointOnLine, lineString, destination, distance, pointToLineDistance, along, angle, booleanPointInPolygon, circle, bbox } from "@turf/turf";
import mapboxgl from "mapbox-gl";
import { buildUnitCoverageArea } from "./buildFeatureBase";

export const calculateClosestPointOnCircle = (clickedPoint: number[], unit: IUnitData): number[] => {
  // Convert degrees to radians
  const centerLatRad = unit.latitude * Math.PI / 180;
  const centerLngRad = unit.longitude * Math.PI / 180;
  const clickedLatRad = clickedPoint[1] * Math.PI / 180;
  const clickedLngRad = clickedPoint[0] * Math.PI / 180;

  const circleRadius = feetToMeters(unit.lengthFeet);

  // Calculate the angle between the center of the circle and the clicked point
  const angle = Math.atan2(Math.sin(clickedLngRad - centerLngRad) * Math.cos(clickedLatRad),
    Math.cos(centerLatRad) * Math.sin(clickedLatRad) - Math.sin(centerLatRad) *
    Math.cos(clickedLatRad) * Math.cos(clickedLngRad - centerLngRad));

  // Calculate the closest point on the circle
  const closestLatRad = Math.asin(Math.sin(centerLatRad) * Math.cos(circleRadius / 6371000) + Math.cos(centerLatRad) * Math.sin(circleRadius / 6371000) * Math.cos(angle));
  const closestLngRad = centerLngRad + Math.atan2(Math.sin(angle) * Math.sin(circleRadius / 6371000) * Math.cos(centerLatRad), Math.cos(circleRadius / 6371000) - Math.sin(centerLatRad) * Math.sin(closestLatRad));

  // Convert radians back to degrees
  const closestLat = closestLatRad * 180 / Math.PI;
  const closestLng = closestLngRad * 180 / Math.PI;

  return [closestLng, closestLat];
}

const rgbToHex = (r, g, b) => {
  return '#' + [r, g, b].map(x => {
    const hex = x.toString(16);
    return hex.length === 1 ? '0' + hex : hex;
  }).join('');
}

export const convertPointsToPolygons = (points: any[]): Feature[] => {
  var cells: { nrOfPoints: number, points: Feature[], properties: { [id: string]: number } }[] = [];
  var minLat = 1000;
  var maxLat = -1000;
  var minLon = 1000;
  var maxLon = -1000;

  const lonCount = 100;
  const latCount = 100;
  for (let i = 0; i < lonCount * latCount; i++) {
    cells[i] = { nrOfPoints: 0, points: [], properties: {} };
  }

  points.forEach(p => {
    if (p.geometry.coordinates[0] < minLon) {
      minLon = p.geometry.coordinates[0];
    }
    if (p.geometry.coordinates[0] > maxLon) {
      maxLon = p.geometry.coordinates[0];
    }
    if (p.geometry.coordinates[1] < minLat) {
      minLat = p.geometry.coordinates[1];
    }
    if (p.geometry.coordinates[1] > maxLat) {
      maxLat = p.geometry.coordinates[1];
    }
  });

  const dLon = (maxLon - minLon) / lonCount;
  const dLat = (maxLat - minLat) / latCount;
  var cellFeatures: Feature[] = [];

  points.forEach(p => {
    const iLon = Math.floor((p.geometry.coordinates[0] - minLon) / dLon);
    const iLat = Math.floor((p.geometry.coordinates[1] - minLat) / dLat);
    const index = iLat * lonCount + iLon;
    if (index < cells.length) {
      cells[index].nrOfPoints++;
      Object.keys(p.properties).forEach(key => {
        if (typeof p.properties[key] === 'number') { //only store number values
          if (!cells[index].properties[key]) {
            cells[index].properties[key] = p.properties[key];
          } else {
            cells[index].properties[key] += p.properties[key];
          }
        }
      });
    }
  });

  for (let rowNr = 0; rowNr < latCount; rowNr++) {
    for (let colNr = 0; colNr < lonCount; colNr++) {
      const index = rowNr * lonCount + colNr;
      if (cells[index].nrOfPoints > 0) {
        const lon1 = minLon + colNr * dLon;
        const lon2 = minLon + (colNr + 1) * dLon;
        const lat1 = minLat + rowNr * dLat;
        const lat2 = minLat + (rowNr + 1) * dLat;
        var cellCoords: Position[] = [[lon1, lat1], [lon1, lat2], [lon2, lat2], [lon2, lat1], [lon1, lat1]];
        var avgProps: { [id: string]: number } = {};
        Object.keys(cells[index].properties).forEach(key => {
          avgProps[key] = parseFloat((cells[index].properties[key] / cells[index].nrOfPoints).toFixed(4));
        });

        cellFeatures.push({
          type: "Feature",
          geometry: { type: "Polygon", coordinates: [cellCoords] },
          properties: avgProps,
        });
      }
    }
  }
  return cellFeatures;
}

export const calculateColsestPointOnLine = (clickedPoint: number[], unit: IUnitData): number[] => {
  var secondaryCoordinate = getSecondaryCoordinates(unit);
  const rect = getRectPoints(unit.longitude, unit.latitude, secondaryCoordinate[0], secondaryCoordinate[1], feetToMeters(unit.lengthFeet));

  const line = lineString([rect[0], rect[3]]);
  const closestPoint = nearestPointOnLine(line, point(clickedPoint));

  return closestPoint.geometry.coordinates;
}

export const getDistanceFromLateralBaseLine = (clickedPoint: number[], coordinates1: number[], coordinates2: number[]): number => {

  var pt = point(clickedPoint);
  var line = lineString([coordinates1, coordinates2]);

  var distance = pointToLineDistance(pt, line, { units: 'miles' });

  return mileToFeet(distance);
}

export const getCoordinatesOnCircleFromAngle = (center, radius, angle): number[] => {
  const lat = center[1];
  const lon = center[0];
  const d = radius / 111300; // Convert meters to degrees

  // Convert angle from degrees to radians
  const rad = (angle * Math.PI) / 180;

  // Calculate the new latitude and longitude
  const newLat = lat + d * Math.cos(rad);
  const newLon = lon + (d * Math.sin(rad)) / Math.cos((lat * Math.PI) / 180);

  return [newLon, newLat];
};

export const getSecondaryCoordinates = (unit: IUnitData): number[] => {
  if (unit.latitude2 && unit.longitude2) {
    return [unit.longitude2, unit.latitude2];
  } else {
    return offsetCoordinatesByMeters(unit.latitude, unit.longitude, 200, 200);
  }
}

export const offsetCoordinatesByMeters = (
  lat: number,
  lon: number,
  dx: number,
  dy: number
): number[] => {
  //Earth’s radius, sphere
  const R = 6378137;

  //Coordinate offsets in radians
  const dLat = dx / R;
  const dLon = dy / (R * Math.cos((Math.PI * lat) / 180));

  //OffsetPosition, decimal degrees
  const latO = lat + (dLat * 180) / Math.PI;
  const lonO = lon + (dLon * 180) / Math.PI;

  return [lonO, latO];
};


export const getAngleFromPoint = (clickedPoint: number[], unit: IUnitData): number => {
  var point1 = point([unit.longitude, unit.latitude]);
  var point2 = point(clickedPoint);
  var b = bearing(point1, point2);

  if (b < 0) {
    b += 360;
  }

  return parseFloat(b.toFixed(2));
}

export const getAngleBetweenTwoPoints = (clickedPoint: number[], middle: number[]): number => {
  var point1 = point(middle);
  var point2 = point(clickedPoint);
  var b = bearing(point1, point2);

  if (b < 0) {
    b += 360;
  }

  return parseFloat(b.toFixed(2));
}

export const getDistanceBetweenPoints = (p1: number[], p2: number[]): number => {
  var from = point(p1);
  var to = point(p2);
  var options = { units: 'miles' };

  return parseFloat(mileToFeet(distance(from, to, options)).toFixed(2));
}

export const getLateralMiddleCoordinate = (unit: IUnitData): number[] => {
  // Assuming rectangle is an array of 4 points in the format [[x1, y1], [x2, y2], [x3, y3], [x4, y4]]
  const rectangle = getLateralRectPointsForUnit(unit);
  const topLeft = rectangle[0];
  const bottomRight = rectangle[2]; // Diagonally opposite corners

  const xCenter = (topLeft[0] + bottomRight[0]) / 2;
  const yCenter = (topLeft[1] + bottomRight[1]) / 2;

  return [xCenter, yCenter];
}

export const getRectPoints = (
  lon1: number,
  lat1: number,
  lon2: number,
  lat2: number,
  length: number
): number[][] => {
  var points: any[] = [];
  var p1 = [lon1, lat1];
  var p2 = [lon2, lat2];
  var b = bearing(p1, p2);
  var p3 = destination(p1, length / 1000, b - 90);
  var p4 = destination(p2, length / 1000, b - 90);
  points = [p1, p2, p4.geometry.coordinates, p3.geometry.coordinates];
  return points;
};

export const getLateralRectPointsForUnit = (unit: IUnitData, lengthToUse?: number) => {
  var secondaryCoordinate = getSecondaryCoordinates(unit);
  var lat2 = secondaryCoordinate ? secondaryCoordinate[1] : unit.latitude;
  var lon2 = secondaryCoordinate ? secondaryCoordinate[0] : unit.longitude;
  var length = lengthToUse !== undefined ? lengthToUse : feetToMeters(unit.lengthFeet);

  return getRectPoints(unit.longitude, unit.latitude, lon2, lat2, length);
};

export const simplifySegments = (segments: Segment[]): Segment[] => {
  //when the segment overlaps 0 degree we split it in 2 to simplify the drawing process
  var simplified: Segment[] = [];
  segments.forEach((s, index) => {
    if (s.start < s.end) {
      simplified.push({ start: Math.floor(s.start), end: Math.floor(s.end), id: index });
    } else {
      simplified.push({ start: 0, end: Math.floor(s.end), id: index });
      simplified.push({ start: Math.floor(s.start), end: 360, id: index });
    }
  });
  return simplified;
};

export interface Segment {
  start: number;
  end: number;
  id: number;
}


export const buildRainDropPolygonCoordinates = (middle: number[], radius: number, unit: IUnitData, angleLat: number): number[][] => {
  var points: number[][] = [];
  var pos = getLateralRectPointsForUnit(unit, feetToMeters(unit.sensor["position"].last.value));
  const line = lineString([middle, unit.systemType === "Pivot" ? [unit.longitude, unit.latitude] : pos[3]]);
  const top = along(line, radius / 500); //default is km
  const currentPosAngle = unit.systemType === "Pivot" ? unit.sensor["position"].last.value : angleLat;
  var halfCircleAngle = currentPosAngle - 110 < 0 ? 360 + currentPosAngle - 110 : currentPosAngle - 110;
  let counter = 0;
  while (counter < 220) {
    points.push(getCoordinatesOnCircleFromAngle(middle, radius, halfCircleAngle));
    halfCircleAngle = halfCircleAngle + 1 > 360 ? 0 : halfCircleAngle + 1;
    counter++;
  }
  points.push(top.geometry.coordinates);

  return points;
}

export const buildDiamondPolygonCoordinates = (middle: number[], radius: number, unit: IUnitData, angleLat: number): number[][] => {
  var points: number[][] = [];
  var pos = getLateralRectPointsForUnit(unit, feetToMeters(unit.sensor["position"].last.value));
  const line = lineString([middle, unit.systemType === "Pivot" ? [unit.longitude, unit.latitude] : pos[3]]);
  const top = along(line, radius / 1000); //default is km
  points.push(top.geometry.coordinates);
  const currentPosAngle = unit.systemType === "Pivot" ? unit.sensor["position"].last.value : Math.round(angleLat);
  var angle1 = currentPosAngle - 90 < 0 ? 360 + currentPosAngle - 90 : currentPosAngle - 90;
  var angle2 = currentPosAngle + 90 > 360 ? currentPosAngle + 90 - 360 : currentPosAngle + 90;
  points.push(getCoordinatesOnCircleFromAngle(middle, radius, angle1));
  points.push(getCoordinatesOnCircleFromAngle(middle, radius, currentPosAngle));
  points.push(getCoordinatesOnCircleFromAngle(middle, radius, angle2));

  return points;
}

export const distFromKeyPoints = (array: number[], item: number) => {
  var min = 100;
  array.forEach(a => {
    if (Math.abs(item - a) < min) {
      min = Math.abs(item - a);
    }
  });

  return min;
}

export const getBounds = (unit: IUnitData) => {
  const feature = buildUnitCoverageArea(unit);
  const boundsBox = bbox(feature);
  const southWest = [boundsBox[0], boundsBox[1]] as [number, number];
  const northEast = [boundsBox[2], boundsBox[3]] as [number, number];
  return new mapboxgl.LngLatBounds(southWest, northEast);
}
