import * as React from "react";
import { useContext, useEffect, useState } from "react";
import MapLayersCtx from "./MapLayersCtx";
import { IMapLayer, IMapLayers } from "./IMapLayer";

const MapLayersCtxProvider: React.FC<React.PropsWithChildren> = (props) => {

    const defaultLayers: { [id: string]: IMapLayer } = {}; //contains pre-defined layers
    const usSoilLayer: IMapLayer = {
        id: 'layer_soil_wms_us',
        displayName: "US Soil",
        readonly: true,
        opacity: 70,
        show: true,
        mapboxLayerConfig: {
            id: 'layer_soil_wms_us',
            type: 'raster',
            source: 'wms-soil-vector-source',
            minzoom: 13,    
            layoutOpacityName: 'raster-opacity'       
          },
        mapboxSource: {
          id: 'wms-soil-vector-source',
          type: 'raster',
          tiles: [
              'https://reincloud2devel.azure-api.net/soilmapproxy?map=/soilmap2/website/gmap/mapunit_wms.map&layers=ssurgo&layers=ssa&mode=tile&tilemode=gmap&tile={x}+{y}+{z}&map.layer[ssurgo].class[0].style[0]=OUTLINECOLOR+255+255+255&map.layer[ssa].class[0].style[0]=OUTLINECOLOR+255+255+255'          
          ],
          minzoom: 12
      }
    };
    defaultLayers[usSoilLayer.id] = usSoilLayer;
  

    const [layers, setLayers] = useState<{ [id: string]: IMapLayer }>({});

    useEffect(() => {
        var layers:{ [id: string]: IMapLayer } = {};

        //remove old storage data
        localStorage.removeItem("soil_wms_us");
        localStorage.removeItem("mapLayers");

        const usSoilLayerFromStorage = localStorage.getItem("layer_soil_wms_us");
       // if(!usSoilLayerFromStorage || (JSON.parse(usSoilLayerFromStorage) as IMapLayer).id == "soil_wms_us"){ //update old format of storing
          localStorage.setItem("layer_soil_wms_us", JSON.stringify(usSoilLayer));
       // }

        const localStorageKeys = Object.keys(localStorage);
        
        localStorageKeys.forEach(key => {
          if(key.startsWith("layer")){
            var layerItem = localStorage.getItem(key);
            if(layerItem){
              const parsed = JSON.parse(layerItem);
              layers[key] = parsed;
            }
          }
        });

        setLayers(layers);
    }, []);

  return (
    <MapLayersCtx.Provider value={{layers : layers, setLayer: (id, layer) => {
       
        if(layer){
          var newlayers = {...layers};
          newlayers[id] = layer;
          setLayers(newlayers);         
          localStorage.setItem(id, JSON.stringify(layer));
        }else{
          var newLayers = {};
          for(let layerId in layers){
            if(layerId !== id){
              newLayers[layerId] = layers[layerId];
            }       
          }
          setLayers(newLayers);
          localStorage.removeItem(id);
        } 
        }}}>
      {props.children}
    </MapLayersCtx.Provider>
  );
};

export default MapLayersCtxProvider;

