import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import AccountCircle from "@mui/icons-material/AccountCircle";
import GroupsIcon from "@mui/icons-material/Groups";
import HomeIcon from "@mui/icons-material/Home";
import MapIcon from "@mui/icons-material/Map";
import LogoutIcon from "@mui/icons-material/Logout";
import FilterIcon from "@mui/icons-material/FilterAlt";
import ArrowDropDown from "@mui/icons-material/ArrowDropDown";
import CellTowerIcon from "@mui/icons-material/CellTower";
import BackgroundDataCtx from "../../context/BackgroundDataCtx/BackgroundDataCtx";
import {
  CircularProgress,
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Tooltip,
  Badge,
  SvgIcon,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import IQueue from "../../context/QueueCtx/IQueueCtx";
import QueueCtx from "../../context/QueueCtx/QueueCtx";
import { useContext } from "react";
import AppInstallCtx from "../../context/AppInstallCtx/AppInstallCtx";
import InstallMobileIcon from "@mui/icons-material/InstallMobile";
import ReinCloudLogoWhite from "../Icons/ReinCloudLogoWhite";
import ReinkeIconWhite from "../Icons/ReinkeIconWhite";

interface Props {
  UnitNav?: React.ReactNode;
}

const TopNav: React.FC<Props> = (props) => {
  const bgdata = useContext(BackgroundDataCtx);
  const [menuOpen, setMenuOpen] = React.useState<boolean>(false);
  const [filterOpen, setFilterOpen] = React.useState<boolean>(false);
  const [selectedProp, setSelectedProp] = React.useState<string | null>(null);
  const queue: IQueue = useContext(QueueCtx);
  const navigate = useNavigate();

  React.useEffect(() => {
    setSelectedProp(bgdata.propertyFilter);
  }, [bgdata.propertyFilter]);

  const appInstall = useContext(AppInstallCtx);

  const getMenuItem = (title: string, icon: React.JSX.Element, navigateTo: string): React.JSX.Element => {
    return (
      <ListItem
        key={title}
        onClick={() => {
          navigate(navigateTo);
          setMenuOpen(false);
        }}
      >
        <ListItemButton>
          <ListItemIcon>{icon}</ListItemIcon>
          <ListItemText primary={title} />
        </ListItemButton>
      </ListItem>
    );
  };

  const getGlobalTreeSelector = (): React.JSX.Element => {
    var list: React.JSX.Element[] = [];
    for (let propId in bgdata.properties) {
      list.push(
        <ListItem
          key={propId}
          onClick={() => {
            if (propId !== selectedProp) {
              bgdata.setPropertyFilter(propId);
              setSelectedProp(propId);
            } else {
              bgdata.setPropertyFilter(""); //show all if filter empty
              setSelectedProp(null);
            }
            setFilterOpen(false);
          }}
        >
          <ListItemButton
            style={{
              backgroundColor: selectedProp === propId ? "lightgray" : "transparent",
            }}
          >
            <ListItemText primary={propId} />
          </ListItemButton>
        </ListItem>
      );
    }
    return <List>{list}</List>;
  };

  return (
    <AppBar position="fixed" elevation={3}>
      <Toolbar sx={{ display: "flex", justifyContent: "space-between" }}>
        <div>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            onClick={() => setMenuOpen(!menuOpen)}
            sx={{ mr: 2 }}
          >
            <SvgIcon component={ReinkeIconWhite} inheritViewBox />
          </IconButton>
          <Drawer
            anchor={"left"}
            open={menuOpen}
            PaperProps={{
              sx: { width: 300, height: "60%" },
            }}
            onClose={() => setMenuOpen(false)}
          >
            <List>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                  padding: 10,
                  cursor: "pointer",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <AccountCircle style={{ marginRight: 10, fontSize: 30 }} />
                  <b>{bgdata.user.contact.displayName}</b>
                </div>

                <Tooltip title={`Log out from ${bgdata.user.contact.displayName}`}>
                  <LogoutIcon />
                </Tooltip>
              </div>
              <Divider style={{ marginTop: 20 }} />

              {getMenuItem("Unit List", <HomeIcon />, "/units")}
              {getMenuItem("Map", <MapIcon />, "/map")}
              {getMenuItem("Account", <AccountCircle />, "/account")}
              {getMenuItem("Groups", <GroupsIcon />, "/groups")}
              {appInstall?.beforeInstallEvent && (
                <ListItem
                  onClick={() => {
                    appInstall.beforeInstallEvent?.prompt();
                    setMenuOpen(false);
                  }}
                >
                  <ListItemButton>
                    <ListItemIcon>
                      <InstallMobileIcon />
                    </ListItemIcon>
                    <ListItemText primary="Install app" />
                  </ListItemButton>
                </ListItem>
              )}
            </List>
          </Drawer>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          {props.UnitNav ? (
            props.UnitNav
          ) : (
            <ReinCloudLogoWhite
              style={{
                width: 83,
                height: 24,
              }}
            />
          )}
          {/*<IconButton size="large" color="inherit">
            <RefreshIcon />
        </IconButton>*/}
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            {queue.tasksInProgress > 0 && <CircularProgress color="inherit" sx={{ mr: 1 }} size={20} />}
            <IconButton color="inherit" onClick={() => navigate(`/activity`)}>
              <Badge badgeContent={queue.tasksInProgress} color="secondary">
                <CellTowerIcon />
              </Badge>
            </IconButton>
          </div>
          {!selectedProp && (
            <IconButton size="large" color="inherit" onClick={() => setFilterOpen(true)}>
              <FilterIcon />
            </IconButton>
          )}
          {selectedProp && (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                cursor: "pointer",
              }}
              onClick={() => setFilterOpen(true)}
            >
              <span style={{ fontSize: 12 }}>{selectedProp}</span>
              <ArrowDropDown style={{ width: 20, height: 20 }} />
            </div>
          )}

          <Drawer
            anchor={"right"}
            open={filterOpen}
            PaperProps={{
              sx: { width: 300, height: "60%" },
            }}
            onClose={() => setFilterOpen(false)}
          >
            {getGlobalTreeSelector()}
          </Drawer>
        </div>
      </Toolbar>
    </AppBar>
  );
};

export default TopNav;
