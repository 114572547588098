import {
  Button,
  Fade,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  MenuList,
  Stack,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useMemo } from "react";
import IUnitData from "../../context/BackgroundDataCtx/IUnitData";
import { useNavigate } from "react-router-dom";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import StatusDot from "../ui/StatusDot";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

interface Props {
  currentUnit: IUnitData;
  units: IUnitData[];
}
const UnitNav: React.FC<Props> = ({ currentUnit, units }: Props) => {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const theme = useTheme();
  const isDesktopScreen = useMediaQuery(theme.breakpoints.up("md"));
  const isBelowSmScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const isAbove450px = useMediaQuery(theme.breakpoints.up(450));

  const sortedUnits = useMemo(() => {
    return [...units].sort((a, b) => a.name.localeCompare(b.name));
  }, [units]);

  const getUnitName = (name: string): string => {
    if (isBelowSmScreen && name.length > 12) {
      return name.slice(0, 12) + "...";
    }
    return name;
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleUnitClick = (newUnitId: string) => {
    navigate(`/units/${newUnitId}`);
    setAnchorEl(null);
  };

  const handleNavigation = (direction: -1 | 1) => {
    const unitIds = sortedUnits.map((unit) => unit.id);
    const currentUnitIndex = unitIds.indexOf(currentUnit.id);
    const nextIndex = (currentUnitIndex + direction + unitIds.length) % unitIds.length;
    navigate(`/units/${unitIds[nextIndex]}`);
  };
  return (
    <div>
      {isAbove450px && (
        <IconButton onClick={() => handleNavigation(-1)} sx={{ color: "white" }}>
          <NavigateBeforeIcon />
        </IconButton>
      )}
      <Button
        variant="outlined"
        sx={{
          color: "white",
          "&:hover": {
            backgroundColor: "rgba(255, 255, 255, 0.1)",
          },
        }}
        onClick={handleClick}
      >
        {getUnitName(currentUnit.name)}
        <ArrowDropDownIcon />
      </Button>
      {isAbove450px && (
        <IconButton onClick={() => handleNavigation(1)} sx={{ color: "white" }}>
          <NavigateNextIcon />
        </IconButton>
      )}
      <Menu anchorEl={anchorEl} open={open} onClose={() => setAnchorEl(null)} TransitionComponent={Fade}>
        <MenuList dense={isDesktopScreen}>
          {sortedUnits.map((unit) => {
            return (
              <MenuItem key={unit.id} selected={unit.id === currentUnit.id} onClick={() => handleUnitClick(unit.id)}>
                <ListItemIcon>
                  <StatusDot status={unit.connection} sizePx={14} />
                </ListItemIcon>
                <ListItemText>{unit.name}</ListItemText>
              </MenuItem>
            );
          })}
        </MenuList>
      </Menu>
    </div>
  );
};

export default UnitNav;
