import React, { useContext } from "react";
import IUnitData, {
  IVriSector,
} from "../../context/BackgroundDataCtx/IUnitData";
import {
  Divider,
  IconButton,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import CheckboxSwitchInput from "../ui/CheckboxSwitchInput";
import SpeedCheckboxInput from "./SpeedCheckboxInput";
import InteractiveInput from "../ui/InteractiveInput";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import Grid from "@mui/material/Unstable_Grid2";
import ExtendedUnitDataCtx from "../../context/ExtendedUnitDataCtx/ExtendedUnitDataCtx";
import SwapVertIcon from '@mui/icons-material/SwapVert';

interface Props {
  vriSectors: IVriSector[];
  onChange: (newSelectors: IVriSector[]) => void;
  unit: IUnitData;
}

const SectorEditor: React.FC<Props> = ({
  onChange,
  vriSectors,
  unit,
}: Props) => {
  const theme = useTheme();
  // const isScreenBelow450 = useMediaQuery(theme.breakpoints.down(400));
  const isScreenBelow450 = true

  const { capabilities } = useContext(ExtendedUnitDataCtx);


  const handleDeleteSector = (index: number) => {
    const newSectors = [...vriSectors];
    newSectors.splice(index, 1);
    onChange(newSectors);
  };

  return (
    <Grid container spacing={2}>
      {vriSectors.map((sector, index) => (
        <Grid container xs={12} key={index}>
          <Grid
            container
            xs={11}
            sm={3}
          >
            <Grid display={"flex"} alignItems={"flex-end"} xs={6} sm={12}>
              <InteractiveInput
                label="Start"
                id={`${index}-begin`}
                value={sector.begin}
                hideErrorHelperText={true}
                onChange={(newValue) => {
                  const newSectors = [...vriSectors];
                  newSectors[index].begin = newValue;
                  onChange(newSectors);
                }}
                unit={unit}
              />
            </Grid>
            <SwapVertIcon 
              style={{ color: 'gray', cursor: 'pointer', marginRight: 'auto', marginLeft: 'auto' }} 
              onClick={() => {
                const newSectors = [...vriSectors];
                const oldStart = newSectors[index].begin;
                newSectors[index].begin = newSectors[index].end;
                newSectors[index].end = oldStart;
                onChange(newSectors);
              }} />
            <Grid display={"flex"} alignItems={"flex-end"} xs={6} sm={12}>
              <InteractiveInput
                label="End"
                id={`${index}-end`}
                value={sector.end}
                hideErrorHelperText={true}
                onChange={(newValue) => {
                  const newSectors = [...vriSectors];
                  newSectors[index].end = newValue;
                  if(index < newSectors.length - 1){
                    newSectors[index + 1].begin = newValue;
                  }
                  onChange(newSectors);
                }}
                unit={unit}
              />
            </Grid>     
          </Grid>
          <Grid
            xs={1}
            sx={{
              display: {
                xs: "flex",
                sm: "none",
              },
            }}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <IconButton onClick={() => handleDeleteSector(index)}>
              <DeleteForeverIcon />
            </IconButton>
          </Grid>
          <Grid xs={12} sm={9} justifyContent={"center"} style={{ borderLeft: '1px solid lightgray'}}>
            <Stack
              direction={"row"}
              justifyContent={"space-between"}
              alignItems={"flex-end"}
              sx={{ mb: 1, width: "100%", }}
            >
              <Typography sx={{ pb: 1 }}>
                {isScreenBelow450 ? "For" : "Forward"}
              </Typography>
              <CheckboxSwitchInput
                disabled={!capabilities.controls.pump}
                label="Pump"
                value={sector.forward.pump}
                onChange={(newValue) => {
                  const newSectors = [...vriSectors];
                  newSectors[index].forward.pump = newValue;
                  if (newValue === -1) newSectors[index].forward.chem = -1;
                  onChange(newSectors);
                }}
              />
              <CheckboxSwitchInput
                disabled={
                  !capabilities.controls.chemPump || sector.forward.pump === -1
                }
                label="Chem"
                value={sector.forward.chem}
                onChange={(newValue) => {
                  const newSectors = [...vriSectors];
                  newSectors[index].forward.chem = newValue;
                  onChange(newSectors);
                }}
              />
              <SpeedCheckboxInput
                speedValue={sector.forward.speedpc}
                disabled={!capabilities.controls.speedpc}
                onChange={(newValue) => {
                  const newSectors = [...vriSectors];
                  newSectors[index].forward.speedpc = newValue;
                  onChange(newSectors);
                }}
              />
            </Stack>
            <Stack
              direction={"row"}
              justifyContent={"space-between"}
              alignItems={"flex-end"}
              sx={{ width: "100%" }}
            >
              <Typography sx={{ pb: 1 }}>
                {isScreenBelow450 ? "Rev" : "Reverse"}
              </Typography>
              <CheckboxSwitchInput
                disabled={!capabilities.controls.pump}
                value={sector.reverse.pump}
                onChange={(newValue) => {
                  const newSectors = [...vriSectors];
                  newSectors[index].reverse.pump = newValue;
                  if (newValue === -1) newSectors[index].reverse.chem = -1;
                  onChange(newSectors);
                }}
              />
              <CheckboxSwitchInput
                disabled={
                  !capabilities.controls.chemPump || sector.reverse.pump === -1
                }
                value={sector.reverse.chem}
                onChange={(newValue) => {
                  const newSectors = [...vriSectors];
                  newSectors[index].reverse.chem = newValue;
                  onChange(newSectors);
                }}
              />
              <SpeedCheckboxInput
                speedValue={sector.reverse.speedpc}
                disabled={!capabilities.controls.speedpc}
                onChange={(newValue) => {
                  const newSectors = [...vriSectors];
                  newSectors[index].reverse.speedpc = newValue;
                  onChange(newSectors);
                }}
              />
            </Stack>
          </Grid>
          {/* <Grid
            xs={1}
            sx={{
              display: {
                xs: "none",
                sm: "flex",
              },
            }}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <IconButton onClick={() => handleDeleteSector(index)}>
              <DeleteForeverIcon />
            </IconButton>
          </Grid> */}
          {vriSectors.length > 1 && index + 1 !== vriSectors.length && (
            <Grid xs={12}>
              <Divider />
            </Grid>
          )}
        </Grid>
      ))}
    </Grid>
  );
};

export default SectorEditor;
