import React, { FC } from "react";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";

interface Props {
  id: string;
  value: number;
  onChange: (newValue: number) => void;
  label: string;
  options: {
    value: number;
    label: string;
  }[];
}

const SelectEditor: FC<Props> = ({ id, value, onChange, label, options }) => {
  const handleChange = (event) => {
    const newValue = event.target.value as number;
    onChange(newValue);
  };

  return (
    <FormControl fullWidth>
      <InputLabel id={`${id}-label`}>{label}</InputLabel>
      <Select
        labelId={`${id}-label`}
        id={id}
        value={value}
        label={label}
        onChange={(event) => handleChange(event)}
      >
        {options.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default SelectEditor;
