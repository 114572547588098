import React, { useContext, useEffect, useState } from "react";
import QueueCtx from "../../context/QueueCtx/QueueCtx";
import Table from "@mui/material/Table";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import TableBody from "@mui/material/TableBody";
import { TableCell as MuiTableCell } from "@mui/material";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Box, Collapse, IconButton, Typography } from "@mui/material";
import { styled } from "@mui/system";

interface Row {
  queueName: string;
  tasks: string[];
}
const QueueActivityTable: React.FC = () => {
  const queue = useContext(QueueCtx);
  const [rows, setRows] = useState<Array<Row>>([]);
  const TableCell = styled(MuiTableCell)({
    width: "33%",
  });
  useEffect(() => {
    const ququeIds = queue.getQueueIds();
    const rows = ququeIds.map((queueId) => {
      return {
        queueName: queueId,
        tasks: queue.getQueueTaskIds(queueId),
      };
    });
    setRows(rows);
  }, [queue.tasksInProgress]);

  const Row = ({ row }: { row: Row }) => {
    const [open, setOpen] = React.useState(false);
    return (
      <>
        <TableRow
          key={row.queueName}
          sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
        >
          <TableCell component="th" scope="row">
            {row.queueName}
          </TableCell>
          <TableCell>{row.tasks.length}</TableCell>
          <TableCell>
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpen(!open)}
            >
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell sx={{ p: 0 }} colSpan={4}>
            <Collapse in={open} timeout={"auto"} unmountOnExit>
              <Table>
                <TableBody>
                  {row.tasks.map((task, index) => (
                    <TableRow key={index}>
                      <TableCell />
                      <TableCell />
                      <TableCell>{task}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Collapse>
          </TableCell>
        </TableRow>
      </>
    );
  };

  return (
    <Box sx={{ p: 1 }}>
      {rows?.length === 0 ? (
        <Typography textAlign={"center"}>No tasks in queue</Typography>
      ) : (
        <TableContainer component={Paper}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>Queue</TableCell>
                <TableCell>Tasks in queue</TableCell>
                <TableCell>Tasks</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows?.map((row) => (
                <Row key={row.queueName} row={row} />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </Box>
  );
};

export default QueueActivityTable;
