import * as React from "react";
import * as ReactDOM from "react-dom/client";

import CssBaseline from "@mui/material/CssBaseline";

import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

import Router from "./routes/Router";

import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import RswsApiCtxProvider from "./context/RswsApiCtx/RswsApiCtxProvider";
import LoginWrapper from "./components/LoginWrapper";
import BackgroundDataCtxProvider from "./context/BackgroundDataCtx/BackgroundDataCtxProvider";
import LocationDataCtxProvider from "./context/LocationDataCtx/LocationDataCtxProvider";
import QueueCtxProvider from "./context/QueueCtx/QueueCtxProvider";
import { ThemeProvider, createTheme } from "@mui/material";
import AppInstallCtxProvider from "./context/AppInstallCtx/AppInstallCtxProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers";
import UserSettingsCtxProvider from "./context/UserSettingsCtx/UserSettingsCtxProvider";
import MapLayersCtxProvider from "./context/MapLayersCtx/MapLayersCtxProvider";
import EventsApiCtxProvider from "./context/EventsApiCtx/EventsApiCtxProvider";
import GroupsApiCtxProvider from "./context/GroupsApiCtx/GroupsApiCtxProvider";
import MapRefCtxProvider from "./context/MapRefCtx/MapRefCtxProvider";
import { LicenseInfo } from '@mui/x-license';

LicenseInfo.setLicenseKey('325897d2c67538cbeb608c2ee7d5fad1Tz05MzY1MixFPTE3NTE2NDE1ODMwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=');

i18next
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    debug: true,
    resources: {
      en: {
        translation: {
          key: "hello world",
        },
      },
    },
  });

const theme = createTheme({
  palette: {
    mode: "light",
    contrastThreshold: 4.5,
    primary: {
      main: "#003da7",
    },
    secondary: {
      main: "#58595b",
    },
  },
});

ReactDOM.createRoot(document.getElementById("app")!).render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <AppInstallCtxProvider>
        <RswsApiCtxProvider>
          <LoginWrapper>
            <QueueCtxProvider>
              <MapRefCtxProvider>
                <UserSettingsCtxProvider>
                  <MapLayersCtxProvider>
                    <LocationDataCtxProvider>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <EventsApiCtxProvider>
                          <GroupsApiCtxProvider>
                            <BackgroundDataCtxProvider>
                              <Router />
                            </BackgroundDataCtxProvider>
                          </GroupsApiCtxProvider>
                        </EventsApiCtxProvider>
                      </LocalizationProvider>
                    </LocationDataCtxProvider>
                  </MapLayersCtxProvider>
                </UserSettingsCtxProvider>
              </MapRefCtxProvider>
            </QueueCtxProvider>
          </LoginWrapper>
        </RswsApiCtxProvider>
      </AppInstallCtxProvider>
    </ThemeProvider>
  </React.StrictMode>
);
