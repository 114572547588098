import { Stack, TextField, Button } from "@mui/material";
import React from "react";

interface Props {
  name: string;
  error: string | undefined;
  onChange: (newName: string) => void;
  handleSave: () => void;
}
const FinalizeEditor: React.FC<Props> = ({
  name,
  error,
  handleSave,
  onChange,
}: Props) => {
  return (
    <Stack gap={1}>
      <TextField
        id="name"
        label="Name your program"
        type="text"
        value={name || ""}
        error={error !== undefined}
        onChange={(e) => onChange(e.target.value)}
        autoComplete="off"
        fullWidth
      />
      <Button variant="contained" onClick={handleSave} fullWidth>
        Save
      </Button>
    </Stack>
  );
};

export default FinalizeEditor;
