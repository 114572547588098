export const feetToMeters = (feet: number): number => {
    return feet * 0.3048;
};

export const metersToFeet = (m: number): number => {
    return m * 3.28084;
};

export const mileToFeet = (nr: number): number => {
    return nr * 5280;
}

export const feetToMiles = (nr: number): number => {
    return nr / 5280;
}

export const mmToInches = (mm: number): number => {
    return mm / 25.4;
}

export const cmToInches = (cm: number): number => {
    return cm / 2.54;
}

export const inchesToMm = (inches: number): number => {
    return inches * 25.4;
}

export const inchesToCm = (inches: number): number => {
    return inches * 2.54;
}

export const cmMinToFtMin = (cmMin: number): number => {
    const feetPerCm = 1 / 30.48;
    return cmMin * feetPerCm;
}

export const ftMinToCmMin = (ftMin: number): number => {
    const cmPerFoot = 30.48;
    return ftMin * cmPerFoot;
}