import { circle, point } from "@turf/turf";
import { UnitData } from "../../context/BackgroundDataCtx/IBackgroundData";
import IUnitData from "../../context/BackgroundDataCtx/IUnitData";
import { FeatureCollection, Feature, Position } from "geojson";
import ILocationData from "../../context/LocationDataCtx/ILocationData";
import { buildProgramFeatures } from "./buildProgramFeatures";
import {
  buildUnitCoverageArea,
  buildUnitCoverageAreaZoomedOut,
  buildCurrentPosition,
  buildParkPosition,
  buildIrrigatedArea,
  buildOperationSymbols,
  buildEndgunAuxStatus,
  buildBadgeFeatures,
} from "./buildFeatureBase";

const buildGeoJson = (units: UnitData, userLocationData?: ILocationData, baseColor?: string): FeatureCollection => ({
  type: "FeatureCollection",
  features: [
    ...Object.values(units).flatMap((u) => {
      try {
        return buildUnitFeatures(u, baseColor);
      } catch (err) {
        return [];
  }}),
    ...buildUserLocationFeatures(userLocationData!),
  ],
});

const buildUserLocationFeatures = (userLocationData: ILocationData): Feature[] => {
  var features: Feature[] = [];

  if (userLocationData?.coords && !userLocationData.error) {
    features.push(
      buildGeoJsonForUserLocationPoint([userLocationData.coords.longitude, userLocationData.coords.latitude])
    );
    features.push(
      buildGeoJsonForUserLocationCircle(
        [userLocationData.coords.longitude, userLocationData.coords.latitude],
        userLocationData.coords.accuracy
      )
    );
  }

  return features;
};

const buildGeoJsonForUserLocationCircle = (coordinatesOfUser: Position, accuracy: number): Feature =>
  circle(coordinatesOfUser, accuracy, { units: "meters", properties: { type: "location-circle" } });
const buildGeoJsonForUserLocationPoint = (coordinatesOfUser: Position): Feature =>
  point(coordinatesOfUser, { title: "You are here", type: "location-point" });

const buildUnitFeatures = (unit: IUnitData, baseColor?: string): Feature[] => {
  var features: Feature[] = [];
  if (unit.latitude !== 0 && unit.longitude !== 0) {
    features.push(buildUnitCoverageArea(unit, baseColor));
    buildUnitCoverageAreaZoomedOut(unit).forEach((f) => features.push(f));
    const irrigatedArea = buildIrrigatedArea(unit);
    if (irrigatedArea) {
      features.push(irrigatedArea);
    }

    //if unit has programs loaded
    buildProgramFeatures(unit).forEach((pf) => features.push(pf));

    //draw current position
    if (unit.sensor["position"] && unit.sensor["position"].last && unit.sensor["position"].last.value) {
      buildCurrentPosition(unit).forEach((pf) => features.push(pf));
      buildOperationSymbols(unit).forEach((pf) => features.push(pf));
      buildEndgunAuxStatus(unit).forEach((pf) => features.push(pf));
    }
    const parkLine = buildParkPosition(unit);
    if (parkLine) {
      features.push(parkLine);
    }

    buildBadgeFeatures(unit).forEach((f) => features.push(f));
  }

  return features;
};

export default buildGeoJson;
