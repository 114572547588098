import * as React from "react";
import { Box, Button, Divider, ListItem, ListItemText, Modal, Stack, TextField, Typography } from "@mui/material";
import { IMapLayer } from "../../../context/MapLayersCtx/IMapLayer";
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { useState } from "react";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

interface Props {
    layer: IMapLayer;
    onLayerModify: (layer: IMapLayer) => void;
    onDelete: () => void;
    onItemClick: () => void;
    onEdit: () => void;
    active: boolean;
}

const LayerListItem: React.FC<Props> = (props: Props) => {

    const [opacityInEditMode, setOpacityInEditMode] = useState<boolean>(false);
    const [opacity, setOpacity] = useState<string>(props.layer.opacity.toString());

    return (
        <div 
            style={{
                display: 'flex', 
                flexDirection: 'row', 
                justifyContent: 'space-between', 
                alignItems: 'center',
                borderRadius: 5, 
                padding: 5, 
                minHeight: 52,
                backgroundColor: props.active ? '#ededeb' : 'white'}} 
            onClick={() => {
                if(!props.layer.readonly){
                    props.onItemClick();
                }
            }} 
        >
            <Stack direction="column">
                <b>{props.layer.displayName}</b>
                {
                    props.layer.mapboxLayerConfig && props.layer.mapboxLayerConfig.paint && props.layer.mapboxLayerConfig.paint['fill-color']
                    && props.layer.mapboxLayerConfig.paint['fill-color']['property'] &&
                    <Stack direction="row" style={{cursor: 'pointer'}} >
                        <span style={{color: 'gray', fontSize: 12, marginRight: 5}} >{props.layer.mapboxLayerConfig.paint['fill-color']['property']}</span>                  
                    </Stack>
                }

                
            </Stack>
            {
                !props.active && props.layer.mapboxLayerConfig && props.layer.mapboxLayerConfig.paint && props.layer.mapboxLayerConfig.paint['fill-color']
                && props.layer.mapboxLayerConfig.paint['fill-color']['stops'] &&
                <div style={{position: 'relative', width: 50, height: 10}}>
                    {
                        props.layer.mapboxLayerConfig.paint['fill-color']['stops'].map((stop, i) => 
                        <div style={{height: 16, width: 16, backgroundColor: stop[1], borderRadius: 8, position: 'absolute', left: i * 12, top :0, border: '1px solid white' }}></div>)
                    }
                </div>
            }
            <Stack direction="row" style={{color: 'gray', marginRight: 10, cursor: 'pointer', display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                {
                    !opacityInEditMode &&
                    <span style={{marginRight: 5}} onClick={() => {
                        setOpacityInEditMode(true);
                        setOpacity(props.layer.opacity.toString());
                    }}>{props.layer.opacity} %</span>
                }
                {
                    opacityInEditMode &&
                    <Stack direction="row" style={{marginRight: 5,}}>
                        <TextField 
                            size="small" 
                            variant="standard" 
                            focused={opacityInEditMode}                      
                            style={{maxWidth: 40, padding: 0, color: 'gray',}}
                            inputProps={{min: 0, max: 100, step: 1, style: { textAlign: 'right' }}} // the change is here
                            value={opacity}
                            onClick={(ev) => ev.stopPropagation()}
                            onChange={(ev) => setOpacity(ev.target.value)}
                            onBlur={() => {
                                setOpacityInEditMode(false);
                                const o = parseInt(opacity);
                                if(!isNaN(o) && o >= 0 && o <= 100){
                                    props.onLayerModify({...props.layer, opacity: o});
                                }
                            }} 
                        />
                        <span>%</span>
                    </Stack>
                }
                {
                    props.layer.show &&
                    <VisibilityIcon onClick={(ev) => {
                        props.onLayerModify({...props.layer, show: false});
                        ev.stopPropagation();
                        }}/>

                }
                {
                    !props.layer.show &&
                    <VisibilityOffIcon onClick={(ev) =>{ props.onLayerModify({...props.layer, show: true});
                        ev.stopPropagation();
                    }} />                        
                }
            </Stack>
            {props.active && 
            <Stack direction="row">
                <EditIcon style={{marginRight: 5, cursor: 'pointer'}} onClick={() => props.onEdit()} />
                <DeleteIcon style={{/*color: '#d32f2f', */cursor: 'pointer'}} onClick={() => props.onDelete()} />
            </Stack>}
        </div>
    );
}

export default LayerListItem;

