import * as React from "react";
import EventsApiCtx from "./EventsApiCtx";
import { IEvent } from "../../components/UnitEvents/createEvent";

const EventsApiCtxProvider: React.FC<React.PropsWithChildren> = (props) => {
  const getEvents = (unitId: string, userId: string): IEvent[] => {
    const events: IEvent[] = JSON.parse(
      localStorage.getItem(`events-${userId}-${unitId}`) || "[]"
    );
    events.sort((a, b) => b.eventStartTimeMs - a.eventStartTimeMs);
    return events;
  };

  const save = (events: IEvent[], unitId: string, userId: string): void => {
    localStorage.setItem(`events-${userId}-${unitId}`, JSON.stringify(events));
  };

  const saveEvent = (
    event: IEvent,
    unitId: string,
    userId: string
  ): IEvent[] => {
    const updatedEvents = [...getEvents(unitId, userId), event];
    save(updatedEvents, unitId, userId);
    return updatedEvents;
  };

  const deleteEvent = (
    event: IEvent,
    unitId: string,
    userId: string
  ): IEvent[] => {
    const updatedEvents = getEvents(unitId, userId).filter(
      (e) => e.eventName !== event.eventName
    );
    save(updatedEvents, unitId, userId);
    return updatedEvents;
  };

  return (
    <EventsApiCtx.Provider value={{ getEvents, saveEvent, deleteEvent }}>
      {props.children}
    </EventsApiCtx.Provider>
  );
};
export default EventsApiCtxProvider;
