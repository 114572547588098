import { Feature, FeatureCollection, Position } from "geojson";
import { GeoJSONSource } from "mapbox-gl";
import IUnitData from "../../context/BackgroundDataCtx/IUnitData";
import { buildSpeedIndLineString } from "./buildFeatureBase";

export const addSpeedRing = (unit: IUnitData, currentMap: mapboxgl.Map, animationRef: any, source: GeoJSONSource) => {
  const { linePattern, gap } = getPatternAndGap(unit!);
  const patternAndGapLength = linePattern.reduce((a, b) => a + b + gap, 0);
  const coordsFullLineStr = buildSpeedIndLineString(unit!, patternAndGapLength).geometry["coordinates"].map(
    (innerArray: number[]) => [...innerArray]
  ) as number[][];

  if (!unit.unitState.running || unit.unitState.speedpc === 0) {
    addSolidRing(unit, source, coordsFullLineStr);
  } else {
    if (unit!.unitState.direction === "Forward") {
      coordsFullLineStr.reverse();
    }
    // Repeat the line pattern to cover the line/circle
    const repeatedLinePattern = Array(Math.trunc(coordsFullLineStr.length / patternAndGapLength))
      .fill(linePattern)
      .flat();

    const geojson = {
      type: "FeatureCollection",
      features: repeatedLinePattern.map((x) => createLineStringFeature(unit, coordsFullLineStr)),
    } as FeatureCollection;

    source.setData(geojson);

    const animateSpeedRing = (timestamp: number) => {
      // divide timestamp by larger value to slow down animation
      const nextStep = Math.trunc((timestamp / 10) % coordsFullLineStr.length);

      let prevOffset = 0;
      for (let i = 0; i < repeatedLinePattern.length; i++) {
        const offset = prevOffset + (i !== 0 ? repeatedLinePattern[i - 1] + gap : 0);
        let startIndex = (nextStep + offset) % coordsFullLineStr.length;
        let endIndex = ((nextStep + repeatedLinePattern[i] + offset) % coordsFullLineStr.length) + 1;
        let lineCoords: number[][] = [];

        // when line ends / breaks north
        if (startIndex > endIndex) {
          if (unit.systemType === "Lateral") {
            lineCoords = coordsFullLineStr.slice(startIndex);
            // for lateral we need to create an extra line feature to continue the pattern
            geojson.features.push(createLineStringFeature(unit, coordsFullLineStr.slice(0, endIndex)));
          } else {
            lineCoords = coordsFullLineStr.slice(startIndex).concat(coordsFullLineStr.slice(0, endIndex));
          }
        } else {
          lineCoords = coordsFullLineStr.slice(startIndex, endIndex);
        }

        geojson.features[i].geometry["coordinates"] = lineCoords;
        prevOffset = offset;
      }
      source.setData(geojson);

      // Remove the broken line feature
      if (geojson.features.length > repeatedLinePattern.length) {
        geojson.features.pop();
      }

      // Request the next frame of the animation.
      animationRef.current = requestAnimationFrame(animateSpeedRing);
    };
      animateSpeedRing(0);
  }
};

const createLineStringFeature = (unit: IUnitData, coords: Position[]): Feature => {
  return {
    type: "Feature",
    geometry: { type: "LineString", coordinates: coords },
    properties: { type: "speed", title: unit.name },
  };
};

const getPatternAndGap = (unit: IUnitData): { linePattern: number[]; gap: number } => {
  let unitLengthFactor = Math.round(4 * (unit!.lengthFeet / 1000) ** 0.4);
  if (unit.systemType === "Lateral") {
    unitLengthFactor = unitLengthFactor * 4;
  }
  const speedpc = unit.unitState.speedpc ?? 0;
  const dot = Math.round(0.7 * unitLengthFactor);
  const dash = 12 * unitLengthFactor;
  const longDash = 36 * unitLengthFactor;
  const gap = 4 * unitLengthFactor;

  if (speedpc > 0 && speedpc < 25) {
    return { linePattern: Array(5).fill(longDash), gap };
  } else if (speedpc >= 25 && speedpc < 50) {
    return { linePattern: [longDash, dash, longDash, dash, longDash, dash], gap };
  } else if (speedpc >= 50 && speedpc < 75) {
    return { linePattern: [dot, dot, dot, dot, dash, dot, dot, dot, dot, dash], gap };
  } else if (speedpc >= 75 && speedpc < 100) {
    return { linePattern: [...Array(10).fill(dot), dash], gap };
  } else {
    // 100%
    // todo max speed of lateral is 90% handle???
    return { linePattern: Array(10).fill(dot), gap };
  }
};

const addSolidRing = (unit: IUnitData, source: GeoJSONSource, coords: Position[]) => {
  source.setData({
    type: "FeatureCollection",
    features: [createLineStringFeature(unit, coords)],
  });
};
