import IUnitData from "../context/BackgroundDataCtx/IUnitData";
import { ILoadUnitResp, IUnitSettings } from "../context/RswsApiCtx/IRswsApi";
import { mmToInches } from "./Conversions";

export const getDepthInch = (unit: IUnitData, speed: number, maxSpeed: number): number | undefined => {
    const appliedDepth = unit.sensor.appliedDepth?.last?.value;
    const minDepth = unit.sensor.minDepth?.last?.value;
    if (appliedDepth) {
        return unit.isUnitOfMeasureMetric ? mmToInches(appliedDepth) : appliedDepth;
    } else if (minDepth) {
        const depth = (maxSpeed / speed) * minDepth;
        return unit.isUnitOfMeasureMetric ? mmToInches(depth) : depth;
    } else {
        return undefined;
    }
};

export const canCalcDepth = (unit: IUnitData): boolean => !!(unit.sensor.appliedDepth?.last?.value || unit.sensor.minDepth?.last?.value);

export const getSpeedPcFromDepth = (unit: IUnitData, newDepth: number, maxSpeed: number): number | undefined => {
    let minDepth = unit.sensor.minDepth?.last?.value;
    if (!minDepth) return;
    if (unit.isUnitOfMeasureMetric) {
        minDepth = mmToInches(minDepth);
    }
    return Math.round(minDepth / newDepth * maxSpeed * 10000) / 10000; // round to 4 decimal places
}

export const isPivot = (unit: ILoadUnitResp): boolean => {
    const sensor = unit.sensors.find((sensor) => sensor.typeId === 8248);
    return sensor?.last?.value === 1;
}

export const speedPcToFtMin = (speedPc: number, unit: IUnitData, unitSettings: IUnitSettings): number => {
    if (unit.systemType === "Pivot") {
        const hoursPerRev = getActualHoursPerRev(speedPc, unit);
        return calculateCircumferenceFeet(unit.lengthFeet) / (hoursPerRev * 60);
    } else {
        const fph = calculateMaxFph(unit, unitSettings) * (speedPc / 90);
        return fph / 60;
    }
}

export const getActualHoursPerRev = (speedPc: number, unit: IUnitData): number => {
    let hoursPerRev = unit.settingsOEM.hoursPerRevolution;
    if (Number.isInteger(unit.settingsOEM.minsPerRevolution)) {
        hoursPerRev += unit.settingsOEM.minsPerRevolution / 60;
    }
    return hoursPerRev / (speedPc / 100);
}

export const ftMinToSpeedPc = (ftMin: number, unit: IUnitData, unitSettings: IUnitSettings): number => {
    if (unit.systemType === "Pivot") {
        const hoursPerRev = calculateCircumferenceFeet(unit.lengthFeet) / (ftMin * 60);
        return getSpeedPcFromHoursPerRev(hoursPerRev, unit);
    } else {
        // lateral
        return ((ftMin * 60) / calculateMaxFph(unit, unitSettings)) * 90;
    }
}

export const getSpeedPcFromHoursPerRev = (hoursPerRev: number, unit: IUnitData): number => {
    let baseHoursPerRev = unit.settingsOEM.hoursPerRevolution;
    if (Number.isInteger(unit.settingsOEM.minsPerRevolution)) {
        baseHoursPerRev += unit.settingsOEM.minsPerRevolution / 60;
    }
    return (baseHoursPerRev / hoursPerRev) * 100;
}

const calculateMaxFph = (unit: IUnitData, unitSettings: IUnitSettings): number => {
    return unit.isUnitOfMeasureMetric ? unitSettings.feetperhour! * 3.28084 : unitSettings.feetperhour!;
}

const calculateCircumferenceFeet = (radius: number): number => {
    return 2 * Math.PI * radius;
}

