import { Feature, FeatureCollection } from "geojson";
import IBackgroundData from "../../context/BackgroundDataCtx/IBackgroundData";
import IUnitData, { IUnitState } from "../../context/BackgroundDataCtx/IUnitData";
import { getCoordinatesOnCircleFromAngle, getLateralRectPointsForUnit } from "../Map/MapHelper";
import { feetToMeters } from "../../helpers/Conversions";
import dayjs from "dayjs";

export interface IEvent {
  eventName: string;
  eventStartTimeMs: number;
  eventEndTimeMs: number;
  sectors: ISector[];
  eventFeatures?: FeatureCollection;
}

export interface ISector {
  sectorStartTimeMS: number;
  sectorEndTimeMS: number;
  begin: number;
  end: number;
  state: IUnitState;
}

interface ISensorChange {
  sensor: string;
  timeStamp: number;
  value: number | string | boolean;
}

interface ISensorHistory {
  sensorName: string;
  values: [number, number][];
}

interface ISensorStates {
  running: boolean;
  direction: "Forward" | "Reverse";
  speedpc: number;
  pump: boolean;
  chemPump: boolean;
  aux1: boolean;
  endGun: boolean;
  position: number;
}

const sensorNames = [
  "running",
  "direction",
  "speedpc",
  "pump",
  "chemPump",
  "aux1",
  "endGun",
  "position",
];

const deepClone = <T>(obj: T): T => {
  return JSON.parse(JSON.stringify(obj));
}


export const createEvent = async (unit: IUnitData, initEvent: IEvent, bgdata: IBackgroundData): Promise<IEvent> => {
  const historicalData = await getHistoricalSensorsData(initEvent.eventStartTimeMs, initEvent.eventEndTimeMs, unit, bgdata);
  const initialState = await getInitialState(initEvent.eventStartTimeMs, unit, bgdata);
  const sensorsChange = getSensorsChange(historicalData);
  const sectors = createSectors(sensorsChange, initialState, initEvent);
  const cloneSector: ISector[] = deepClone(sectors);
  const eventFeatures = createEventFeatureCollection({ ...initEvent, sectors: cloneSector }, unit)
  return {
    ...initEvent,
    sectors,
    eventFeatures
  }
};

const createEventFeatureCollection = (event: IEvent, unit: IUnitData): FeatureCollection => {
  const { sectors, eventFeatures, ...restOfEvent } = event

  const features: Feature[] = sectors.reduce((acc: Feature[], sect) => {
    const { sectorStartTimeMS, sectorEndTimeMS, state } = sect;

    if (state.direction === "Reverse") {
      const temp = sect.begin;
      sect.begin = sect.end;
      sect.end = temp;
    }

    // shapefile keys are limited to 10 characters
    const featureProps = {
      eventName: restOfEvent.eventName,
      eStartTS: dayjs(restOfEvent.eventStartTimeMs).format("YYYY-MM-DD HH:mm:ss"),
      eEndTS: dayjs(restOfEvent.eventEndTimeMs).format("YYYY-MM-DD HH:mm:ss"),
      seStartTS: dayjs(sectorStartTimeMS).format("YYYY-MM-DD HH:mm:ss"),
      seEndTS: dayjs(sectorEndTimeMS).format("YYYY-MM-DD HH:mm:ss"),
      ...state
    }

    if (unit.systemType === "Pivot" && sect.begin > sect.end) {
      //when the segment overlaps 0 degree we split it in 2 
      const feature1 = createFeature(unit, featureProps, { ...sect, end: 360 });
      if (sect.end === 0) {
        return [...acc, feature1] as Feature[];
      } else {
        const feature2 = createFeature(unit, featureProps, { ...sect, begin: 0 });
        return [...acc, feature1, feature2] as Feature[];
      }
    } else {
      return [...acc, createFeature(unit, featureProps, sect)] as Feature[];
    }
  }, [] as Feature[]);
  return {
    type: "FeatureCollection",
    features: features
  }
}

const createFeature = (unit: IUnitData, properties: any, sector: ISector): Feature => {
  return {
    type: "Feature",
    geometry: {
      type: "Polygon",
      coordinates: [buildPolygonPoints(unit, sector)]
    },
    properties
  }
}

const buildPolygonPoints = (unit: IUnitData, sector: ISector): number[][] => {
  if (unit.systemType === "Pivot") {
    return buildPolygonPointsPivot(unit, sector);
  } else {
    return buildPolygonPointsLateral(unit, sector);
  }
}

const buildPolygonPointsPivot = (unit: IUnitData, sector: ISector): number[][] => {
  const points: number[][] = [];
  points.push([unit.longitude, unit.latitude]);
  for (let i = Math.round(sector.begin); i <= Math.round(sector.end); i++) {
    if (sector.end - sector.begin < 1) {
      // if sector is less than 1 degree, add cords on the circle at the middle of the sector
      // TODO handle if begin === end. Draw a line? 
      points.push(getCoordinatesOnCircleFromAngle([unit.longitude, unit.latitude], feetToMeters(unit.lengthFeet), (sector.begin + sector.end) / 2));
    }
    points.push(getCoordinatesOnCircleFromAngle([unit.longitude, unit.latitude], feetToMeters(unit.lengthFeet), i));
  }

  points.push([unit.longitude, unit.latitude]);
  return points;
}

const buildPolygonPointsLateral = (unit: IUnitData, sector: ISector): number[][] => {
  const rect1 = getLateralRectPointsForUnit(unit, feetToMeters(sector.begin));
  const rect2 = getLateralRectPointsForUnit(unit, feetToMeters(sector.end));
  return [rect1[3], rect1[2], rect2[2], rect2[3], rect1[3]]
}


const createSectors = (sensorChanges: ISensorChange[], initialState: ISensorStates, initEvent: IEvent): ISector[] => {
  const sectors: ISector[] = [];
  let newState: ISensorStates = deepClone(initialState);
  let prevState: ISensorStates | null = null;
  let sector: ISector | null = null;

  // Finalize a sector and push it to the sectors array
  const finalizeSector = (timeStamp: number) => {
    if (sector !== null && prevState !== null) {
      sector.end = prevState.position;
      sector.sectorEndTimeMS = timeStamp;
      const {position, ...rest} = prevState;
      sector.state = rest
      sectors.push(sector);
      sector = null;
    }
  };

  // Initiate a new sector
  const initiateNewSector = (change: ISensorChange) => {
    const { position, ...state } = newState;
    sector = {
      sectorStartTimeMS: change.timeStamp,
      sectorEndTimeMS: NaN,
      begin: position,
      end: NaN,
      state: state
    };
  };

  // Handle the initial sector if the initial state is running
  if (initialState.running) {
    const { position, ...state } = initialState;
    sector = {
      sectorStartTimeMS: initEvent.eventStartTimeMs,
      sectorEndTimeMS: NaN,
      begin: initialState.position,
      end: NaN,
      state: state
    };
    prevState = {position, ...state};
  }

  // Process each sensor change
  // Doesnt break sector when it passes north. However when geojson is built this is done. 
  for (let i = 0; i < sensorChanges.length; i++) {
    const change = sensorChanges[i];
    newState = { ...newState, [change.sensor]: change.value };

    if (change.sensor !== "position") {
      if (change.sensor === "running" && change.value === false) {
        finalizeSector(change.timeStamp);
        prevState = { ...newState };
        continue;
      }

      if (newState.running) {
        // A sensor change happened while the unit is running
        if (sector === null) {
          initiateNewSector(change);
        }
        if (sector?.begin !== newState.position) {
          // if position changed, finalize the sector and initiate a new one
          finalizeSector(change.timeStamp);
          initiateNewSector(change);
        }
      }
    }

    if (i === sensorChanges.length - 1) {
      finalizeSector(initEvent.eventEndTimeMs);
    }

    prevState = { ...newState };
  }
  return sectors;
};

const getHistoricalSensorsData = async (from: number, to: number, unit: IUnitData, bgdata: IBackgroundData): Promise<ISensorHistory[]> => {
  return await Promise.all(
    sensorNames.map(async (sensorName) => {
      const sensor = unit.sensor[sensorName];
      const sensorHistory = await bgdata.getSensorData(
        unit,
        sensor,
        from.toString(),
        to.toString()
      );

      return {
        sensorName,
        values: sensorHistory.sensorData.values,
      } as ISensorHistory;
    })
  );
}

/**
 * Get sensor changes from sensor history data. Build an array of sensor changes. And sort them by timestamp.
 *
 * @param histData - The sensor history data.
 * @returns An array of sensor changes.
 */
const getSensorsChange = (histData: ISensorHistory[]): ISensorChange[] => {
  const result: ISensorChange[] = [];
  for (const sensor of histData) {
    let previousValue: number | null = null;
    for (const [timestamp, value] of sensor.values) {
      if (previousValue !== value) {
        previousValue = value;

        result.push({
          sensor: sensor.sensorName,
          timeStamp: timestamp,
          value: transformToUnitStateValue(sensor.sensorName, value),
        });
      }
    }
  }
  return result.sort((a, b) => a.timeStamp - b.timeStamp);
};

/**
 * Retrieves the initial state before an event from sensor historic sensor data.
 */
const getInitialState = async (eventStart: number, unit: IUnitData, bgdata: IBackgroundData): Promise<ISensorStates> => {
  const ONE_WEEK_MS = 1000 * 60 * 60 * 24 * 7;
  const to = eventStart;
  let from = eventStart - ONE_WEEK_MS;
  let sensorData = {} as ISensorStates;
  let iteration = 1;
  while (sensorNames.some((sensorName) => sensorData[sensorName] === undefined)) {
    const historicalData = await getHistoricalSensorsData(from, to, unit, bgdata);
    historicalData.forEach((sensor) => {
      const lastValue = sensor.values[sensor.values.length - 1][1]
      if (sensorData[sensor.sensorName] === undefined && lastValue !== undefined) {
        sensorData[sensor.sensorName] = transformToUnitStateValue(sensor.sensorName, lastValue);
      }
    });

    from = from - ONE_WEEK_MS * iteration;
    iteration++;
    if (iteration > 20) throw new Error("Could not get initial state");
  }

  return sensorData;
}

/**
 * Transforms sensor raw value into a more readable value, aligned with IUnitState, based on the key.
 * @param key - The key of the pair.
 * @param value - The value of the pair.
 * @returns The transformed value.
 */
const transformToUnitStateValue = (key: string, value: number): boolean | number | string => {
  if (key === "direction") {
    return value === 1 ? "Reverse" : "Forward";
  } if (key === "speedpc" || key === "position") {
    return value;
  } else {
    return value === 1;
  }
}