import * as React from "react";
import { useContext, useState } from "react";
import { useParams } from "react-router-dom";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Map, { MapDrawingMode } from "../../components/Map";
import BackgroundDataCtx from "../../context/BackgroundDataCtx/BackgroundDataCtx";
import { MapInteractionCallback } from "../../context/MapInteractionCtx/IMapWithInteraction";
import MapInteractionCtx from "../../context/MapInteractionCtx/MapInteractionCtx";
import InfoBar from "./InfoBar";
import ActionPanel from "./ActionPanel";

const ControlsEditor: React.FC = () => {
  const { unitId } = useParams();
  if (!unitId) return <>Unit not recognised</>;
  const bgdata = useContext(BackgroundDataCtx);
  const unit = bgdata.units[unitId];
  const [mapInEditMode, setMapEditModeStatus] = useState<MapDrawingMode>(
    MapDrawingMode.Disabled
  );
  const mapEditCallback = React.useRef<MapInteractionCallback>();

  const beginDrawPoint = (callback: MapInteractionCallback) => {
    setMapEditModeStatus(MapDrawingMode.DrawClosest);
    mapEditCallback.current = callback;
  };

  const beginContinuousLocationDraw = (callback: MapInteractionCallback) => {
    setMapEditModeStatus(MapDrawingMode.ContinuousUserLocation);
    mapEditCallback.current = callback;
  };


  return (
    <Stack
      direction={{ xs: "column", md: "row" }}
      alignItems="stretch"
      sx={{
        height: "100%",
      }}
    >
      <Box sx={{ flexGrow: 1, position: "relative" }}>
        <InfoBar unit={unit} />
        <Map
          unitId={unitId}
          interactiveMode={mapInEditMode}
          onExitInteractiveMode={(calcPos, realPos, angle) => {
            setMapEditModeStatus(MapDrawingMode.Disabled);
            mapEditCallback.current!(calcPos, realPos, angle);
          }}
        />
      </Box>
      <MapInteractionCtx.Provider
        value={{
          beginDrawPoint: beginDrawPoint, beginDrawContinuousLocation: beginContinuousLocationDraw
        }}
      >
        <ActionPanel/>
      </MapInteractionCtx.Provider>
    </Stack>
  );
};

export default ControlsEditor;

