import * as React from "react";
import IUserSettings, { UnitColorStatus } from "./IUserSettings";
import UserSettingsCtx from "./UserSettingsCtx";

const UserSettingsCtxProvider: React.FC<React.PropsWithChildren> = (props) => {


  let userSettings: IUserSettings = {
    statusColors: []
  }; //later this could be configurable

  userSettings.statusColors[UnitColorStatus.Wet] = "#0000ff"; //blue
  userSettings.statusColors[UnitColorStatus.Dry] = "#dec362"; //yellow
  userSettings.statusColors[UnitColorStatus.Chemigation] = "#2a781e"; //green
  userSettings.statusColors[UnitColorStatus.Fertigation] = "#c20f0f"; //red
  userSettings.statusColors[UnitColorStatus.Effluent] = "#4f2a0a"; //brown
  userSettings.statusColors[UnitColorStatus.Overwater] = "#0000ff"; //blue
  userSettings.statusColors[UnitColorStatus.Offline] = "#787878"; //gray
  userSettings.statusColors[UnitColorStatus.Error] = "#ff0000"; //red
  userSettings.statusColors[UnitColorStatus.Default] = "#96d48e"; //greenPastel

  return (
    <UserSettingsCtx.Provider value={userSettings}>
      {props.children}
    </UserSettingsCtx.Provider>
  );
};

export default UserSettingsCtxProvider;

