import {
  Stack,
  IconButton,
  ListItemText,
  Menu,
  MenuItem,
  MenuList,
  ListItemIcon,
  Tooltip,
  ClickAwayListener,
  Typography,
  Box,
  Button,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import LayersIcon from "@mui/icons-material/Layers";

import React, { useEffect, useState } from "react";
import { IMapLayer } from "../../context/MapLayersCtx/IMapLayer";
import AddEditLayerModal from "./layers/AddEditLayerModal";
import LayerSelectorModal from "./layers/LayerSelectorModal";
import { IGroup } from "../../context/GroupsApiCtx/IGroupsApi";
import GroupsApiCtx from "../../context/GroupsApiCtx/GroupsApiCtx";
import GroupsIcon from "@mui/icons-material/Groups";
import EventIcon from "@mui/icons-material/Event";
import UnitEventsDialog from "../UnitEvents/UnitEventsDialog";
import IUnitData from "../../context/BackgroundDataCtx/IUnitData";
import IBackgroundData from "../../context/BackgroundDataCtx/IBackgroundData";
import LocationDataCtx from "../../context/LocationDataCtx/LocationDataCtx";
import FmdBadIcon from "@mui/icons-material/FmdBad";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import DisabledByDefaultIcon from "@mui/icons-material/DisabledByDefault";
import CommandsDialog from "../../routes/UnitList/CommandsDialog";
import UnitsList from "../ui/UnitsList";

interface Props {
  unit: IUnitData | null;
  bgdata: IBackgroundData;
  flyToGroup: (group: IGroup) => void;
  setSelectMode: (mode: boolean) => void;
  isSelectMode: boolean;
}

const MapMenu: React.FC<Props> = ({ flyToGroup, unit, bgdata, setSelectMode, ...props }: Props) => {
  const groupApi = React.useContext(GroupsApiCtx);
  const groups = groupApi.getGroups(bgdata.user.name);
  const { error: locationError } = React.useContext(LocationDataCtx);
  const selectedUnits = React.useMemo(() => Object.values(bgdata.selectedUnits ?? {}), [bgdata.selectedUnits]);
  const [isSelectMode, setIsSelectMode] = useState<boolean>(props.isSelectMode);
  const [openCommadsDialog, setOpenCommandsDialog] = React.useState(false);

  useEffect(() => {
    setIsSelectMode(props.isSelectMode);
  }, [props.isSelectMode]);

  const [groupMenuAnchorEl, setGroupMenuAnchorEl] = React.useState<null | HTMLElement>(null);
  const [moreActionsMenuAnchorEl, setMoreActionsMenuAnchorEl] = React.useState<null | HTMLElement>(null);
  const [layersModalOpen, setLayersModalOpen] = useState<boolean>(false);
  const [addLayerModalOpen, setAddLayersModalOpen] = useState<boolean>(false);
  const [editingLayer, setEditingLayer] = useState<IMapLayer | undefined>(undefined);
  const [openEventsModal, setOpenEventsModal] = useState<boolean>(false);
  const [openLocationErrorTooltip, setOpenLocationErrorTooltip] = useState<boolean>(false);
  const handleTooltipClose = () => {
    setOpenLocationErrorTooltip(false);
  };

  return (
    <>
      <Stack direction={"row"} spacing={1} alignItems={"center"} sx={{ position: "absolute", top: 20, right: 20 }}>
        {locationError && (
          <ClickAwayListener onClickAway={handleTooltipClose}>
            <Tooltip
              PopperProps={{
                disablePortal: true,
              }}
              disableFocusListener
              disableHoverListener
              disableTouchListener
              open={openLocationErrorTooltip}
              title={locationError}
              onClick={() => setOpenLocationErrorTooltip(true)}
            >
              <FmdBadIcon color="error" />
            </Tooltip>
          </ClickAwayListener>
        )}

        <IconButton
          style={{
            backgroundColor: "white",
            borderRadius: 5,
            cursor: "pointer",
          }}
          onClick={() => {
            setSelectMode(!isSelectMode);
            setIsSelectMode(!isSelectMode);
          }}
        >
          {isSelectMode ? (
            <DisabledByDefaultIcon style={{ color: "black" }} />
          ) : (
            <CheckBoxIcon style={{ color: "black" }} />
          )}
        </IconButton>
        <IconButton
          style={{
            backgroundColor: "white",
            borderRadius: 5,
            cursor: "pointer",
          }}
          onClick={() => setLayersModalOpen(true)}
        >
          <LayersIcon style={{ color: "black" }} />
        </IconButton>
        <IconButton
          style={{
            backgroundColor: "white",
            borderRadius: 5,
            cursor: "pointer",
          }}
          onClick={(e) => {
            setMoreActionsMenuAnchorEl(e.currentTarget);
          }}
        >
          <MoreVertIcon style={{ color: "black" }} />
        </IconButton>
      </Stack>
      {isSelectMode && (
        <Box
          sx={{
            position: "absolute",
            top: 80,
            right: 20,
            backgroundColor: "white",
            borderRadius: 1,
            boxShadow: 1,
            py: 1,
          }}
        >
          <Box sx={{ px: 2 }}>
            <Typography variant="h6" color="textSecondary">
              Select mode
            </Typography>
            <Typography variant="body2" color="textSecondary">
              Click on units to select them
            </Typography>
          </Box>
          {selectedUnits.length > 0 && (
            <>
              <UnitsList
                units={selectedUnits}
                deleteUnit={(unitId) => {
                  bgdata.setSelectedUnits((prev) => {
                    const newSelectedUnits = { ...prev };
                    delete newSelectedUnits[unitId];
                    return newSelectedUnits;
                  });
                }}
              />
              <Button fullWidth sx={{ mt: 1, }} onClick={() => setOpenCommandsDialog(true)}>
                Group Commands
              </Button>
            </>
          )}
        </Box>
      )}
      {layersModalOpen && (
        <LayerSelectorModal
          open={layersModalOpen}
          onClose={() => setLayersModalOpen(false)}
          openAddNewLayerModal={() => setAddLayersModalOpen(true)}
          openLayerForEdit={(l) => setEditingLayer(l)}
        />
      )}
      {addLayerModalOpen && <AddEditLayerModal open={addLayerModalOpen} onClose={() => setAddLayersModalOpen(false)} />}
      {editingLayer !== undefined && (
        <AddEditLayerModal
          open={editingLayer !== undefined}
          onClose={() => setEditingLayer(undefined)}
          editingLayer={editingLayer}
        />
      )}
      <Menu
        anchorEl={moreActionsMenuAnchorEl}
        open={Boolean(moreActionsMenuAnchorEl)}
        onClose={() => setMoreActionsMenuAnchorEl(null)}
      >
        <MenuList dense>
          <MenuItem disabled={!unit} onClick={(e) => setOpenEventsModal(true)}>
            <ListItemIcon>
              <EventIcon />
            </ListItemIcon>
            <ListItemText>Events</ListItemText>
          </MenuItem>
          <MenuItem disabled={groups.length === 0} onClick={(e) => setGroupMenuAnchorEl(e.currentTarget)}>
            <ListItemIcon>
              <GroupsIcon />
            </ListItemIcon>
            <ListItemText>Go to group</ListItemText>
          </MenuItem>
        </MenuList>
      </Menu>
      <Menu
        anchorEl={groupMenuAnchorEl}
        open={Boolean(groupMenuAnchorEl)}
        onClose={() => {
          setMoreActionsMenuAnchorEl(null);
          setGroupMenuAnchorEl(null);
        }}
      >
        <MenuList dense>
          {groups.map((g) => {
            return (
              <MenuItem key={g.id} onClick={() => flyToGroup(g)}>
                <ListItemText>{g.name}</ListItemText>
              </MenuItem>
            );
          })}
        </MenuList>
      </Menu>
      {unit !== null && (
        <UnitEventsDialog
          unit={unit}
          bgdata={bgdata}
          onClose={() => setOpenEventsModal(false)}
          isOpen={openEventsModal}
        />
      )}

      <CommandsDialog
        open={openCommadsDialog}
        onClose={() => {
          setOpenCommandsDialog(false);
        }}
        units={selectedUnits}
        bgdata={bgdata}
      />
    </>
  );
};

export default MapMenu;
