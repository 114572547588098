import {
  Button,
  CircularProgress,
  Dialog,
  DialogTitle,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Stack,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import React, { useContext, useEffect } from "react";
import IUnitData, { IUnitState, UnitStateKey } from "../../context/BackgroundDataCtx/IUnitData";
import useMediaQuery from "@mui/material/useMediaQuery";
import WarningIcon from "@mui/icons-material/Warning";
import { ICapabilites } from "../../context/ExtendedUnitDataCtx/IExtendedUnitData";
import IBackgroundData from "../../context/BackgroundDataCtx/IBackgroundData";
import { directionWithStartCommand } from "../../helpers/unitCapabilites";
import QueueCtx from "../../context/QueueCtx/QueueCtx";
import NumberBoxEditor from "../../components/ui/NumberBoxEditor";
import { IUnitSettings } from "../../context/RswsApiCtx/IRswsApi";
import MultipleUnitsSpeedDepthInput from "../../components/ui/MultipleUnitsSpeedDepthInput";

interface ICommand {
  key: UnitStateKey;
  label: string;
}
const commands: ICommand[] = [
  { key: "running", label: "Start/Stop" },
  { key: "direction", label: "Direction" },
  { key: "speedpc", label: "Speed" },
  { key: "park", label: "Park" },
  { key: "pump", label: "Pump" },
  { key: "chemPump", label: "Chem" },
  { key: "endGun", label: "End Gun" },
  { key: "aux1", label: "Aux" },
];

export interface IUnitsCapabilitiesAndSettings {
  [id: string]: { capabilities: ICapabilites; settings: IUnitSettings };
}

interface Props {
  onClose: () => void;
  open: boolean;
  units: IUnitData[];
  bgdata: IBackgroundData;
}
const CommandsDialog: React.FC<Props> = ({ onClose, units, open, bgdata }: Props) => {
  const [unitCapabilitiesAndSettings, setUnitCapabilitiesAndSettings] = React.useState<IUnitsCapabilitiesAndSettings>(
    {}
  );
  const queue = useContext(QueueCtx);
  const theme = useTheme();
  const isBelowSm = useMediaQuery(theme.breakpoints.down("sm"));
  const [loading, setLoading] = React.useState(true);

  useEffect(() => {
    setLoading(true);
    if (!open) return;
    const getCapAndSett = async () => {
      const res = (await Promise.all(
        units.map((unit) =>
          bgdata.getUnitCapabilitiesAndUnitSettings(unit).then((res) => {
            if (!res) return {};
            return { [unit.id]: { capabilities: res[0], settings: res[1] } };
          })
        )
      )) as IUnitsCapabilitiesAndSettings[];
      const unitsCapAndSettings = Object.assign({}, ...res) as IUnitsCapabilitiesAndSettings;
      setUnitCapabilitiesAndSettings(unitsCapAndSettings);
      setLoading(false);
    };

    getCapAndSett();
  }, [open]);

  const changeControlState = <T extends UnitStateKey>(key: T, value: IUnitState[T]) => {
    for (const unit of units) {
      if (!hasCapability(key, unit) || unit.unitState[key] === value || key === "speedpc") continue;
      if (key === "running" && unitCapabilitiesAndSettings[unit.id].capabilities.controls.directionCommandWithStart) {
        directionWithStartCommand(unit, bgdata);
        continue;
      }
      bgdata.changeUnitState(unit, key, value);
    }
  };

  const changeSpeedState = (newSpeedPcValues: { [unitId: string]: number }) => {
    for (const unit of units) {
      if (!hasCapability("speedpc", unit)) continue;
      let value = newSpeedPcValues[unit.id];
      if (unit.systemType === "Lateral" && value > 90) {
        // Lateral speed is capped at 90%
        value = 90;
      }
      bgdata.changeUnitState(unit, "speedpc", value);
    }
  };

  const isInProgress = (key: UnitStateKey): boolean => {
    return units.some((unit) => queue.isQueueInProgress(`${unit.id}-controls-${key}`));
  };

  const hasCapability = (key: UnitStateKey, unit: IUnitData): boolean => {
    if (key === "running") {
      return (
        unitCapabilitiesAndSettings[unit.id]?.capabilities.controls.start ||
        unitCapabilitiesAndSettings[unit.id]?.capabilities.controls.stop
      );
    } else {
      return !!unitCapabilitiesAndSettings[unit.id]?.capabilities.controls[key];
    }
  };

  const allUnitsHasNoCapability = (key: UnitStateKey): boolean => units.every((unit) => !hasCapability(key, unit));
  const someUnitsHasNoCapability = (key: UnitStateKey): boolean => units.some((unit) => !hasCapability(key, unit));

  return (
    <Dialog fullWidth fullScreen={isBelowSm} maxWidth={"xs"} onClose={onClose} open={open}>
      <Stack sx={{ p: 3, minHeight: 300, position: "relative" }} alignContent={"center"}>
        {isBelowSm && (
          <IconButton onClick={() => onClose()} sx={{ position: "absolute", top: 2, left: 2 }}>
            <ArrowBackIcon />
          </IconButton>
        )}
        {!loading && units.length > 0 ? (
          <>
            <DialogTitle textAlign={"center"}>Group Commands</DialogTitle>
            <Typography variant="body1">Send commands to: {units.map((unit) => unit.name).join(", ")}</Typography>
            <List>
              {commands.map((command) => (
                <ListItem
                  key={command.key}
                  secondaryAction={
                    <Stack direction={"row"} gap={1} alignItems={"center"}>
                      {someUnitsHasNoCapability(command.key) && (
                        <Tooltip
                          placement="top"
                          title="Some unit(s) doesn't have capability for this command. Those will be ignored."
                        >
                          <WarningIcon color="error" />
                        </Tooltip>
                      )}
                      {isInProgress(command.key) && <CircularProgress size={22} />}
                      {command.key === "speedpc" ? (
                        <MultipleUnitsSpeedDepthInput
                          units={units}
                          disabled={allUnitsHasNoCapability(command.key) || isInProgress(command.key)}
                          onBlur={changeSpeedState}
                          width="176px"
                          unitsCapabilitiesAndSettings={unitCapabilitiesAndSettings}
                        />
                      ) : (
                        <>
                          <Button
                            size="small"
                            variant="contained"
                            color={command.key === "running" ? "success" : "primary"}
                            onClick={() =>
                              changeControlState(command.key, command.key !== "direction" ? true : "Reverse")
                            }
                            disabled={allUnitsHasNoCapability(command.key) || isInProgress(command.key)}
                            sx={{ minWidth: 84 }}
                          >
                            {command.key === "running" ? "START" : command.key === "direction" ? "Reverse" : "ON"}
                          </Button>
                          <Button
                            size="small"
                            variant="contained"
                            color={command.key === "running" ? "error" : "primary"}
                            disabled={allUnitsHasNoCapability(command.key) || isInProgress(command.key)}
                            onClick={() =>
                              changeControlState(command.key, command.key !== "direction" ? false : "Forward")
                            }
                            sx={{ minWidth: 84 }}
                          >
                            {command.key === "running" ? "STOP" : command.key === "direction" ? "Forward" : "OFF"}
                          </Button>
                        </>
                      )}
                    </Stack>
                  }
                >
                  <ListItemText primary={`${command.label}`} />
                </ListItem>
              ))}
            </List>
          </>
        ) : (
          <CircularProgress
            size={24}
            sx={{
              position: "absolute",
              top: "50%",
              marginTop: "-12px",
              left: "50%",
              marginLeft: "-12px",
            }}
          />
        )}
      </Stack>
    </Dialog>
  );
};

export default CommandsDialog;
