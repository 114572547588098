import * as React from "react";
import { Box, Button, Divider, ListItem, ListItemText, Modal, Stack, Typography } from "@mui/material";
import LayersIcon from '@mui/icons-material/Layers';
import CloseIcon from '@mui/icons-material/Close';
import MapLayersCtx from "../../../context/MapLayersCtx/MapLayersCtx";
import { useContext, useState } from "react";
import LayerListItem from "./LayerListItem";
import AddIcon from '@mui/icons-material/Add';
import AddEditLayerModal from "./AddEditLayerModal";
import { IMapLayer } from "../../../context/MapLayersCtx/IMapLayer";

interface Props {
    open: boolean;
    onClose: () => void;
    openAddNewLayerModal: () => void;
    openLayerForEdit: (layer: IMapLayer) => void;
}

const LayerSelectorModal: React.FC<Props> = (props: Props) => {

    const layerContext = useContext(MapLayersCtx);
    const [selectedListItem, setSelectedListItem] = useState<string | null>(null);


    return (
        <Modal
            open={props.open}
            onClose={props.onClose}
        >
            <div 
                style={{
                    position: 'absolute', 
                    borderRadius: 5, 
                    backgroundColor: 'white',  
                    top: '50%',
                    left: '50%',
                    minWidth: 400,
                    transform: 'translate(-50%, -50%)',
                    padding: 10,
                    display: 'flex',
                    flexDirection: 'column'
                    }}>
                <Stack direction="row" style={{justifyContent: 'space-between', width: '100%'}}>
                    <Stack direction="row">
                        <LayersIcon />
                        <span style={{fontWeight: 'bold', marginLeft: 5}}>Layers</span>
                    </Stack>
                    <CloseIcon style={{color: 'gray', cursor: 'pointer'}} onClick={() => props.onClose()}/>
                </Stack>
                <Divider style={{width: '100%', marginTop: 10, marginBottom: 10}} />
                {
                    Object.values(layerContext.layers).map(layer => 
                    <LayerListItem 
                        key={layer.id} 
                        layer={layer} 
                        active={selectedListItem === layer.id}
                        onItemClick={() => setSelectedListItem(selectedListItem === layer.id ? null : layer.id)}
                        onEdit={() => {
                            props.openLayerForEdit(layer);
                            props.onClose();
                        }}
                        onLayerModify={(l)=> {
                            layerContext.setLayer(l.id, l);
                        }}
                        onDelete={() => {
                            layerContext.setLayer(layer.id, null);
                        }}
                    />)
                }
                 <Divider style={{width: '100%', marginTop: 10, marginBottom: 10}} />
                 <Stack direction="row" style={{cursor: 'pointer'}} onClick={() => {
                    props.openAddNewLayerModal();
                    props.onClose();
                    }}>
                    <div 
                        style={{
                            width: 40, 
                            height: 40, 
                            backgroundColor: '#c7d5ed', 
                            marginRight: 10, 
                            borderRadius: 5, 
                            display: 'flex', 
                            justifyContent: 'center', 
                            alignItems: 'center',
                            border: '2px dashed #003da7'
                        }}>
                        <AddIcon style={{color: '#003da7'}} />
                    </div>
                    <Stack direction="column">
                        <b style={{color: '#003da7'}}>Add new layer</b>
                        <span style={{ color: '#003da7', opacity: 0.5, fontSize: 12}}>Create and view layers for comprehensive field data</span>
                    </Stack>
                 </Stack>
                 
            </div>      
        </Modal>
    );
}

export default LayerSelectorModal;

