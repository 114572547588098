import * as React from "react";
import Box from "@mui/material/Box";
import TopNav from "../../components/Navigation/TopNav";
import GroupGrid from "./GroupGrid";
import UnitGrid from "./UnitGrid";
import { Tabs, Tab } from "@mui/material";
const UnitList: React.FC = () => {
  const [tab, setTab] = React.useState("unit-list");
  return (
    <>
      <Box
        sx={{
          height: "100vh",
          overflow: "auto",
        }}
      >
        <TopNav />
        <Box
          sx={{
            height: {
              xs: "calc(100% - 56px)",
              sm: "calc(100% - 64px)",
            },
            mt: {
              xs: "56px",
              sm: "64px",
            },
          }}
        >
          <Tabs value={tab} onChange={(e, newVal) => setTab(newVal)}>
            <Tab label="Unit View" value={"unit-list"} />
            <Tab label="Group View" value={"group-list"} />
          </Tabs>
          <Box height={"calc(100% - 48px)"}>
            {tab === "unit-list" && <UnitGrid />}
            {tab === "group-list" && <GroupGrid />}
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default UnitList;
