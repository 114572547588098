import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { useParams } from "react-router-dom";
import BackgroundDataCtx from "../../context/BackgroundDataCtx/BackgroundDataCtx";
import { FormControlLabel, Radio, RadioGroup } from "@mui/material";
import { useContext } from "react";
import LocationDataCtx from "../../context/LocationDataCtx/LocationDataCtx";

export enum EnterDataMode {
  Manual = "manual",
  DrawOnMap = "map",
  UseLocation = "gps",
  UseSensor = "sensor",
  Nudge = "nudge",
}

interface Props {
  onOptionSelected: (option: EnterDataMode) => void;
  open: boolean;
  onClose: () => void;
  disableSensorChoice?: boolean;
  unitId?: string;
}

export default function InputTypeChoiceModal(props: Props) {
  const [eneterDataMode, setEnterDataMode] = React.useState<EnterDataMode | null>(null  );

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  var { unitId } = useParams();
  if(!unitId){
      unitId = props.unitId; //in map popup
  }
  if (!unitId) return <>Unit not recognised</>;

  const bgdata = useContext(BackgroundDataCtx);
  const unit = bgdata.units[unitId];
  const locationData = useContext(LocationDataCtx);

  var currentPosAvailable =
    unit.sensor["position"] &&
    unit.sensor["position"].last &&
    unit.sensor["position"].last.value;

  return (
    <React.Fragment>
      <Dialog
        fullScreen={fullScreen}
        open={props.open}
        onClose={props.onClose}
        aria-labelledby="copy-program-modal"
      >
        <DialogTitle id="copy-program-modal">Enter data mode</DialogTitle>
        <DialogContent>
          <RadioGroup
            value={eneterDataMode}
            onChange={(evt) => {
              props.onOptionSelected(evt.target.value as EnterDataMode);
              props.onClose();
            }}
          >
            <FormControlLabel
              value={EnterDataMode.Manual}
              control={<Radio color="primary" />}
              label="Manually"
            />
            <FormControlLabel
              value={EnterDataMode.DrawOnMap}
              control={<Radio color="primary" />}
              label="Draw on map"
            />
            <FormControlLabel
              disabled={!locationData || !(locationData.coords && !locationData.error)}
              value={EnterDataMode.UseLocation}
              control={<Radio color="primary" />}
              label="Use my location (GPS)"
            />
            <FormControlLabel
              disabled={!currentPosAvailable || props.disableSensorChoice}
              value={EnterDataMode.UseSensor}
              control={<Radio color="primary" />}
              label="Use device location (sensor)"
            />
             <FormControlLabel
               disabled={!locationData || !(locationData.coords && !locationData.error)}
              value={EnterDataMode.Nudge}
              control={<Radio color="primary" />}
              label="Nudge"
            />
          </RadioGroup>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}
