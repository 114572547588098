import * as React from "react";
import { useEffect, useState } from "react";

import IAppInstallData from "./IAppInstallData";
import AppInstallCtx from "./AppInstallCtx";
import { BeforeInstallPromptEvent } from "./beforeinstallprompt";

let beforeInstallEvent: BeforeInstallPromptEvent;
window.addEventListener("beforeinstallprompt", (ev) => {
  beforeInstallEvent = ev;
});

const AppInstallCtxProvider: React.FC<React.PropsWithChildren> = (props) => {
  const [ beforeInstallEventState, setBeforeInstallEventState ] = useState<BeforeInstallPromptEvent | undefined>();

  const data: IAppInstallData = {
    beforeInstallEvent: beforeInstallEventState
  };

  useEffect(() => {
    if (beforeInstallEvent) {
      setBeforeInstallEventState(beforeInstallEvent);
    } else {
      window.addEventListener("beforeinstallprompt", (ev) => {
        setBeforeInstallEventState(ev);
      });
    }
  }, []);

  return (
    <AppInstallCtx.Provider value={data}>
      {props.children}
    </AppInstallCtx.Provider>
  );
};
export default AppInstallCtxProvider;
