import React from "react";
import { NavLink, LinkProps } from "react-router-dom";

/**
 * Adapts the react-router NavLink class to assign the Mui-selected class to the active
 * link, which is used by MUI components such as BottomNavigation. Now supports forwarding refs.
 * @param props Props from parent component.
 * @param ref Ref forwarded to the NavLink component.
 * @returns A NavLink component with forwarded ref and conditional className.
 */
const MuiNavLink = React.forwardRef<HTMLAnchorElement, LinkProps>(
  (props, ref) => {
    const { className, ...rest } = props;
    return (
      <NavLink
        ref={ref}
        {...rest}
        className={({ isActive }) =>
          isActive ? `${className} Mui-selected` : className
        }
      />
    );
  }
);

export default MuiNavLink;
