import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import useMediaQuery from "@mui/material/useMediaQuery";
import useTheme from "@mui/material/styles/useTheme";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import {
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
} from "@mui/material";
import dayjs, { Dayjs } from "dayjs";
import { DateRange } from "@mui/x-date-pickers-pro/models";
import { DateTimeRangePicker } from "@mui/x-date-pickers-pro/DateTimeRangePicker";
import { IEvent } from "./createEvent";

interface Props {
  open: boolean;
  onClose: () => void;
  addNewEvent(event: IEvent): void;
  events: IEvent[];
}
const CreateNewEventDialog = ({
  open,
  onClose,
  addNewEvent,
  events,
}: Props) => {
  const theme = useTheme();
  const isBelowSm = useMediaQuery(theme.breakpoints.down("sm"));
  const getEmptyEvent = () =>
    ({
      eventName: "",
      sectors: [],
      eventStartTimeMs: dayjs().subtract(1, "day").valueOf(),
      eventEndTimeMs: dayjs().valueOf(),
    } as IEvent);
  const [event, setEvent] = React.useState<IEvent>(getEmptyEvent());
  const [error, setError] = React.useState<string | null>(null);
  const handleClose = () => {
    setEvent(getEmptyEvent());
    onClose();
  };

  const handleNewEvent = async () => {
    if (event.eventName.length > 1 && !error) {
      addNewEvent(event);
      handleClose();
    }
  };

  const nameNotUnique = events.some((e) => e.eventName === event.eventName);
  return (
    <Dialog
      onClose={handleClose}
      open={open}
      fullWidth
      fullScreen={isBelowSm}
      maxWidth={"sm"}
    >
      {isBelowSm && (
        <IconButton
          onClick={() => onClose()}
          sx={{ position: "absolute", top: 3, right: 3 }}
        >
          <CloseIcon />
        </IconButton>
      )}
      <DialogTitle sx={{ display: "flex", justifyContent: "space-between" }}>
        Create New Event
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          Please select a name and time range for the event.
        </DialogContentText>
        <TextField
          sx={{ mt: 1 }}
          autoFocus
          label="Event Title"
          variant="outlined"
          fullWidth
          value={event.eventName}
          onChange={(e) => setEvent({ ...event, eventName: e.target.value })}
        />
        <DateTimeRangePicker
          disableFuture
          sx={{ mt: 2 }}
          //@ts-ignore
          value={
            [
              dayjs(event.eventStartTimeMs),
              dayjs(event.eventEndTimeMs),
              //@ts-ignore
            ] as DateRange<Dayjs>
          }
          onChange={(newValue) => {
            if (!newValue[0] || !newValue[1]) return;

            setEvent({
              ...event,
              //@ts-ignore
              eventStartTimeMs: newValue[0].valueOf(),
              //@ts-ignore
              eventEndTimeMs: newValue[1].valueOf(),
            });
          }}
          onError={(reason) => setError(reason[0] || reason[1] || null)}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button
          disabled={event.eventName.length < 2 || !!error || nameNotUnique}
          onClick={handleNewEvent}
        >
          Create Event
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CreateNewEventDialog;
