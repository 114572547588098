import React from "react";
import IUnitData, {
  IBarricade,
} from "../../context/BackgroundDataCtx/IUnitData";
import { FormControlLabel, Stack, Switch, Typography } from "@mui/material";
import InteractiveInput from "../ui/InteractiveInput";
import SwapVertIcon from '@mui/icons-material/SwapVert';

interface Props {
  barricade: IBarricade | undefined;
  onChange: (newBarricade: IBarricade) => void;
  unit: IUnitData;
}

const BarricadeEditor: React.FC<Props> = ({
  onChange,
  barricade,
  unit,
}: Props) => {
  return (
    <>
      {barricade && (
        <Stack gap={1}>
          <div>
            <Typography>Reverse</Typography>
            <Stack direction={"row"} gap={1} justifyContent={"space-between"}>
              <InteractiveInput
                id="barricade-reverse"
                hideErrorHelperText={true}
                value={barricade.reverse.position}
                onChange={(newValue) => {
                  const newBarricade = { ...barricade };
                  newBarricade.reverse.position = newValue;
                  onChange(newBarricade);
                }}
                unit={unit}
                maxWidth="272px"
              />
              <FormControlLabel
                sx={{ width: "100%" }}
                control={
                  <Switch
                    checked={barricade.reverse.autoRev}
                    onChange={(event) => {
                      const newBarricade = { ...barricade };
                      newBarricade.reverse.autoRev = event.target.checked;
                      onChange(newBarricade);
                    }}
                    name="switch"
                    color="primary"
                  />
                }
                label={barricade.reverse.autoRev ? "Auto Rev" : "Auto Stop"}
                labelPlacement="start"
              />
            </Stack>
          </div>
          <SwapVertIcon style={{ color: 'gray', cursor: 'pointer', marginLeft: '55%'}} onClick={() => {
            const newBarricade = { ...barricade };
            const oldForward = newBarricade.forward;
            newBarricade.forward = newBarricade.reverse;
            newBarricade.reverse = oldForward;
            onChange(newBarricade);
          }} />
          <div>
            <Typography>Forward</Typography>
            <Stack direction={"row"} gap={1} justifyContent={"space-between"}>
              <InteractiveInput
                id="barricade-forward"
                hideErrorHelperText={true}
                value={barricade.forward.position}
                onChange={(newValue) => {
                  const newBarricade = { ...barricade };
                  newBarricade.forward.position = newValue;
                  onChange(newBarricade);
                }}
                maxWidth="272px"
                unit={unit}
              />
              <FormControlLabel
                sx={{ width: "100%" }}
                control={
                  <Switch
                    checked={barricade.forward.autoRev}
                    onChange={(event) => {
                      const newBarricade = { ...barricade };
                      newBarricade.forward.autoRev = event.target.checked;
                      onChange(newBarricade);
                    }}
                    name="switch"
                    color="primary"
                  />
                }
                label={barricade.forward.autoRev ? "Auto Rev" : "Auto Stop"}
                labelPlacement="start"
              />
            </Stack>
          </div>
        </Stack>
      )}
    </>
  );
};

export default BarricadeEditor;
