import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import ListItemButton from "@mui/material/ListItemButton";
import DialogTitle from "@mui/material/DialogTitle";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { List, ListItem, ListItemIcon, ListItemText } from "@mui/material";
import IUnitData, { IProgram } from "../../context/BackgroundDataCtx/IUnitData";
import ProgramIcon from "../ProgramIcon";
import buildGeoJson from "../Map/buildGeoJson";
import { UnitData } from "../../context/BackgroundDataCtx/IBackgroundData";

interface Props<T extends keyof IProgram> {
  progUnits?: { name: string; settings: IProgram[T] }[];
  onChange: (copiedSettings: IProgram[T]) => void;
  buttonWidth?: string;
  unit: IUnitData;
  programType: T;
}

export default function CopyProgramModal<T extends keyof IProgram>({
  progUnits,
  onChange,
  buttonWidth,
  unit,
  programType,
}: Props<T>) {
  const [open, setOpen] = React.useState(false);
  const theme = useTheme();
  const isBelowSM = useMediaQuery(theme.breakpoints.down("sm"));
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleSelectProgram = (programSectors: IProgram[T]) => {
    onChange(programSectors);
    setOpen(false);
  };
  return (
    <React.Fragment>
      <Button
        sx={{ width: buttonWidth ?? "100%" }}
        color="primary"
        variant="contained"
        onClick={handleClickOpen}
        disabled={!progUnits || progUnits.length === 0}
        startIcon={<ContentCopyIcon />}
      >
        Copy
      </Button>
      <Dialog
        fullScreen={isBelowSM}
        open={open}
        onClose={handleClose}
        aria-labelledby="copy-program-modal"
      >
        <DialogTitle id="copy-program-modal">
          Copy settings from another program
        </DialogTitle>
        <DialogContent>
          <List>
            {progUnits!.map((programSector, index) => {
              let ud: UnitData = {};
              let newUnit = { ...unit };
              const programDefault: IProgram = {
                name: "",
                endGunSectors: [],
                auxSectors: [],
                vriSectors: [],
                scheduledActions: [],
              };
              newUnit.runningProgram = {
                ...programDefault,
                [programType]: programSector.settings,
              } as IProgram;
              ud[unit.id] = newUnit;

              return (
                <ListItem key={index} disablePadding>
                  <ListItemButton
                    onClick={() => handleSelectProgram(programSector.settings)}
                  >
                    <ListItemIcon>
                      <ProgramIcon
                        featureCollection={buildGeoJson(
                          ud,
                          undefined,
                          "#bfbfbf"
                        )}
                      />
                    </ListItemIcon>
                    <ListItemText primary={programSector.name} />
                  </ListItemButton>
                </ListItem>
              );
            })}
          </List>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose}>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
