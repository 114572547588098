import * as React from "react";
import { useContext, useEffect, useState } from "react";
import RswsApiCtx from "../RswsApiCtx/RswsApiCtx";
import BackgroundDataCtx from "./BackgroundDataCtx";
import { ILoadUserResp, ISaveProgramReq, IUnitFromPropRes } from "../RswsApiCtx/IRswsApi";
import IBackgroundData, { PropertyData, UnitData, UnitFilter } from "./IBackgroundData";
import CircularProgress from "@mui/material/CircularProgress";
import Unit from "../../helpers/Unit";
import { IProgramResponse } from "../RswsApiCtx/IProgramResponse";
import { getProgramUnitIds, getPrograms, getRunningProgram, transformProgramToApi } from "../../helpers/programUtils";
import IUnitData, { IProgram, IUnitState, UnitStateKey } from "./IUnitData";
import QueueCtx from "../QueueCtx/QueueCtx";
import { transformSensorValue } from "../../helpers/controlsUtils";
import IUserSettings from "../UserSettingsCtx/IUserSettings";
import UserSettingsCtx from "../UserSettingsCtx/UserSettingsCtx";
import { getUnitCapabilities } from "../../helpers/unitCapabilites";
import GroupsApiCtx from "../GroupsApiCtx/GroupsApiCtx";
import { IGroup } from "../GroupsApiCtx/IGroupsApi";
import isEqual from "lodash.isequal";

const BackgroundDataCtxProvider: React.FC<React.PropsWithChildren> = (props) => {
  const api = useContext(RswsApiCtx);
  const queue = useContext(QueueCtx);
  const userSettings = useContext(UserSettingsCtx);
  const groupsApi = useContext(GroupsApiCtx);

  const [loadingInitialData, setLoadingInitialData] = useState(true);
  const [errorLoadingInitialData, setErrorLoadingInitialData] = useState<string | undefined>(undefined);
  const [userData, setUserData] = useState<ILoadUserResp | undefined>(undefined);
  const [propertyData, setPropertyData] = useState<PropertyData | undefined>(undefined);
  const [unitData, setUnitData] = useState<UnitData | undefined>(undefined);
  const [filterByProperty, setFilterByProperty] = useState<string | null>(null);
  const [selectedUnits, setSelectedUnits] = useState<UnitData>({});

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    const intervalId = setInterval(refreshPropAndUnitData, 10000);

    return () => clearInterval(intervalId);
  }, [userData]);

  const fetchData = async () => {
    try {
      setLoadingInitialData(true);
      // get filter from local storage
      const property = localStorage.getItem("unitFilterProperty");
      if (property) {
        setFilterByProperty(property);
      }
      const userData = await api.loadUser();
      setUserData(userData);
      await getAndSetPropAndUnitData(userData);
    } catch (ex) {
      console.log(ex);
      setErrorLoadingInitialData(`${ex}`);
    } finally {
      setLoadingInitialData(false);
    }
  };

  const refreshPropAndUnitData = async () => {
    try {
      console.log("Refreshing prop and unit data");
      await getAndSetPropAndUnitData(userData!);
      setErrorLoadingInitialData("");
    } catch (ex) {
      console.error("Error refreshing prop and unit data");
      console.info("Error INFO: ", ex);
    }
  };

  const getAndSetPropAndUnitData = async (userData: ILoadUserResp) => {
    const rsUserNames = userData.properties.map((p) => p.rsuname);
    const [newPropertyData, newPrograms] = await Promise.all([
      loadPropertiesData(rsUserNames),
      loadPrograms(rsUserNames),
    ]);
    const newUnitData = createUnitData(newPropertyData, newPrograms, userSettings, groupsApi.getGroups(userData.name));

    setPropertyData((prev) => {
      if (isEqual(prev, newPropertyData)) {
        return prev;
      } else {
        return newPropertyData;
      }
    });

    setUnitData((prev) => {
      if (isEqual(prev, newUnitData)) {
        return prev;
      } else {
        return newUnitData;
      }
    });
  };

  const loadPropertiesData = async (rsUNames: string[]): Promise<PropertyData> => {
    const propertyPromises = rsUNames.map((uName) => api.loadProperty({ prop: uName }));
    const propertiesData = await Promise.all(propertyPromises);
    return rsUNames.reduce((acc, uName, index) => {
      acc[uName] = propertiesData[index];
      return acc;
    }, {} as PropertyData);
  };

  const loadPrograms = async (rsUNames: string[]) => {
    const programPromises = rsUNames.map((uName) => api.findPrograms({ programQueryFilter: { propertyName: uName } }));

    const programArrays = await Promise.all(programPromises);

    return rsUNames.reduce((acc, uName, i) => {
      acc[uName] = programArrays[i].programs;
      return acc;
    }, {} as { [rsuname: string]: IProgramResponse[] });
  };

  if (errorLoadingInitialData) {
    return <>Error loading initial data: {errorLoadingInitialData}</>;
  }

  if (loadingInitialData) {
    return (
      <CircularProgress
        size={24}
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          marginTop: "-12px",
          marginLeft: "-12px",
        }}
      />
    );
  }

  const saveProgram = async (unit: IUnitData, program: IProgram): Promise<void> => {
    const programUnits: ISaveProgramReq[] = transformProgramToApi(unit, program);
    await Promise.all(programUnits.map((req) => api.saveProgram(req)));
    setUnitData((prev) => {
      if (!prev) return prev;
      const newUnit = { ...prev[unit.id] };
      newUnit.programs = [...(newUnit.programs || []), program];
      return { ...prev, [unit.id]: newUnit };
    });
  };

  const deleteProgram = async (program: IProgram): Promise<void> => {
    const resPromises = getProgramUnitIds(program).map((id) => api.deleteProgram([id]));
    await Promise.all(resPromises);

    setUnitData((prev) => {
      if (!prev) return prev;
      const newUnits = { ...prev };
      Object.values(newUnits).forEach((unit) => {
        unit.programs = unit.programs?.filter((p) => p.name !== program.name);
      });
      return newUnits;
    });
  };

  const unloadProgram = async (program: IProgram, unit: IUnitData): Promise<void> => {
    await queueLoadUnloadProgram(program, unit, false);

    setUnitData((prev) => {
      if (!prev) return prev;
      const newUnit = { ...prev[unit.id] };
      newUnit.runningProgram = undefined;
      return { ...prev, [unit.id]: newUnit };
    });
  };

  const loadProgram = async (program: IProgram, unit: IUnitData): Promise<void> => {
    await queueLoadUnloadProgram(program, unit, true);

    setUnitData((prev) => {
      if (!prev) return prev;
      const newUnit = { ...prev[unit.id] };
      newUnit.runningProgram = program;
      return { ...prev, [unit.id]: newUnit };
    });
  };

  const queueLoadUnloadProgram = async (program: IProgram, unit: IUnitData, isLoad: boolean) => {
    const promises = getProgramUnitIds(program).map((id) =>
      queue.enqueueTask({
        queueId: `${unit.id}-program-queue`,
        id: `${unit.id}-program-${program.name}`,
        perform: async () => {
          const res = isLoad ? await api.loadProgram([id]) : await api.unloadProgram([id]);
          const resString = isLoad ? "Program loaded successfully" : "Program unloaded successfully";
          return res === resString ? "ok" : "error";
        },
        verifyCompletion: async () => {
          const res = await api.findPrograms({
            programQueryFilter: {
              propertyName: unit.property,
              programIds: [id],
            },
          });
          return res.programs[0].state === (isLoad ? "loaded" : "unloaded");
        },
      })
    );
    await Promise.all(promises);
  };

  const changeUnitState = async <T extends UnitStateKey>(
    unit: IUnitData,
    key: T,
    value: IUnitState[T]
  ): Promise<void> => {
    if (value === undefined || value === null) throw new Error("Invalid value");
    const sensorId = unit.sensor[key]?.id;
    if (!sensorId) throw new Error("Sensor not found");

    const valueToSend = transformSensorValue(key, value);

    await queue.enqueueTask({
      id: `${unit.id}-controls-${key}`,
      queueId: `${unit.id}-controls-${key}`,
      perform: async () => {
        const res = await api.actuateSensor([sensorId, valueToSend, 10]);
        return res.result === 0 ? "ok" : "error";
      },
      verifyCompletion: async () => {
        const pollUnit = await api.loadUnit({ unitName: unit.id });
        const sensor = pollUnit.sensors.find((s) => s.id === sensorId);
        return sensor?.last.value === valueToSend;
      },
      timeoutSec: 8,
    });

    setUnitData((prev) => {
      if (!prev) return prev;
      const newUnit = { ...prev[unit.id] };
      newUnit.unitState = { ...newUnit.unitState, [key]: value };
      return { ...prev, [unit.id]: newUnit };
    });
  };

  const bgdata: IBackgroundData = {
    user: userData!,
    properties: propertyData!,
    units: unitData!,
    saveProgram: saveProgram,
    deleteProgram: deleteProgram,
    loadProgram: loadProgram,
    unloadProgram: unloadProgram,
    selectedUnits: selectedUnits,
    setSelectedUnits,
    filteredUnits: Object.values(unitData!)
      .filter((unit) => {
        if (filterByProperty === null || filterByProperty === "") {
          return true;
        } else {
          return unit.property === filterByProperty;
        }
      })
      .reduce((obj, unit) => {
        obj[unit.id] = unit;
        return obj;
      }, {}),
    setPropertyFilter: (property) => {
      //TODO save filter on backend when possible
      if (property === "") {
        localStorage.removeItem("unitFilterProperty");
      } else {
        localStorage.setItem("unitFilterProperty", property);
      }
      setFilterByProperty(property);
    },
    propertyFilter: filterByProperty,
    changeUnitState: changeUnitState,
    getUnitCapabilitiesAndUnitSettings: async (unit) => {
      try {
        const extUnit = await api.loadUnit({ unitName: unit.id });
        return [getUnitCapabilities(extUnit), extUnit.unitSettings];
      } catch (error) {
        setErrorLoadingInitialData(`${error}`);
      }
    },
    saveUserContact: async (userName, contact) => {
      return api.saveUserContact({ userName, contact });
    },

    changePassord: async (currentPassword, newPassword) => {
      return api.changePassword({ currentPassword, newPassword });
    },
    getSensorData: async (unit, sensor, from, to) => {
      return api.findSensorData({
        sensorDataQueryFilter: {
          from,
          to,
          unitId: unit.id,
          port: sensor.port.toString(),
          subPort: sensor.subPort.toString(),
          tzAdj: false,
        },
      });
    },
  };

  return <BackgroundDataCtx.Provider value={bgdata}>{props.children}</BackgroundDataCtx.Provider>;
};

export default BackgroundDataCtxProvider;

const createUnitData = (
  pd: PropertyData,
  programs: { [rsuname: string]: IProgramResponse[] },
  userSettings: IUserSettings,
  groups: IGroup[]
): UnitData => {
  const unitData: UnitData = {};
  Object.values(pd).forEach((property) => {
    property.units.forEach((unit) => {
      // Find sensors connected to the unit
      const sensors = Object.values(property.sensors).filter((s) => s.unitId === unit.id);

      const programUnits = programs[property.id].filter((p) => p.definition.unitName === unit.id);

      const unitDerivedFromPropRes: IUnitFromPropRes = { ...unit, sensors };
      const unitHelper = new Unit(unitDerivedFromPropRes);
      unitData[unit.id] = {
        id: unit.id,
        name: unit.descr,
        settingsOEM: unitHelper.settingsOEM,
        property: property.id,
        groups: groups.filter((g) => g.units.some((u) => u.id === unit.id)),
        lastCallIn: unit.lastCallIn,
        status: unitHelper.getStatus(),
        statusColor: unitHelper.getStatusColor(userSettings),
        connection: unitHelper.getConnectionStatus(),
        barricade:
          unitHelper.getForwardBarricade() && unitHelper.getReverseBarricade()
            ? [unitHelper.getForwardBarricade()!, unitHelper.getReverseBarricade()!]
            : undefined,
        ETA: unitHelper.getETA(getRunningProgram(programUnits)) || "N/A",
        latitude: unit.coord.lat,
        longitude: unit.coord.long,
        longitude2: unit.coord.long2,
        latitude2: unit.coord.lat2,
        lengthFeet: unitHelper.isPivot() ? unit.coord.alt : unitHelper.getLateralFieldLengthFeet(),
        isUnitOfMeasureMetric: unitHelper.isUnitofMeasureMetric(),
        systemType: unitHelper.isPivot() ? "Pivot" : "Lateral",
        unitState: {
          running: unitHelper.isRunning(),
          speedpc: unitHelper.getSpeed(),
          parkAngle: unitHelper.getParkAngle(),
          direction: unitHelper.getDirection(),
          pump: unitHelper.isPumpOn(),
          chemPump: unitHelper.isChemPumpOn(),
          aux1: unitHelper.isAux1On(),
          endGun: unitHelper.isEndGunOn(),
          park: unitHelper.isParked(),
        },
        sensor: unitHelper.sensor,
        runningProgram: getRunningProgram(programUnits),
        programs: getPrograms(programUnits),
      } as IUnitData;
    });
  });

  return unitData;
};
