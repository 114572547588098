import React from "react";
import IUnitData, {
  IBarricade,
  IEndGunAuxSector,
  IProgram,
  IScheduledAction,
  IVriSector,
} from "../../context/BackgroundDataCtx/IUnitData";
import { Button, Typography } from "@mui/material";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import CopyProgramModal from "./CopyProgramModal";
import { filterOutEmptySectors } from "../../helpers/programUtils";
import Grid from "@mui/material/Unstable_Grid2";
import EndgunAuxEditor from "./EndgunAuxEditor";
import dayjs from "dayjs";
import SectorEditor from "./SectorEditor";
import BarricadeEditor from "./BarricadeEditor";
import ScheduledActionsEditor from "./ScheduleActionsEditor";
import RestartAltIcon from "@mui/icons-material/RestartAlt";

interface Props<T extends keyof IProgram> {
  label: string;
  settings: IProgram[T];
  onChange: (newSettings: IProgram[T]) => void;
  unit: IUnitData;
  programType: T;
  onReset?: () => void;
}

const ProgramEditorWrapper = <T extends keyof IProgram>({
  label,
  onChange,
  settings,
  unit,
  programType,
  onReset
}: Props<T>): React.ReactElement => {
  const addSettingBasedOnProgramType = () => {
    if (programType === "barricade") {
      onChange({
        forward: { position: NaN, autoRev: false },
        reverse: { position: NaN, autoRev: false },
      } as IProgram[T]);
    } else if (programType === "vriSectors") {
      const updatedSettings = [...(settings as IVriSector[])];
      updatedSettings.push({
        begin: !updatedSettings.length ? NaN : updatedSettings[updatedSettings.length - 1].end,
        end: NaN,
        forward: { pump: -1, chem: -1, speedpc: null },
        reverse: { pump: -1, chem: -1, speedpc: null },
      });
      onChange(updatedSettings as IProgram[T]);
    } else if (programType === "scheduledActions") {
      const updatedSettings = [...(settings as IScheduledAction[])];
      updatedSettings.push({
        time: {
          runAtTime: dayjs().add(1, "d").toISOString(),
          repeatOnDays: [],
        },
        state: {},
      });
      onChange(updatedSettings as IProgram[T]);
    } else if (
      programType === "endGunSectors" ||
      programType === "auxSectors"
    ) {
      const updatedSettings = [...(settings as IEndGunAuxSector[])];
      updatedSettings.push({ begin: NaN, end: NaN });
      onChange(updatedSettings as IProgram[T]);
    }
  };

  const hasSettings = (): boolean => {
    if (programType === "barricade") {
      return settings !== undefined;
    } else {
      return Array.isArray(settings) && settings.length > 0;
    }
  };

  const renderEditorBasedOnProgramType = () => {
    if (programType === "barricade") {
      return (
        <BarricadeEditor
          barricade={settings as IBarricade}
          onChange={(newBarricade) => {
            onChange(newBarricade as IProgram[T]);
          }}
          unit={unit}
        />
      );
    } else if (
      programType === "endGunSectors" ||
      programType === "auxSectors"
    ) {
      return (
        <EndgunAuxEditor
          endGunAuxSectors={settings as IEndGunAuxSector[]}
          onChange={(newSectors) => {
            onChange(newSectors as IProgram[T]);
          }}
          unit={unit}
        />
      );
    } else if (programType === "vriSectors") {
      return (
        <SectorEditor
          vriSectors={settings as IVriSector[]}
          onChange={(newSectors) => {
            onChange(newSectors as IProgram[T]);
          }}
          unit={unit}
        />
      );
    } else if (programType === "scheduledActions") {
      return (
        <ScheduledActionsEditor
          scheduledActions={settings as IScheduledAction[]}
          onChange={(newScheduledActions) => {
            onChange(newScheduledActions as IProgram[T]);
          }}
          unit={unit}
        />
      );
    }
  };
  return (
    <Grid container spacing={2}>
      <Grid xs={12}>
        {!hasSettings() ? (
          <Typography>No {label} Settings</Typography>
        ) : (
          renderEditorBasedOnProgramType()
        )}
      </Grid>
      <Grid xs={12} display={"flex"} gap={1} justifyContent={"space-between"}>
        <Button
          variant="contained"
          color="primary"
          size="medium"
          onClick={addSettingBasedOnProgramType}
          startIcon={<AddCircleIcon />}
          sx={{ width: "33%" }}
        >
          Add
        </Button>
        <CopyProgramModal
          buttonWidth="33%"
          onChange={(copiedSettings) => {
            if (programType === "barricade") {
              onChange(copiedSettings);
            } else if (
              Array.isArray(settings) &&
              Array.isArray(copiedSettings)
            ) {
              onChange([...settings, ...copiedSettings] as IProgram[T]);
            }
          }}
          unit={unit}
          programType={programType}
          progUnits={unit.programs?.reduce((acc, program) => {
            if (programType === "barricade" && program.barricade) {
              acc.push({
                name: program.name,
                settings: program.barricade as IProgram[T],
              });
            } else if (Array.isArray(program[programType])) {
              const filteredSectors = filterOutEmptySectors(
                program[programType] as IEndGunAuxSector[] | IVriSector[]
              );
              if (filteredSectors.length > 0) {
                acc.push({
                  name: program.name,
                  settings: filteredSectors as IProgram[T],
                });
              }
            }
            return acc;
          }, [] as { name: string; settings: IProgram[T] }[])}
        />
        <Button
          variant="contained"
          color="primary"
          size="medium"
          startIcon={<RestartAltIcon />}
          onClick={() => {
            if (programType === "barricade") {
              onChange(undefined as IProgram[T]);
            } else {
              onChange([] as unknown as IProgram[T]);
            }
            if(onReset){
              onReset();
            }
          }}
          sx={{ width: "33%" }}
        >
          Reset
        </Button>
      </Grid>
    </Grid>
  );
};

export default ProgramEditorWrapper;
