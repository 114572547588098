import * as React from "react";
import BackgroundDataCtx from "../../context/BackgroundDataCtx/BackgroundDataCtx";
import InputTypeChoiceModal, { EnterDataMode } from "./InputTypeChoiceModal";
import MapInteractionCtx from "../../context/MapInteractionCtx/MapInteractionCtx";
import IUnitData from "../../context/BackgroundDataCtx/IUnitData";
import { useContext } from "react";
import LocationDataCtx from "../../context/LocationDataCtx/LocationDataCtx";
import { getAngleFromPoint, getDistanceBetweenPoints } from "../Map/MapHelper";
import { useParams } from "react-router-dom";
import { Position } from "geojson";
import NumberBoxEditor from "./NumberBoxEditor";
import Public from '@mui/icons-material/Public';

interface Props {
    onChange: ( angle: number, realPos?: Position) => void;
    onError?: (hasError: boolean) => void;
    value?: number;
    id: string;
    unit: IUnitData;
    label?: string;
    width?: string;
    maxWidth?: string;
    maxValue?: number;
    displayUnit?: string;
    minValue?: number;
    disabled?: boolean;
    disableSensorChoice?: boolean;
    showValidation?: boolean;
    hideErrorHelperText?: boolean;
    unitId?: string;
}

export default function InteractiveInput(props: Props) {

    const [modalOpen, setModalOpen] = React.useState<boolean>(false);
    const [enterDataMode, setEnterDataMode] = React.useState<EnterDataMode>(EnterDataMode.Manual);

    var { unitId } = useParams();
    if(!unitId){
        unitId = props.unitId; //in map popup
    }
    if (!unitId) return <>Unit not recognised</>;
  
    const bgdata = useContext(BackgroundDataCtx);
    const unit = bgdata.units[unitId];

    const mapInteractionCtx = useContext(MapInteractionCtx);
    const locationData = useContext(LocationDataCtx);
  return (
    <>
        <InputTypeChoiceModal 
            disableSensorChoice={props.disableSensorChoice}
            unitId={props.unitId}
            onOptionSelected={(opt) => { 
                setEnterDataMode(opt);
                if(opt === EnterDataMode.DrawOnMap){  
                    mapInteractionCtx.beginDrawPoint((p,p2, angle) => {
                        props.onChange(angle!, p2!);
                    }); 
                    
                }
                if(opt === EnterDataMode.UseLocation){
                    if(locationData && locationData.coords && !locationData.error){

                    var value = unit.systemType === "Pivot" ? getAngleFromPoint([locationData.coords!.longitude, locationData.coords!.latitude], unit) :
                        getDistanceBetweenPoints([locationData.coords!.longitude, locationData.coords!.latitude], [unit.longitude, unit.latitude])
                        props.onChange(value, [locationData.coords!.longitude, locationData.coords!.latitude]);
                    }                   
                }
                if(opt === EnterDataMode.UseSensor){
                    if(unit.sensor["position"] && unit.sensor["position"].last && unit.sensor["position"].last.value){
                        props.onChange(unit.sensor["position"].last.value); 
                    }                                
                }

                if(opt === EnterDataMode.Nudge){
                    if(locationData && locationData.coords && !locationData.error){
                        mapInteractionCtx.beginDrawContinuousLocation((p,p2, angle) => {
                            props.onChange(angle!, p2!);
                        }); 
                    }
                }
             }}
            open={modalOpen}
            onClose={() => setModalOpen(false)}
        />
        <NumberBoxEditor 
            id={props.id}
            value={props.value!}  
            onError={props.onError ? props.onError : (b) => {}}           
            onClick={() => 
                setModalOpen(true)}
            onChange={(v) => {
                switch(enterDataMode){
                    case EnterDataMode.Manual:
                      //  props.onChange(v);
                    break;
                    case EnterDataMode.DrawOnMap:
                        // do nothing
                    break;
                }
            }}
            onBlur={(v) => {
                if(enterDataMode === EnterDataMode.Manual){
                    if(v !== props.value){
                        props.onChange(v);
                    }                 
                }
            }}
            min={props.minValue ?? 0}
            disabled={props.disabled}
            max={props.maxValue ?? (props.unit.systemType === "Pivot" ? 360 : props.unit.lengthFeet)}
            size="small"
            unit={props.displayUnit ?? (props.unit.systemType === "Pivot" ? "°" : "ft")}
            isUnitPositionEnd={true}
            width={props.width ?? "100%"}
            maxWidth={props.maxWidth}
            hideErrorHelperText={props.hideErrorHelperText}
            label={props.label}
            inlineIcon={props.disabled ? undefined : <Public style={{color: 'lightgray'}}/>}
        />
    </>
  );
}
