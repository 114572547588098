import * as React from "react";
import {
  DataGrid,
  GridRowsProp,
  GridColDef,
  GridEventListener,
  GridToolbarContainer,
  GridToolbarQuickFilter,
} from "@mui/x-data-grid";
import IBackgroundData, { UnitData } from "../../context/BackgroundDataCtx/IBackgroundData";
import { useNavigate } from "react-router-dom";
import BackgroundDataCtx from "../../context/BackgroundDataCtx/BackgroundDataCtx";
import { useContext, useEffect } from "react";
import StatusDot from "../../components/ui/StatusDot";
import CommandsDialog from "./CommandsDialog";
import { Button } from "@mui/material";

interface UnitRow {
  id: string;
  name: string;
  status: string;
  connection: string;
  ETA: string;
}

const UnitGrid: React.FC = () => {
  const navigate = useNavigate();
  const bgdata: IBackgroundData = useContext(BackgroundDataCtx);
  const [selectedUnitIds, setSelectedUnitIds] = React.useState<string[]>(
    bgdata.selectedUnits ? Object.values(bgdata.selectedUnits).map((u) => u.id) : []
  );
  const [openCommadsDialog, setOpenCommandsDialog] = React.useState(false);
  const rows: GridRowsProp<UnitRow> = Object.values(bgdata.filteredUnits).map((unit) => {
    return {
      id: unit.id,
      name: unit.name,
      status: unit.status,
      connection: unit.connection,
      ETA: unit.ETA,
    };
  });

  useEffect(() => {
    if (bgdata.selectedUnits && Object.keys(bgdata.selectedUnits).length !== selectedUnitIds.length) {
      setSelectedUnitIds(Object.values(bgdata.selectedUnits).map((u) => u.id));
    }
  }, [bgdata.selectedUnits]);

  const columns: GridColDef[] = [
    {
      field: "name",
      headerName: "Name",
      flex: 1,
      minWidth: 150,
      maxWidth: 300,
    },
    { field: "id", headerName: "ID", width: 85 },
    { field: "status", headerName: "Status", width: 150 },
    {
      field: "connection",
      headerName: "Connection",
      width: 150,
      renderCell: (params) => {
        return (
          <>
            <StatusDot status={params.value} sizePx={14} />
            <span style={{ marginLeft: 6 }}>{params.value}</span>
          </>
        );
      },
    },
    { field: "ETA", headerName: "ETA", flex: 1, minWidth: 150 },
  ];

  const rowClicked: GridEventListener<"rowClick"> = (params, event, details) => {
    navigate(`/units/${params.id}`);
  };

  function CustomToolbar() {
    return (
      <GridToolbarContainer sx={{ display: "flex", justifyContent: "space-between", px: 2 }}>
        <GridToolbarQuickFilter />
        {selectedUnitIds.length > 0 && (
          <Button
            variant="outlined"
            onClick={() => {
              setOpenCommandsDialog(true);
            }}
          >
          Group Commands
          </Button>
        )}
        {/*
        <GridToolbarFilterButton />
         <GridToolbarColumnsButton />
        <GridToolbarDensitySelector />
        <GridToolbarExport /> */}
      </GridToolbarContainer>
    );
  }
  return (
    <>
      <DataGrid
        onRowClick={rowClicked}
        rows={rows}
        columns={columns}
        checkboxSelection
        disableRowSelectionOnClick
        rowSelectionModel={rows.filter((x) => selectedUnitIds.includes(x.id)).map((x) => x.id)}
        onRowSelectionModelChange={(newSelection) => {
          setSelectedUnitIds(newSelection as string[]);
          var selected: UnitData = {};
          (newSelection as string[]).forEach((id) => {
            selected[id] = bgdata.filteredUnits[id];
          });
          bgdata.setSelectedUnits(selected);
        }}
        slots={{ toolbar: CustomToolbar }}
        slotProps={{
          toolbar: {
            showQuickFilter: true,
          },
        }}
      />
      <CommandsDialog
        open={openCommadsDialog}
        onClose={() => {
          setOpenCommandsDialog(false);
        }}
        units={selectedUnitIds.map((id) => bgdata.filteredUnits[id])}
        bgdata={bgdata}
      />
    </>
  );
};

export default UnitGrid;
