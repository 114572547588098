import * as React from "react";
import {
  createBrowserRouter,
  Navigate,
  RouterProvider,
} from "react-router-dom";
import RootLayout from "./RootLayout";
import UnitList from "./UnitList";
import UnitLayout from "./Unit/UnitLayout";
import UnitControls from "./Unit/UnitControls";
import UnitSettings from "./Unit/UnitSettings";
import UnitSensors from "./Unit/UnitSensors";
import UnitPrograms from "./Unit/UnitPrograms";
import MapPage from "./Map";
import SensorGraph from "./Unit/UnitSensors/SensorGraph";
import ActivityPage from "../components/Activity";
import AccountPage from "./Account";
import GroupsPage from "./Groups";

const router = createBrowserRouter([
  {
    path: "/",
    element: <RootLayout />,
    children: [
      {
        index: true,
        element: <Navigate to="/units" replace />,
      },
      {
        path: "index.html",
        element: <Navigate to="/units" replace />,
      },
      {
        path: "units",
        element: <UnitList />,
      },
      {
        path: "map",
        element: <MapPage />,
      },
      {
        path: "account",
        element: <AccountPage />,
      },
      {
        path: "groups",
        element: <GroupsPage />,
      },
      {
        path: "activity",
        element: <ActivityPage />,
      },
      {
        path: "units/:unitId",
        element: <UnitLayout />,
        children: [
          {
            index: true,
            element: <Navigate to="controls" replace />,
          },
          {
            path: "controls",
            element: <UnitControls />,
          },
          {
            path: "settings",
            element: <UnitSettings />,
          },
          {
            path: "sensors",
            element: <UnitSensors />,      
          },
          {
            path: "sensors/:sensorId",
            element: <SensorGraph />,     
            children: [
              {
                index: true,
                element: <Navigate to="info" replace />,
              },
            ]    
          },
          {
            path: "programs",
            element: <UnitPrograms />,
          },
        ],
      },
    ],
  },
]);

const Router = () => {
  return <RouterProvider router={router} />;
};

export default Router;
