import * as React from "react";
import Box from "@mui/material/Box";
import TopNav from "../../components/Navigation/TopNav";
import { IconButton, Typography } from "@mui/material";
import QueueActivityTable from "./QueueActivityTable";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router-dom";

const ActivityPage: React.FC = () => {
  const navigate = useNavigate();

  return (
    <Box
      sx={{
        height: "100vh",
        overflow: "hidden",
        position: "relative",
      }}
    >
      <TopNav />
      <Box
        sx={{
          height: {
            xs: "calc(100% - 56px)",
            sm: "calc(100% - 64px)",
          },
          mt: {
            xs: "56px",
            sm: "64px",
          },
        }}
      >
        <IconButton onClick={() => navigate(-1)} sx={{ position: "absolute" }}>
          <ArrowBackIcon />
        </IconButton>
        <Typography textAlign={"center"} variant="h6">
          Activity
        </Typography>
        <QueueActivityTable />
      </Box>
    </Box>
  );
};

export default ActivityPage;
