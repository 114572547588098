import * as React from "react";
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import IUnitData, { IProgram } from "../../context/BackgroundDataCtx/IUnitData";
import { useContext, useState, useRef, useMemo, useEffect } from "react";
import { Alert, Button, Snackbar, Stack, Tab, useMediaQuery, useTheme } from "@mui/material";
import BackgroundDataCtx from "../../context/BackgroundDataCtx/BackgroundDataCtx";
import IBackgroundData from "../../context/BackgroundDataCtx/IBackgroundData";
import ExtendedUnitDataCtx from "../../context/ExtendedUnitDataCtx/ExtendedUnitDataCtx";
import Map, { MapDrawingMode } from "../Map";
import MapInteractionCtx from "../../context/MapInteractionCtx/MapInteractionCtx";
import { MapInteractionCallback } from "../../context/MapInteractionCtx/IMapWithInteraction";
import { validateProgramInput } from "../../helpers/programUtils";
import { ICapabilites } from "../../context/ExtendedUnitDataCtx/IExtendedUnitData";
import ProgramEditorWrapper from "./ProgramEditorWrapper";
import FinalizeEditor from "./FinalizeEditor";
import {
  calculateClosestPointOnCircle,
  calculateColsestPointOnLine,
  getAngleFromPoint,
  getDistanceBetweenPoints,
} from "../Map/MapHelper";
import mapboxgl from "mapbox-gl";
import LocationDataCtx from "../../context/LocationDataCtx/LocationDataCtx";

type ProgramTypes = "endGunSectors" | "auxSectors" | "barricade" | "vriSectors" | "scheduledActions" | "finalize";

interface Tab {
  type: ProgramTypes;
  label: string;
  progCapabilitesKey?: keyof ICapabilites["programs"];
}
const tabs: Tab[] = [
  { type: "endGunSectors", label: "Endgun", progCapabilitesKey: "eg" },
  { type: "auxSectors", label: "Auxiliary", progCapabilitesKey: "aux" },
  { type: "barricade", label: "Barricade", progCapabilitesKey: "barricade" },
  { type: "vriSectors", label: "Sector", progCapabilitesKey: "sector" },
  {
    type: "scheduledActions",
    label: "Actions",
    progCapabilitesKey: "scheduledActions",
  },
  { type: "finalize", label: "Finalize" },
];

interface Tabs {
  label: string;
  id: keyof ICapabilites["programs"];
}

interface Props {
  programToEdit?: IProgram;
  unitData: IUnitData;
  handleSaveProgram: (program: IProgram) => void;
  closeEditor: () => void;
  editExistingProgram: boolean;
}
const CreateProgramEditor = ({
  closeEditor,
  unitData,
  programToEdit,
  handleSaveProgram,
  editExistingProgram,
}: Props) => {
  const bgdata = useContext(BackgroundDataCtx);
  const { capabilities } = useContext(ExtendedUnitDataCtx);
  const [mapInEditMode, setMapEditModeStatus] = useState<MapDrawingMode>(MapDrawingMode.Disabled);
  const mapEditCallback = useRef<MapInteractionCallback>();
  const [error, setError] = useState<string | undefined>(undefined);
  const locationData = useContext(LocationDataCtx);
  const segmentDrawingInProgress = useRef<boolean>(false);
  const [program, setProgram] = useState<IProgram>(
    programToEdit
      ? programToEdit
      : {
          name: "",
          endGunSectors: [],
          auxSectors: [],
          vriSectors: [],
          scheduledActions: [],
        }
  );
  const programRef = useRef<IProgram>(program);

  useEffect(() => {
    programRef.current = program;
  }, [program]);

  const theme = useTheme();
  const isSMScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const filteredTabs = tabs.filter((tab) => {
    return tab.type === "finalize" || capabilities.programs[tab.progCapabilitesKey!];
  });
  const [activeTab, setActiveTab] = useState<ProgramTypes>(filteredTabs[0].type);
  const updateInputOnLocationChange = () => {
    if (locationData.coords) {
      var newCoord =
        unitData.systemType === "Pivot"
          ? calculateClosestPointOnCircle([locationData.coords!.longitude, locationData.coords!.latitude], unitData)
          : calculateColsestPointOnLine([locationData.coords!.longitude, locationData.coords!.latitude], unitData);

      mapEditCallback.current!(
        newCoord,
        [locationData.coords!.longitude, locationData.coords!.latitude],
        unitData.systemType === "Pivot"
          ? getAngleFromPoint(newCoord, unitData)
          : getDistanceBetweenPoints(newCoord, [unitData.longitude, unitData.latitude])
      );
    }
  };

  useEffect(() => {
    //when location data has changed and we are in Nudge mode we redraw the point closest to user's location on unit
    if (mapInEditMode === MapDrawingMode.ContinuousUserLocation) {
      updateInputOnLocationChange();
    }
  }, [locationData]);

  const isValid = <T extends keyof IProgram>(programType: T, settings: IProgram[T]): boolean => {
    const { isValid, error } = validateProgramInput(settings, programType, unitData);
    if (!isValid) {
      setError(error);
      return false;
    } else {
      setError(undefined);
      return true;
    }
  };

  const validateName = () => {
    if (!program.name || program.name.trim() === "") {
      setError("Program name cannot be empty");
      return false;
    } else if (program.name.length < 2) {
      setError("Program name must be at least 2 char");
      return false;
    }
    const programNames = unitData?.programs?.map((p) => p.name) ?? [];
    if (editExistingProgram) {
      const index = programNames.indexOf(programToEdit?.name ?? "");
      if (index > -1) programNames.splice(index, 1);
    }
    if (programNames.includes(program.name)) {
      setError("Program name must be unique");
      return false;
    }
    setError(undefined);
    return true;
  };

  const handleSave = () => {
    if (validateName()) {
      handleSaveProgram(program);
      closeEditor();
    }
  };

  const handleTabChange = (event: React.SyntheticEvent, newValue: ProgramTypes) => {
    if (segmentDrawingInProgress.current) segmentDrawingInProgress.current = false;
    if (activeTab === "finalize") {
      setActiveTab(newValue);
    } else if (isValid(activeTab, program[activeTab])) {
      setActiveTab(newValue);
    }
  };

  const beginDrawPoint = (callback: MapInteractionCallback) => {
    setMapEditModeStatus(MapDrawingMode.DrawClosest);
    mapEditCallback.current = callback;
  };

  const beginContinuousLocationDraw = (callback: MapInteractionCallback) => {
    setMapEditModeStatus(MapDrawingMode.ContinuousUserLocation);
    mapEditCallback.current = callback;
    updateInputOnLocationChange(); //to init input with current location
  };

  const editingBgData: IBackgroundData = {
    ...bgdata,
    units: {
      ...bgdata.units,
      [unitData.id]: {
        ...bgdata.units[unitData.id],
        runningProgram: program,
      },
    },
    filteredUnits: {
      ...bgdata.filteredUnits,
      [unitData.id]: {
        ...bgdata.units[unitData.id],
        runningProgram: program,
      },
    },
  };

  const handleMapMouseAndTouchEvents = (ev: mapboxgl.MapMouseEvent | mapboxgl.MapTouchEvent) => {
    if (mapInEditMode === MapDrawingMode.Disabled && activeTab !== "finalize" && activeTab !== "scheduledActions") {
      const angleOrDistance =
        unitData.systemType === "Pivot"
          ? getAngleFromPoint([ev.lngLat.lng, ev.lngLat.lat], unitData)
          : getDistanceBetweenPoints([ev.lngLat.lng, ev.lngLat.lat], [unitData.longitude, unitData.latitude]);
          const updatedProgram = { ...programRef.current };
      switch (
        ev.type as string //otherwise "touchmove" is not recognized on this side as a valid option
      ) {
        case "mousedown":
        case "touchstart":
          if (activeTab === "barricade") {
            if (!updatedProgram.barricade) {
              updatedProgram.barricade = {
                forward: { position: angleOrDistance, autoRev: false },
                reverse: { position: NaN, autoRev: false },
              };
            } else {
              updatedProgram.barricade.reverse.position = angleOrDistance;
            }
            setProgram(updatedProgram);
          } else if (!segmentDrawingInProgress.current) {
            if (
              updatedProgram[activeTab] &&
              updatedProgram[activeTab][updatedProgram[activeTab].length - 1] &&
              isNaN(updatedProgram[activeTab][updatedProgram[activeTab].length - 1].begin) &&
              isNaN(updatedProgram[activeTab][updatedProgram[activeTab].length - 1].end)
            ) {
              //Add button clicked, so map interaction should fill up the empty segment instead of adding a new
              updatedProgram[activeTab][updatedProgram[activeTab].length - 1].begin = angleOrDistance;
            } else {
              if (activeTab === "vriSectors") {
                updatedProgram[activeTab]!.push({
                  begin: angleOrDistance,
                  end: NaN,
                  forward: { chem: 0, pump: 0, speedpc: null },
                  reverse: { chem: 0, pump: 0, speedpc: null },
                });
              } else {
                updatedProgram[activeTab]!.push({ begin: angleOrDistance, end: NaN });
              }
            }
            segmentDrawingInProgress.current = true;
            setProgram(updatedProgram);
          }
          break;
        case "mousemove":
        case "touchmove":
          if (
            segmentDrawingInProgress.current &&
            activeTab !== "barricade" &&
            updatedProgram[activeTab][updatedProgram[activeTab]!.length - 1]
          ) {
            updatedProgram[activeTab][updatedProgram[activeTab]!.length - 1].end = angleOrDistance;
            setProgram(updatedProgram);
          }
          break;
        case "mouseup":
        case "touchend":
          if (
            segmentDrawingInProgress.current &&
            activeTab !== "barricade" &&
            program[activeTab]![program[activeTab]!.length - 1] &&
            !isNaN(program[activeTab]![program[activeTab]!.length - 1].end)
          ) {
            updatedProgram[activeTab]![updatedProgram[activeTab]!.length - 1].end = angleOrDistance;
            if (activeTab === "vriSectors") {
              updatedProgram[activeTab]!.push({
                begin: angleOrDistance,
                end: NaN,
                forward: { chem: 0, pump: 0, speedpc: null },
                reverse: { chem: 0, pump: 0, speedpc: null },
              });
            } else {
              segmentDrawingInProgress.current = false;
            }
            setProgram(updatedProgram);
          }
          break;
      }
    }
  };

  return (
    <BackgroundDataCtx.Provider value={editingBgData}>
      <Stack
        direction={{ xs: "column", lg: "row" }}
        alignItems="stretch"
        sx={{
          position: "relative",
          height: "100%",
          width: "100%",
        }}
      >
        <Box sx={{ flexGrow: 1 }}>
          <Map
            unitId={unitData.id}
            interactiveMode={mapInEditMode}
            onExitInteractiveMode={(calcPos, realPos, angle) => {
              setMapEditModeStatus(MapDrawingMode.Disabled);
              mapEditCallback.current!(calcPos, realPos, angle);
            }}
            onMouseEvent={(ev) => handleMapMouseAndTouchEvents(ev)}
            onTouchEvent={(ev) => handleMapMouseAndTouchEvents(ev)}
          />
        </Box>
        <MapInteractionCtx.Provider
          value={{
            beginDrawPoint: beginDrawPoint,
            beginDrawContinuousLocation: beginContinuousLocationDraw,
          }}
        >
          <Stack
            sx={{
              width: {
                xs: "100%",
                lg: 600,
              },
              height: {
                xs: "50%",
                lg: "100%",
              },
              overflow: "auto",
              position: "relative",
            }}
            alignItems={"center"}
          >
            {mapInEditMode !== 0 && (
              <div
                style={{
                  height: "100%",
                  width: "100%",
                  backgroundColor: "black",
                  opacity: 0.4,
                  position: "absolute",
                  top: 0,
                  left: 0,
                  zIndex: 3,
                }}
              />
            )}

            <Box sx={{ width: "100%", borderBottom: 1, borderColor: "divider" }}>
              <Tabs
                value={activeTab}
                onChange={handleTabChange}
                variant={isSMScreen ? "scrollable" : "standard"}
                centered={!isSMScreen}
                scrollButtons
                allowScrollButtonsMobile
              >
                {filteredTabs.map((tab, index) => (
                  <Tab key={index} label={tab.label} value={tab.type} />
                ))}
              </Tabs>
            </Box>
            <Stack
              alignItems="stretch"
              justifyContent={"stretch"}
              sx={{ p: { xs: 1, sm: 2 }, width: "100%", maxWidth: 600 }}
            >
              {filteredTabs.map(
                (tab, index) =>
                  activeTab === tab.type && (
                    <div key={index}>
                      {tab.type !== "finalize" ? (
                        <ProgramEditorWrapper
                          label={tab.label}
                          settings={program[tab.type]}
                          programType={tab.type}
                          onReset={() => {
                            segmentDrawingInProgress.current = false;
                          }}
                          onChange={(newSettings) => {
                            setProgram({ ...program, [tab.type]: newSettings });
                          }}
                          unit={unitData}
                        />
                      ) : (
                        <FinalizeEditor
                          name={program.name}
                          error={error}
                          onChange={(newName) => setProgram({ ...program, name: newName })}
                          handleSave={handleSave}
                        />
                      )}
                    </div>
                  )
              )}
            </Stack>
          </Stack>
          {segmentDrawingInProgress.current && activeTab === "vriSectors" && (
            <div
              style={{
                position: "absolute",
                bottom: 30,
                left: 30,
                zIndex: 3,
              }}
            >
              <Button
                variant="contained"
                onClick={() => {
                  var updatedProgram = { ...program };
                  updatedProgram[activeTab].splice(-1, 1);
                  setProgram(updatedProgram);
                  segmentDrawingInProgress.current = false;
                }}
              >
                DONE
              </Button>
            </div>
          )}
          <Snackbar
            open={error !== undefined}
            autoHideDuration={5000}
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            sx={{ mb: 7 }}
            onClose={() => setError(undefined)}
          >
            <Alert severity="error" variant="filled">
              {error}
            </Alert>
          </Snackbar>
        </MapInteractionCtx.Provider>
      </Stack>
    </BackgroundDataCtx.Provider>
  );
};

export default CreateProgramEditor;
