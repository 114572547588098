import React from "react";
import {
  Card,
  Stack,
  Box,
  Typography,
  IconButton,
  Divider,
  Menu,
  MenuItem,
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "@mui/material";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import DownloadIcon from "@mui/icons-material/Download";
import dayjs, { Dayjs } from "dayjs";
import EditIcon from "@mui/icons-material/Edit";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { IEvent } from "./createEvent";
import EventInfoTable from "./EventInfoTable";
import shpwrite from "@mapbox/shp-write";
import { saveAs } from "file-saver";

interface Props {
  event: IEvent;
  editEvent(event: IEvent): void;
  deleteEvent(event: IEvent): void;
}
const EventCard: React.FC<Props> = ({
  event,
  editEvent,
  deleteEvent,
}: Props) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const openMenu = Boolean(anchorEl);

  const handleMenuClick = (event: React.MouseEvent<HTMLButtonElement>) =>
    setAnchorEl(event.currentTarget);

  const closeMenu = () => setAnchorEl(null);

  const handleDownload = async () => {
    const geojson = event.eventFeatures;
    if (geojson) {
      try {
        const zip = await shpwrite.zip(geojson, {
          outputType: "blob",
          compression: "DEFLATE",
        });
        saveAs(zip, `${event.eventName}-shapefile.zip`);
      } catch (error) {
        console.error("Error converting or downloading shapefile:", error);
      }
    }
  };

  return (
    <Card>
      <Stack direction="row" alignItems={"center"} spacing={2} sx={{ p: 2 }}>
        <Box sx={{ flexGrow: 1 }}>
          <Typography variant="h6">{event.eventName}</Typography>
          <Typography variant="body1">
            {dayjs(event.eventStartTimeMs).format("MMM D, YYYY h:mm A")} -{" "}
            {dayjs(event.eventEndTimeMs).format("MMM D, YYYY h:mm A")}
          </Typography>
        </Box>
        <IconButton onClick={handleMenuClick}>
          <MoreHorizIcon />
        </IconButton>
      </Stack>
      <Divider />
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="body1">Details</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Stack
            direction="row"
            alignItems={"center"}
            spacing={2}
            sx={{ p: 2 }}
          >
            <EventInfoTable event={event} />
          </Stack>
        </AccordionDetails>
      </Accordion>
      <Menu
        anchorEl={anchorEl}
        open={openMenu}
        onClose={closeMenu}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem
          onClick={() => {
            editEvent(event);
            closeMenu();
          }}
        >
          <EditIcon sx={{ mr: 2 }} />
          Edit
        </MenuItem>
        <MenuItem
          onClick={() => {
            deleteEvent(event);
            closeMenu();
          }}
        >
          <DeleteForeverIcon sx={{ mr: 2 }} />
          Delete
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleDownload();
            closeMenu();
          }}
        >
          <DownloadIcon sx={{ mr: 2 }} />
          Download
        </MenuItem>
      </Menu>
    </Card>
  );
};

export default EventCard;
